// create slice for app
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ISpecialOrderSlice {
    specialOrderSchoolId: number,
    paymentGateway: any,
    specialOrderSchoolName: string,
    specialOrderProgramId: number,
    specialOrderProgramName: string,
    specialOrderIdValue: number,
    specialOrderStudentIdValue: number,
    specialOrderMajorIdValue: number,
    specialOrderMajorName: number,
    specialOrderStudentNumberValue: number,
    specialOrderMajorId: number,

    editPricingRuleName: string,
    editPricingRuleAmount: string | number,
    editPricingRuleId: string | number,
    editPricingRuleSchoolId: string | number,
    editPricingRuleSchoolName: string | number,
    editPricingRuleEffectiveDateFrom: string | number | undefined | any,
    editPricingRuleEffectiveDateTo: string | number | undefined | any,

    so: {
        specialOrderId: any,
        studentId: number,
        schoolId: number,
        programId: number,
        name: any
    },
    queryKey: any;
    sos: any[],
    requirements: any,
    specialOrderIsOnApplyAll: boolean,
    paramsStatus: any,
    selectAllButton: any,
    rowsSelected: any,
    confirmationDialogTitle: string
    confirmationDialogTitleSelected: string
    viewStateStudentIdTranscriptOfRecord: string
}

const initialState: ISpecialOrderSlice = {
    specialOrderSchoolId: 0,
    paymentGateway: "cashier",
    specialOrderSchoolName: "",
    specialOrderProgramId: 0,
    specialOrderProgramName: "",
    specialOrderIdValue: 0,
    specialOrderMajorIdValue: 0,
    specialOrderMajorName: 0,
    specialOrderMajorId: 0,
    specialOrderStudentIdValue: 0,
    specialOrderStudentNumberValue: 0,
    editPricingRuleName: "",
    editPricingRuleAmount: "",
    editPricingRuleEffectiveDateFrom: "",
    editPricingRuleEffectiveDateTo: "",
    editPricingRuleId: "",
    editPricingRuleSchoolId: "",
    editPricingRuleSchoolName: "",
    queryKey: "",

    so: {
        specialOrderId: 0,
        studentId: 0,
        schoolId: 0,
        programId: 0,
        name: ""
    },
    specialOrderIsOnApplyAll: false,
    paramsStatus: null,
    sos: [],
    rowsSelected: [],
    selectAllButton: () => {
    },
    viewStateStudentIdTranscriptOfRecord: "",
    confirmationDialogTitle: "",
    confirmationDialogTitleSelected: "",
    requirements: {
        files: [] as any
    } as any
};

const appSlice = createSlice({
    name: "specialOrder",
    initialState,
    reducers: {
        setQueryKey: (state, action: PayloadAction<any>) => {
            state.queryKey = action.payload;
        },
        selectedViewSO: (state, action: PayloadAction<any>) => {
            state.specialOrderSchoolId = action.payload?.schoolId ?? 0;
            state.specialOrderSchoolName = action.payload?.schoolName ?? 0;
            state.specialOrderProgramId = action.payload?.programId ?? 0;
            state.specialOrderProgramName = action.payload?.programName ?? 0;
            state.specialOrderMajorName = action.payload?.majorName ?? 0;
            state.specialOrderMajorIdValue = action.payload?.majorId ?? 0;
            state.specialOrderIdValue = action.payload?.id ?? 0;
            return state;
        },
        setEditPricingRuleValue: (state, action: PayloadAction<any>) => {
            state.editPricingRuleName = action.payload?.name
            state.editPricingRuleAmount = action.payload?.amount
            state.editPricingRuleEffectiveDateFrom = action.payload?.effectiveDateFrom
            state.editPricingRuleId = action.payload?.id
            state.editPricingRuleSchoolId = action.payload?.schoolId
            state.editPricingRuleSchoolName = action.payload?.schoolName
            return state;
        },
        setUploadRequirement: (state, action: PayloadAction<any>) => {

            state.specialOrderMajorId = action.payload?.majorId ?? 0;
            state.specialOrderSchoolId = action.payload?.schoolId ?? 0;
            state.specialOrderProgramId = action.payload?.programId ?? 0;
            state.specialOrderStudentIdValue = action.payload?.studentId ?? 0;
            state.specialOrderIdValue = action.payload?.specialOrderId ?? 0;
            state.specialOrderStudentNumberValue = action.payload?.studentNumber ?? 0;
            return state;
        },
        setPaymentGateway: (state, action: PayloadAction<any>) => {
            state.paymentGateway = action.payload
            return state;
        }, setRowsSelected: (state, action: PayloadAction<any>) => {
            state.rowsSelected = action.payload
            return state;
        },
        setFiles: (state, action: PayloadAction<any>) => {

            state.requirements.files = action.payload ?? [];
            return state;
        },


        isOnApplyAll: (state, action: PayloadAction<any>) => {
            state.specialOrderIsOnApplyAll = action.payload;
            return state;
        },
        updateSo: (state, action: PayloadAction<any>) => {
            state.so = action.payload;
            return state;
        },
        updatesSo: (state, action: PayloadAction<any>) => {
            state.sos = [...action.payload];
            return state;
        }, updatesSoStatus: (state, action: PayloadAction<any>) => {
            let ___sos = [...state.sos]?.map(elem => {
                return Object.assign({}, elem,);
            });


            var sosIndex = ___sos.findIndex(c => c.studentId == action.payload)
            ___sos[sosIndex].completed = true
            state.sos = ___sos
            return state;
        },
        getParamsStatus: (state, action: PayloadAction<any>) => {
            state.paramsStatus = action.payload;
            return state;
        },
        selectAllButton: (state, action: PayloadAction<any>) => {
            state.selectAllButton = action.payload;
            return state;
        },
        addConfirmationDialogTitle: (state, action: PayloadAction<any>) => {
            state.confirmationDialogTitle = action.payload;
            return state;
        },

        viewStudentIdTranscriptOfRecord: (state, action: PayloadAction<any>) => {
            state.viewStateStudentIdTranscriptOfRecord = action.payload;
            return state;
        },

        addConfirmationDialogTitleSelected: (state, action: PayloadAction<any>) => {
            state.confirmationDialogTitleSelected = action.payload;
            return state;
        },
    },
});

export const {
    setUploadRequirement,
    updatesSo,
    addConfirmationDialogTitle,
    getParamsStatus,
    setRowsSelected,
    viewStudentIdTranscriptOfRecord,
    selectedViewSO,
    isOnApplyAll,
    setFiles,
    updatesSoStatus,
    setEditPricingRuleValue,
    setQueryKey,
    setPaymentGateway
} = appSlice.actions;
export default appSlice.reducer;
