import React, { useCallback, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions
} from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import ChatIcon from "@mui/icons-material/Chat";
import GroupIcon from "@mui/icons-material/Group";
import { WorkspaceContentProps } from "./WorkspaceContent";
import { useAppSelector } from "../../redux/store";
import { Logout, PersonAdd } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import { useWorkspaceQuery } from "../../hooks/workspaces/use-get-workspaces";
import { useHistory } from "react-router-dom";
import { useCreateWorkspaceModal } from "../../store/use-create-workspace-modal";
import PreferenceModal from "./create-workspace-preference-modal";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { fetchChannelsByWorkspaceId, createChannel } from "../../requests/channel";
import {fetchMembersByWorkspaceId} from "../../requests/member.request";
import InviteMemberModal from "./create-workspace-join-code-modal";
import {Thread} from "./thread";
import {usePanel} from "./use-panel";
import {getAcademicCalendarId} from "../../requests/academicCalendar.request";
import {getMessage} from "../../requests/message.request";
import {GetMessageInputType} from "../../schemas/major.schema";
import Profile from "./Profile";

const drawerWidth = 460;



const  RightSidebar: React.FC<WorkspaceContentProps> = ({ workspaceId }) => {
    const { parentMessageId, onOpenMessage, onOpenProfile, profileMemberId, onClose } = usePanel();


    return (
        <Box
            sx={{
                height: '100%',
                width: drawerWidth,
                backgroundColor: "white",
                overflowY: "auto",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
        >
            {parentMessageId ?
                <Thread onClose={onClose}  messageId={parentMessageId}></Thread> : (profileMemberId ? <Profile memberId={profileMemberId} onClose={onClose}></Profile> : null)
            }


        </Box>
    );
};

export default RightSidebar;
