import {AxiosRequestConfig} from "axios";
import {getCourses} from "./../requests/course.request";
import {useQuery} from "react-query";
import {useCallback} from "react";
import {GetCoursesResponseType} from "../types";
import flat from "flat";

export const useCourseQuery = (params: AxiosRequestConfig["params"]) => {
  return useQuery(["courses", params?.search, params?.schoolId, params?.curriculumId, params?.pageNumber, params?.page, params?.pageSize], () => getCourses(params), {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      select: useCallback((data: GetCoursesResponseType) => {
          return {
              ...data,
              data: data.data?.map((course) => ({
                  ...course,
                  ...(!params.flatten
                      ? {}
                      : {
                          ...(flat({...course}, {safe: true}) as object),
                      }),
        })),
      };
    }, []),
  });
};
