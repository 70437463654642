import {Dictionary} from "@reduxjs/toolkit";

export function a11yProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
type Edge = [string, string, number];

export function removeCycles(data: Edge[][]): Edge[][] {
  const nodes: { [key: string]: { to: string; weight: number }[] } = {};
  const edges = data.slice(1); // This assumes the first row is the header
  const safeEdges: Edge[] = [];

  // Organize edges by their source node
  (edges as any[]).forEach(([from, to, weight]) => {
    if (!nodes[from]) nodes[from] = [];
    nodes[from].push({ to, weight });
  });

  function hasCycle(v: string, visited: { [key: string]: boolean }, recStack: { [key: string]: boolean }, path: string[]): boolean {
    if (!visited[v]) {
      visited[v] = true;
      recStack[v] = true;
      path.push(v);

      const nodeNeighbors = nodes[v] || [];
      for (let { to: neighbor } of nodeNeighbors) {
        if (!visited[neighbor] && hasCycle(neighbor, visited, recStack, path)) {
          return true;
        } else if (recStack[neighbor]) {
          path.push(neighbor);
          return true;
        }
      }
    }
    recStack[v] = false;
    return false;
  }

  Object.keys(nodes).forEach((node) => {
    const visited: { [key: string]: boolean } = {};
    const recStack: { [key: string]: boolean } = {};
    const path: string[] = [];

    if (hasCycle(node, visited, recStack, path)) {
      const cycleStart = path.pop()!;
      const cycleEnd = path.pop()!;

      // Find and remove the edge that completes the cycle
      const index = (edges as any[]).findIndex(([from, to]) => from === cycleEnd && to === cycleStart);
      if (index > -1) {
        edges.splice(index, 1);
      }
    } else {
      // If no cycle was detected from this node, add all outgoing edges from this node
      nodes[node].forEach(({ to, weight }) => {
        safeEdges.push([node, to, weight]);
      });
    }
  });

  return [["From", "To", "Weight"], ...safeEdges] as any[];
}
export function findIndexByProperty(data: any, key: any, value: any) {
  for (var i = 0; i < data?.length; i++) {
    if (data?.[i]?.[key] == value) {
      return i;
    }
  }
  return -1;
}

export function onDownloadDocument(doc: string, downloadName: string) {
  var link = document.createElement('a');
  link.href = doc;
  link.setAttribute("download", `${downloadName}.xlsx`);
  link.dispatchEvent(new MouseEvent('click'));
  link?.remove();
}


export const provinces = [
  {"name": "Metro Manila", "region": "NCR", "key": "MM"},

  {"name": "Abra", "region": "CAR", "key": "ABR"},
  {"name": "Apayao", "region": "CAR", "key": "APA"},
  {"name": "Benguet", "region": "CAR", "key": "BEN"},
  {"name": "Ifugao", "region": "CAR", "key": "IFU"},
  {"name": "Kalinga", "region": "CAR", "key": "KAL"},
  {"name": "Mountain Province", "region": "CAR", "key": "MOU"},

  {"name": "Ilocos Norte", "region": "I", "key": "ILN"},
  {"name": "Ilocos Sur", "region": "I", "key": "ILS"},
  {"name": "La Union", "region": "I", "key": "LUN"},
  {"name": "Pangasinan", "region": "I", "key": "PAN"},

  {"name": "Batanes", "region": "II", "key": "BTN"},
  {"name": "Cagayan", "region": "II", "key": "CAG"},
  {"name": "Isabela", "region": "II", "key": "ISA"},
  {"name": "Nueva Vizcaya", "region": "II", "key": "NUV"},
  {"name": "Quirino", "region": "II", "key": "QUI"},

  {"name": "Aurora", "region": "III", "key": "AUR"},
  {"name": "Bataan", "region": "III", "key": "BAN"},
  {"name": "Bulacan", "region": "III", "key": "BUL"},
  {"name": "Nueva Ecija", "region": "III", "key": "NUE"},
  {"name": "Pampanga", "region": "III", "key": "PAM"},
  {"name": "Tarlac", "region": "III", "key": "TAR"},
  {"name": "Zambales", "region": "III", "key": "ZMB"},

  {"name": "Batangas", "region": "IV-A", "key": "BTG"},
  {"name": "Cavite", "region": "IV-A", "key": "CAV"},
  {"name": "Laguna", "region": "IV-A", "key": "LAG"},
  {"name": "Quezon", "region": "IV-A", "key": "QUE"},
  {"name": "Rizal", "region": "IV-A", "key": "RIZ"},

  {"name": "Marinduque", "region": "IV-B", "key": "MAD"},
  {"name": "Occidental Mindoro", "region": "IV-B", "key": "MDC"},
  {"name": "Oriental Mindoro", "region": "IV-B", "key": "MDR"},
  {"name": "Palawan", "region": "IV-B", "key": "PLW"},
  {"name": "Romblon", "region": "IV-B", "key": "ROM"},

  {"name": "Albay", "region": "V", "key": "ALB"},
  {"name": "Camarines Norte", "region": "V", "key": "CAN"},
  {"name": "Camarines Sur", "region": "V", "key": "CAS"},
  {"name": "Catanduanes", "region": "V", "key": "CAT"},
  {"name": "Masbate", "region": "V", "key": "MAS"},
  {"name": "Sorsogon", "region": "V", "key": "SOR"},

  {"name": "Aklan", "region": "VI", "key": "AKL"},
  {"name": "Antique", "region": "VI", "key": "ANT"},
  {"name": "Capiz", "region": "VI", "key": "CAP"},
  {"name": "Guimaras", "region": "VI", "key": "GUI"},
  {"name": "Iloilo", "region": "VI", "key": "ILI"},
  {"name": "Negros Occidental", "region": "VI", "key": "NEC"},

  {"name": "Bohol", "region": "VII", "key": "BOH"},
  {"name": "Cebu", "region": "VII", "key": "CEB"},
  {"name": "Negros Oriental", "region": "VII", "key": "NER"},
  {"name": "Siquijor", "region": "VII", "key": "SIG"},

  {"name": "Biliran", "region": "VIII", "key": "BIL"},
  {"name": "Eastern Samar", "region": "VIII", "key": "EAS"},
  {"name": "Leyte", "region": "VIII", "key": "LEY"},
  {"name": "Northern Samar", "region": "VIII", "key": "NSA"},
  {"name": "Samar", "region": "VIII", "key": "WSA"},
  {"name": "Southern Leyte", "region": "VIII", "key": "SLE"},

  {"name": "Zamboanga del Norte", "region": "IX", "key": "ZAN"},
  {"name": "Zamboanga del Sur", "region": "IX", "key": "ZAS"},
  {"name": "Zamboanga Sibugay", "region": "IX", "key": "ZSI"},

  {"name": "Bukidnon", "region": "X", "key": "BUK"},
  {"name": "Camiguin", "region": "X", "key": "CAM"},
  {"name": "Lanao del Norte", "region": "X", "key": "LAN"},
  {"name": "Misamis Occidental", "region": "X", "key": "MSC"},
  {"name": "Misamis Oriental", "region": "X", "key": "MSR"},

  {"name": "Compostela Valley", "region": "XI", "key": "COM"},
  {"name": "Davao del Norte", "region": "XI", "key": "DAV"},
  {"name": "Davao del Sur", "region": "XI", "key": "DAS"},
  {"name": "Davao Occidental", "region": "XI", "key": "DAC"},
  {"name": "Davao Oriental", "region": "XI", "key": "DAO"},

  {"name": "Cotabato", "region": "XII", "key": "NCO"},
  {"name": "Sarangani", "region": "XII", "key": "SAR"},
  {"name": "South Cotabato", "region": "XII", "key": "SCO"},
  {"name": "Sultan Kudarat", "region": "XII", "key": "SUK"},

  {"name": "Agusan del Norte", "region": "XIII", "key": "AGN"},
  {"name": "Agusan del Sur", "region": "XIII", "key": "AGS"},
  {"name": "Dinagat Islands", "region": "XIII", "key": "DIN"},
  {"name": "Surigao del Norte", "region": "XIII", "key": "SUN"},
  {"name": "Surigao del Sur", "region": "XIII", "key": "SUR"},

  {"name": "Basilan", "region": "ARMM", "key": "BAS"},
  {"name": "Lanao del Sur", "region": "ARMM", "key": "LAS"},
  {"name": "Maguindanao", "region": "ARMM", "key": "MAG"},
  {"name": "Sulu", "region": "ARMM", "key": "SLU"},
  {"name": "Tawi-tawi", "region": "ARMM", "key": "TAW"}
]


export function getDownload(response: BlobPart, downloadName: string) {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${downloadName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
export function setErrorSnackbar(error: any, setSnackbarStore?: any) {
  var errors = Object?.keys(
      error?.response?.data?.errors
          ? error?.response?.data?.errors
          : error?.response?.data?.error
              ? error?.response?.data?.error
              : {}
  );
  var errs = "";

  if (errors?.length > 0) {


    for (const property in errors) {
      console.log(error?.response?.data?.errors?.[errors?.[property]]?.[0]  )
      var _err = error?.response?.data?.errors?.[parseInt(errors?.[property])]
          ? error?.response?.data?.errors?.[parseInt(errors?.[property])]?.description
          : error?.response?.data?.error?.[parseInt(errors?.[property])]?.description
              ? error?.response?.data?.error?.[parseInt(errors?.[property])]?.description
              : error?.response?.data?.errors?.[errors?.[property]]  ? error?.response?.data?.errors?.[errors?.[property]]?.[0]
                  :"Something went wrong!";

      console.log(_err)

      errs = _err + "\n";
    }
    setSnackbarStore({
      message: errs,
      severity: "error",
      open: true,
    });
  } else {
    const errorMsg =
        error?.response?.data?.message ||
        error.message || error ||
        "Something went wrong please try again";
    setSnackbarStore({
      message: errorMsg,
      severity: "error",
      open: true,
    });
  }
}
export function pluralize(count: number, singularWord:string, pluralWord = singularWord + 's') {
  return count === 1 ? singularWord : pluralWord;
}
export function setErrorPRSnackbar(error: any, setSnackbarStore?: any) {
  var errors = Object?.keys(
      error?.response?.data?.errors
          ? error?.response?.data?.errors
          : error?.response?.data?.error
              ? error?.response?.data?.error
              : [error]
  );

  console.log(error)

  var errs = "";




  if (errors?.length > 0 ) {
    for (const property in errors) {
      var _err = error?.response?.data?.errors?.[errors?.[property]]
          ? error?.response?.data?.errors?.[errors?.[property]]
          : error?.response?.data?.error?.[errors?.[property]]?.code
              ? error?.response?.data?.error?.[errors?.[property]]?.code
              : "";

      errs = _err + "\n";
    }
    setSnackbarStore({
      message: errs,
      severity: "error",
      open: true,
    });
  } else {
    const errorMsg =
        error?.response?.data?.message ||
        error.message || error ||
        "Something went wrong please try again";
    setSnackbarStore({
      message: errorMsg,
      severity: "error",
      open: true,
    });
  }
}

export function mergeArrays(arrays: any, prop: any) {
  const merged: any = {};

  arrays?.forEach((arr: any) => {
    arr?.forEach((item: any) => {
      merged[item[prop]] = Object.assign({}, merged[item[prop]], item);
    });
  });

  return Object.values(merged);
}
export function validateError(newCourses: Dictionary<{}>[]) {
  return newCourses.map(c => {
    c['error'] = false
    const filterAllCourseCode = newCourses.filter(nc => nc['courseCode'] == c['courseCode']);
    if (filterAllCourseCode.length > 1) {
      c['error'] = true
    }


    return c
  })
}


export function yearLevelFormat(yearLevel:string) {

  var firstYear =  [ "1st year",
    "1st",
    "first year",
    "first",
    "maiden Year",
    "year 1",
    "year one",
    "1st year of study",
    "inaugural year",
    "Freshman year",
    "1"]

  var secondYear = [
    "2nd year",
    "second",
    "2nd",
    "sophomore year",
    "year 2",
    "year two",
    "2nd year of study",
    "middle Year",
    "second year",
    "2"
  ]

  var thirdYear = [
    "third year",
    "third",
    "year three",
    "3rd",
    "junior year",
    "3rd year of study",
    "tertiary Year",
    "Trifecta Year",
    "3"
  ]

  var forthYear = [
    "forth year",
    "year fourth",
    "fourth",
    "4th year",
    "4th",
    "senior year",
    "4th year of study",
    "4"
  ]

  var fifth = [
    "fifth year",
    "year fifth",
    "5th year",
    "5th",
    "5th year of study",
    "5"
  ]

  if(firstYear?.indexOf(yearLevel.toLowerCase()) > -1){
    return "1st Year"
  }else if(secondYear?.indexOf(yearLevel.toLowerCase()) > -1){
    return "2nd Year"
  }else if(thirdYear?.indexOf(yearLevel.toLowerCase()) > -1){
    return "3rd Year"
  } else if (forthYear?.indexOf(yearLevel.toLowerCase()) > -1) {
    return "4th Year"
  } else if (fifth?.indexOf(yearLevel.toLowerCase()) > -1) {
    return "5th Year"
  } else {
    return yearLevel
  }

  return yearLevel;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}


export function stringAvatar(name: string = "") {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split?.(' ')?.[0]?.[0]}${name?.split?.(' ')?.[1]?.[0] ?? ""}`,
  };
}