//create slice for curriculum

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ICourseSlice {
    isCourse: boolean;
    editCoursesId?: number | undefined;
    editCurriculumId?: number | undefined;
    editAcademicId?: number | undefined;
    editCourseSchoolId?: number | undefined;
    editCourseSchoolName?: string | undefined;
}

const initialState: ICourseSlice = {
    isCourse: false,
    editAcademicId: 0,
    editCoursesId: 0,
    editCurriculumId: 0,
    editCourseSchoolId: 0,
    editCourseSchoolName: ""
};

const curriculumSlice = createSlice({
    name: "curriculum",
    initialState,
    reducers: {

        setEditCourse: (
            state,
            action: PayloadAction<{ editCoursesId: number, editCurriculumId: number }>
        ) => {
            state.editCoursesId = action.payload?.editCoursesId;
            state.editCurriculumId = action.payload?.editCurriculumId;
            return state;
        },
        editCourseSchoolId: (
            state,
            action: PayloadAction<number>
        ) => {
            state.editCourseSchoolId = action.payload;
            return state;
        },
        editCourseSchoolName: (
            state,
            action: PayloadAction<string>
        ) => {
            state.editCourseSchoolName = action.payload;
            return state;
        },
    },
});

export const {editCourseSchoolName, editCourseSchoolId, setEditCourse} =
    curriculumSlice.actions;
export default curriculumSlice.reducer;
