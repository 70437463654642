import { Box, Typography } from "@mui/material";

export const Value = ({ value }: { value: string | undefined | null }) => {
    return (
        <div>
            <Typography
                component="span"
                variant='body1'
                id="modal-modal-title"
                fontWeight="bold"
                sx={{
                    borderBottom: 2,
                    borderColor: "grey.400",
                }}
            >
                {value}
            </Typography>
        </div>
    )
};
export const Label = ({ label }: { label: string }) => {
    return (

        <Typography
            variant='overline'
            id="modal-modal-title"
        >
            {label}
        </Typography>
    )
}

export const FormSheetField = ({ label, value }: { label: string, value: string | undefined | null }) => {
    return (
        <Box>
            <Value value={value} />
            <Label label={label} />
            <br />
        </Box>
    )
}