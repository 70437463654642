import {AxiosRequestConfig} from "axios";
import {getCourse} from "./../requests/course.request";
import {useQuery} from "react-query";

export const useCourseByIdQuery = (id: number | string | undefined, params: AxiosRequestConfig["params"]) => {
    return useQuery(["course", id, params?.curriculumCount, params?.search, params?.schoolId, params?.curriculumId, params?.pageNumber, params?.page, params?.pageSize], () => getCourse(id, params), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};
