import {userSchema} from "./user.schema.type";
import * as Yup from "yup";

const loginUserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  id: Yup.string().required("Id is required"),
  password: Yup.string().nullable(),
  region: Yup.object().shape({}).nullable(),
  regionId: Yup.number().nullable(),
  roles: Yup.string()
    .oneOf(["SuperAdmin", "Admin", "User"])
    .required("Roles is required"),
  school: Yup.object().shape({
    address: Yup.string().nullable(),
    code: Yup.string().nullable(),
    id: Yup.number().nullable(),
    status: Yup.string().nullable(),
    institutionalCode: Yup.string().nullable(),
    street: Yup.string().nullable(),
    institutionalFormOfOwnership: Yup.string().nullable(),
    institutionalTypeId: Yup.string().nullable(),
    municipalityCity: Yup.string().nullable(),
    fax: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
    institutionalHeadTelephone: Yup.string().nullable(),
    yearEstablished: Yup.string().nullable(),
    secRegistration: Yup.string().nullable(),
    yearConvertedtoCollegeStatus: Yup.string().nullable(),
    yearConvertedtoUniversityStatus: Yup.string().nullable(),
    nameofInstitutionalHead: Yup.string().nullable(),
    titleofHeadofInstitution: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    latitude: Yup.string().nullable(),
    highestEducationalAttainmentoftheHead: Yup.string().nullable(),
  }).nullable(),

  status: Yup.boolean().required("Status is required"),
  type: Yup.object().shape({
    createdBy: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    id: Yup.number().required("Id is required"),
    lastModifiedBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    name: Yup.string().required("Name is required"),
    uuid: Yup.string().nullable(),
  }),
  userName: Yup.string().required("User Name is required"),
  userProfile: Yup.object().shape({
    designation: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    middleName: Yup.string().nullable(),
    userId: Yup.string().nullable(),
  }),
});

export const loginResponseSchema = Yup.object({
  user: Yup.lazy(() => loginUserSchema),
  message: Yup.string().required("Message is required"),
  succeeded: Yup.boolean().required("Succeeded is required"),
  error: Yup.array(
    Yup.object({
      code: Yup.string().required("Code is required"),
      description: Yup.string().required("Description is required"),
    })
  ).nullable(),
  token: Yup.string().required("Token is required"),
});


export type LoginResponseType = Yup.InferType<typeof loginResponseSchema>;

export const registerSchema = Yup.object({
  user: userSchema.nullable(),
  message: Yup.string().required("Message is required"),
  succeeded: Yup.boolean().required("Succeeded is required"),
  error: Yup.array(
    Yup.object({
      code: Yup.string().required("Code is required"),
      description: Yup.string().required("Description is required"),
    })
  ).nullable(),
  token: Yup.string().nullable(),
});

export type RegisterType = Yup.InferType<typeof registerSchema>;
