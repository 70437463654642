import React, {FC, useCallback, useMemo} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Avatar, Box, Collapse, IconButton, Link, Typography,} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DownloadIcon from "@mui/icons-material/Download";
import {useAppSelector} from "../../redux/store";
import {setLoading} from "../../redux/features/app";
import {
  getSchoolsExportEFormA,
  getSchoolsExportEFormBC,
  getSchoolsExportPrcListOfGraduates,
} from "../../requests/school.request";
import {getDownload, setErrorSnackbar} from "../../helper/utils/helper";
import {useDispatch} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useAddEntities} from "../../hooks/useAddEntities";

interface IReportTableProps {
  title: string;
}

const ReportTable: FC<IReportTableProps> = (props) => {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const selectedSchool = useAppSelector((store) => store.school.selectedSchool);
  const { schoolId, regionId } = useAddEntities();


  const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const handleDownloadEFormA = async (selectedSchool: any) => {
    dispatch(setLoading(true));
    if( selectedSchool?.id > 0 || schoolId){
      try {
        await getSchoolsExportEFormA(
            selectedSchool?.id ? selectedSchool?.id : schoolId
        ).then((response) => {
          getDownload(
              response,
              `${
                  (selectedSchool?.institutionalCode ? selectedSchool?.institutionalCode : (schoolId ?? regionId) ?? "")
              }_NONSUC-e-Forms-A_${new Date().getFullYear()}`
          );
        });
      } catch (error: any) {
        setErrorSnackbar(error, setSnackbarStore);
      } finally {
        dispatch(setLoading(false));
      }
    }else{
      setErrorSnackbar("Please select the HEI profile", setSnackbarStore);
      dispatch(setLoading(false));
    }

  };
  const handleDownloadEFormBC = async (level: any, selectedSchool: any) => {
    dispatch(setLoading(true));
    try {
      await getSchoolsExportEFormBC(
          level,
          selectedSchool?.id ? selectedSchool?.id : schoolId,
          regionId
      ).then((response) => {
        getDownload(
          response,
          `${
              (selectedSchool?.institutionalCode ? selectedSchool?.institutionalCode : (schoolId ?? regionId) ?? "")
          }_NONSUC-e-Forms-B-C_${level}_${new Date().getFullYear()}`
        );
      });
    } catch (error: any) {
      console.error({ error });
      const errorMsg =
        error.message || "Something went wrong while deactivating school.";
      setErrorSnackbar(error, setSnackbarStore);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleDownloadPrc = async (selectedSchool: any) => {
    dispatch(setLoading(true));
    try {
      await getSchoolsExportPrcListOfGraduates(
        selectedSchool?.id ? selectedSchool?.id : schoolId
      ).then((response) => {
        getDownload(
          response,
          `${
              (selectedSchool?.institutionalCode ? selectedSchool?.institutionalCode : (schoolId ?? regionId) ?? "")
          }_NONSUC-PRC-List-of-Graduates_"${new Date().getFullYear()}`
        );
      });
    } catch (error: any) {
      console.error({ error });
      const errorMsg =
        error.message || "Something went wrong while deactivating school.";
      setErrorSnackbar(error, setSnackbarStore);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const levels = useMemo(() => {
    return [
      "Doctoral",
      "Baccalaureate",
      "Basic",
      "Pre-Baccalaureate",
      "Post-Baccalaureate",
      "Masters",
    ];
  }, []);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <React.Fragment>
              <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>

                      <Link
                        onClick={() =>
                          handleDownloadEFormBC(null, selectedSchool)
                        }
                        underline="none"
                      >
                        <Typography variant="subtitle1" component="h2">
                          E Form BC
                        </Typography>
                      </Link>
                    </Box>

                    <Box>
                      <Box style={{ paddingRight: 10 }}>
                        <Avatar
                          onClick={() =>
                            handleDownloadEFormBC(null, selectedSchool)
                          }
                          sx={{
                            ":hover": {
                              bgcolor: "primary.main", // theme.palette.primary.main
                              color: "white",
                            },
                          }}
                        >
                          <DownloadIcon />
                        </Avatar>
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>File</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {levels.map((level) => {
                            return (
                              <TableRow key={level}>
                                <TableCell color={"#ffffff"}>
                                  <Box
                                    sx={{
                                      flex: 1,
                                      display: "flex",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Box>
                                        <Link
                                          onClick={() =>
                                            handleDownloadEFormBC(
                                              level,
                                              selectedSchool
                                            )
                                          }
                                          underline="none"
                                        >
                                          {`Download ${level}`}
                                        </Link>
                                      </Box>
                                    </Box>
                                    <Box style={{ paddingRight: 10 }}>
                                      <Avatar
                                        onClick={() =>
                                          handleDownloadEFormBC(
                                            level,
                                            selectedSchool
                                          )
                                        }
                                        sx={{
                                          ":hover": {
                                            bgcolor: "primary.main", // theme.palette.primary.main
                                            color: "white",
                                          },
                                        }}
                                      >
                                        <DownloadIcon />
                                      </Avatar>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <Link
                      onClick={() => handleDownloadPrc(selectedSchool)}
                      underline="none"
                    >
                      <Typography variant="subtitle1" component="h2">
                        PRC List of Graduate
                      </Typography>
                    </Link>
                  </Box>
                  <Box style={{ paddingRight: 10 }}>
                    <Avatar
                        onClick={() => handleDownloadPrc(selectedSchool)}
                      sx={{
                        ":hover": {
                          bgcolor: "primary.main", // theme.palette.primary.main
                          color: "white",
                        },
                      }}
                    >
                      <DownloadIcon />
                    </Avatar>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {" "}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <Box
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <Link
                      onClick={() => handleDownloadEFormA(selectedSchool)}
                      underline="none"
                    >
                      <Typography variant="subtitle1" component="h2">
                        Institutional Profile
                      </Typography>
                    </Link>
                  </Box>
                  <Box style={{ paddingRight: 10 }}>
                    <Avatar
                        onClick={() => handleDownloadEFormA(selectedSchool)}
                      sx={{
                        ":hover": {
                          bgcolor: "primary.main", // theme.palette.primary.main
                          color: "white",
                        },
                      }}
                    >
                      <DownloadIcon />
                    </Avatar>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportTable;
