import * as Yup from "yup";

export interface IDisciplineSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
  code?: string | null;
}

export const disciplineSchema: Yup.SchemaOf<IDisciplineSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    name: Yup.string().nullable(),
    code: Yup.string().nullable(),
  });

export type DisciplineType = Yup.InferType<typeof disciplineSchema>;
