import {Dispatch, SyntheticEvent} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {setIsLockedDialog} from "../redux/features/promotionalReports";

export function useIsLocked(dispatch: React.Dispatch<AnyAction>, title: string, content: string, hIsLocked: any) {
    const handleIsLocked = (e: SyntheticEvent<HTMLButtonElement>, isLocked: any, name?:string, body?: string) => {

        e.stopPropagation()
        const id = e.currentTarget.value
      //  if(!isLocked){
            dispatch(setIsLockedDialog({
                open: true,
                data: {
                    isLocked: isLocked,
                    id: id ,
                    title: `${name}`,
                    body: `${body}`,
                    content: `${content || ""}`,
                    handleIsLocked: () => hIsLocked(id)
                }
            }))
        //}

    }
    return handleIsLocked;
}
