import {getHistoryLog} from './../requests/historyLog.request';
import {useQuery} from "react-query";


export const useHistoryLogQuery = (params: {
    schoolId?: string,
    pageSize?: number,
    page?: string | number,
    search?: string;
    dateStart?: string | undefined;
    dateEnd?: string | undefined
}) => {
    return useQuery(["log-history", params.schoolId, params.search, params.page, params.pageSize, params.dateStart, params.dateEnd,], () => getHistoryLog(params), {
        refetchOnWindowFocus: false,
    });
}
