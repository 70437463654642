import * as React from "react";
import {useEffect} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import {useDebounce} from "../../hooks/useDebounce";
import {useSearchProgramQuery} from "../../hooks/useSearchProgramQuery";
import {ProgramType} from "../../types";
import {useAppSelector} from "../../redux/store";

interface ISearchAsYouTypeProps {
    label: string;
    schoolId: any;
    defaultValue?: any;
    disabled?: boolean,
    status?:string,
    onSelected: (value: ProgramType) => void;
}

const SearchTextField: React.FC<ISearchAsYouTypeProps> = (props) => {
    const {label, onSelected, schoolId, defaultValue, disabled = false} = props;

    const [name, setName] = React.useState("");
    const debouncedName = useDebounce(name, 500);
    const {data: options, isLoading} = useSearchProgramQuery(debouncedName, {
        schoolId,
        ...props.status && {status: props.status}
    });


    const [open, setOpen] = React.useState(false);
    const program = useAppSelector((store) => store?.school?.program);

  useEffect(() => {
      if (!program) return
    onSelected(program);
  }, []);


    return (
    <Autocomplete
        id={"searchProgram"}
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        defaultValue={defaultValue ?? program}
        onClose={() => {
            setOpen(false);
        }}
        disabled={disabled}
        getOptionLabel={(option) => option?.name}
        renderOption={(props, option) => (
            <li {...props} id={option?.id} key={option?.id}>
                <div id={"searchasyoutypesettingprogram" + option?.id}
                     style={{flex: 1, display: "flex", flexDirection: "column"}}>
                    {option?.name}
                    <p style={{
                        fontStyle: "italic",
                        margin: 0,
                        fontSize: 12
                    }}>{option?.major ? option?.major?.name : ""}</p>
                </div>
            </li>
        )}
        options={(options?.data || []) as ProgramType[]}
        loading={isLoading}
        onChange={(e, value, reason) => {
            onSelected(value);
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                label={label}
                margin="dense"
          size="small"
          value={name}
          onChange={(e) => {
            setName(e?.target?.value);
          }}
          InputProps={{
            ...params?.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params?.InputProps?.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchTextField;
