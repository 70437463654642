import React, { useState } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';

import { Hamburger } from './Hamburger';
import { AppTitle } from './AppTitle';
import { More, UserAccount } from '../Actions';
import { DefaultMenu, MobileMenu } from './Menu';
import { ThemeSwitcher } from './ThemeSwitcher';
import Notification from "../Menu/Notification";

interface HeaderProps {
	toggleNavigation: () => void;
}

export const Header = ({ toggleNavigation }: HeaderProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMobileMoreAnchorEl(event.currentTarget);

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	return (
		<>
			<AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, display: { paddingTop: 10, paddingBottom: 10 } }}>
				<Toolbar disableGutters variant='dense'>
					<Hamburger toggleNavigation={toggleNavigation} />
					<AppTitle />
					{/* <Search /> */}
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'flex-end' } }}>
						<Notification/>
						<ThemeSwitcher />
						{/* <Messages total={15} /> */}

						<UserAccount onClick={handleProfileMenuOpen} />
						{/* <SignOut onClick={handleSignOutClick} /> */}
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<More onClick={handleMobileMenuOpen} />
					</Box>
				</Toolbar>
			</AppBar>
			<MobileMenu
				isMenuOpen={Boolean(mobileMoreAnchorEl)}
				handleMenuOpen={handleMobileMenuOpen}
				handleMenuClose={handleMobileMenuClose}
				anchorEl={mobileMoreAnchorEl}
			/>
			<DefaultMenu isMenuOpen={Boolean(anchorEl)} handleMenuClose={handleMenuClose} anchorEl={anchorEl} />
		</>
	);
};
