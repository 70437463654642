import * as React from "react";
import {useCallback, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {Alert, Box, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {SearchSchoolResponseType} from "../../types";
import {useAddEntities} from "../../hooks/useAddEntities";
import {RootState, useAppSelector} from "../../redux/store";
import {useMutation, useQueryClient} from "react-query";
import {SaveRounded} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import SearchAsYouType from "../SearchAsYouType";
import {setColoredRows} from "../../redux/features/curriculum";
import {postMajor} from "../../requests/major.request";
import {MajorInputType} from "../../schemas/major.schema";

const validationSchema = yup.object({
  name: yup.string().required("Major Name is required"),
  code: yup.string().notRequired().nullable(),
});

const initialValues = {
  schoolId: "",
  code: "",
  name: "",
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  setting?: boolean;
  schoolRegionId?: any;
}

const AddMajor = (props: IProps) => {
    const {open, handleClose, setting, schoolRegionId} = props;
    const dispatch = useDispatch();
    const [school, setSchool] = useState<any>()
    const schoolSelectorById = useAppSelector(
        (store) => store?.school.schoolSelectorById
    );
    const schoolSetting = useAppSelector(
        (store) => store?.school.schoolSetting
    );

    const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
    const queryClient = useQueryClient();

    const {mutate: addMajor, isLoading} = useMutation({
        mutationKey: "addMajor",
        mutationFn: async (value: MajorInputType) =>
            await postMajor(value),
    });

  const {schoolId, regionId} = useAddEntities();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);


  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: async (values, actions) => {
      if (!setting) {
        values.schoolId = schoolId;
      } else if (setting) {
        values.schoolId = schoolId ? schoolId : school?.id
      }
      void addMajor(values as unknown as MajorInputType, {
        onSuccess: (res) => {
          dispatch(setColoredRows(res?.data?.id))
          if (res.message) {
            var __message = res?.data?.name + " already exists in our system. Please try a different one."
            setSnackbarStore({
              message: res.message,
              severity: __message == res.message ? "error" : "success",
              open: true,
            })
          } else {
            setSnackbarStore({
              message: "Successfully added curriculum",
              severity: "success",
              open: true,
            });
          }
          queryClient.setQueriesData(['majors'], (oldData: any) => {
            return {
              ...oldData,
              data: [...oldData?.data, {
                id: res?.data?.id,
                name: values.name,
                code: values.code,

                timeout: 3,
              }]
            }
          })

          actions.resetForm();

          queryClient.invalidateQueries("majors");
          handleClose();
        },
        onError: () => {
          setSnackbarStore({
            message: "Failed to add major",
            severity: "error",
            open: true,
          });
        },
      });
    },
  });

  const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
  const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
    setSchool(value)
    formik.setFieldValue('schoolId', value?.id ?? "")
  }, [formik])
  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  const authStore = useSelector((store: RootState) => store.auth);
  return (
      <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Add Major <p
            style={{
                margin: 0,
                fontSize: 12,
                fontStyle: "italic"
            }}>({authStore?.user?.school?.name ?? schoolSetting?.name ?? ""})</p>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Please fill in the details of the curriculum you want to add
            <br/>
            Note: Fields with <strong>*</strong> are required
          </Alert>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 3,
              }}
          >
            {<Box paddingBottom={1}>
              {setting ? <SearchAsYouType
                  id={"curriculumselect"}
                  value={schoolSelectorById ?? schoolSetting}
                  disabled={schoolSelectorById ?? schoolSetting}
                  required={(typeId == 1)}
                  error={onErrorSelectedSchool}
                  label='School Institutional Name'
                  onSelected={handleOnSelectedSchool}
              /> : null}
            </Box>
            }
            <TextField
                label="Major Name"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="majorName"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                    formik?.touched?.name && Boolean(formik?.errors?.name)
                }
                helperText={
                    formik.touched.name && formik.errors.name
                }
            />

            <TextField
                label="Major Code"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                id="majorCode"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={
                    formik?.touched?.code && Boolean(formik?.errors?.code)
                }
                helperText={
                    formik.touched.code && formik.errors.code
                }
            />
          </Box>

        </DialogContent>

        <DialogActions>
          <Button id={"cancelMajor"} onClick={handleClose}>Cancel</Button>
          <LoadingButton
              loading={isLoading}
              disabled={
                  formik.values.name.length < 1
              }
              id={"loadingAdding"}
              loadingPosition="start"
              variant="contained"
              startIcon={<SaveRounded/>}
              onClick={handleSubmit}
          >
            {isLoading ? "Adding" : "Add"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  );
};

export default AddMajor;
