import { AxiosRequestConfig } from "axios";
import { useQuery } from "react-query";
import { getAcademicCalendar } from "../requests/academicCalendar.request";
import { useCallback } from "react";
import flat from 'flat';

export const useAcademicCalendaryQuery = (
  params: AxiosRequestConfig["params"]
) => {
  return useQuery(
    ["academic-calendar", params, params.month,  params.schoolId],
    () => getAcademicCalendar(params),
    {
      //enabled: !!params.schoolId ,
      select: useCallback((data: any) => {
        return {
          ...data,
          data: data?.data?.map((ac: any) => {
            return {
              ...ac,
              academicCalendar: {
                firstSemStart: ac.firstSemStart,
                firstSemEnd: ac.firstSemEnd,
                secondSemStart: ac.secondSemStart,
                secondSemEnd: ac.secondSemEnd,
                thirdSemStart: ac.thirdSemStart,
                thirdSemEnd: ac.thirdSemEnd,
              },
              ...(flat(ac, { safe: true }) as object),
            };
          }),
        };
      }, []),
    }
  );
};
