import { createContext, FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../redux/features/app';
import { RootState } from '../redux/store';
import { setHeaderToken } from '../requests/config';

export interface IAppContext {
	access_token: Partial<string | null>;
}
export const AppContext = createContext<IAppContext>({
	access_token: null,
});

interface IAppContextProvider {
	children: React.ReactNode;
}

const AppContextProvider: FC<IAppContextProvider> = ({ children }) => {
	const authState = useSelector((state: RootState) => state.auth)
	const dispatch = useDispatch()

	useEffect(() => {
		// reset loading state
		return () => {
			dispatch(setLoading(false))
		}
	}, [])

	const contextValue = useMemo(() => ({ access_token: authState.token }), [authState.token])
	if (authState.token) setHeaderToken(authState.token)

	return (
		<AppContext.Provider value={contextValue}>
			{children}
		</AppContext.Provider>
	)

}

export default AppContextProvider;