import {AxiosRequestConfig} from 'axios';
import {useQuery} from "react-query";
import {getSchools} from "../requests/school.request";
import {getSchoolYearsSelect} from '../requests/schoolYear.request';

export const useSchoolQuery = (params: AxiosRequestConfig['params'], enabled: boolean = true) => {
    return useQuery(["schools", params?.status, params?.page, params?.pageNumber, params?.pageSize, params?.select, params?.schoolId, params?.schoolId, params?.search],
        () => getSchools(params),
        {
            enabled
        } );
}

export const useSchoolYearSelectQuery = () => {
    return useQuery(["schoolyear", "select"], getSchoolYearsSelect);
}
