import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {fetchMembersByWorkspaceId, getMembersById} from "../../requests/member.request";
import {AlertTriangle, ChevronDownIcon, Loader, MailIcon, XIcon} from "lucide-react";
import React, {useState} from "react";
import {Avatar, Button, Divider, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useSelector} from "react-redux";
import type {RootState} from "../../redux/store";

interface ProfileProps {

    memberId: any;
    onClose: () => void;
}

const Profile = ({ memberId, onClose }: ProfileProps) => {
    const {workspaceId} = useParams() as {
        workspaceId: string
    };
    const { data: member, isLoading: memberLoading } = useQuery(["member", memberId], () => getMembersById(memberId))
    const authStore = useSelector((state: RootState) => state.auth)

    const [anchorEl, setAnchorEl] = useState(null);
    if (memberLoading) {
        return (
            <div className="h-full flex flex-col">
                <div className="h-[49px] flex justify-between items-center px-4 border-b">
                    <p className="text-lg font-bold">Profile</p>
                    <Button onClick={onClose} size="small" variant="text">
                        <XIcon className="size-5 stroke-[1.5]"/>
                    </Button>
                </div>
                <div className="flex flex-col gap-y-2 h-full items-center justify-center">
                    <Loader className="size-5 animate-spin text-muted-foreground"/>
                </div>
            </div>
        )
    }
    if (!member) {
        return (
            <div className="h-full flex flex-col">
                <div className="h-[49px] flex justify-between items-center px-4 border-b">
                    <p className="text-lg font-bold">Profile</p>
                    <Button onClick={onClose} size="small" variant="text">
                        <XIcon className="size-5 stroke-[1.5]"/>
                    </Button>
                </div>
                <div className="flex flex-col gap-y-2 h-full items-center justify-center">
                    <AlertTriangle className="size-5 text-muted-foreground" />
                    <p className="text-sm text-muted-foreground">Profile not found</p>
                </div>
            </div>
        );
    }

    const open = Boolean(anchorEl);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const avatarFallback =  [member.data.member?.user?.userProfile?.firstName,  member.data.member?.user?.userProfile?.middleName, member.data.member?.user?.userProfile?.lastName].filter(c => c != null).join(" ") || "M";

    function onUpdate(admin: string) {

    }

    function onRemove() {

    }
    function onLeave() {

    }
    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <div style={{
                height: 49,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 16px",
                borderBottom: "1px solid #e0e0e0"
            }}>
                <Typography variant="h6" fontWeight="bold">Profile</Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon/>
                </IconButton>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 16
            }}>
                <Avatar src={member?.user?.image} alt="Avatar" sx={{width: 256, height: 256}}>
                    {!member?.user?.image && <Typography variant="h2">{avatarFallback}</Typography>}
                </Avatar>
            </div>
            <div style={{padding: 16}}>
                <Typography variant="h6" fontWeight="bold">{avatarFallback}</Typography>
                {authStore?.user?.roles?.toLowerCase() === "admin" && authStore?.user?.id !== memberId ? (
                    <div style={{display: "flex", alignItems: "center", gap: 8, marginTop: 16}}>
                        <Button
                            variant="outlined"
                            onClick={handleClick}
                            endIcon={<ChevronDownIcon/>}
                            style={{textTransform: "capitalize", width: "100%"}}
                        >
                            {member.role}
                        </Button>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={() => {
                                onUpdate("admin");
                                handleClose();
                            }}>Admin</MenuItem>
                            <MenuItem onClick={() => {
                                onUpdate("member");
                                handleClose();
                            }}>Member</MenuItem>
                        </Menu>
                        <Button onClick={onRemove} variant="outlined" style={{width: "100%"}}>
                            Remove
                        </Button>
                    </div>
                ) : authStore?.user?.id === memberId && authStore?.user?.role !== "admin" ? (
                    <Button onClick={onLeave} variant="outlined" style={{marginTop: 16, width: "100%"}}>
                        Leave
                    </Button>
                ) : null}
            </div>
            <Divider/>
            <div style={{padding: 16}}>
                <Typography variant="subtitle2" fontWeight="bold" gutterBottom>Contact Information</Typography>
                <div style={{display: "flex", alignItems: "center", gap: 8}}>
                    <div style={{
                        width: 36,
                        height: 36,
                        backgroundColor: "#e0e0e0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 4
                    }}>
                        <MailIcon fontSize="small"/>
                    </div>
                    <div>
                        <Typography variant="caption" color="textSecondary">Email Address</Typography>
                        <Typography component="a" href={`mailto:${member.data.member?.user?.email}`} variant="body2"
                                    style={{color: "#1264a3", textDecoration: "none"}}>
                            {member.data.member?.user?.email}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile