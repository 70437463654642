import * as Yup from "yup";

export interface IMajorSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
}

export const majorSchema: Yup.SchemaOf<IMajorSchema> = Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    name: Yup.string().nullable(),
});

export type MajorType = Yup.InferType<typeof majorSchema>;
export const majorInputSchema = Yup.object().shape({
    schoolId: Yup.string().required("School Id is required"),
    code: Yup.string().notRequired().nullable(),
    name: Yup.string().required("Name is required"),
});

export type MajorInputType = Yup.InferType<typeof majorInputSchema>;


export const messageInputSchema = Yup.object().shape({
    body: Yup.string().notRequired().nullable(),
    workspaceId: Yup.string().notRequired().nullable(),
    conversationId: Yup.string().notRequired().nullable(),
    parentMessageId: Yup.string().notRequired().nullable(),
    image: Yup.string().notRequired().nullable(),
    channelId: Yup.string().notRequired().nullable()
});
export const updateMessageInputSchema = Yup.object().shape({
    body: Yup.string().notRequired().nullable(),
    id: Yup.string().notRequired().nullable()
});
export const removeMessageInputSchema = Yup.object().shape({
    id: Yup.string().notRequired().nullable()
});
export const toggleReactionMessageInputSchema = Yup.object().shape({
    messageId: Yup.string().notRequired().nullable(),
    value: Yup.string().notRequired().nullable()
});
export const getMessageInputSchema = Yup.object().shape({
    messageId: Yup.string().notRequired().nullable()
});
export type ToggleReactionMessageInputType = Yup.InferType<typeof toggleReactionMessageInputSchema>;
export type RemoveMessageInputType = Yup.InferType<typeof removeMessageInputSchema>;
export type UpdateMessageInputType = Yup.InferType<typeof updateMessageInputSchema>;
export type GetMessageInputType = Yup.InferType<typeof getMessageInputSchema>;
export type MessageInputType = Yup.InferType<typeof messageInputSchema>;