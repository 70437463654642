// AUTHOR: JOEVER E. MONCEDA
// LICENSE: MIT
import jwt_decode from "jwt-decode";
import api, {setHeaderToken} from "../../requests/config";
import Axios from "axios";

let isRefreshing = false;
let currentAccessToken: undefined = undefined;
let currentRefreshToken: undefined = undefined;
let currentTokens: null = null;

const headerOptions = {
    authPrefix: "Authorization", bearerPrefix: "Bearer"
};
var _cancelToken = Axios.CancelToken.source()
let source = _cancelToken
let isLoading = false;
export async function setNewTokens(options: {
    refreshTokenEndpoint: any;
    headerOptions: any;
    initialRefreshToken?: any;
    initialAccessToken?: any;
    accessTokenResultPath?: any;
    refreshTokenResultPath?: any;
}) {
    isRefreshing = true;
    if (isLoading) return
    isLoading = true
    if (!options.headerOptions) {
        options.headerOptions = headerOptions;
    }
    if (!options.refreshTokenEndpoint) throw "No refresh token endpoint!";

    var localStorageRefreshToken: any = localStorage.getItem('session')
    var sessionToken = JSON.parse(localStorageRefreshToken);

    const refreshToken = sessionToken?.refreshToken;
    const accessToken = sessionToken?.token

    //Save the cancel token for the current request

    var cancelToken = source?.token ? {
        cancelToken: source?.token
    } : {}

    if (!accessToken) return

    return await api
        .post(options.refreshTokenEndpoint, {refreshToken, accessToken}, cancelToken)
        .then((result: any) => {
            if (typeof source != typeof undefined) {
                source?.cancel("Operation canceled due to new request.")
            }
            var _cancelToken = Axios?.CancelToken?.source()
            source = _cancelToken;
            if (!result.data.data) return
            const accessTokenResult = result.data.data[options.accessTokenResultPath];
            setHeaderToken(accessTokenResult);
            currentAccessToken = result.data.data[options.accessTokenResultPath];
            currentRefreshToken = result.data.data[options.refreshTokenResultPath];
            currentTokens = result.data.data;

            var refreshToken = JSON.parse(localStorageRefreshToken);
            refreshToken.refreshToken = currentRefreshToken
            refreshToken.token = currentAccessToken
            localStorage.setItem("session", JSON.stringify(refreshToken));
            if (accessTokenResult) {
                api.defaults.headers.common[options.headerOptions.authPrefix] = `${options.headerOptions.bearerPrefix} ${accessTokenResult}`;
            } else {
                return Promise.resolve(new Error("Unable to locate access token object path!"));
            }
            isLoading = false
            return result.data.data[options.accessTokenResultPath];
        })
        .catch((error: any) => {

            if (error?.response?.status === 400 || error?.response?.status === 500) {
                var refreshToken = JSON.parse(localStorageRefreshToken);
                if (refreshToken?.token) {
                    localStorage.clear()
                    window.location.replace("/login");
                }
            }
            isLoading = false
        })
        .finally(() => {
            isLoading = false
            isRefreshing = false
        });
}

function isValidAccessToken(options: any) {
    if (!options.headerOptions) {
        options.headerOptions = headerOptions;
    }
    var localStorageRefreshToken: any = localStorage.getItem('session')
    var sessionToken = JSON.parse(localStorageRefreshToken);

    const accessToken: any = `${options.headerOptions.bearerPrefix} ${sessionToken?.token}`
    if (!accessToken) {
        console.log('No access token available!');
        return true;
    }
    try {
        const jwtDecoded: any = jwt_decode(accessToken);
        const now = Math.floor(Date.now() / 1000);
        if (jwtDecoded["exp"] < now) {
            return false;
        }
    } catch (error) {
        return false;
    }
    return true;
}

const tokenRefresher = {
    interceptRequest: function (options: any) {
        var localStorageRefreshToken: any = localStorage.getItem('session')
        var sessionToken = JSON.parse(localStorageRefreshToken);
        if (!api) throw "No axios instance!";
        if (!options) throw "No options available!";

        api.interceptors.request.use((config: any) => {

            if (config.url.indexOf(options.refreshTokenEndpoint) === -1 && sessionToken?.token?.trim() ) {
                let headers = api.defaults.headers;
                if (isRefreshing) {
                    return new Promise(resolve => {
                        let counterInterval = 0;
                        let idInterval = setInterval(() => {
                            counterInterval++;
                            if (sessionToken) {
                                config.headers.Authorization = `${options.headerOptions.bearerPrefix} ${sessionToken?.token}`;
                                clearInterval(idInterval);
                                resolve(config);
                            } else if (counterInterval > 8) {
                                clearInterval(idInterval);
                                resolve(config);
                            }
                        }, 3000);
                    });
                }
                if (!isValidAccessToken(options)) {
                        return setNewTokens(options).then(() => {
                            var localStorageRefreshToken: any = localStorage.getItem('session')
                            var sessionToken = JSON.parse(localStorageRefreshToken);
                            config.headers.Authorization = `${options.headerOptions.bearerPrefix} ${sessionToken?.token}`;
                            return config;
                        });
                }
            }

            return config;
        });

    }
};


export default tokenRefresher
