import api from "./config";
import {AxiosRequestConfig} from "axios/index";
import {GetSpecialOrderGroupBySchoolResponseType} from "../types";
import axios from "axios";

let cancelToken = axios.CancelToken.source();
export const getGroupBySchool = async (params: AxiosRequestConfig["params"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api.get<GetSpecialOrderGroupBySchoolResponseType>("/special-orders/group-by-school", {
        ...{cancelToken: cancelToken?.token},
        params
    });
    return res?.data;
};

export const getSpecialOrderGroupByStudents = async (params: AxiosRequestConfig["params"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api.get("/special-orders/group-by-students", {
        ...{cancelToken: cancelToken?.token},
        params
    });
    return res?.data;
};
export const uploadReceipt = async <T>(body: T, config: AxiosRequestConfig, id: number) => {
    const res = await api.post(`/special-orders/upload-receipt/${id}`, body, config)
    return res
}


export const getApprovedCount = async (params: any) => {
    const res = await api({
        url: `/special-orders/approved`,
        params: params,
        method: "GET",
    });

    return res?.data?.data;
};
export const getPendingCount = async (params: any) => {
  const res = await api({
    url: `/special-orders/pending`,
    params: params,
    method: "GET",
  });

  return res?.data?.data;
};
