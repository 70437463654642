/* eslint no-eval: 0 */
import {
	ApprovalRounded,
	ArticleRounded,
	AssignmentTurnedInRounded,
	BeenhereRounded,
	CheckBoxRounded,
	DisabledByDefaultRounded,
	DocumentScannerRounded,
	DoneAllRounded,
	FactCheckRounded,
	GirlRounded,
	GradingRounded,
	LibraryAddCheckRounded,
	SummarizeRounded,
	TaskRounded,
	VolunteerActivismRounded,
	WarehouseRounded
} from "@mui/icons-material";
import React from "react";
export function textClipboard(name:string) {
	const textArea = document.createElement("textarea");
	textArea.value = name;

	textArea.style.position = "fixed";
	textArea.style.left = "-9999px";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
		console.log('Text copied to clipboard using fallback method!');
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}

	document.body.removeChild(textArea);
}

export const JSONfn = {
	stringify: (obj: any) => {
		return JSON.stringify(obj, function (key, value) {
			return (typeof value === 'function') ? value.toString() : value;
		});
	},
	parse: (str: string) => {
		return JSON.parse(str, function (key, value) {
			if (typeof value != 'string') return value;
			return (value.substring(0, 8) === 'function') ? eval('(' + value + ')') : value;
		});
	}
};
export const currency = (number: number) => {
	var formatter = new Intl.NumberFormat('fil-PH', {
		style: 'currency',
		currency: 'PHP',
	});
	return formatter.format(number);
};
export function getElement(v: any) {
	return v?.id == 9 ?
		v?.status ? <ApprovalRounded color={v?.status ? "success" : "error"}/> :
			<DisabledByDefaultRounded
				color={v?.status ? "success" : "error"}/> :
		v?.id == 10 ?
			v?.status ?
				<ArticleRounded color={v?.status ? "success" : "error"}/> :
				<DisabledByDefaultRounded
					color={v?.status ? "success" : "error"}/> :
			v?.id == 11 ?
				v?.status ? <DocumentScannerRounded
						color={v?.status ? "success" : "error"}/> :
					<DocumentScannerRounded
						color={v?.status ? "success" : "error"}/> :
				v?.id == 2 ?
					v?.status ? <SummarizeRounded
							color={v?.status ? "success" : "error"}/> :
						<SummarizeRounded
							color={v?.status ? "success" : "error"}/> :
					v?.id == 5 ?
						v?.status ? <AssignmentTurnedInRounded
								color={v?.status ? "success" : "error"}/> :
							<AssignmentTurnedInRounded
								color={v?.status ? "success" : "error"}/> :
						v?.id == 7 ?
							v?.status ? <FactCheckRounded
									color={v?.status ? "success" : "error"}/> :
								<FactCheckRounded
									color={v?.status ? "success" : "error"}/> :
							v?.id == 4 ?
								v?.status ? <TaskRounded
										color={v?.status ? "success" : "error"}/> :
									<TaskRounded
										color={v?.status ? "success" : "error"}/> :
								v?.id == 1 ?
									v?.status ? <GradingRounded
											color={v?.status ? "success" : "error"}/> :
										<GradingRounded
											color={v?.status ? "success" : "error"}/> :
									v?.id == 8 ?
										v?.status ? <BeenhereRounded
												color={v?.status ? "success" : "error"}/> :
											<BeenhereRounded
												color={v?.status ? "success" : "error"}/> :
										v?.id == 13 ?
											v?.status ? <LibraryAddCheckRounded
													color={v?.status ? "success" : "error"}/> :
												<LibraryAddCheckRounded
													color={v?.status ? "success" : "error"}/> :
											v?.id == 14 ?
												v?.status ?
													<VolunteerActivismRounded
														color={v?.status ? "success" : "error"}/> :
													<VolunteerActivismRounded
														color={v?.status ? "success" : "error"}/> :
												v?.id == 12 ?
													v?.status ?
														<WarehouseRounded
															color={v?.status ? "success" : "error"}/> :
														<WarehouseRounded
															color={v?.status ? "success" : "error"}/> :
													v?.id == 6 ?
														v?.status ? <GirlRounded
																color={v?.status ? "success" : "error"}/> :
															<GirlRounded
																color={v?.status ? "success" : "error"}/> :
														v?.id == 15 ?
															v?.status ?
																<DoneAllRounded
																	color={v?.status ? "success" : "error"}/> :
																<DoneAllRounded
																	color={v?.status ? "success" : "error"}/> :
															v?.status ?
																<CheckBoxRounded
																	color={v?.status ? "success" : "error"}/> :
																<DisabledByDefaultRounded
																	color={v?.status ? "success" : "error"}/>;
}

export function isObjectEmpty(value: any) {
	return (
		Object.prototype.toString.call(value) === '[object Object]' &&
		JSON.stringify(value) === '{}'
	);
}