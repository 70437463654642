// create slice for app
import { ReactNode } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDisciplineSlice {
  name: any;
}

const initialState: IDisciplineSlice = {
  name: "",
};

const appSlice = createSlice({
  name: "discipline",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<any>) => {
      state.name = action.payload;
      return state;
    },
  },
});

export const { setName } = appSlice.actions;
export default appSlice.reducer;
