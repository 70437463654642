import {Autocomplete, Box, Container, Divider, List, Paper, TextField, Typography,} from "@mui/material";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useDebounce} from "../hooks/useDebounce";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions,} from "mui-datatables";
import {useAddEntities} from "../hooks/useAddEntities";
import {useAcademicCalendaryQuery} from "../hooks/useAcademicCalendar";
import moment from 'moment-timezone';
import {useSchoolQuery} from "../hooks/useSchoolQuery";
import {RootState, useAppSelector} from "../redux/store";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useMonthSelect} from "../hooks/useMonthSelect";
import {useRegionSelectQuery} from "../hooks/useRegionQuery";
import {ArrowDropDown} from "@mui/icons-material";

const DeadlineListPage = () => {
  const { academicCalendarId } = useParams() as { academicCalendarId: string };
  const [searchKey, setSearchKey] = React.useState<any>("");
  const [selectedSchool, setSelectedSchool] = React.useState<any>(0);
  const debouncedSearchKey = useDebounce(searchKey, 500);
  const month = useMonthSelect({name: "All", id: -1})
  const [selectedRegionId, setSelectedRegionId] = React.useState<any>( {
    id: 0,
    name: "All"
  });

  const type = useSelector((store: RootState) => store.auth?.user?.type)


  const { data: dataSchools, isLoading } = useSchoolQuery({
    pageSize: "1000",
    search: '',
    ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
  });
  const { schoolId , regionId, userInstitutionalName} = useAddEntities();
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";


  const { data: academicCalendar, refetch } = useAcademicCalendaryQuery({

    ...selectedSchool?.id > 0 && {schoolId: selectedSchool?.id},
    ...month.selectedMonth?.id > -1 && {month: month.selectedMonth?.id},
    ...(typeof regionId == "number" && {regionId: regionId}),
    ...(regionId?.id && {regionId: regionId?.id}),
    ...selectedRegionId?.id > 0 && {
      regionId: selectedRegionId?.id
    },
    pageSize: 100,
    search: debouncedSearchKey,
    direction: "desc",
    orderBy: "DateCreated",
  });

  const columns: MUIDataTableColumn[] = [
    // {
    //   name: "schoolName",
    //   label: "SCHOOL",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "academicCalendar",
      label: "ACADEMIC CALENDAR",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data: any) => {
          return (
            <>
              <div>
                First:{" "}
                <b>
                  {" "}
                  {moment(data.firstSemStart).format("MMMM D, YYYY")} -{" "}
                  {moment(data.firstSemEnd).format("MMMM D, YYYY")}{" "}
                </b>
              </div>
              <div>
                Second:{" "}
                <b>
                  {moment(data.secondSemStart).format("MMMM D, YYYY")} -{" "}
                  {moment(data.secondSemEnd).format("MMMM D, YYYY")}
                </b>
              </div>
              {data.thirdSemStart && data.thirdSemEnd && (
                <div>
                  Third:{" "}
                  <b>
                    {moment(data.thirdSemStart).format("MMMM D, YYYY")} -
                    {moment(data.thirdSemEnd).format("MMMM D, YYYY")}
                  </b>
                </div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "schoolName",
      label: "SCHOOL NAME",
      options: {
          filter: true,
          sort: true,
          display: type?.id == 2 || type?.id == 3 && selectedSchool?.id == 0,
      },
    },{
      name: "schoolYear",
      label: "Academic Year",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remarks",
      label: "REMARKS",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dateCreated",
      label: "CREATED DATE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data: any) => {
          return (
              <div style={{flex: 1, display: "flex", alignItems: "center",  flexDirection: "column",  justifyContent: "center"}}>
                <strong>{moment.utc(data).tz('Asia/Manila').format('MMMM D, YYYY')}</strong>
                <p style={{margin: 0}}>{moment.utc(data).tz('Asia/Manila').format("h:mm a")}</p>
              </div>

          );
        },
      },
    },
    {
      name: "lastDatedDate",
      label: "UPDATED DATE",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (data: any) => {
          var date = moment.utc(data).tz('Asia/Manila');
          return (
              <div style={{flex: 1, display: "flex", alignItems: "center",  flexDirection: "column",  justifyContent: "center"}}>
                <strong>{date.format('MMMM D, YYYY')}</strong>
                <p style={{margin: 0}}>{date.format("h:mm a")}</p>
              </div>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filter: false,
    search: true, textLabels: {
      body: {
        noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
      }
    },
    selectableRows: "none",
    elevation: 0,
    resizableColumns: false,
    rowsPerPage: 100,
    downloadOptions: {
      filename: (userInstitutionalName && schoolId ? schoolId + "_" + userInstitutionalName + '_HEI_AcademicCalendar.csv' : 'HEI_AcademicCalendar.csv'),
      separator: ','
    },

    onDownload: (buildHead, buildBody, columns, data) => {


      var _data = data?.map((dt: any, index: any) => {
        var _dt = dt?.data[0]
        if( !dt?.data?.[0]) return false
        dt.data[0] = "1st sem start: " +_dt?.firstSemStart + " " +  "1st sem end: " +_dt?.firstSemStart + "\n" +
            "2nd sem start: " +_dt?.secondSemStart + " " +  "2nd sem end: " +_dt?.secondSemStart + "\n" +
            "3rd sem start: " +_dt?.thirdSemStart + " " +  "3rd sem end: " +_dt?.thirdSemStart;
        return dt
      })

      return "\uFEFF" + buildHead(columns) + buildBody(_data);
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case "search":
          setSearchKey(newTableState.searchText);
          break;
      }
    },
  };
  const { data: regions } = useRegionSelectQuery()
  const user = useAppSelector((store) => store?.auth?.user)
  useEffect(() => {
    if(dataSchools?.data?.find(c => c.id == parseInt(academicCalendarId)) && academicCalendarId){
      setSelectedSchool( dataSchools?.data?.find(c => c.id == parseInt(academicCalendarId)));
    }else if (dataSchools?.data && user?.school?.id ) {
      setSelectedSchool(user?.school?.id ? dataSchools?.data?.find(c => c.id == user?.school?.id) : {
          name: "All",
          id: undefined
      });
    }else if(!selectedSchool){
        setSelectedSchool({name: "All", id: undefined})
    }
  }, [dataSchools]);

  return (
    <>
      <Helmet>
        <title>Academic Calendar</title>
      </Helmet>

      <Box
        component="main"
        sx={{ flexGrow: 1, pt: 10 }}
        style={{
          paddingBottom: "10px",
          paddingLeft: " 20px",
          paddingRight: "20px",
        }}
      >
        <Container>
          <Paper>
            <List
              dense
              subheader={
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                  >
                    <Typography variant="h5" fontWeight="bold">
                      Academic Calendar
                    </Typography>

                    <Box display="flex" alignItems="center" gap={2}>
                      {type?.id === 2 || roles?.toLowerCase() == "superadmin" ? <form onSubmit={(event) => event.preventDefault()}>
                        <>
                          <Autocomplete
                              disabled={isLoading}
                              disableClearable

                              size="small"
                              id={"heiacademiccalendarselectAutocomplete"}
                              value={selectedSchool}
                              onChange={(event: any, newValue: string | null | any) => {

                              if (newValue?.regionId && newValue?.regionName) {
                                setSelectedRegionId({
                                  id: newValue?.regionId,
                                  name: newValue?.regionName,
                                });
                              } else {
                                setSelectedRegionId({
                                  id: 0,
                                  name: "All",
                                });
                              }

                              setSelectedSchool(newValue);
                            }}
                              getOptionLabel={(option) => {
                                return option.name
                              }
                              }
                              popupIcon={<ArrowDropDown
                                  id={"ArrowDropDown-" + "heiacademiccalendarselectAutocomplete"}/>}
                              renderOption={(props, option: any) => (
                                  <li {...props} id={"heiacademiccalendarselectAutocomplete-" + option?.id}
                                      key={option.id}>
                                      <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                          {option.name ?? option.displayName}
                                          <p style={{
                                              fontStyle: "italic",
                                              margin: 0,
                                              fontSize: 12
                                          }}>{option?.institutionalCode ? option.institutionalCode + " - " : ""}{option?.address ? option.address : (option?.address ? option.address + " - " : "") + (option?.province?.name ? option.province.name + " - " : "") + (option?.region?.name ? option.region.name + "" : "")}</p>
                                      </div>
                                  </li>
                              )}
                              options={[{name: "All", id: undefined}, ...(dataSchools?.data || [])] || []}
                              sx={{width: 300}}
                              renderInput={(params) => (
                                  <TextField {...params} label="Select HEI Profile"/>
                              )}
                          />
                        </>
                      </form> : null}
                        {((roles?.toLowerCase() == "superadmin" || type?.id == 2 || type?.id == 3) && roles?.toLowerCase() != "user") ?
                            <form onSubmit={(event) => event.preventDefault()}>
                                <>
                                    <Autocomplete
                                        id={"heiacademiccalendarselectmonthAutocomplete"}
                                        popupIcon={<ArrowDropDown
                                            id={"ArrowDropDown-" + "heiacademiccalendarselectmonthAutocomplete"}/>}

                                        disabled={isLoading}
                                        disableClearable
                                        size="small"
                                        value={month.selectedMonth}
                              onChange={(event: any, newValue: any) => {
                                month.handleChange(newValue)
                              }}
                              getOptionLabel={(option) => {
                                return option.name
                              }
                              }
                              renderOption={(props, option) => (
                                  <li {...props}  id={"settingacademic" + option.id} key={option.id}>
                                    <div  style={{flex: 1, display: "flex", flexDirection: "column" }}>
                                      { option.name }</div>
                                  </li>
                              )}
                                        options={[{name: "All", id: -1}, ...(month?.monthOptions || [])] || []}
                                        style={{width: 300}}

                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Month"/>
                                        )}
                                    />
                                </>
                            </form> : null}
                      {((roles?.toLowerCase() == "superadmin" || type?.id == 3) && roles?.toLowerCase() != "user") ?
                          <form onSubmit={(event) => event.preventDefault()}>
                            <>
                              <Autocomplete
                                  popupIcon={<ArrowDropDown
                                      id={"ArrowDropDown-" + "heiacademiccalendarselectRegionAutocomplete"}/>}

                                  disabled={isLoading}
                                  disableClearable
                                  size="small"
                                  id={"heiacademiccalendarselectRegionAutocomplete"}
                                  value={selectedRegionId}
                                  onChange={(event: any, newValue: string | null) => {
                                    if (selectedSchool?.regionId != regionId?.id) {
                                      setSelectedSchool({
                                        id: 0,
                                        name: "All",
                                      });
                                    }
                                    setSelectedRegionId(newValue ? newValue : {});
                                  }}
                                  getOptionLabel={(option) => {
                                    return option.name
                                  }
                                  }
                                  renderOption={(props, option) => (
                                      <li {...props} id={"settingregionselect" + option.id} key={option.id}>
                                          <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                              {option.name}</div>
                                      </li>
                                  )}
                                  options={[{name: "All", id: undefined}, ...(regions || [])] || []}
                                  style={{width: 200, marginRight: 6,}}

                                  renderInput={(params) => (
                                      <TextField {...params} label="Select Region"/>
                                  )}
                              />
                            </>
                          </form> : null}

                    </Box>
                  </Box>
                  <Divider />
                </>
              }
            >
              <Box
                sx={{
                  mt: 1,
                  overflowY: "scroll",
                }}
              >
                <MUIDataTable
                    options={options}
                    title={selectedSchool?.name || ""}
                    data={academicCalendar?.data as any}
                    columns={columns}
                />
              </Box>
            </List>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default React.memo(DeadlineListPage);
