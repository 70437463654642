import {
    Autocomplete,
    Box,
    Chip,
    Container,
    FormControl,
    List,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Tab,
    TableCell,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {ArrowDropDown, CheckBox, PendingActions} from "@mui/icons-material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions} from "mui-datatables";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDebounce} from "../hooks/useDebounce";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../redux/store";
import {useSchoolQuery} from "../hooks/useSchoolQuery";
import {useAddEntities} from "../hooks/useAddEntities";
import {useRegionSelectQuery} from "../hooks/useRegionQuery";
import useDialog from "../hooks/useDialog";
import {getParamsStatus, selectedViewSO, updatesSo} from "../redux/features/specialOrder";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import GraduateIcon from "../svg/graduate";
import {useHistory} from "react-router-dom";
import {useSOApprovedQuery} from "../hooks/useSOApprovedQuery";
import {usePendingQuery} from "../hooks/usePendingQuery";
import _ from "lodash";
import {useProgramQuery} from "../hooks/useProgramQuery";
import InputLabel from "@mui/material/InputLabel";
import {useLevelQuery} from "../hooks/useLevelQuery";
import {useGmailTabItemStyles, useGmailTabsStyles} from "../styles/gmail";
import {TabCard} from "../components/Tab/Card";
import {useGroupBySchoolQuery} from "../hooks/useGroupBySchoolQuery";
import SpecialOrderViewDialog from "../components/dialogs/SpecialOrderView.dialog";
import {useV2GraduateQuery} from "../hooks/useV2GraduateQuery";
import UploadFileDialog from "../components/dialogs/UploadFile.dialog";
import {UploadIcon} from "lucide-react";
import PesosSign from "../svg/pesossign";
import FilesPreviewDialog from "../components/dialogs/FilesPreview.dialog";
import AddSONumberDialog from "../components/dialogs/AddSONumber";
import {PrintDialog} from "../components/dialogs/PrintDialog";
import soarLoading from "../assets/soar-loading.json";
import soarlogo from "../assets/SOAR.json";
import Lottie from "lottie-react";

export function SpecialOrderPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const {schoolId, regionId, userInstitutionalName} = useAddEntities();
    const {data: regions} = useRegionSelectQuery()
    const [selectedSchool, setSelectedSchool] = React.useState<any>({name: "All", id: undefined});
    const [preview, setPreview] = React.useState<any>(null);
    const [info, setInfo] = React.useState<any>({});
    const [selectedProgram, setSelectedProgram] = React.useState<any>({name: "All", id: undefined});
    const [selectedYearLevel, setSelectedYearLevel] = React.useState<any>({name: "All", id: undefined});
    const user = useAppSelector((store) => store?.auth?.user)
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: undefined,
        name: "All"
    });
    const indicatorColors = useMemo(() => {
        return ['#d93025', '#1a73e8', '#2f7d32', '#2f7d32']
    }, []);

    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const paramsStatus = useSelector((store: RootState) => store.specialOrder?.paramsStatus)
    const [searchProgram, setSearchProgram] = React.useState("");
    const [degree, setDegree] = useState<any>(0)
    const debouncedSearchProgram = useDebounce(searchProgram, 500);
    const roles = useAppSelector((store) => store?.auth?.user?.roles) as
        | "User"
        | "Admin"
        | "SuperAdmin";
    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    const [tabIndex, setTabIndex] = React.useState(0);
    useEffect(() => {
        setTabIndex(type?.id === 2 && roles?.toLowerCase() == "admin" || type?.id === 4 ? 1 : type?.id === 7 && roles?.toLowerCase() == "cashier" ? 2 : 0)
        if ((type?.id === 2 || type?.id === 4) && roles?.toLowerCase() == "admin") {
            dispatch(getParamsStatus("pending"))
        } else if (type?.id === 7) {
            dispatch(getParamsStatus("approved"))
        }
    }, [])
    const tabsStyles = useGmailTabsStyles({indicatorColors});
    const tabItem1Styles = useGmailTabItemStyles({color: indicatorColors[0]});
    const tabItem2Styles = useGmailTabItemStyles({color: indicatorColors[1]});
    const tabItem3Styles = useGmailTabItemStyles({color: indicatorColors[2]});

    const rowSelectionChange = (newTableState: any) => {
        var _updates: any[] = []
        newTableState?.selectedRows?.data?.forEach((c: { index: number, dataIndex: number }) => {
            var _data = newTableState?.data?.[c.dataIndex]?.data

            _updates.push({
                majorId: _data?.[4],
                studentId: _data?.[9],
                specialOrderId: _data?.[3],
                schoolId: _data?.[2],
                programId: _data?.[1]
            })
            return c
        })
        dispatch(updatesSo(_updates))
    }

    const {
        handleOpen: handleSpecialOrderView,
        handleClose: handleCloseSpecialOrderView,
        open: openSpecialOrderView,
    } = useDialog();

    const {
        handleOpen: handleAddSpecialOrderNumberView,
        handleClose: handleCloseAddSpecialOrderNumberView,
        open: openAddSpecialOrderNumberView,
    } = useDialog();
    const {data: dataPrograms, isLoading: programLoading} = useProgramQuery({
        pageSize: "100",
        select: true,

        ...(selectedSchool?.id > 0 && {schoolId: selectedSchool?.id}),
        ...(debouncedSearchProgram && {search: debouncedSearchProgram}),
    });

    const {data: dataSchools} = useSchoolQuery({
        pageSize: "1000",
        search: '',
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
    });
    const {data: levels} = useLevelQuery();
    const [page, setPage] = React.useState(0);
    const useRegionParams = useMemo(() => {
        return {
            ...(typeof regionId == "number" && {regionId: regionId}),
            ...(regionId?.id && {regionId: regionId?.id}),
            ...schoolId && {schoolId: schoolId}
        }
    }, [regionId, regionId?.id, schoolId])
    const {data: graduatesCount} = useV2GraduateQuery({
        ...{isCandidates: true},
        ...(useRegionParams)
    });
    const {data: approvedCount} = useSOApprovedQuery(useRegionParams);
    const {data: pendingCount} = usePendingQuery(useRegionParams);

    const graduatesCountMemo = useMemo(() => {
        let count = 0;
        if (pendingCount > 0) {
            count += pendingCount
        }
        if (approvedCount > 0) {
            count += approvedCount
        }
        return graduatesCount > 0 ? graduatesCount - count : 0
    }, [approvedCount, pendingCount, graduatesCount])

    const {data: students, refetch, isLoading, isFetching} = useGroupBySchoolQuery({
        pageSize: "100",
        search: debouncedSearchKey,
        //...schoolId && {schoolId: schoolId},
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
        ...graduatesCount > 0 &&  {noOfStudent: graduatesCount},
        ...((paramsStatus) && {status: paramsStatus}),
        ...((degree > 0 && degree != 2) && {levelId: degree}),
        ...(selectedYearLevel?.id > 0 && {yearLevel: selectedYearLevel?.id}),
        ...((selectedSchool?.id > 0 || schoolId) && {schoolId: selectedSchool?.id || schoolId}),
        ...(selectedProgram?.id && selectedProgram?.id != "All" && selectedProgram && {programId: selectedProgram?.id}),
        ...(page && {page: page + 1}),
    });

    const {
        handleOpen: handlePrint,
        handleClose: handleClosePrintDialog,
        open: printDialogOpen,
    } = useDialog();
    const options: MUIDataTableOptions = {
        filter: false,
        search: true,
        print: false,
        customToolbar: () => {
            return <Button onClick={handlePrint}>Report</Button>;
        },
        elevation: 0,
        textLabels: {
            body: {
                noMatch: isLoading || isFetching ? <Box display="flex" justifyContent="center" flexDirection={"column"}
                                                         alignItems="center" p={3}>
                    <Lottie style={{height: 100}} animationData={soarLoading}/>
                    <Typography variant="body1">
                        {isLoading || isFetching ? "Please wait while we load your content." : "Sorry, no matching records can be found."}
                    </Typography>
                </Box> :  "Sorry, no matching records can be found."
            }
        },
        selectableRows: 'none',
        resizableColumns: false,
        rowsPerPage: 100,
        /*  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

              return (
                  <div style={{paddingRight: 6}}>
                      <Tooltip title={applySOOrApprove}>
                          <Button onClick={() => {
                              setSelectAllButton(() => () => setSelectedRows([]))
                              handleSelectedOpenSpecialOrder()
                          }
                          } color={"success"} variant={"contained"}>Apply All</Button>
                      </Tooltip>
                  </div>

              )
          },

  */
        onTableChange: (action, newTableState) => {
            switch (action) {
                case "search":
                    setSearchKey(newTableState.searchText);
                    break;
                case "rowSelectionChange":
                    rowSelectionChange(newTableState);
                    break;


            }
        },
    };


    /*const groupByStudent = useMemo(() => {
        return  students?.data.reduce((r: any, { school, programName,  schoolId, programId, schoolYear,...rest }) => {
            const key = `${school}-${programName}-${schoolYear}`;
            r[key] = r[key] || { school, programName, schoolYear, schoolId, programId, students: [] };
            r[key]?.["students"].push(rest)
            return r;
        }, {})
    }, [students?.data])*/
    const typeMemo = useMemo(() => {
        return type?.id != 3 &&
            (
                (type?.id == 1 && roles?.toLowerCase() == "admin" && (paramsStatus == null)) ||
                (type?.id == 2 && roles?.toLowerCase() == "admin" && (paramsStatus == "pending"))
            )
    }, [type?.id, roles?.toLowerCase(), tabIndex, paramsStatus])




    const schoolAdminColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        }, {
            name: "programId",
            label: "ProgramId",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        }, {
            name: "schoolId",
            label: "SchoolId",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            name: "programName",
            label: "Program Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        },
        {
            name: "majorId",
            label: "Major Id",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        }, {
            name: "majorName",
            label: "Major Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        }, {
            name: "schoolName",
            label: "School Name",
            options: {
                display: type?.id === 3 || (type?.id === 2 && roles?.toLowerCase() == "admin"),
                filter: false,
                sort: true,
            },
        }, {
            name: "noStudents",
            label: "Number of Candidates".toUpperCase(),
            options: {
                customBodyRender: (data: any) => {
                    return <span
                        className="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium">{data}
                        </span>
                },
                customHeadRender: ({index, ...column}) => {
                    return (
                        <TableCell align="center" key={column.label}>
                            {column.label}
                        </TableCell>
                    );
                },
                setCellProps: (cellValue) => ({style: {textAlign: "center"}}),
                display: true,
                filter: false,
                sort: true,
            },
        },
        {
            name: "receiptFile",
            label: "Receipt File".toUpperCase(),
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                display: (type?.id == 2 && roles?.toLowerCase() == "admin" && (paramsStatus == "pending" || paramsStatus == "approved")),
                filter: false,
                sort: true,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {

                    return (
                        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
                            <div>
                                <>

                                    {value == "pending" || value == null || value == "" ?
                                        <Tooltip title={"SO application requested"} arrow>
                                            <Chip label="For Verification" color="warning"/>
                                        </Tooltip> : null}

                                    {value == "paymentverification" ? <Tooltip title={"SO application requested"} arrow>
                                        <Chip label="Payment Verification" color="warning"/>
                                    </Tooltip> : null}

                                    {value == "forapproval" ? <Tooltip title={"SO application requested"} arrow>
                                        <Chip label="For Approval" color="info"/>
                                    </Tooltip> : null}
                                    {value == "approved" ? <Tooltip title={"SO application requested"} arrow>
                                        <Chip label="Approve" color="success"/>
                                    </Tooltip> : null}

                                </>
                            </div>
                        </div>

                    );
                },
            },
        },
        {
            name: "bankName",
            label: "Bank Name",
            options: {
                display: false,
                filter: false,
                sort: true
            },
        }, {
            name: "receiptNumber",
            label: "Receipt Number",
            options: {
                display: false,
                filter: false,
                sort: true
            },
        }, {
            name: "totalAmount",
            label: "Total Amount",
            options: {
                display: false,
                filter: false,
                sort: true
            },
        },{
            name: "programCode",
            label: "Program Code",
            options: {
                display: false,
                filter: false,
                sort: true
            },
        }, {
            name: "datePaid",
            label: "Date Paid",
            options: {
                display: false,
                filter: false,
                sort: true
            },
        },
        {
            name: "id",
            label: "Action",
            options: {
                filter: true,
                sort: true,

                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const programId =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "programId")
                            ];
                    const schoolId = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "schoolId")
                        ];
                    const schoolName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "schoolName")
                        ];
                    const programName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "programName")
                        ];

                    const majorName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "majorName")
                        ];

                    const majorId = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "majorId")
                        ];
                    const receiptFile = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "receiptFile")
                        ];

                    const _datePaid = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "datePaid")
                        ];


                    const _totalAmount = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "totalAmount")
                        ];

                    const _bankName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "bankName")
                        ];

                    const _receiptNumber = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "receiptNumber")
                        ];


                    const _programCode = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "programCode")
                        ];
                    return (
                        <div style={{flex: 1, display: "flex",}}>
                            {!((type?.id == 1 || type?.id == 2)  && roles?.toLowerCase() == "admin") && (paramsStatus?.toLowerCase() == "pending" || paramsStatus?.toLowerCase() == "approved")
                            && !receiptFile ?
                                <div style={{paddingRight: 6}}>
                                    <Tooltip title={"Upload Receipt"}>
                                        <Button id={"upload-receipt-" + tableMeta?.rowIndex} onClick={() => {
                                            viewSpecialOrder(schoolId, programId, value, schoolName, programName, majorName, majorId)
                                            handleSelectedOpenReceiptSpecialOrder()
                                        }} variant={"outlined"}><UploadIcon/></Button>
                                    </Tooltip>
                                </div> : null
                            }
                            {(paramsStatus?.toLowerCase() == "pending" || paramsStatus?.toLowerCase() == "approved") && !(type?.id == 1 && roles?.toLowerCase() == "admin") && receiptFile ?
                                <div style={{paddingRight: 6}}>
                                    <Tooltip title={"Paid"}>
                                        <Button id={"preview-receipt-" + tableMeta?.rowIndex} onClick={() => {
                                            setPreview(receiptFile)
                                            setInfo({
                                                datePaid: _datePaid,
                                                totalAmount: _totalAmount,
                                                bankName: _bankName,
                                                receiptNumber: _receiptNumber
                                            })
                                            handleOpenPreview()
                                        }} variant={"outlined"}><PesosSign/></Button>
                                    </Tooltip>
                                </div> : null
                            }
                            <div style={{paddingRight: 6}}>
                                <Button id={"view" + tableMeta?.rowIndex} onClick={() => {
                                    viewSpecialOrder(schoolId, programId, value, schoolName, programName, majorName, majorId)
                                    if(type?.id === 2 && roles?.toLowerCase() == "admin" && !_programCode) {
                                        handleAddSpecialOrderNumberView()
                                    }
                                    else if(type?.id === 2 && roles?.toLowerCase() == "admin" && _programCode) {
                                        handleSpecialOrderView()
                                    }else {
                                        handleSpecialOrderView()
                                    }
                                }
                                } variant={"outlined"}>View</Button>
                            </div>
                        </div>

                    );
                },
            },
        },
    ];
    useEffect(() => {
        if (dataSchools?.data && user?.school?.id) {
            setSelectedSchool(user?.school?.id ? dataSchools?.data?.find(c => c.id == user?.school?.id) : {
                name: "All",
                id: 0
            });
        } else if (!selectedSchool) {
            setSelectedSchool({name: "All", id: undefined})
        }
    }, [dataSchools]);





    const handleDegreeChange = (event: SelectChangeEvent) => {
        setDegree(event.target.value);
    };
    const viewSpecialOrder = (schoolId: number, programId: number, value: number, schoolName: string, programName: string, majorName: string, majorId: number) => {

        dispatch(selectedViewSO({
            schoolId: schoolId,
            programId: programId,
            id: value,
            schoolName: schoolName,
            programName: programName,
            majorId: majorId,
            majorName: majorName
        }))

    }

    const SpecialOrderViewDialogMemo = useMemo(() => {
        return <SpecialOrderViewDialog
            title={"Special Order Payment"}
            open={openSpecialOrderView}
            handleClose={() => {
                handleCloseSpecialOrderView()
            }}
        />
    }, [openSpecialOrderView])

    const AddSoNumberMemo = useMemo(() => {
        // @ts-ignore
        return <AddSONumberDialog
            title={"Please enter the Special Order (SO) Number below."}
            open={openAddSpecialOrderNumberView}
            onSave={async (soNumber) => {
                await refetch()
                handleCloseAddSpecialOrderNumberView()
                setSnackbarStore({
                    message: "Successfully added Special Order(SO) number",
                    severity: "success",
                    open: true,
                })
            }}
            handleClose={() => {
                handleCloseAddSpecialOrderNumberView()
            }}
        />
    }, [openAddSpecialOrderNumberView])
    const {
        handleOpen: handleSelectedOpenReceiptSpecialOrder,
        handleClose: handleSelectCloseReceiptSpecialOrder,
        open: openSelectedReceiptSpecialOrder,
    } = useDialog();

    const {
        handleOpen: handleOpenPreview,
        handleClose: handleClosePreview,
        open: openPreview,
    } = useDialog();
    const filesPreviewHandleClose = () => {
        setPreview("")
        setInfo({})
        handleClosePreview()
    }

    return <>
        <Helmet>
            <title>Special Orders</title>
        </Helmet>

        <Box
            component="main"
            sx={{flexGrow: 1, pt: 10}}
            style={{
                paddingBottom: "10px",
                paddingLeft: " 20px",
                paddingRight: "20px",
            }}
        >

            <Container>

                <Paper>

                    <List
                        dense
                        subheader={
                            <>

                            </>
                        }
                    >
                        <Box display="flex" justifyContent="space-between">
                            <Lottie style={{height: 100}} loop={false} animationData={soarlogo}/>
                            <Tabs
                                value={tabIndex}
                                onChange={(e, index) => {
                                    if (index == 0) {
                                        dispatch(getParamsStatus(null))
                                    } else if (index == 1) {
                                        dispatch(getParamsStatus("pending"))
                                    } else if (index == 2) {
                                        dispatch(getParamsStatus("approved"))
                                    }
                                    setTabIndex(index)

                                }
                                }
                                classes={tabsStyles}
                                TabIndicatorProps={{
                                    children: <div className={`MuiIndicator-${tabIndex}`}/>,
                                }}
                            >

                                <Tab
                                    classes={tabItem1Styles}
                                    disableTouchRipple
                                    label={''}
                                    icon={<TabCard id={"candidate_1"} onClick={() => {

                                    }
                                    } color={"#CE2028"} title={"No. of Candidates"} count={graduatesCountMemo}>
                                        <GraduateIcon/>
                                    </TabCard>}
                                />
                                <Tab
                                    classes={tabItem2Styles}
                                    disableTouchRipple
                                    label={
                                        ""
                                    }
                                    icon={<TabCard id={"pending_2"} onClick={() => {

                                    }
                                    } color={"#034F8B"} title={"No. of Pending"} count={pendingCount}>
                                        <PendingActions style={{fontSize: 26, color: "#fff"}}/>
                                    </TabCard>}
                                />
                                <Tab
                                    classes={tabItem3Styles}
                                    disableTouchRipple
                                    label={''}
                                    icon={<TabCard id={"approved_3"} onClick={() => {

                                    }
                                    } color={"#2f7d32"} title={"No. of Approved"} count={approvedCount}>
                                        <CheckBox style={{fontSize: 26, color: "#fff"}}/>
                                    </TabCard>}
                                />
                            </Tabs>
                        </Box>

                        <Box
                            sx={{
                                mt: 1,
                                overflowY: "scroll",
                            }}
                        >
                            <MUIDataTable

                                options={options}
                                title={
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <div style={{display: "flex", flex: 1}}>
                                            <Typography variant={"h6"}>{selectedSchool?.name || ""}</Typography>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "row",
                                            justifyContent: "flex-end",
                                            alignItems: "center"
                                        }}>

                                            {((roles?.toLowerCase() == "superadmin" || type?.id == 3) && roles?.toLowerCase() != "user") ?
                                                <form onSubmit={(event) => event.preventDefault()}>
                                                    <>
                                                        <Autocomplete
                                                            popupIcon={<ArrowDropDown
                                                                id={"ArrowDropDown-" + "heiacademiccalendarselectRegionAutocomplete"}/>}

                                                            disabled={isLoading}
                                                            disableClearable
                                                            size="small"
                                                            id={"heiacademiccalendarselectRegionAutocomplete"}
                                                            value={selectedRegionId}
                                                            onChange={(event: any, newValue: string | null) => {
                                                                if (selectedSchool?.regionId != regionId?.id) {
                                                                    setSelectedSchool({
                                                                        id: 0,
                                                                        name: "All",
                                                                    });
                                                                }
                                                                setSelectedRegionId(newValue ? newValue : {});
                                                            }}
                                                            getOptionLabel={(option) => {
                                                                return option.name
                                                            }
                                                            }
                                                            renderOption={(props, option) => (
                                                                <li {...props} id={"settingregionselect" + option.id}
                                                                    key={option.id}>
                                                                    <div style={{
                                                                        flex: 1,
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}>
                                                                        {option.name}</div>
                                                                </li>
                                                            )}
                                                            options={[{
                                                                name: "All",
                                                                id: undefined
                                                            }, ...(regions || [])] || []}
                                                            style={{width: 200, marginRight: 6,}}

                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Select Region"/>
                                                            )}
                                                        />
                                                    </>
                                                </form> : null}

                                            {(type?.id === 3 || roles?.toLowerCase() == "superadmin") || (type?.id == 2 && roles?.toLowerCase() == "admin") ?
                                                <form onSubmit={(event) => event.preventDefault()}>
                                                    <>
                                                        <Autocomplete
                                                            disabled={isLoading}
                                                            disableClearable

                                                            size="small"
                                                            id={"heiacademiccalendarselectAutocomplete"}
                                                            value={selectedSchool}
                                                            onChange={(event: any, newValue: string | null | any) => {

                                                                if (newValue?.regionId && newValue?.regionName) {
                                                                    setSelectedRegionId({
                                                                        id: newValue?.regionId,
                                                                        name: newValue?.regionName,
                                                                    });
                                                                } else {
                                                                    setSelectedRegionId({
                                                                        id: 0,
                                                                        name: "All",
                                                                    });
                                                                }

                                                                setSelectedSchool(newValue);
                                                            }}
                                                            getOptionLabel={(option) => {
                                                                return option.name
                                                            }
                                                            }
                                                            popupIcon={<ArrowDropDown
                                                                id={"ArrowDropDown-" + "heiacademiccalendarselectAutocomplete"}/>}
                                                            renderOption={(props, option: any) => (
                                                                <li {...props}
                                                                    id={"heiacademiccalendarselectAutocomplete-" + option?.id}
                                                                    key={option.id}>
                                                                    <div style={{
                                                                        flex: 1,
                                                                        display: "flex",
                                                                        flexDirection: "column"
                                                                    }}>
                                                                        {option.name ?? option.displayName}
                                                                        <p style={{
                                                                            fontStyle: "italic",
                                                                            margin: 0,
                                                                            fontSize: 12
                                                                        }}>{option?.institutionalCode ? option.institutionalCode + " - " : ""}{option?.address ? option.address : (option?.address ? option.address + " - " : "") + (option?.province?.name ? option.province.name + " - " : "") + (option?.region?.name ? option.region.name + "" : "")}</p>
                                                                    </div>
                                                                </li>
                                                            )}
                                                            options={[{
                                                                name: "All",
                                                                id: 0
                                                            }, ...(dataSchools?.data || [])] || []}
                                                            sx={{width: 300}}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Select HEI Profile"/>
                                                            )}
                                                        />
                                                    </>
                                                </form> : null}

                                            <form onSubmit={(event) => event.preventDefault()}>
                                                <>
                                                    <div style={{paddingLeft: 6}}>
                                                        <Autocomplete id={"studentliststudentdashboardschoolyear"}
                                                                      style={{width: 200}}
                                                                      disabled={isLoading}
                                                                      disableClearable
                                                                      size="small"
                                                                      value={selectedProgram}
                                                                      onChange={(event: any, newValue: string | null) => {


                                                                          setSelectedProgram(newValue ? newValue : {});
                                                                      }}
                                                                      getOptionLabel={(option) => {
                                                                          return option.name
                                                                      }
                                                                      }
                                                                      renderOption={(props, option, index) => (
                                                                          <li {...props}
                                                                              key={option.name + "-" + option.id + "" + props.id}>
                                                                              <div style={{
                                                                                  flex: 1,
                                                                                  display: "flex",
                                                                                  flexDirection: "column"
                                                                              }}>
                                                                                  {option.name}

                                                                              </div>
                                                                          </li>
                                                                      )}
                                                                      options={[{
                                                                          name: "All",
                                                                          id: 0
                                                                      }, ...(_.uniqWith(dataPrograms?.data?.map((c: any, index: number) => {
                                                                          return c
                                                                      }), function (arrVal: any, othVal: any) {
                                                                          return arrVal?.id === othVal?.id;
                                                                      }) || [])] || []}
                                                                      sx={{width: 500}}
                                                                      renderInput={(params) => (
                                                                          <TextField  {...params}
                                                                                      label="Select Program"/>
                                                                      )}
                                                        />
                                                    </div>

                                                </>
                                            </form>
                                            <FormControl style={{paddingLeft: 6}}>
                                                <InputLabel
                                                    id="degree-select">Degree</InputLabel>
                                                <Select
                                                    style={{width: 200,}}
                                                    aria-label={"degree-select-role-label"}
                                                    size={"small"}
                                                    onChange={handleDegreeChange}
                                                    labelId="degree-select"
                                                    id="degree-select"
                                                    inputProps={{
                                                        'id': `degree-select`
                                                    }}
                                                    SelectDisplayProps={{
                                                        // @ts-ignore
                                                        "id": `degree-select`
                                                    }}
                                                    label="Degree"
                                                    value={degree}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>Select Degree</em>
                                                    </MenuItem>
                                                    {(levels?.data ?? [{
                                                        id: 0,
                                                        name: "All"
                                                    }])?.map((c: any, index: any) => {

                                                        return (
                                                            <MenuItem
                                                                id={"degree-" + index}
                                                                value={c?.id}>{c?.name}</MenuItem>
                                                        );
                                                    })}


                                                </Select>
                                            </FormControl>
                                        </div>

                                    </div>

                                }
                                data={students?.data as any}
                                columns={schoolAdminColumns}
                            />
                        </Box>
                    </List>
                </Paper>
                <FilesPreviewDialog open={openPreview}
                                    handleClose={filesPreviewHandleClose}
                                    preview={preview}
                                    info={info}
                                    title={"Preview Receipt"}/>
                <UploadFileDialog title={"Upload Receipt"}
                                  open={openSelectedReceiptSpecialOrder}
                                  handleClose={() => {
                                      handleSelectCloseReceiptSpecialOrder()
                                  }
                                  }
                                  onSave={() => {

                                  }}></UploadFileDialog>
                {SpecialOrderViewDialogMemo}
                {AddSoNumberMemo}
                <PrintDialog open={printDialogOpen} handleClose={handleClosePrintDialog} handlePrint={handlePrint} />

            </Container>
        </Box>

    </>
}
