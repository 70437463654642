import * as React from "react";
const CurriculumSvg = (props: any) => (
    <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"

        y="0px"
        viewBox="0 0 31 31"
        style={{
            enableBackground: "new 0 0 420 420",
        }}
        xmlSpace="preserve"
        width={31}
        fill="#ffff"
        height={31}
        {...props}
    >
        <g>
            <path
                d="M28.118 5.831c-0.002 -0.018 -0.005 -0.036 -0.008 -0.053 -0.001 -0.006 -0.002 -0.012 -0.003 -0.019a0.738 0.738 0 0 0 -0.016 -0.062q-0.001 -0.004 -0.002 -0.007a0.738 0.738 0 0 0 -0.022 -0.061q-0.001 -0.003 -0.002 -0.007a0.738 0.738 0 0 0 -0.026 -0.055c-0.002 -0.003 -0.003 -0.007 -0.005 -0.01a0.738 0.738 0 0 0 -0.028 -0.047c-0.003 -0.005 -0.006 -0.01 -0.009 -0.015 -0.009 -0.014 -0.02 -0.027 -0.03 -0.04 -0.005 -0.006 -0.009 -0.012 -0.013 -0.018a0.738 0.738 0 0 0 -0.038 -0.042c-0.004 -0.004 -0.007 -0.008 -0.01 -0.012l-5.167 -5.167c-0.003 -0.003 -0.007 -0.006 -0.011 -0.01a0.738 0.738 0 0 0 -0.042 -0.038c-0.005 -0.005 -0.011 -0.008 -0.017 -0.013 -0.014 -0.01 -0.027 -0.021 -0.041 -0.03 -0.005 -0.003 -0.009 -0.006 -0.014 -0.009a0.738 0.738 0 0 0 -0.048 -0.029c-0.003 -0.002 -0.006 -0.003 -0.009 -0.005 -0.018 -0.009 -0.037 -0.018 -0.056 -0.026 -0.002 -0.001 -0.004 -0.001 -0.006 -0.002a0.738 0.738 0 0 0 -0.061 -0.022c-0.002 -0.001 -0.005 -0.001 -0.008 -0.002a0.738 0.738 0 0 0 -0.062 -0.016c-0.006 -0.001 -0.013 -0.002 -0.019 -0.003 -0.017 -0.003 -0.035 -0.006 -0.053 -0.008A0.664 0.664 0 0 0 22.217 0H3.617c-0.408 0 -0.738 0.33 -0.738 0.738v29.524c0 0.408 0.331 0.738 0.738 0.738h23.767c0.408 0 0.738 -0.33 0.738 -0.738V5.905q0 -0.037 -0.004 -0.074M22.955 2.52 25.601 5.167H22.955zM4.355 29.524V1.476h17.124v4.429c0 0.408 0.331 0.738 0.738 0.738h4.429v22.881z"/>
            <path
                d="M9.521 11.367c0 0.408 0.331 0.738 0.738 0.738h4.724c0.408 0 0.738 -0.33 0.738 -0.738s-0.331 -0.738 -0.738 -0.738h-4.724c-0.408 0 -0.738 0.33 -0.738 0.738"/>
            <path
                d="M14.836 7.676c0 -1.221 -0.993 -2.214 -2.214 -2.214s-2.214 0.993 -2.214 2.214 0.993 2.214 2.214 2.214 2.214 -0.993 2.214 -2.214m-2.952 0c0 -0.407 0.331 -0.738 0.738 -0.738s0.738 0.331 0.738 0.738 -0.331 0.738 -0.738 0.738 -0.738 -0.331 -0.738 -0.738"/>
            <path
                d="M7.307 15.057h10.629c0.408 0 0.738 -0.33 0.738 -0.738v-2.354c0 -0.408 -0.331 -0.738 -0.738 -0.738s-0.738 0.33 -0.738 0.738V13.581H8.045V4.429h9.152v5.019c0 0.408 0.331 0.738 0.738 0.738s0.738 -0.33 0.738 -0.738V3.69c0 -0.408 -0.331 -0.738 -0.738 -0.738H7.307c-0.408 0 -0.738 0.33 -0.738 0.738v10.629c0 0.408 0.331 0.738 0.738 0.738"/>
            <path
                d="M23.693 17.271H7.307c-0.408 0 -0.738 0.33 -0.738 0.738s0.331 0.738 0.738 0.738h16.386c0.408 0 0.738 -0.33 0.738 -0.738s-0.331 -0.738 -0.738 -0.738"/>
            <path
                d="M23.693 20.962H7.307c-0.408 0 -0.738 0.33 -0.738 0.738s0.331 0.738 0.738 0.738h16.386c0.408 0 0.738 -0.33 0.738 -0.738s-0.331 -0.738 -0.738 -0.738"/>
            <path
                d="M23.693 24.652H7.307c-0.408 0 -0.738 0.33 -0.738 0.738s0.331 0.738 0.738 0.738h16.386c0.408 0 0.738 -0.33 0.738 -0.738s-0.331 -0.738 -0.738 -0.738"/>
        </g>
    </svg>
);
export default CurriculumSvg;
