import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useEffectiveDateQuery} from "../../hooks/useEffectiveDate"
import {setPaymentGateway} from "../../redux/features/specialOrder";
import {ReviewDialog} from "../dialogs/Review.dialog";
import Button from "@mui/material/Button";
import {useTransactionCodes} from "../../hooks/useTransactionCodes";


interface Student {
    studentId: any;
    majorId: any;
    schoolId: any;
    programId: any;
    name: string;
    studentNumber: string;
}

interface EffectiveDate {
    amount?: number;
}
    interface ExpandableRowProps {
        so: Student;
        effectiveDate: EffectiveDate;
        handleOpen: (program: any) => void;
    }

    const ExpandableRow: React.FC<ExpandableRowProps> = ({ so, effectiveDate, handleOpen  }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
            handleOpen({
                programId: so.programId,
                schoolId: so.schoolId,
                studentNumber: so.studentNumber,
                majorId: so.majorId,
                studentId: so.studentId
            })

        };



        return (
            <>
                <tr onClick={toggleExpand} className="cursor-pointer">
                    <td className="border-b dark:border-darkmode-400">
                        <div className="font-medium whitespace-nowrap">{so?.name}</div>
                        <div className="text-slate-500 text-sm mt-0.5 whitespace-nowrap">{so?.studentNumber}</div>
                    </td>
                    <td className="text-right border-b dark:border-darkmode-400 w-32">
                        <div className="font-medium whitespace-nowrap">
                            <span>₱ {effectiveDate?.amount ?? 30}</span>
                        </div>

                    </td>
                    <td className="text-right border-b dark:border-darkmode-400 w-32">
                        <Button id={"cancelacademiccalendar"} onClick={toggleExpand}>View Preview</Button>

                    </td>
                </tr>

            </>
        );
    };

    function Review(props: any) {
        const dispatch = useDispatch();
        const sos = useSelector((store: RootState) => store.specialOrder?.sos)
        const specialOrderProgramName = useSelector((store: RootState) => store.specialOrder?.specialOrderProgramName)
        const specialOrderSchoolName = useSelector((store: RootState) => store.specialOrder?.specialOrderSchoolName)
        const paymentGateway = useSelector((store: RootState) => store.specialOrder?.paymentGateway)
        const {schoolId} = useAddEntities();
        const authStore = useSelector((store: RootState) => store.auth);
        const handlePaymentGatewayChange = (event: any) => {
            dispatch(setPaymentGateway(event.target.value));

        };
        const {data, error, isLoading, refetch} = useTransactionCodes("SO");
        const {data: effectiveDate} = useEffectiveDateQuery({
            ...(schoolId && {schoolId: schoolId})
        });


        const [dialogOpen, setDialogOpen] = useState(false);
        const [studentId, setStudentId] = useState();
        const [majorId, setMajorId] = useState();
        const [programId, setProgramId] = useState();
        const [studentNumber, setStudentNumber] = useState("");
        const [contactNumber, setContactNumber] = useState("");
        const [_schoolId, setSchoolId] = useState();
        const handleDialogOpen = (so: Student) => {
            setDialogOpen(true);
            setSchoolId(so.schoolId)
            setStudentId(so.studentId)
            setMajorId(so.majorId)
            setStudentNumber(so.studentNumber)
            setProgramId(so.programId)
        };

        const handleDialogClose = () => {
            setDialogOpen(false);
        };

        const handleSave = () => {
            // Perform save action
            setDialogOpen(false);
        };
        return (
            <React.Fragment>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                    <div className="sm:w-1/2 intro-y box px-5 pt-5 mt-5">
                        <div
                            className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
                            <div
                                className="mt-6 lg:mt-0 flex-1 px-5 border-t lg:border-0 border-slate-200/60 dark:border-darkmode-400 pt-5 lg:pt-0">

                                <div className="space-y-4">
                                    <div className="font-medium text-center lg:text-left lg:mt-5">Payment Details</div>
                                    <div className="relative">
                                        <select aria-label="Payment Gateway:" onChange={handlePaymentGatewayChange}
                                                className="peer p-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
  focus:pt-6
  focus:pb-2
  [&:not(:placeholder-shown)]:pt-6
  [&:not(:placeholder-shown)]:pb-2
  autofill:pt-6
  autofill:pb-2">
                                            <option value={"Cashier"}>Over the counter</option>
                                            <option value={"LandBank"}>Land Bank</option>
                                        </select>
                                        <label className="absolute top-0 start-0 p-4 h-full truncate pointer-events-none transition ease-in-out duration-100 border border-transparent dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
    peer-focus:text-xs
    peer-focus:-translate-y-1.5
    peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
    peer-[:not(:placeholder-shown)]:text-xs
    peer-[:not(:placeholder-shown)]:-translate-y-1.5
    peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">Payment
                                            Gateway:</label>
                                    </div>
                                    <div
                                        className="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700"
                                        data-hs-input-number="">
                                        <div className="w-full flex justify-between items-center gap-x-3">
                                            <div>
      <span className="block text-sm text-gray-800 dark:text-white">
        No. Student Applied:
      </span>
                                                <span
                                                    className="text-success font-medium  block text-sm text-gray-500 dark:text-neutral-400">
       {sos?.length ?? 0}
      </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700"
                                        data-hs-input-number="">
                                        <div className="w-full flex justify-between items-center gap-x-3">
                                            <div>
      <span className="block text-sm text-gray-800 dark:text-white">
        Per student amount:
      </span>
                                                <span
                                                    className="text-success font-medium  block text-sm text-gray-500 dark:text-neutral-400">
       ₱ {(effectiveDate?.amount ? effectiveDate?.amount : 0)}
      </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700"
                                        data-hs-input-number="">
                                        <div className="w-full flex justify-between items-center gap-x-3">
                                            <div>
      <span className="block text-sm text-gray-800 dark:text-white">
        Rule:
      </span>
                                                <span
                                                    className="text-success  font-medium  block text-sm text-gray-500 dark:text-neutral-400">
       {effectiveDate?.name ?? ""}
      </span>
                                            </div>
                                        </div>
                                    </div>
                                    {paymentGateway == "LandBank" ? <div
                                        className="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700"
                                        data-hs-input-number="">
                                        <div className="w-full flex justify-between items-center gap-x-3">
                                            <div>
                                    <span className="block text-sm text-gray-800 dark:text-white">
                                    Institutional Code:
                                    </span>
                                                <span
                                                    className="text-success font-medium  block text-sm text-gray-500 dark:text-neutral-400">
                                {authStore?.user?.school?.code ?? ""}
                            </span>
                                            </div>
                                        </div>
                                    </div> : null
                                    }
                                    {
                                        authStore?.user?.email && paymentGateway == "LandBank" ? <div
                                            className="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700"
                                            data-hs-input-number="">
                                            <div className="w-full flex justify-between items-center gap-x-3">
                                                <div>
      <span className="block text-sm text-gray-800 dark:text-white">
        Email Address:
      </span>
                                                    <span
                                                        className="text-success font-medium  block text-sm text-gray-500 dark:text-neutral-400">
       {authStore?.user?.email ?? ""}
      </span>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                    {
                                        paymentGateway == "LandBank" ?


                                            <div className="relative">
                                                <input
                                                    className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                            focus:pt-6
                            focus:pb-2
                            [&:not(:placeholder-shown)]:pt-6
                            [&:not(:placeholder-shown)]:pb-2
                            autofill:pt-6
                            autofill:pb-2"
                                                    type="text"
                                                    id="contactNumber"
                                                    value={contactNumber}
                                                    onChange={(e) => setContactNumber(e.target.value)}
                                                    maxLength={50}
                                                />
                                                <label htmlFor="contactNumber" className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                          peer-focus:scale-90
                          peer-focus:translate-x-0.5
                          peer-focus:-translate-y-1.5
                          peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                          peer-[:not(:placeholder-shown)]:scale-90
                          peer-[:not(:placeholder-shown)]:translate-x-0.5
                          peer-[:not(:placeholder-shown)]:-translate-y-1.5
                          peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">
                                                    Contact Number:
                                                </label>
                                            </div>
                                            : null
                                    }

                                    {paymentGateway == "LandBank" ? <div className="relative">
                                        <input
                                            className="text-success peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:ring-neutral-600
                            focus:pt-6
                            focus:pb-2
                            [&:not(:placeholder-shown)]:pt-6
                            [&:not(:placeholder-shown)]:pb-2
                            autofill:pt-6
                            autofill:pb-2"
                                            type="text"
                                            id="merchantReference"
                                            value={isLoading ? "loading..." : data?.response?.item1 || ''}
                                            readOnly
                                            maxLength={50}
                                        />
                                        <label htmlFor="merchantReference" className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent  origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
                          peer-focus:scale-90
                          peer-focus:translate-x-0.5
                          peer-focus:-translate-y-1.5
                          peer-focus:text-gray-500 dark:peer-focus:text-neutral-500
                          peer-[:not(:placeholder-shown)]:scale-90
                          peer-[:not(:placeholder-shown)]:translate-x-0.5
                          peer-[:not(:placeholder-shown)]:-translate-y-1.5
                          peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 dark:text-neutral-500">
                                            Transaction Code:
                                        </label>
                                    </div> : null}

                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="sm:w-1/2 intro-y box overflow-hidden mt-5">

                        <div
                            className="flex flex-col lg:flex-row pt-10 px-5 sm:px-20 sm:pt-20 lg:pb-20 text-center sm:text-left">
                            <div className="font-semibold text-primary text-3xl">SUMMARY</div>

                        </div>
                        <div
                            className="flex flex-col lg:flex-row border-b px-5 sm:px-20  sm:pb-20 text-center sm:text-left">
                            <div>
                                <div className="text-base text-slate-500">School Details</div>
                                <div
                                    className="text-lg font-medium text-primary mt-2">{specialOrderSchoolName ?? ""}</div>
                            </div>
                            <div className="mt-10 lg:mt-0 lg:ml-auto lg:text-right">
                                <div className="text-base text-slate-500">Program Details</div>
                                <div
                                    className="text-lg text-primary font-medium mt-2">{specialOrderProgramName ?? ""}</div>
                            </div>
                        </div>
                        <div className="px-5 sm:px-16 py-10 sm:py-20">
                            <div className="overflow-x-auto">
                                <table className="table">
                                <thead>
                                <tr>
                                    <th className="border-b-2 dark:border-darkmode-400 whitespace-nowrap">Name</th>
                                    <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">QTY</th>
                                    <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    sos?.map(so => {
                                        return <>
                                            <ExpandableRow handleOpen={handleDialogOpen} so={so} effectiveDate={effectiveDate}/>
                                        </>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ReviewDialog
                    schoolId={_schoolId}
                    majorId={majorId}
                    studentId={studentId}
                    programId={programId}
                    studentNumber={studentNumber}
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    onSave={handleSave} />
                    <div className="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row">
                        <div className="text-center sm:text-left mt-10 sm:mt-0">
                            <div
                                className="text-lg text-primary font-medium mt-2">{paymentGateway?.toUpperCase() ?? ""}</div>
                        </div>
                        <div className="text-center sm:text-right sm:ml-auto">
                            <div className="text-base text-slate-500">Total Amount</div>
                            <div
                                className="text-xl text-primary font-medium mt-2">₱ {(sos?.length ? (effectiveDate?.amount ?? 30) * sos?.length : 0)}</div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }




    export default Review;
