// create slice for app
import {ReactNode} from "react";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IAppSlice {
  loading: boolean;

  deleteDialog: Partial<DeleteDialog>;
  logoutDialog: Partial<LogoutDialog>;
  updateDialog: Partial<UpdateDialog>;
}

type DeleteDialog = {
  open: boolean;
  data: Partial<{
    id: any;
    customMessage: string;
    title: string;
    body: string;
    content: ReactNode;
    onDelete: any;
  }>;
};
type LogoutDialog = {
  open: boolean;
  data: Partial<{
    id: any;
    title: string;
    content: ReactNode;
  }>;
};

type UpdateDialog = {
  open: boolean;
  data: {
    title: string;
    content: ReactNode;
  };
};

const initialState: IAppSlice = {
  loading: false,

  deleteDialog: {
    open: false,
    data: {
      id: null,
      title: "Delete item",
      content: null,
      onDelete: null,
      body: "",
    },
  },
  logoutDialog: {
    open: false,
    data: {
      id: null,
      title: "logout item",
      content: null,
    },
  },
  updateDialog: {
    open: false,
    data: {
      title: "Update item",
      content: null,
    },
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      return state;
    },
    setDeleteDialog: (
      state,
      action: PayloadAction<IAppSlice["deleteDialog"]>
    ) => {
      state.deleteDialog = action.payload;
      return state;
    },

    setLogoutDialog: (
      state,
      action: PayloadAction<IAppSlice["logoutDialog"]>
    ) => {

      state.logoutDialog = action.payload;
      return state;
    },
    setUpdateDialog: (
      state,
      action: PayloadAction<IAppSlice["updateDialog"]>
    ) => {
      state.updateDialog = action.payload;
      return state;
    },
  },
});

export const { setLogoutDialog, setLoading, setDeleteDialog, setUpdateDialog } =
  appSlice.actions;
export default appSlice.reducer;
