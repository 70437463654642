import {
    Block,
    DeleteRounded,
    EventNoteRounded,
    MoreHoriz,
    SchoolRounded,
    ThumbUpRounded,
    VisibilityRounded
} from "@mui/icons-material"
import {
    Avatar,
    Box,
    Chip,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Tooltip,
    Typography
} from "@mui/material"
import React, {MouseEvent, useCallback, useMemo, useState} from "react"
import {useQueryClient} from "react-query"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {setLoading} from "../../redux/features/app"
import {ISnackbarSlice, openSnackbar,} from "../../redux/features/snackbar"
import {deleteSchool, updateSchoolStatus} from "../../requests/school.request"
import {SchoolType} from "../../types"
import useMenuDialog from "../dialogs/Menu.dialog"
import useSchoolDetailDialog from "../dialogs/SchoolDetail.dialog"
import {setErrorSnackbar} from "../../helper/utils/helper";
import {setPostCount, setSelectedAcademicCalendar, setSelectedSchool} from "../../redux/features/school";
import {RootState} from "../../redux/store";

const status = {
    active: < Chip  label="Active" variant="outlined" color="success"/>,
    inactive: <Chip label="Inactive" variant="outlined" color="error"/>,
    pending: <Chip label="Pending" variant="outlined" color="warning"/>,
    rejected: <Chip label="Rejected" variant="outlined" color="error"/>,
    approved: <Chip label="Approved" variant="outlined" color="success"/>
}

const SchoolList = ({schools}: { schools: SchoolType[] }) => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const history = useHistory()
    const [selectedSchoolId, setSelectedSchoolId] = React.useState<number | null>(null)
    const authStore = useSelector((store: RootState) => store.auth)

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const {
        Component: SchoolDetail,
        handleClickOpen,
        handleClose
    } = useSchoolDetailDialog({onClose: () => setSelectedSchoolId(null)})
    const {
        Component: Menu,
        handleClick: handleClickOpenMenu,
        handleClose: handleCloseMenu
    } = useMenuDialog({onClose: () => setSelectedSchoolId(null)})

    const handleDelete = async () => {
        if (selectedSchoolId) {
            try {
                dispatch(setLoading(true))
                await deleteSchool(selectedSchoolId)
                setSnackbarStore({
                    message: 'School deleted successfully', severity: 'success', open: true
                })
                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData, data: oldData.data?.filter((school: any) => school.id !== selectedSchoolId)
                    }
                })
            } catch (error: any) {
                console.error({error})
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                dispatch(setLoading(false))
            }
        }
    }
    const handleApprove = useCallback(async () => {
        if (selectedSchoolId) {
            try {
                dispatch(setLoading(true))
                const res = await updateSchoolStatus(selectedSchoolId, 'approved')
                setSnackbarStore({
                    message: 'School approved successfully. Your request will be reflected after 5 minutes.', severity: 'success', open: true
                })

                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((school: any) => {
                            if (school.id === selectedSchoolId) {
                                res.status = "approved"
                                return res
                            }
                            return school
                        })
                    }
                })
                dispatch(setPostCount())
            } catch (error: any) {
                dispatch(setPostCount())
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                dispatch(setPostCount())
                dispatch(setLoading(false))
            }
        }
    }, [selectedSchoolId])

    const handleViewStudent = useCallback(async (school: any) => {
        dispatch(setSelectedSchool(school.school))
        history.push(`/students/school/${school.school?.id}`, {from: '/schools'})
    }, [])
    const handleViewAcademic = useCallback(async (school: any) => {
        dispatch(setSelectedAcademicCalendar(school.school))
        history.push(`/academic/calendar/${school.school?.id}`, {from: '/schools'})
    }, [])

    const handleDeactivate = useCallback(async () => {
        if (selectedSchoolId) {
            try {
                dispatch(setLoading(true))
                const res = await updateSchoolStatus(selectedSchoolId, 'inactive')
                setSnackbarStore({
                    message: 'School deactivated successfully. Your request will be reflected after 5 minutes.', severity: 'success', open: true
                })
                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((school: any) => {
                            if (school.id === selectedSchoolId) {
                                res.status = "inactive"
                                return res
                            }
                            return school
                        })
                    }
                })
            } catch (error: any) {
                console.error({error})
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                dispatch(setLoading(false))
            }

        }
    }, [selectedSchoolId])
    const actions = useMemo(() => {
        return ({
                pending: <MenuItem
                    value="approve"
                    sx={{color: 'success.main',}}
                    onClick={handleApprove}
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <ThumbUpRounded fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Approve</ListItemText>
                </MenuItem>, inactive: <MenuItem
                    value="approve"
                    sx={{color: 'success.main',}}
                    onClick={handleApprove}
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <ThumbUpRounded fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Approve</ListItemText>
                </MenuItem>, approved: <MenuItem
                    onClick={handleDeactivate}
                    sx={{color: 'warning.main',}}
                    value="deactivate"
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <Block fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Deactivate</ListItemText>
                </MenuItem>
            }

        )
    }, [handleApprove, handleDeactivate])
    const selectedSchool = useMemo(() => {
        return schools?.find((school) => school.id === selectedSchoolId)
    }, [selectedSchoolId])
    const onRowClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setSelectedSchoolId(+e.currentTarget.value)
        handleClickOpenMenu(e)
    }, [])
    const onPreviewClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setSelectedSchoolId(+e.currentTarget.value)
        handleClickOpen()
    }, [])
    const handleViewSchool = useCallback(({
                                              school,
                                              schoolId,
                                              title,
                                              region,
                                              institutionalCode
                                          }: { school: any, schoolId: number, title: string, region: string, institutionalCode: string }) => {
        dispatch(setSelectedSchool(school))
        history.push(`/schools/${schoolId}`, {schoolId, region, institutionalCode, from: '/schools', title})
    }, [])

    const levels = useMemo(() => {
        return ["Doctoral", "Baccalaureate", "Basic", "Pre-Baccalaureate", "Post-Baccalaureate", "Masters",

        ]
    }, []);


    const userAdminRoles = useMemo(() => ["user", "admin"].indexOf(authStore?.user?.roles.toLowerCase()), [authStore?.user?.roles])
    const [open, setOpen] = useState(false);






    return <>
        <SchoolDetail school={selectedSchool}/>


        <Menu>

            <MenuList sx={{width: 320, maxWidth: '100%'}}>
                {userAdminRoles == -1 || (authStore?.user?.type?.id === 2 || authStore?.user?.type?.id === 3) ? actions[selectedSchool?.status as keyof typeof actions] : null}
                {userAdminRoles == -1 || (authStore?.user?.type?.id === 2 || authStore?.user?.type?.id === 3) ?
                    <MenuItem
                        value="delete"

                        sx={{color: 'error.main',}}
                        onClick={handleDelete}
                    >
                        <ListItemIcon sx={{color: 'inherit'}}>
                            <DeleteRounded fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem> : null}

               {/* {userAdminRoles == -1 || (authStore?.user?.type?.id === 2) ? <MenuItem
                    value="view-student"

                    sx={{color: 'primary.dark',}}
                    onClick={handleViewStudent.bind(null, {school: selectedSchool})}
                >
                    <ListItemIcon sx={{color: 'inherit'}}>
                        <VisibilityRounded fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>View Student</ListItemText>
                </MenuItem> : null}*/}


            </MenuList>
        </Menu>


        {schools?.map((school, index) => {


            return (<React.Fragment key={index}>
                    <ListItem
                        id={"hie-" + index}
                        disablePadding
                        secondaryAction={<Box sx={{
                            display: "flex", gap: 2,
                        }}>
                            {status[school.status as keyof typeof status]}
                            {userAdminRoles == -1 || (authStore?.user?.type?.id === 2)? <Tooltip title="Academic Calendar">
                                <IconButton  sx={{
                                    '&:hover': {
                                        color: 'primary.main'
                                    }
                                }}
                                    onClick={handleViewAcademic.bind(null, {school: selectedSchool ?? school})}
                                    value={school.id}>
                                    <EventNoteRounded/>
                                </IconButton>
                            </Tooltip> : null}
                            {userAdminRoles > -1 ? <Tooltip title="List Students">
                                <IconButton

                                    onClick={handleViewStudent.bind(null, {school: selectedSchool ?? school})}
                                    value={school.id}>
                                    <VisibilityRounded/>
                                </IconButton>
                            </Tooltip> : null}
                            {(authStore?.user?.type?.id === 2 || authStore?.user?.type?.id === 3) ? <IconButton
                                onClick={onRowClick}
                                value={school.id}>
                                <MoreHoriz/>
                            </IconButton> : null}
                        </Box>}
                    >
                        <ListItemButton

                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <SchoolRounded/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography onClick={handleViewSchool.bind(null, {
                                    school: school,
                                    schoolId: school.id,
                                    title:  school.name,
                                    region: school.region?.name,
                                    institutionalCode: school.institutionalCode
                                })} variant="inherit" fontWeight="bold">{school.name}</Typography>}
                                secondary={<>
                                    <div style={{flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap"}}>

                                        <Typography
                                            onClick={handleViewSchool.bind(null, {
                                                school: school,
                                                schoolId: school.id,
                                                title: school.name,
                                                region: school.region?.name,
                                                institutionalCode: school.institutionalCode
                                            })}
                                            style={{
                                                maxWidth: '50%',
                                                whiteSpace: 'normal',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {(school.institutionalCode ? "Inst. Code: " + school.institutionalCode : "") + " | "}
                                        </Typography>

                                        <div style={{flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap"}}>

                                            <Typography
                                                onClick={handleViewSchool.bind(null, {
                                                    school: school,
                                                    schoolId: school.id,
                                                    title: school.name,
                                                    region: school.region?.name,
                                                    institutionalCode: school.institutionalCode
                                                })}
                                                style={{
                                                    maxWidth: '50%',
                                                    whiteSpace: 'normal',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {"Address: " + (school.address ? school.address : " (No Address Found. Please update your")}
                                            </Typography>

                                            {!school.address &&
                                                <Typography
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        history.push(`/account-settings`);
                                                    }}
                                                    color={"primary"}
                                                    style={{
                                                        maxWidth: '50%',
                                                        whiteSpace: 'normal',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    &nbsp;School Institutional Profile
                                                </Typography>
                                            }

                                            <Typography
                                                style={{
                                                    maxWidth: '50%',
                                                    whiteSpace: 'normal',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {school.address ? "" : ".)"}
                                            </Typography>

                                        </div>
                                    </div>
                                </>}

                            />
                        </ListItemButton
                        >
                    </ListItem>
                    <Divider/>
                </React.Fragment>)
        })}


    </>
}

export default React.memo(SchoolList)
