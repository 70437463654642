import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { useDebounce } from "../../hooks/useDebounce";
import { useSearchUserQuery } from "../../hooks/useSearchUserQuery";
import { CurriculumType } from "../../types";
import { useAppSelector } from "../../redux/store";

interface ISearchAsYouTypeProps {
    label: string;
    onSelected: (value: CurriculumType) => void;
    schoolId: any;
    required?: boolean;
    curriculumId?: any;
    defaultValue?: CurriculumType | null;
    disableClearable?: boolean;
}

const SearchUserTextField: React.FC<ISearchAsYouTypeProps> = (props) => {
    const {
        label,
        onSelected,
        schoolId,
        required,
        curriculumId,
        disableClearable,
        defaultValue,
    } = props;

    const [selected, setSelected] = React.useState<CurriculumType | null>(defaultValue || null);
    const [name, setName] = React.useState("");
    const debouncedName = useDebounce(name, 500);

    const { data: options, isLoading } = useSearchUserQuery(
        debouncedName,
        {
            ...(schoolId && { schoolId: schoolId }),
        }
    );

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (defaultValue) {
            setSelected(defaultValue);
        }
    }, [defaultValue]);

    const handleSelectionChange = (event: any, newValue: any | null) => {
        setSelected(newValue);
        if (newValue) {
            onSelected(newValue);
        }
    };

    const renderSelectedOption = (option: any) => (
        <div id={"searchasyoutypeuserschoolinstutional" + option?.id} style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {option.email}
        </div>
    );

    const getOptionLabel = (option: any) => {
        const parts = [option.email].filter(Boolean);
        return parts.join(" - ");
    };


    return (
        <Autocomplete
            id="searchCurriculum"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            disableClearable={disableClearable}
            getOptionLabel={(option) => getOptionLabel(option) || ""}
            options={options?.data || []}
            // @ts-ignore
            value={selected}
            // @ts-ignore
            onChange={handleSelectionChange}
            onInputChange={(event, newInputValue) => setName(newInputValue)}
            loading={isLoading}
            renderOption={(props, option) => (
                <li {...props} id={"settingschoolinstutional" + option?.id} key={option?.id}>
                    {renderSelectedOption(option)}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    margin="dense"
                    size="small"
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        style: { whiteSpace: "pre-wrap" },
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default React.memo(SearchUserTextField);
