import {ISnackbarSlice} from "../../redux/features/snackbar";
import {QueryClient} from "react-query";
import {
    IPostFileUploadsSpecialOrderRequirementFiles,
    PostFileUploadsSpecialOrderRequirementFiles
} from "../../requests/requirements.request";
import {setErrorSnackbar} from "../../helper/utils/helper";

export async function studentFileManagerSave(files: any[], user: any, specialOrderStudentNumberValue: number | string, schoolId: number | string, programId: number | string, majorId: number | string, studentId: number | string, setSnackbarStore: (payload: ISnackbarSlice) => void, queryClient: QueryClient, specialOrderStudentIdValue: number | number, queryKey: string) {
    var _programId = programId;
    var _____files = [...files]
    var retry = 0
    let body: IPostFileUploadsSpecialOrderRequirementFiles = {
        formFiles: [],
        specialOrderRequirementId: 0,
        specialOrderRequirementsName: 0,
        studentNumber: "",
        schoolId: 0,
        programId: 0,
        fileUploadType: 1,
        majorId: 0,
        uploadedBy: user.id,
        studentId: 0
    };
    for (const c of _____files) {
        body = {
            formFiles: c.files,
            specialOrderRequirementId: c.id,
            specialOrderRequirementsName: c.name,
            studentNumber: specialOrderStudentNumberValue,
            schoolId: +schoolId,
            programId: +_programId,
            fileUploadType: 1,
            majorId: majorId,
            uploadedBy: user.id,
            studentId: studentId
        };
        try {
            body.programId = _programId

            if (c.files?.filter((c: any) => !c?.id && !c.schoolId && !c.programId)?.length > 0) {

                var _PostFileUploadsSpecialOrderRequirementFiles = await PostFileUploadsSpecialOrderRequirementFiles(body)

                _____files = _____files.map((file: any) => {

                    if (c?.id == file?.id) {
                        var _______file = JSON.parse(JSON.stringify(file))
                        _______file.files = _______file?.files?.filter((c: any) => c.id)
                        _PostFileUploadsSpecialOrderRequirementFiles.data?.forEach((____file: any) => {
                            _______file?.files.push({
                                schoolId: schoolId,
                                programId: programId,
                                studentNumber: specialOrderStudentNumberValue,
                                preview: ____file
                            })
                        })
                    }

                    return _______file
                })
                setTimeout(() => {
                }, 10000)


            }

        } catch (e) {

            setErrorSnackbar(e, setSnackbarStore);
        }


    }
    queryClient.setQueriesData(queryKey, (oldData: any) => {
        return {
            ...oldData, data: oldData?.data?.map((sogbs: any) => {
                sogbs.students = sogbs?.students?.map((sogbsStudents: any) => {

                    sogbsStudents.status = sogbsStudents.status.map((sogbsStudentsStatus: any) => {
                        var filesIndex = files.findIndex((file: any) => file?.id == (sogbsStudentsStatus?.id))
                        if (filesIndex > -1 && !sogbsStudentsStatus?.isDeleted && sogbsStudents.id == specialOrderStudentIdValue && files?.[filesIndex]?.files?.length > 0 && sogbsStudents.id == specialOrderStudentIdValue) {
                            sogbsStudentsStatus.status = true
                        }
                        return sogbsStudentsStatus
                    })

                    sogbsStudents.requirementCompleted = sogbsStudents.status.length == sogbsStudents.status.filter((c: any) => c.status == true)?.length
                    return sogbsStudents
                })
                return sogbs
            })
        }
    })


    return _____files
}