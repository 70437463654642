import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Alert, Box, TextField} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {postProgram} from "../../requests/program.request";
import SearchMajorTextField from "../SearchTextField/SearchMajorTextField";
import {
    SearchDisciplineResponseType,
    SearchLevelResponseType,
    SearchMajorResponseType,
    SearchSchoolResponseType
} from "../../types";
import SearchLevelTextField from "../SearchTextField/SearchLevelTextField";
import SearchDisciplineTextField from "../SearchTextField/SearchDisciplineTextField";
import {RootState, useAppSelector} from "../../redux/store";
import {setDiscipline, setDisciplineBreadcrumb, setDisciplineId, setDisciplineTemp} from "../../redux/features/school";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setErrorSnackbar} from "../../helper/utils/helper";
import SearchAsYouType from "../SearchAsYouType";
import {getSchoolById} from "../../requests/school.request";
import {LoadingButton} from "@mui/lab";
import {useQueryClient} from "react-query";

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    majorId: yup.number(),
    programId: yup.number(),
    disciplineId: yup.number(),

})

const initialValues = {
    schoolId: "",
    name: '',
    majorName: '',
    majorId: "",
    programCode: "",
    levelName: "",
    levelId: "",
    disciplineName: "",
    disciplineId: ""
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    open: boolean
    handleClose: () => void
    setting?:boolean
}

const AddProgram = React.memo((props: IProps) => {
    const {open, handleClose, setting} = props
    const dispatch = useDispatch()
    const {schoolId, regionId} = useAddEntities();
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false)
    const tempDiscipline = useAppSelector((store) => store?.school?.tempDiscipline);
    const disciplineName = useAppSelector((store) => store?.school?.disciplineName);
    const [school, setSchool] = useState<any>()
    const schoolIdSetting = useAppSelector((store) => store?.school.schoolIdSetting);
    const [schoolById, setSchoolById] = useState()
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {


                if(!values?.levelId){
                    setLoading(false)
                    setSnackbarStore({
                        message: "Level is required",
                        severity: "error",
                        open: true
                    })
                    return
                }
                if(!values?.disciplineId){
                    setLoading(false)
                    setSnackbarStore({
                        message: "Discipline is required",
                        severity: "error",
                        open: true
                    })
                    return
                }


                if(!setting){
                    values.schoolId = schoolId
                }else if(setting){
                    values.schoolId = schoolId ? schoolId : school?.id ?? schoolIdSetting
                }



                var res = await postProgram(values)
                setLoading(false)
                if(res.message){
                    var __message = values?.name + " already exists in our system. Please try a different one."

                    setSnackbarStore({
                        message: res.message,
                        severity: __message  == res.message ? "error" :  "success",
                        open: true,
                    });

                } else {
                    setSnackbarStore({
                        message: "Successfully added program",
                        severity: "success",
                        open: true,
                    })
                }
                queryClient.setQueriesData('program', (oldData: any) => {
                    return {
                        ...oldData, data: [...oldData?.data, res?.data]
                    }
                })

                if (disciplineName != null) {
                    dispatch(setDisciplineId(values?.disciplineId))
                    dispatch(setDisciplineBreadcrumb(values?.disciplineName))
                    dispatch(setDiscipline(tempDiscipline))
                }

                //  dispatch(setProgramTabStateCount())
                actions.resetForm()

                /*dispatch(setTabValue("curriculum"))
                dispatch(setCurriculumTabState(true))*/

               handleClose()
            } catch (error: any) {
                console.error({error})
                setLoading(false)
                setErrorSnackbar(error, setSnackbarStore)
            }
        }
    })

    useEffect(() => {
        if(!schoolIdSetting) return
        getSchoolById(schoolIdSetting).then(c => {

            formik.setFieldValue('schoolId', schoolIdSetting)
            setSchoolById(c?.data)
        } )
    }, [schoolIdSetting])
    useEffect(()=>{


        return () =>{
            formik.resetForm()
        }
    }, [])


    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit()
    }, [formik])

    const handleOnSelectedMajor = useCallback((value: SearchMajorResponseType) => {
        formik.setFieldValue('majorId', value?.id ?? "")
        formik.setFieldValue('majorName', value?.name ?? "")
    }, [formik])
    const authStore = useSelector((store: RootState) => store.auth);
    const handleOnSelectedDiscipline = useCallback((value: SearchDisciplineResponseType) => {
        dispatch(setDisciplineTemp(value))
        formik.setFieldValue('disciplineId', value?.id ?? "")
        formik.setFieldValue('disciplineName', value?.name ?? "")
    }, [formik])
    const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
    const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
        setSchool(value)
        formik.setFieldValue('schoolId', value?.id ?? "")
    }, [formik])
    const handleOnSelectedLevel = useCallback((value: SearchLevelResponseType) => {
        formik.setFieldValue('levelId', value?.id ?? "")
        formik.setFieldValue('levelName', value?.name ?? "")
    }, [formik])
    const schoolSelectorById = useAppSelector(
        (store) => store?.school.schoolSelectorById
    );
    const schoolSetting = useAppSelector(
        (store) => store?.school.schoolSetting
    );
    return (
        <Dialog

            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Add Program <p style={{margin: 0,fontSize: 12, fontStyle: "italic"}}>({authStore?.user?.school?.name ?? ""})</p></DialogTitle>

            <DialogContent>
                <Alert style={{marginBottom: 12}} severity="info">
                    Please fill in the details of the program you want to add
                    <br />
                    Note: Fields with <strong>*</strong> are required
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}
                >

                    {setting ? <Box paddingBottom={1}>
                        {<SearchAsYouType
                            value={schoolById ?? schoolSelectorById ?? schoolSetting}
                            disabled={schoolById ?? schoolSelectorById ?? schoolSetting}
                            id={"SchoolInstitutionalNameselect"}
                            required={true}
                            error={onErrorSelectedSchool}
                            label='School Institutional Name'
                            onSelected={handleOnSelectedSchool}
                        />}
                    </Box> : null

                    }
                    {setting ?
                        <SearchDisciplineTextField
                            disabled={!!disciplineName}
                            schoolId={formik.values.schoolId ?? schoolId}
                            label='Discipline'
                            onSelected={handleOnSelectedDiscipline}
                        />
                        : <SearchDisciplineTextField
                            disabled={!!disciplineName}
                            schoolId={schoolId}
                            label='Discipline'
                            onSelected={handleOnSelectedDiscipline}
                        />}
                    <TextField
                        label="Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        id='programName'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />


                    <SearchLevelTextField
                        label='Level'
                        onSelected={handleOnSelectedLevel}
                    />
                    <SearchMajorTextField
                        label='Major'
                        onSelected={handleOnSelectedMajor}
                    />
                    {/*<TextField
                        label="Program Code"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        id='programCode'
                        name='programCode'
                        value={formik.values.programCode}
                        onChange={formik.handleChange}
                        error={formik.touched.programCode && Boolean(formik.errors.programCode)}
                        helperText={formik.touched.programCode && formik.errors.programCode}
                    />*/}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelProgram"} onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} disabled={!(formik.isValid && formik.dirty)} id={"addProgram"}
                               variant='contained' onClick={handleSubmit}>Add</LoadingButton>
            </DialogActions>
        </Dialog>
    )
})
export default AddProgram
