import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { APP_TITLE } from '../../../helper/utils/constants';

export const AppTitle = () => (
	<NavLink
		to='/'
		style={{
			textDecoration: 'none',
			color: 'inherit',
		}}
	>
		<Typography
			noWrap
			sx={{
				display: {
					xs: 'none',
					sm: 'block',
				},
				cursor: 'pointer',
				color: '#fff',
			}}
		>
			{APP_TITLE}
		</Typography>
	</NavLink>
);
