import {
  AddRounded,
  BlockRounded,
  CheckRounded,
  DeleteRounded,
  EditRounded,
  FilterAltRounded,
  ManageAccountsRounded,
  ViewColumnRounded,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import React, {FC, ReactNode, SyntheticEvent, useEffect, useMemo, useState,} from "react";
import {useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/features/app";
import {openSnackbar} from "../../redux/features/snackbar";
import {deleteUser, updateUserStatus} from "../../requests/user.request";
import {useDelete} from "../../hooks/useDelete";
import useDialog from "../../hooks/useDialog";
import AddUser from "../dialogs/AddUser.dialog";
import UserApplication from "../dialogs/UserApplication.dialog";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useSearchUserQuery} from "../../hooks/useSearchUserQuery";
import {useDebounce} from "../../hooks/useDebounce";
import {usePager} from "../../hooks/usePager";
import EditUser from "../dialogs/EditUser.dialog";
import {setEditUser} from "../../redux/features/auth";
import {useAppSelector} from "../../redux/store";
import {useRegionSelectQuery} from "../../hooks/useRegionQuery";
import {setSelectedSchool, setSelectedSettingSchool} from "../../redux/features/school";
import {useSchoolQuery} from "../../hooks/useSchoolQuery";

interface IUserTableProps {
  title: string;
}

const UserTable: FC<IUserTableProps> = (props) => {
  const { title } = props;
  const { schoolId, regionId } = useAddEntities();
  const [name, setName] = React.useState<any>("");
  const debouncedName = useDebounce(name, 500);
  const type = useAppSelector((store) => store?.auth?.user?.type);
  const user = useAppSelector((store) => store?.auth?.user);
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";

  const [status, setStatus] = React.useState<any>("");
  const [selectedStatusId, setSelectedStatusId] = React.useState<any>( {
    id: 0,
    name: "All"
  });

  const [selectedRegionId, setSelectedRegionId] = React.useState<any>( {
    id: 0,
    name: "All"
  });
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [sort, setSort] = useState<string>("desc")
  const [role, setRole] = useState<any>({
    id: type?.id == 2 &&  roles == "User" ? "Admin" : 0,
    name: type?.id == 2 &&  roles == "User" ? "Admin" : "All"
  })
  const regionSetting = useAppSelector((store) => store?.school.regionSetting);
  const { data: regions } = useRegionSelectQuery()
  const [email, setEmail] = useState<string | undefined>("")
  const { data: users, refetch, isLoading } = useSearchUserQuery(
    debouncedName,
    {
      ...(schoolId && {schoolId: schoolId}),
      ...(email && {email: email}),
      ...(role?.id && {role: role?.id}),
      ...(selectedStatusId?.name != null && {status: selectedStatusId.name}),
      ...(sort && {sortOrder: sort}),
      ...(pageSize && {pageSize: pageSize}),
      ...(page && {page: page + 1}),
      ...(selectedRegionId?.id != 0 && {regionId: selectedRegionId?.id}),
      ...(regionSetting?.id && {regionId: regionSetting?.id}),
      ...(regionId?.id && {regionId: regionId?.id}),
      ...(typeof regionId == "number" && {regionId: regionId}),
    },
      status
  );
  const totalRowPage = useMemo(() => {
    return (users?.pageSize)
  }, [users?.pageSize])
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleDelete = useDelete("Delete User", "user", deleteUser);

  const handleActivate = async (e: SyntheticEvent<HTMLButtonElement>, fullName?: string) => {

    e.stopPropagation();
    const id = e.currentTarget.value;
    dispatch(setLoading(true));
    try {
      var res = await updateUserStatus(id, true);

      setStatus(true);
      queryClient.setQueriesData('users', (oldData: any) => {
        return {
          ...oldData, data: oldData?.data?.map((school: any) => {
            if (school.id === id) {
              school.status = true
              return school
            }
            return school
          })
        }
      })
      await queryClient.invalidateQueries("users", );

      dispatch(
          openSnackbar({
            open: true,
            message: "User has been activated." + " Please verify '" + fullName + "' access list.",
            severity: "success",
          })
      );

    } catch (err) {
      const errorMsg = "Something went wrong while activating user";
      dispatch(
        openSnackbar({
          open: true,
          message: errorMsg,
          severity: "error",
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleBlock = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const id = e.currentTarget.value;

    dispatch(setLoading(true));
    try {
      await updateUserStatus(id, false);
      setStatus(false);
      await queryClient.invalidateQueries("users");

      dispatch(
        openSnackbar({
          open: true,
          message: "User has been disabled.",
          severity: "success",
        })
      );
    } catch (err) {
      const errorMsg = "Something went wrong while blocking user";
      dispatch(
        openSnackbar({
          open: true,
          message: errorMsg,
          severity: "error",
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const [userId, setUserId] = useState<string>("");
    const [userFullname, setFullName] = useState<string>("");

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
      {
          name: "userId",
          label: "userId",
          options: {
              display: false,
              filter: false,
              sort: true,
          },
      },
    {
      name: "userName",
      label: "USERNAME",
      options: {

        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return   <Typography sx={{ wordBreak: "break-word" }} >
            {value}
          </Typography>
        }
      },
    },
    {
      name: "fullName",
      label: "FULLNAME",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return   <Typography sx={{ wordBreak: "break-word" }} >
            {value}
          </Typography>
        }
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          const id =
              tableMeta.rowData[
                  columns.findIndex((col) => col.name === "id")
                  ];
        var column = users?.data?.find(c => c.id == id);

          return   <div>
            <Typography sx={{ wordBreak: "break-word" }} >
              {value}
            </Typography>
            <Typography fontStyle={"italic"} fontSize={12}>
              { column ? column?.schoolName : ""}
            </Typography>
          </div>
        }
      }
    },
    {
      name: "role",
      label: "ROLE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          const id =
              tableMeta.rowData[
                  columns.findIndex((col) => col.name === "id")
                  ];

          const regionName = users?.data?.find(c => c.id == id) ? users?.data?.find(c => c.id == id)?.regionName : ""
          var __type = users?.data?.find(c => c.id == id) ? users?.data?.find(c => c.id == id)?.types?.name + " " : ""
          return <div style={{flex: 1, display: "flex", flexDirection: "column"}}>

            <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
              <div style={{
                fontStyle: "italic",
                fontWeight: "bold",
                margin: 0,
              }}>{__type?.trim()?.toLowerCase() == "ched" ? "REGIONAL" : __type}</div>
              <div>&nbsp;-{value ?? ""}</div>
            </div>
            {roles?.toLowerCase() != "user" ?
                <Typography fontSize={12} fontStyle={"italic"}>{regionName}</Typography> : null}
          </div>
        }
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: false,
        sort: true,

        customBodyRender: (value: boolean) => {
          return (
            <Chip
              variant="outlined"
              label={value ? "Approved" : "Pending"}
              color={value ? "success" : "warning"}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          const status =
            tableMeta.rowData[
              columns.findIndex((col) => col.name === "status")
            ];
          const fullName =
            tableMeta.rowData[
              columns.findIndex((col) => col.name === "fullName")
            ];
          const id =
              tableMeta.rowData[
                  columns.findIndex((col) => col.name === "userId")
                  ];

          return (
            <Stack direction="row" spacing={0.2}>
              <Tooltip title="Access List" arrow>
                  <IconButton
                      color="info"
                      size="small"
                      value={id}
                      onClick={(event) => {
                          setFullName(fullName)
                          handleAccessList(event)
                      }}
                  >
                      <ManageAccountsRounded/>
                  </IconButton>
              </Tooltip>
              {(type?.id == 2) || (type?.id == 1 && roles?.toLowerCase() != "user") ? <Tooltip title="Edit User" arrow>
                <IconButton
                    color="info"
                    size="small"
                    value={id}
                    onClick={(event) => {

                      if (event.currentTarget.value) {
                        dispatch(setEditUser(event.currentTarget.value))
                      }
                      handleOpenEditUser()
                    }}
                >
                  <EditRounded />
                </IconButton>
              </Tooltip> : null}
              <Tooltip title="Delete" arrow>
                <IconButton
                  color="error"
                  size="small"
                  value={id}
                  onClick={(event) => {
                    handleDelete(event,fullName )
                    refetch()
                  }}
                >
                  <DeleteRounded />
                </IconButton>
              </Tooltip>
              {status ? (
                <Tooltip title="Disable" arrow>
                  <IconButton
                    color="warning"
                    size="small"
                    value={id}
                    onClick={handleBlock}
                  >
                    <BlockRounded />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Activate" arrow>
                  <IconButton
                    color="success"
                    size="small"
                    value={id}
                    onClick={(e) => handleActivate(e, fullName)}
                  >
                    <CheckRounded />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
    },
  ];

  const statusFilterMemo = useMemo(() =>{
    return [
      {
          id: undefined,
          name: "All"
      },
      {
        id: true,
        name: "Approved",
        color: "success.main"
      },

      {
        id: false,
        name: "Pending",
        color: "warning.main"
      },

    ]
  }, [])

  const RoleFilterMemo = useMemo(() =>{



    return [
        {
            id: undefined,
            name: "All"
        },
      {
        id: "Admin",
        name: "Admin"
      },

      {
        id: "User",
        name: "User"
      },

    ]
  }, [])

  const totalCount = useMemo(() =>{
    return (users?.totalCount)
  }, [users?.totalCount])
  const totalPage = useMemo(() =>{
    return (users?.totalPages)
  }, [users?.totalPages])
  const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);
  const {
    handleOpen: handleOpenAddUser,
    handleClose: handleCloseAddUser,
    open: openAddUser,
  } = useDialog();


  const {
    handleOpen: handleOpenEditUser,
    handleClose: handleCloseEditUser,
    open: openEditUser,
  } = useDialog();
  const {
    handleOpen: handleOpenUserApplication,
    handleClose: handleCloseUserApplication,
    open: openUserApplication,
  } = useDialog();
  const handleAccessList = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const id = e.currentTarget.value;
    setUserId(id);

    handleOpenUserApplication();
  };

  function changeSort(newTableState: string) {
      setSort(newTableState)
  }
function changeRoleFilterChange(param: any) {
    setRole(param)
  }
  const options: MUIDataTableOptions = {
    serverSide: true, textLabels: {
      body: {
        noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
      }
    },
    selectableRows: "none",
    filter: false,
    tableId: "usertable",
    count: totalCount,
    rowsPerPage: totalRowPage ?? pageSize,
    page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
    onTableChange: (action, newTableState) => {
      switch (action) {

        case "changeRowsPerPage":
          setPage(0)
          changeRowsPerPage(newTableState.rowsPerPage);
          break;
        case "sort":
          changeSort(newTableState.sortOrder.name + "_" + newTableState.sortOrder.direction);
          break;
        case "changePage":
          changePage(newTableState.page, (newTableState.data?.length ?? 100));
          break;
        case "propsUpdate":
          break
        case "search":
          setName(newTableState.searchText);
          break;
      }
    },
    //selectableRowsOnClick: true,
    expandableRows: false,
    expandableRowsHeader: false,
    print: false,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow sx={{ p: 2 }}>
          <TableCell colSpan={rowData.length}>
            {/*   Lorem ipsum dolor sit, amet consectetur adipisicing elit. Autem nemo vel necessitatibus sequi obcaecati cum neque vero est ad ullam?*/}
          </TableCell>
        </TableRow>
      );
    },
  };
  useEffect(()=>{
    dispatch(setSelectedSchool({
      id: 0,
      name: "All"
    }))
    dispatch(setSelectedSettingSchool({
      id: 0,
      name: "All"
    }))
  }, [])
  useEffect(() => {
    refetch();
  }, [schoolId, openAddUser]);

  const schoolSetting = useAppSelector((store) => store?.school.schoolSetting);
  const { data: dataSchools } = useSchoolQuery({
    pageSize: "1000",
    search: '',
    ...(selectedRegionId?.id != 0 && {regionId: selectedRegionId?.id}),
  });
  const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
  return (
    <>
      <div style={{flex: 1, display: "flex", paddingTop: 6, paddingBottom: 12, flexDirection: "row"}}>

        {((roles?.toLowerCase() == "superadmin" || type?.id == 3) &&
            (roles?.toLowerCase() != "user" || (type?.id == 3 && (roles?.toLowerCase() == "user")))) ?
            <form onSubmit={(event) => event.preventDefault()}>
              <>
                <Autocomplete
                    id={"usertableautocomple"}
                    disabled={isLoading}
                    disableClearable
                    size="small"
                    value={selectedRegionId}
                    onChange={(event: any, newValue: string | null) => {
                        dispatch(setSelectedSettingSchool({name: "All", id: undefined}));
                        dispatch(setSelectedSchool({name: "All", id: undefined}))
                        setSelectedRegionId(newValue ? newValue : {});
                    }}
                    getOptionLabel={(option) => {
                  return option.name
                }
                }
                    renderOption={(props, option) => (
                        <li {...props} id={"settingregion" + option.id} key={option.id}>
                            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                {option.name}</div>
                        </li>
                    )}
                    options={[{name: "All", id: undefined}, ...(regions || [])] || []}
                    style={{width: 300}}

                    renderInput={(params) => (
                    <TextField {...params} label="Select Region" />
                )}
            />
          </>
        </form>: <div style={{paddingLeft: 6,paddingRight: 12,  display: "flex", }} >
          <div style={{paddingLeft: 6, flex: 1, display: "flex", alignItems: "center", }}>
            <Typography fontSize={21} fontWeight={"bold"}>{user?.region?.name || ""}</Typography>
          </div>

        </div>}

        {(roles?.toLowerCase() == "superadmin" || type?.id == 2) ?
            <form onSubmit={(event) => event.preventDefault()}>
              <>
                <Autocomplete
                    id={"heiusertableautocomple"}
                    disabled={isLoading}
                    disableClearable
                    size="small"
                    value={schoolSetting}
                    onChange={(event: any, newValue: string | null) => {

                      dispatch(setSelectedSettingSchool(newValue ? newValue : {}));
                    }}
                    renderOption={(props, option) => (
                        <li {...props}  id={"settinguser" + option.id} key={option.id}>
                          <div  style={{flex: 1, display: "flex", flexDirection: "column" }}>
                            { option.name ?? option.displayName}
                            <p  style={{fontStyle: "italic", margin: 0,fontSize: 12}}>{option?.institutionalCode ? option.institutionalCode + " - " : "" }{option?.address ? option.address : (option?.address ?  option.address + " - " : "") + (option?.province?.name ?  option.province.name + " - " : "") + (option?.region?.name ?  option.region.name + "" : "")}</p>
                          </div>
                        </li>
                    )}
                    getOptionLabel={(option) => option.name}
                    options={[{name: "All", id: undefined}, ...(dataSchools?.data || [])] || []}
                    style={{width: 200, marginLeft: 6}}
                    renderInput={(params) => (
                        <TextField {...params} id={"heiuserselect"} label="Select HEI Profile"/>
                    )}
                />
              </>
            </form> : null}
          {(!(typeId == 2 && roles?.toLowerCase() == "user")) && (!(typeId == 1 && roles?.toLowerCase() == "admin")) ?
              <Autocomplete
                  style={{width: 200, marginLeft: 6}}
                  disabled={isLoading}
                  disableClearable
                  size="small"
                  value={role}
                  onChange={(event: any, newValue: string | null) => {
                      changeRoleFilterChange(newValue ? newValue : {})
                  }}
                  getOptionLabel={(option) => {
                      return option.name
            }
            }
            renderOption={(props, option) => (
                <li {...props}  id={"settingrole" + option.id} key={option.id}>
                  { option.name }
                </li>
            )}
            id={"roleautocomplete"}
            options={RoleFilterMemo || []}
            sx={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} label="Select Role" />
            )}
        /> : null}
        <Autocomplete
            id={"Statususertableautocomple"}
            style={{width: 200, marginLeft: 6}}
            disabled={isLoading}
            disableClearable
            size="small"
            value={selectedStatusId}
            onChange={(event: any, newValue: string | null) => {
              setSelectedStatusId(newValue ? newValue : {});
            }}
            getOptionLabel={(option) => {
              return option.name
            }
            }
            renderOption={(props, option) => (
                <li {...props}   id={"settingstudes" + option.id} key={option.id}>
                  <Typography color={option.color ? option.color : "#000"}>{ option.name }</Typography>
                </li>
            )}
            options={statusFilterMemo || []}
            sx={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} label="Select Status" />
            )}
        />

      </div>


      <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>

      <MUIDataTable

          title={<div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
            <Typography fontSize={18} fontWeight={"bold"} sx={{paddingRight: 3}}>{title}</Typography>
            <Button
                id={"addUser"}
                startIcon={<AddRounded />}
                variant="contained"
                onClick={handleOpenAddUser}
            >
              Add User
            </Button>
          </div>}
        data={users?.data as any}
        columns={columns}
        options={options}

        components={{

          TableFilterList: (props) => {
            return <TableFilterList {...props} />;
          },
          TableToolbar: (props) => {
            return (
              <>
                <TableToolbar {...props} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mx: 3,
                    my: 1,
                  }}
                >
                  <Stack direction="row" gap={1}>
                    {/*  <Dropdown
                                            label='Role'
                                            items={[{
                                                name: "All",
                                                value: "all"
                                            }]}
                                        />
                                        <Dropdown
                                            label='Status'
                                            items={[{
                                                name: "All",
                                                value: "all"
                                            }]}
                                        />*/}
                  </Stack>


                </Box>
              </>
            );
          },
          icons: {
            FilterIcon: FilterAltRounded as unknown as ReactNode,
            ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
          },
        }}
      />
      </div>
      <AddUser open={openAddUser} handleClose={handleCloseAddUser} />
        <EditUser open={openEditUser} handleClose={handleCloseEditUser}/>
        <UserApplication
            userId={userId}
            open={openUserApplication}
            handleClose={handleCloseUserApplication}
            fullName={userFullname}/>
    </>
  );
};

export default UserTable;
