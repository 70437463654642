import * as yup from "yup";
import { ISubjectSchema, subjectSchema } from "./subject.schema";
import { IUnitSchema, unitSchema } from "./unit.schema";

export interface ISubjectUnitSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  subjectId?: number | null;
  subject: ISubjectSchema;
  unitId?: number | null;
  unit: IUnitSchema;
}

export const subjectUnitSchema: yup.SchemaOf<ISubjectUnitSchema> = yup
  .object()
  .shape({
    id: yup.number().required("Id is required"),
    uuid: yup.string().nullable(),
    dateCreated: yup.string().nullable(),
    createdBy: yup.string().nullable(),
    lastModifiedDate: yup.string().nullable(),
    lastModifiedBy: yup.string().nullable(),
    subjectId: yup.number().nullable(),
    subject: yup.lazy(() => subjectSchema),
    unitId: yup.number().nullable(),
    unit: yup.lazy(() => unitSchema),
  });

export type SubjectUnitType = yup.InferType<typeof subjectUnitSchema>;
