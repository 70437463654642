import { GetPromotionalReportsResponseType } from './../types/apiResponse.type';
import { getPromotionalReport, getPromotionalReportById } from './../requests/promotionalReport.request';
import { useQuery } from 'react-query';
import { useCallback } from 'react';
import { PromotionalReportType } from '../types';


export const usePromotionalReportQuery = (params: { [key: string]: any }) => {
    return useQuery(['promotional-report', params.header], () => getPromotionalReport(params), {
        select: useCallback((data: GetPromotionalReportsResponseType) => {
            return {
                ...data,
                data: data.data.map(pr => {
                    return {
                        ...pr,
                        _data: pr
                    }
                })
            }
        }, [])
    })
}

export const usePromotionalReportByIdQuery = (id: string | number) => {
    return useQuery(['promotional-report-by-id', id], () => getPromotionalReportById(id))
}