import Lottie from "lottie-react";
import searching from "../assets/searching.json";
import noDataAnimation from "../assets/no-data.json";
import React from "react";

function IsLoading(props: { loading: false | true, fetching: boolean }) {
    return <>
        {props.loading || props.fetching ? <Lottie animationData={searching}/> :
            <Lottie animationData={noDataAnimation}/>}
    </>;
}


export default IsLoading
