// create slice for tabValue
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ISchoolSlice {
  tabValue: any;
  disciplineTabOpen: boolean;
  discipline: any;
  majorTabOpen: boolean;
  programTabOpen: boolean;
  curriculumTableTabOpen: boolean;
  courseTableTabOpen: boolean;
  majorTableTabOpen: boolean;
  disciplineId: any;
  programId: any;
  curriculumId: any;
  prerequisiteId: any;
  prerequisiteName: any;
  courseId: any;
  disciplineName: any;
  programName: any;
  curriculumName: any;
  courseName: any;
  searchDisciplineName: any;
  searchLevelName: any;
  program: any;
  curriculumTableTabOpenCount: number;
  programTabOpenCount: number;
  tempDiscipline: any;
    tempProgram: any;
    curriculum: any;
    curriculumTemp: any;
    institutionalCode: any;
    selectedSchool: any;
    schoolSetting: any;
    schoolIdSetting: any;
    regionSetting: any;
    selectedAcademicCalendar: any;
    postCount: any;
    schoolSelectorById: any;
}

const initialState: ISchoolSlice = {
  selectedSchool: null,
  curriculum: null,
  tabValue: 0,
  disciplineTabOpen: false,
  disciplineId: 0,
  disciplineName: "",
  discipline: null,
  programId: 0,
  programName: "",
  curriculumId: 0,
  curriculumName: "",
  courseId: 0,
  prerequisiteId: 0,
  prerequisiteName: "",
  courseName: "",
  programTabOpen: false,
  majorTabOpen: false,
  curriculumTableTabOpen: false,
  majorTableTabOpen: false,
  courseTableTabOpen: false,
  searchDisciplineName: null,
  searchLevelName: null,
  program: null,
  curriculumTableTabOpenCount: 0,
  programTabOpenCount: 0,
  tempDiscipline: null,
  tempProgram: null,
  curriculumTemp: null,
  institutionalCode: null,
    schoolSetting: null,
    schoolIdSetting: null,
    postCount: 0,
    regionSetting: {
        id: undefined,
        name: "All"
    },
    schoolSelectorById: null,
    selectedAcademicCalendar: null
};

const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
      setSelectedSchool: (state, action: PayloadAction<any>) => {
          state.selectedSchool = action.payload;
          return state;
      },
      setSchoolSelectorById: (state, action: PayloadAction<any>) => {
          state.schoolSelectorById = action.payload;
          return state;
      },
      setSelectedAcademicCalendar: (state, action: PayloadAction<any>) => {
          state.selectedAcademicCalendar = action.payload;
          return state;
      },
      setSelectedSettingSchool: (state, action: PayloadAction<any>) => {
          state.schoolSetting = action.payload;
          return state;
      }, setSelectedSettingSchoolId: (state, action: PayloadAction<any>) => {
          state.schoolIdSetting = action.payload;
          return state;
    },
    setSelectedSettingRegion: (state, action: PayloadAction<any>) => {
      state.regionSetting = action.payload;
      return state;
    },
    setInstitutionalCode: (state, action: PayloadAction<any>) => {
      state.institutionalCode = action.payload;
      return state;
    },

    setTabValue: (state, action: PayloadAction<any>) => {
      state.tabValue = action.payload;
      return state;
    },
    setSearchDisciplineName: (state, action: PayloadAction<any>) => {
      state.searchDisciplineName = action.payload;
      return state;
    },
    setSearchLevelName: (state, action: PayloadAction<any>) => {
      state.searchLevelName = action.payload;
      return state;
    },
    setDisciplineTabState: (state, action: PayloadAction<any>) => {
      state.disciplineTabOpen = action.payload;
      return state;
    },

    setMajorTabState: (state, action: PayloadAction<any>) => {
      state.majorTableTabOpen = action.payload;
      return state;
    },

    setProgramTabState: (state, action: PayloadAction<any>) => {
      state.programTabOpen = action.payload;
      return state;
    },

    setProgramTabStateCount: (state) => {
      state.programTabOpenCount = +state.programTabOpenCount + 1;
      return state;
    },
    setCurriculumTabState: (state, action: PayloadAction<any>) => {
      state.curriculumTableTabOpen = action.payload;
      return state;
    },
    setCourseTabState: (state, action: PayloadAction<any>) => {
      state.courseTableTabOpen = action.payload;
      return state;
    },
    setDisciplineId: (state, action: PayloadAction<any>) => {
      state.disciplineId = action.payload;
      return state;
    },
    setPrerequisiteId: (state, action: PayloadAction<any>) => {
      state.prerequisiteId = action.payload;
      return state;
    },
    setPrerequisiteName: (state, action: PayloadAction<any>) => {
      state.prerequisiteName = action.payload;
      return state;
    },
    setDisciplineTemp: (state, action: PayloadAction<any>) => {
      state.tempDiscipline = action.payload;
      return state;
    },

    setProgramTemp: (state, action: PayloadAction<any>) => {
      state.tempProgram = action.payload;
      return state;
    },
    setProgramId: (state, action: PayloadAction<any>) => {
      state.programId = action.payload;
      return state;
    },
    setCurriculumTemp: (state, action: PayloadAction<any>) => {
      state.curriculumTemp = action.payload;
      return state;
    },
    setCourseId: (state, action: PayloadAction<any>) => {
      state.courseId = action.payload;
      return state;
    },
    setCurriculumId: (
      state,
      action: PayloadAction<ISchoolSlice["curriculumId"]>
    ) => {
      state.curriculumId = action.payload;
      return state;
    },
    setDisciplineBreadcrumb: (state, action: PayloadAction<any>) => {
      state.disciplineName = action.payload;
      return state;
    },

    setProgramBreadcrumb: (state, action: PayloadAction<any>) => {
      state.programName = action.payload;
      return state;
    },

    setCurriculumBreadcrumb: (state, action: PayloadAction<any>) => {
      state.curriculumName = action.payload;
      return state;
    },

    setCourseBreadcrumb: (state, action: PayloadAction<any>) => {
      state.courseName = action.payload;
      return state;
    },

    setDiscipline: (state, action: PayloadAction<any>) => {
      state.discipline = action.payload;
      return state;
    },

    setCurriculum: (state, action: PayloadAction<any>) => {
      state.curriculum = action.payload;
      return state;
    },
    setProgram: (state, action: PayloadAction<any>) => {
      state.program = action.payload;
      return state;
    },
    setPostCount: (state) => {
      state.postCount = Math.random() ;
      return state;
    },
    setCurriculumTableTabOpenCount: (state) => {
      state.curriculumTableTabOpenCount = state.curriculumTableTabOpenCount + 1;
      return state;
    },

    setClear: (state) => {
      state.curriculumId = null;
      state.courseId = null;
      state.disciplineId = null;
      state.programId = null;

      state.curriculumName = null;
      state.courseName = null;
      state.disciplineName = null;
      state.programName = null;

      state.discipline = null;
      state.program = null;
      state.curriculum = null;

      return state;
    },
  },
});

export const {
  setSelectedAcademicCalendar,
  setSelectedSettingRegion,
  setSelectedSchool,
  setCurriculumTemp,
  setCurriculum,
  setProgramTemp,
  setDisciplineTemp,
  setProgramTabStateCount,
  setPostCount,
  setCurriculumTableTabOpenCount,
  setSelectedSettingSchool,
  setProgram,
  setSearchLevelName,
  setSearchDisciplineName,
  setClear,
  setDiscipline,
  setDisciplineBreadcrumb,
  setProgramBreadcrumb,
  setCurriculumBreadcrumb,
  setMajorTabState,
  setCourseBreadcrumb,
  setProgramId,
  setCourseId,
  setCurriculumId,
  setDisciplineId,
  setTabValue,
  setDisciplineTabState,
  setProgramTabState,
  setCurriculumTabState,
  setCourseTabState,
    setPrerequisiteId,
    setPrerequisiteName,
    setSelectedSettingSchoolId, setSchoolSelectorById
} = schoolSlice.actions;
export default schoolSlice.reducer;
