import React, {FC, SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import {Helmet} from 'react-helmet-async';
import {APP_TITLE} from '../helper/utils/constants';
import {useScreenDimension} from '../hooks/useScreenDimension';
import {useHistory} from 'react-router-dom';
import {VisibilityOffRounded, VisibilityRounded} from '@mui/icons-material';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {register} from '../requests/auth.request';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../redux/features/snackbar';
import {setLoading} from '../redux/features/app';
import SearchAsYouType from '../components/SearchAsYouType';
import {useRegionSelectQuery} from '../hooks/useRegionQuery';

// Type
import type {SearchSchoolResponseType} from '../types';
import {AxiosError} from 'axios';
import Lottie from "lottie-react";
import edcs from "../assets/edcs.json";
import FormControl from "@mui/material/FormControl";
import PRCLogo from "../svg/prc";

const initialValues = {
    schoolId: '',
    schoolName: '',
    accountType: 1,
    schoolAddress: '',
    regionId: '',
    email: '',
    userName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    designation: '',
    confirmPassword: '',
}

const SignupPage: FC = () => {

    const [dynamicType, setDynamicType] = useState<any>(1)

    const validationSchema = yup.object({
        schoolId: dynamicType == 1 ? yup.string().nullable() : yup.string().nullable(),
        schoolName: dynamicType == 1 ? yup.string().required('School Name is required') : yup.string().nullable(),
        schoolAddress: dynamicType == 1 ? yup.string().nullable() : yup.string().nullable(),
        regionId: yup.string().required('Region is required'),
        accountType: yup.string().required('Account type is required'),
        email: yup.string().required('School Email is required'),
        userName: yup.string().required('Username is required'),
        firstName: yup.string().required('First Name is required'),
        middleName: yup.string(),
        designation: yup.string().required('Designation is required'),
        lastName: yup.string().required('Last Name is required'),
        password: yup.string()
            .required("Please enter your password")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one number and one special case character"
            ),
        confirmPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    const { height } = useScreenDimension()
    const history = useHistory()
    const dispatch = useDispatch()
    const { data: regions } = useRegionSelectQuery()

    const [showPassword, setShowPassword] = useState(false)

    const VisibilityTag = useMemo(() => showPassword ? VisibilityRounded : VisibilityOffRounded, [showPassword])

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])

    const pushTo = useCallback((path: string) => {
        history.push(path)
    }, [history])

    const pushBack = useCallback(() => {
        history.goBack()
    }, [history])

    const handleLoading = useCallback((loading: boolean) => {
        dispatch(setLoading(loading))
    }, [])

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                handleLoading(true)
                const res = await register(values)
                setSnackbarStore({
                    message: res.message,
                    severity: "success",
                    open: true,
                })
                actions.resetForm()
                history.replace('/login')
            } catch (error) {
                let errorMsg = 'Something went wrong while creating your acoount. '
                if (error instanceof AxiosError) {
                    errorMsg = error.response?.data?.error[0].description
                }
                setSnackbarStore({
                    message: errorMsg,
                    severity: "error",
                    open: true,
                })
            } finally {
                handleLoading(false)
            }
        }
    })

    const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {

        formik.setFieldValue('schoolId', value?.institutionalCode ?? "")
        formik.setFieldValue('schoolName', value?.name ?? "")
        formik.setFieldValue('schoolAddress', value?.address ?? value?.province?.name ?? "")
        formik.setFieldValue('regionId', value?.regionId)
    }, [formik])

    const handleSignUp = useCallback((e: SyntheticEvent) => {

        e.preventDefault()


        if (!formik.values.schoolName && dynamicType == 1) {
            setSnackbarStore({
                message: "School Institutional Name is required",
                severity: "error",
                open: true,
            })
        }

        formik.handleSubmit()
    }, [formik])


    console.log(formik)

    return (
        <>
            <Helmet>
                <title>Sign-up | {APP_TITLE}</title>
            </Helmet>
            <main
                style={{
                    backgroundColor: "#034F8B",
                }}
            >
                <Container>
                    <Box
                        sx={{

                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: 5,
                            paddingBottom: 5,
                            height
                        }}
                    >
                        <Paper
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: 4,

                    backgroundColor: "#E9E9E9",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    borderRadius: {
                      xs: "inherit",
                      md: 0,
                    },

                      borderStartStartRadius: "inherit !important",
                    borderEndStartRadius: "inherit !important",
                    backgroundColor: "white",
                    p: 6,
                  }}
                >

                  <Box
                    component={"form"}
                    onSubmit={handleSignUp}
                    sx={{

                        display: "flex",
                      flexDirection: "column",
                      mb: 3,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      component="p"
                      sx={{
                        mb: 2,
                      }}
                    >
                      Sign up
                    </Typography>

                    <Alert severity="info" sx={{ mb: 2 }}>
                      Please fill in the details of your information.
                      <br />
                      Note: Fields with <strong>*</strong> are required
                    </Alert>





                              <FormControl>
                                  <FormLabel id="accountType">Account Type</FormLabel>
                                  <RadioGroup
                                      row
                                      onChange={(event) => {
                                          setDynamicType( event.target.value)
                                          formik.setFieldValue('accountType', event.target.value)
                                      }}
                                      value={formik.values.accountType}
                                      aria-labelledby="accountType"

                                      defaultValue={1}
                                      name="radio-buttons-group"
                                  >

                                      <FormControlLabel checked={formik.values.accountType == 1} value={1} control={<Radio />} label="School" />
                                      <FormControlLabel  checked={formik.values.accountType == 2 } value= {2} control={<Radio />} label="CHED" />
                                  </RadioGroup>

                              </FormControl>
                      { formik.values.accountType == 1 ? <SearchAsYouType
                          id={"signupselect"}
                          required={true}
                          label="School Institutional Name"
                          onSelected={handleOnSelectedSchool}
                      /> : null}
                     {/* <Grid container gap={2}>
                          <Grid item flex={1}>
                              { formik.values.accountType == 1 ? <TextField

                        disabled={true}
                      label="School ID"
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      required
                      name="schoolId"
                      value={formik.values.schoolId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.schoolId &&
                        Boolean(formik.errors.schoolId)
                      }
                      helperText={
                        formik.touched.schoolId && formik.errors.schoolId
                      }
                    /> : null}
                          </Grid>
                          <Grid item flex={1}>
                              { formik.values.accountType == 1 ? <TextField
                        disabled={true}
                      label="School Name"
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      required
                      name="schoolName"
                      value={formik.values.schoolName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.schoolName &&
                        Boolean(formik.errors.schoolName)
                      }
                      helperText={
                        formik.touched.schoolName && formik.errors.schoolName
                      }
                    />: null}

                          </Grid>
                      </Grid>*/}
                      <Grid container gap={2}>
                          { formik.values.accountType != 1 ? <Grid item flex={formik.values.accountType == 1 ?1 : 2} >
                    <TextField
                        disabled={formik.values.accountType == 1 ? true : false}
                      label="Region"
                      variant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth
                      required
                      select
                      id="regionId"
                      name="regionId"
                      value={formik.values.regionId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.regionId &&
                        Boolean(formik.errors.regionId)
                      }
                      helperText={
                        formik.touched.regionId && formik.errors.regionId
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Region
                      </MenuItem>
                      {regions?.map((region) => {
                        return (
                          <MenuItem key={region.id} value={region.value}>
                            {region.name}
                          </MenuItem>
                        );
                      })}
                      {regions?.length === 0 && (
                        <MenuItem value="" disabled>
                          No Region available
                        </MenuItem>
                      )}
                    </TextField>
                          </Grid>: null}
                          {/*{ formik.values.accountType == 1 ?<Grid item flex={1}>
                               <TextField
                        disabled={true}
                      label="School Address"
                      variant="outlined"
                      margin="dense"
                      size="small"
                      required
                      fullWidth
                      name="schoolAddress"
                      value={formik.values.schoolAddress}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.schoolAddress &&
                        Boolean(formik.errors.schoolAddress)
                      }
                      helperText={
                        formik.touched.schoolAddress &&
                        formik.errors.schoolAddress
                      }
                    />
                          </Grid>: null}*/}
                      </Grid>
                    <Grid container gap={2}>
                      <Grid item flex={1}>
                        <TextField
                          label="Username"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          fullWidth
                          required
                          id="userName"
                          name="userName"
                          value={formik.values.userName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.userName &&
                            Boolean(formik.errors.userName)
                          }
                          helperText={
                            formik.touched.userName && formik.errors.userName
                          }
                        />
                      </Grid>
                      <Grid item flex={1}>
                        <TextField
                          label="Email"
                          type="email"
                          variant="outlined"
                          margin="dense"
                          size="small"
                          fullWidth
                          required
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>
                    </Grid>

                      <Grid container gap={2}>
                          <Grid item flex={1}>
                              <TextField
                                  label="First Name"
                                  variant="outlined"
                                  margin="dense"
                                  size="small"
                                  fullWidth
                                  required
                                  id="firstName"
                                  name="firstName"
                                  value={formik.values.firstName}
                                  onChange={formik.handleChange}
                                  error={
                                      formik.touched.firstName &&
                                      Boolean(formik.errors.firstName)
                                  }
                                  helperText={
                                      formik.touched.firstName && formik.errors.firstName
                                  }
                              />
                          </Grid>
                          <Grid item flex={1}>
                              <TextField
                                  label="Middle Name"
                                  variant="outlined"
                                  margin="dense"
                                  size="small"
                                  fullWidth
                                 // required
                                  id="middleName"
                                  name="middleName"
                                  value={formik.values.middleName}
                                  onChange={formik.handleChange}
                                  error={
                                      formik.touched.middleName &&
                                      Boolean(formik.errors.middleName)
                                  }
                                  helperText={
                                      formik.touched.middleName && formik.errors.middleName
                                  }
                              />
                          </Grid>
                          <Grid item flex={1}>
                              <TextField
                                  id="Last Name"
                                  label="Last Name"
                                  variant="outlined"
                                  margin="dense"
                                  size="small"
                                  fullWidth
                                  required
                                  name="lastName"
                                  value={formik.values.lastName}
                                  onChange={formik.handleChange}
                                  error={
                                      formik.touched.lastName &&
                                      Boolean(formik.errors.lastName)
                                  }
                                  helperText={
                                      formik.touched.lastName && formik.errors.lastName
                                  }
                              />
                          </Grid>

                      </Grid>

                    <Grid container gap={2}>
                      <Grid item flex={1}>
                        <TextField
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          fullWidth
                          required
                          id="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowPassword((status) => !status)
                                  }
                                >
                                  <VisibilityTag />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item flex={1}>
                        <TextField
                          label="Confirm Password"
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          fullWidth
                          required
                          id="confirmPassword"
                          name="confirmPassword"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.confirmPassword &&
                            Boolean(formik.errors.confirmPassword)
                          }
                          helperText={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowPassword((status) => !status)
                                  }
                                >
                                  <VisibilityTag />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                      <Grid container gap={2}>
                          <Grid item flex={1}>
                              <TextField
                                  label="Designation"
                                  variant="outlined"
                                  margin="dense"
                                  size="small"
                                  fullWidth
                                  required
                                  id="designation"
                                  name="designation"
                                  value={formik.values.designation}
                                  onChange={formik.handleChange}
                                  error={
                                      formik.touched.designation &&
                                      Boolean(formik.errors.designation)
                                  }
                                  helperText={
                                      formik.touched.designation && formik.errors.designation
                                  }
                              />
                          </Grid>
                      </Grid>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={handleSignUp}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Sign up
                    </Button>
                    <Divider
                      sx={{
                        my: 2,
                      }}
                    >
                      or
                    </Divider>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => pushTo("/login")}
                    >
                      Login
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: {
                      xs: "none",
                        md: "flex",
                    },
                      flex: 1,
                      borderRadius: "inherit",
                      p: 6,
                  }}
                  style={{
                      backgroundColor: "#E9E9E9",
                  }}
                >
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            alignItems: "space-between",
                            justifyContent: "space-between",
                            flexDirection: "column",
                        }}
                    >
                        <div style={{marginBottom: 50}}>
                            <div style={{
                                marginBottom: 15,
                                flexDirection: "column",
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <PRCLogo/>
                            </div>
                            <div>
                                <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>
                                    <img src="/assets/svg/prctext.svg"/>
                                </div>
                            </div>


                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <Lottie style={{height: "130px"}} loop={false} animationData={edcs}/>


                            {/*    <img alt="EDC Logo" src={"/assets/logo.png"} height="130px" />*/}

                            <Typography
                                textTransform={"uppercase"}
                                variant="subtitle2"
                                marginTop={"10px"}
                                color="primary.main"
                            >
                                {APP_TITLE}
                            </Typography>
                        </div>

                        <div>
                            <Typography textAlign={"center"} marginTop={"60px"} fontSize={14}>
                                Operated and maintenance by CHED Regional Office 10
                            </Typography>
                            <Typography textAlign={"center"} fontWeight={"bold"} fontSize={12}>
                                Powered by
                            </Typography>
                            <Typography textAlign={"center"} fontWeight={"bold"} fontSize={12}>
                                USTP
                            </Typography>
                        </div>


                    </Grid>
                </Box>
              </Paper>
            </Box>
          </Container>
        </main>
      </>
    );
};

export default SignupPage;
