
import { useQuery } from 'react-query';
import {getMajorByName} from "../requests/major.request";


export const useSearchMajorQuery = (str: string) => {
    return useQuery(['major', str], ({ signal }) => getMajorByName(str, signal), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}
