import {AccountCircleRounded, HomeRounded} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Grid,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import moment from 'moment-timezone';
import React, {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {AvatarCustomBadge} from "../components/Avatar";
import TabPanel from "../components/TabPanel";
import {useAppSelector} from "../redux/store";
import AddEditProfile from "../components/dialogs/AddEditProfile.dialog";
import useDialog from "../hooks/useDialog";
import UserTable from "../components/Tables/UserTable";
import AcademicCalendarTable from "../components/Tables/AcademicCalendarTable";
import ChangePassword from "../components/dialogs/ChangePassword.dialog";
import UpdateSchoolDialog from "../components/dialogs/UpdateSchool.dialog";
import {InstitutionalProfile} from "./InstutionalProfile.page";
import {SpecialOrderPricingRuleTable} from "../components/Tables/SpecialOrderPricingRuleTable";
import {RequirementsTable} from "../components/Tables/RequirementsTable";
import {useSchoolIdQuery} from "../hooks/useSchoolIdQuery";

function Account(props: { user: any }) {
  return <Stack direction="row" gap={10}>
    <Stack gap={1}>
      <Box>
        <Typography variant="subtitle1">Username</Typography>
        <Chip variant="filled" label={props.user?.userName ?? "-"}></Chip>
      </Box>
      <Box>
        <Typography variant="subtitle1">First Name</Typography>
        <Chip
            variant="filled"
            label={props.user.userProfile.firstName ?? "-"}
        ></Chip>
      </Box>
      {props.user?.type?.name?.trim() ? <Box>
        <Typography variant="subtitle1">Type</Typography>
        <Chip
            variant="filled"
            label={props.user?.type?.name?.trim() ? props.user?.type?.name?.trim() : "-"}
        ></Chip>
      </Box> : null}
      <Box>
        <Typography variant="subtitle1">Designation</Typography>
        <Chip
            variant="filled"
            label={props.user?.userProfile?.designation?.trim() ? props.user?.userProfile?.designation : "-"}
        ></Chip>
      </Box>

      <Box>
        <Typography variant="subtitle1">Region</Typography>
        <Chip
            variant="filled"
            label={props.user?.region?.name ?? "-"}
        ></Chip>
      </Box>
    </Stack>

    <Stack gap={1}>
      <Box>
        <Typography variant="subtitle1">Email</Typography>
        <Chip variant="filled" label={props.user?.email ?? "-"}></Chip>
      </Box>
      <Box>
        <Typography variant="subtitle1">Last Name</Typography>
        <Chip
            variant="filled"
            label={props.user.userProfile.lastName ?? "-"}
        ></Chip>
      </Box>
    </Stack>
  </Stack>;
}


const AccountSetting = () => {
  const history = useHistory();
  const {user} = useAppSelector((store) => store.auth);
  const [tabValue, setTabValue] = useState(0);
  const _school = useAppSelector((store) => store?.auth?.user?.school);

  const pushTo = useCallback(
      (path: string) => {
        history.push(path);
      },
      [history]
  );
  const {data: school, isLoading} = useSchoolIdQuery(
      _school?.id
  );
  const breadcrumbs = [
    <Link
        underline="hover"
        key="1"
        color="inherit"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => pushTo("/")}
    >
      <HomeRounded sx={{ mr: 1 }} fontSize="small" />
      Home
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <AccountCircleRounded sx={{ mr: 1 }} fontSize="small" />
      Account
    </Typography>,
  ];
  const {
    handleOpen: handleOpenAddUser,
    handleClose: handleCloseAddUser,
    open: openAddUser,
  } = useDialog();
  const {
    handleOpen: handleOpenEditInstitutionalProfile,
    handleClose: handleCloseEditInstitutionalProfile,
    open: openInstitutionalProfile,
  } = useDialog();

  const {
    handleOpen: handleOpenChangePassword,
    handleClose: handleCloseChangePassword,
    open: openChangePassword,
  } = useDialog();



  return (
    <>
      <Box
          component="main"
          sx={{flexGrow: 1, pt: 10}}
          style={{paddingLeft: " 20px", paddingRight: "20px"}}
      >
        <Container>
          <Paper
              sx={{
                p: 2,
                mb: 2,
              }}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Paper>

          <Paper
              sx={{
                p: 2,
              }}
          >
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
            >
              <Tabs variant="scrollable"
                    scrollButtons="auto"
                  value={tabValue}
                  onChange={(e, value) => setTabValue(value)}
                  sx={{
                    mb: 2,
                  }}
              >
                <Tab id="Account" label="Account" value={0}/>

                {(["Admin", "User"].includes(user.roles) && user?.type?.id != 2 && user?.type?.id != 3) && (
                    <Tab id={"InstitutionProfile"} label="Institution Profile" value={1}/>
                )}

                <Tab id="Security" label="Security" value={2}/>
                  {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                      <Tab label="Users" value={3}/>
                  )}

                  {(["Admin"].includes(user.roles) && user?.type?.id != 2 && user?.type?.id != 3) && (
                      <Tab id={"AcademicCalendar"} label="Academic Calendar" value={4}/>
                  )}
                  {(["Admin"].includes(user.roles) && user?.type?.id == 2) && (
                      <Tab label="Pricing Rules" value={6}/>
                  )}
                  {(["Admin"].includes(user.roles) && user?.type?.id == 2) && (
                      <Tab label="Requirements" value={7}/>
                  )}
              </Tabs>

              {tabValue === 0 && (
                  <Button id={"editButton"} onClick={handleOpenAddUser} variant="contained">
                    Edit
                  </Button>
              )}
              {tabValue === 2 && (
                  <Button id={"changePassword"} onClick={handleOpenChangePassword} variant="contained">
                    Change Password
                  </Button>
              )}

              {tabValue === 1 && !(user.type?.id == 1 && user?.roles?.toLowerCase() == "user") && (
                  <Button id={"EditInstitutionalProfile"}
                          onClick={handleOpenEditInstitutionalProfile}
                          variant="contained"
                  >
                    Edit Institutional Profile
                  </Button>
              )}
            </Box>
            <Grid container gap={2}>
              {[0, 2].includes(tabValue) && (
                  <Grid justifyContent={"space-between"} item>
                    <AvatarCustomBadge
                        sx={{width: 200, height: 200}}
                        src={""}
                        alt={user?.userName ?? ""}
                        badgeSrc="/assets/Verified_Badge.png"
                        badgeAlt={""}
                        variant="rounded"
                    />
                  </Grid>
              )}
              <Grid item>
                <TabPanel value={tabValue} index={0}>
                  <Account user={user}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <InstitutionalProfile school={user?.school}/>
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  <Stack gap={1}>
                    <Box>
                      <Typography variant="subtitle1">Password</Typography>
                      <Chip variant="filled" label="********"></Chip>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1">
                        Confirm Password
                      </Typography>
                      <Chip variant="filled" label="********"></Chip>
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <Stack gap={1}>
                    <Box>
                      <Typography variant="subtitle1">Last Login</Typography>
                      <Chip
                          variant="filled"
                          label={moment("2021-07-20 12:00:00").fromNow()}
                      ></Chip>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1">Last Logout</Typography>
                      <Chip
                          variant="filled"
                          label={moment("2021-07-20 12:00:00").fromNow()}
                      ></Chip>
                    </Box>
                  </Stack>
                </TabPanel>
              </Grid>
            </Grid>

            {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                <TabPanel value={tabValue} index={3}>
                  <UserTable title="Users"/>
                </TabPanel>
            )}

            {user.roles == "Admin" ? (
                <TabPanel value={tabValue} index={4}>
                    <AcademicCalendarTable title="Academic Calendar"/>
                </TabPanel>
            ) : null}

              {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                  <TabPanel value={tabValue} index={6}>
                      <SpecialOrderPricingRuleTable title="Pricing Rules"/>
                  </TabPanel>
              )}
              {(["Admin", "SuperAdmin"].includes(user.roles) || user?.type?.id == 2 || user?.type?.id == 3) && (
                  <TabPanel value={tabValue} index={7}>
                      <RequirementsTable title="Requirements"/>
                  </TabPanel>
              )}
          </Paper>
        </Container>
      </Box>
      <UpdateSchoolDialog
          currentSchool={school?.data}
          open={openInstitutionalProfile}
          handleClose={handleCloseEditInstitutionalProfile}
      />
      <AddEditProfile open={openAddUser} handleClose={handleCloseAddUser}/>
      <ChangePassword
          open={openChangePassword}
          handleClose={handleCloseChangePassword}
      />
    </>
  );
};

export default AccountSetting;
