import { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import {
    getNotificationPromotionalReportByIdCount
} from "../requests/promotionalReport.request";


export const useNotificationPromotionReportCountQuery = (params?: AxiosRequestConfig['params']) => {
    return useQuery(['notification-promotion-report-count' + params?.header, params.enrollmentId], () => getNotificationPromotionalReportByIdCount(params.enrollmentId, params.header));
}
