import * as Yup from "yup";
import { curriculumSchema, ICurriculumSchema } from "./curriculum.schema";
import { disciplineSchema, IDisciplineSchema } from "./discipline.schema";
import { enrollmentSchema, IEnrollmentSchema } from "./enrollment.schema";
import { ILevelSchema, levelSchema } from "./level.schema";
import { IMajorSchema, majorSchema } from "./major.schema";
import {
  IProgramSchoolSchema,
  programSchoolSchema,
} from "./programSchool.schema";
import {
  ISubjectProgramSchema,
  subjectProgramSchema,
} from "./subjectProgram.schema";
export interface IProgramSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name: string;
  majorId?: number | null;
  majorUuid?: string | null;
  major: IMajorSchema;
  levelId?: number | null;
  levelUuid?: string | null;
  level: ILevelSchema;
  diciplineId?: number | null;
  diciplineUuid?: string | null;
  dicipline: IDisciplineSchema;
  isApproved?: boolean;
  isDeleted?: boolean;
  subject_Programs: ISubjectProgramSchema[];
  program_Schools: IProgramSchoolSchema[] ;
  curriculums: ICurriculumSchema[];
  enrollments: IEnrollmentSchema[];
}

export const programSchema: Yup.SchemaOf<IProgramSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  name: Yup.string().required("Name is required"),
  majorId: Yup.number().nullable(),
  majorUuid: Yup.string().nullable(),
  major: Yup.lazy(() => majorSchema),
  levelId: Yup.number().nullable(),
  levelUuid: Yup.string().nullable(),
  level: Yup.lazy(() => levelSchema),
  diciplineId: Yup.number().nullable(),
  diciplineUuid: Yup.string().nullable(),
  dicipline: Yup.lazy(() => disciplineSchema),
  isApproved: Yup.boolean().optional(),
  isDeleted: Yup.boolean().optional(),
  subject_Programs: Yup.array(Yup.lazy(()=> subjectProgramSchema)),
  program_Schools:Yup.array(Yup.lazy(()=> programSchoolSchema)),
  curriculums: Yup.array().of(curriculumSchema),
  enrollments: Yup.array().of(enrollmentSchema),
});

export type ProgramType = Yup.InferType<typeof programSchema>;
