// create slice for student
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IStudentSlice {
  addStudentCount: any;
  tabValue: number
}

const initialState: IStudentSlice = {
  addStudentCount: 0,
  tabValue: 0
};

const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    setAddStudentCount: (state) => {
      state.addStudentCount = +state.addStudentCount + 1;
      return state;
    },
    setTabValue: (state, action: PayloadAction<any>) => {
      state.tabValue = action.payload;
      return state;
    },
  },
});

export const {setAddStudentCount, setTabValue} = studentSlice.actions;
export default studentSlice.reducer;
