import React, {FC, SyntheticEvent, useCallback, useMemo, useState} from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {APP_TITLE} from "../helper/utils/constants";
import {useScreenDimension} from "../hooks/useScreenDimension";
import {Redirect, useHistory} from "react-router-dom";
import {VisibilityOffRounded, VisibilityRounded,} from "@mui/icons-material";
import * as yup from "yup";
import {useFormik} from "formik";
import {onLogin} from "../redux/features/auth";
import {login} from "../requests/auth.request";
import {setHeaderToken} from "../requests/config";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import {setLoading} from "../redux/features/app";
import {useAppDispatch, useAppSelector} from "../redux/store";
import {setErrorSnackbar} from "../helper/utils/helper";
import Lottie from "lottie-react";
import edcs from "../assets/edcs.json";
import PRCLogo from "../svg/prc";
import {makeStyles} from '@material-ui/core/styles';

const validationSchema = yup.object({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
});

const useStyles = makeStyles((theme) => ({
    shimmer: {
        color: 'grey',
        display: 'inline-block',
        WebkitMask: 'linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%',
        backgroundRepeat: 'no-repeat',
        animation: '$shimmer 2.5s infinite',
        fontSize: '50px',
        maxWidth: '200px',
    },
    '@keyframes shimmer': {
        '100%': {
            WebkitMaskPosition: 'left',
        },
    },
}));
const LoginPage: FC = () => {
    const dispatch = useAppDispatch();
    const authStore = useAppSelector((store) => store.auth);
    const {height} = useScreenDimension();
    const history = useHistory();
    const playbackInstance: any = React.useRef(null);
    const [showPassword, setShowPassword] = useState(false);

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);

    const handleLoading = useCallback((loading: boolean) => {
        dispatch(setLoading(loading));
    }, []);

    const pushTo = useCallback(
        (path: string) => {
            history.push(path);
        },
        [history]
    );

    const pushBack = useCallback(() => {
        history.goBack();
  }, [history]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {

          handleLoading(true);
          const res = await login(values);
          setHeaderToken(res.token);

          /* if (playbackInstance?.current != null) {
               await playbackInstance?.current?.pause();
               playbackInstance.current = null;
           }
           const sound = new Audio(  require('./../assets/sound/notification_sound.mp3'));

           playbackInstance.current = sound;

           await playbackInstance.current?.play();*/

          dispatch(
              onLogin({
                  ...res,
                  isAuth: true,
              })
          );
      } catch (error: any) {
        console.log({ error });
        try {
          setErrorSnackbar(error, setSnackbarStore);
        } catch (e) {
          console.log(e);
        }
      } finally {
        handleLoading(false);
      }
    },
  });

  const handleLogin = useCallback(
      async (e: SyntheticEvent) => {
          e.preventDefault();

          localStorage.clear()
          formik.handleSubmit();
      },
      [formik]
  );


    const VisibilityTag = useMemo(
    () => (showPassword ? VisibilityRounded : VisibilityOffRounded),
    [showPassword]
  );
    const classes = useStyles();
  if (authStore.isAuth) return <Redirect to="/" />;

  return (
    <>
      <Helmet>
        <title>Login - {APP_TITLE}</title>
      </Helmet>
      <main
        style={{
          backgroundColor: "#034F8B",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: height,
            }}
          >
            <Paper
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                borderRadius: 3,
                backgroundColor: "#E9E9E9",
              }}
            >
              <Box
                sx={{
                    flex: 1,
                    borderRadius: {
                        xs: "inherit",
                        md: 0,
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStartStartRadius: "inherit !important",
                    borderEndStartRadius: "inherit !important",
                    backgroundColor: "white",
                    p: 6,
                }}
              >
               {/* <IconButton onClick={pushBack}>
                  <ArrowBack />
                </IconButton>*/}
                <Box
                  component={"form"}
                  onSubmit={handleLogin}
                  sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      flex: 1,
                      mb: 3,
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    component="p"
                    sx={{
                      mb: 2,
                    }}
                  >
                    User Login
                  </Typography>
                  <TextField
                    label="Username"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                    InputLabelProps={{shrink: true}}
                  />

                  <TextField
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    name="password"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setShowPassword((status) => !status)}
                          >
                            <VisibilityTag />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={handleLogin}
                      fullWidth
                      sx={{
                          mt: 2,
                      }}
                  >
                      Login
                  </Button>

                    <Button
                        type="button"
                        color="primary"
                        variant="text"
                        onClick={() => pushTo("/forgot-password")}
                        fullWidth
                        sx={{
                            mt: 2,
                        }}
                    >
                        Forgot Password
                    </Button>
                    <Divider
                        sx={{
                            my: 2,
                        }}
                    >
                        or
                    </Divider>
                    <Button
                        variant="outlined"
                        fullWidth
                    onClick={() => pushTo("/sign-up")}
                  >
                    Sign Up
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                    display: {
                        xs: "none",
                        md: "flex",
                    },
                    flex: 1,
                    borderRadius: "inherit",
                    p: 4,
                }}
                style={{
                  backgroundColor: "#E9E9E9",
                }}
              >
                  <Grid
                      container
                      sx={{
                          display: "flex",
                          alignItems: "space-between",
                          justifyContent: "space-between",
                          flexDirection: "column",
                      }}
                  >
                      <div style={{
                          marginBottom: 50,
                          flexDirection: "column",
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center"
                      }}>
                          <div style={{marginBottom: 15}}>
                              <PRCLogo className={classes.shimmer}/>
                          </div>
                          <div>
                              <div style={{display: "flex", flex: 1, justifyContent: "center", alignItems: "center"}}>
                                  <img src="/assets/svg/prctext.svg" className={classes.shimmer}/>
                              </div>
                          </div>


                      </div>

                      <div style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",

                      }}>
                          <Lottie className={classes.shimmer} style={{height: "130px"}} loop={false}
                                  animationData={edcs}/>


                          {/*    <img alt="EDC Logo" src={"/assets/logo.png"} height="130px" />*/}

                          <Typography
                              textTransform={"uppercase"}
                              variant="subtitle2"
                              marginTop={"10px"}
                              color="primary.main"
                          >
                              {APP_TITLE}
                          </Typography>
                      </div>

                      <div>
                          <Typography textAlign={"center"} marginTop={"60px"} fontSize={14}>
                              Operated and Maintained by CHED Regional Office 10
                          </Typography>
                          <Typography fontStyle={"italic"} textAlign={"center"} fontWeight={"bold"} fontSize={12}>
                              Powered by
                          </Typography>
                          <Typography fontStyle={"italic"} textAlign={"center"} fontWeight={"bold"} fontSize={12}>
                              USTP
                          </Typography>
                          <Typography fontStyle={"italic"} textAlign={"center"} fontWeight={"bold"} fontSize={12}>
                              Copyright © 2023
                          </Typography>
                      </div>


                  </Grid>
              </Box>
            </Paper>
          </Box>
        </Container>
      </main>
    </>
  );
};

export default LoginPage;
