import { FaChevronDown } from "react-icons/fa";
import {Avatar, Button} from "@mui/material";

interface HeaderProps {
  memberName?: string;
  memberImage?: string;
  onClick?: () => void;
};

export const Header = ({ 
  memberName = "Member",
  memberImage,
  onClick,
}: HeaderProps) => {
  const avatarFallback = memberName.charAt(0).toUpperCase();

  return (
    <div className="bg-white border-b h-[49px] flex items-center px-4 overflow-hidden">
      <Button
        variant="text"
        className="text-lg font-semibold px-2 overflow-hidden w-auto"
        size="small"
        onClick={onClick}
      >
        <Avatar title={avatarFallback} className="size-6 mr-2">

        </Avatar>
        <span className="truncate">{memberName}</span>
        <FaChevronDown className="size-2.5 ml-2" />
      </Button>
    </div>
  );
};
