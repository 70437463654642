import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Alert,
    Box,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {useQueryClient} from 'react-query';
import {editUser} from "../../requests/user.request";
import {useRegionSelectQuery} from "../../hooks/useRegionQuery";
import {useAppSelector} from "../../redux/store";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {SearchSchoolResponseType} from "../../types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {useUserIdQuery} from "../../hooks/useUserIdQuery";

const validationSchema = yup.object({
    schoolId: yup.string(),
    id: yup.string().nullable(),
    email: yup.string().email().required('Email is required'),
    //confirmPassword: yup.string().required('Confirm Password is required'),
    firstName: yup.string().required('First Name is required'),
    middleName: yup.string(),
    lastName: yup.string().required('Last Name is required'),
    designation: yup.string().required('Designation is required'),
    typeId: yup.number(),
    roleName: yup.string(),
    status: yup.boolean(),
})


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    open: boolean
    handleClose: () => void
}


const EditUser = React.memo((props: IProps) => {
    const { open, handleClose } = props
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const { schoolId,  institutionalCode} = useAddEntities();
    const { data: regions } = useRegionSelectQuery()
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])



    const editUsersId = useAppSelector((store) => store?.auth?.editUsersId);
    const regionId = useAppSelector((store) => store?.auth?.user?.region?.id);
    const type = useAppSelector((store) => store?.auth?.user?.type?.name);
    const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
    const roles = useAppSelector((store) => store?.auth?.user?.roles)
    const [loading, setLoading] = useState(false);


    const { data: user } = useUserIdQuery(
        {
            userId:editUsersId
        })

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        formik.setFieldValue('status',event.target.checked)
    };
    useEffect(() =>{
        formik.setFieldValue('email', user?.email ?? "", false )
        formik.setFieldValue('firstName', user?.firstName  ?? "" , false)
        formik.setFieldValue('id', user?.id  ?? "", false )
        formik.setFieldValue('lastName', user?.lastName  ?? "" , false)
        formik.setFieldValue('middleName', user?.middleName ?? "" , false)
        formik.setFieldValue('roleName',  typeId == 1 && roles.toLowerCase() == "admin" ? "User" : (user?.roleName  ?? "") , false)
        formik.setFieldValue('status', user?.status ?? "" , false )
        formik.setFieldValue('typeId', user?.typeId ?? "" , false )
        setChecked(user?.status ?? "" )
        formik.setFieldValue('designation', user?.designation ?? "", false  )
    }, [user])
    const initialValues = {
        schoolId: "",
        userName: '',
        password: '',
        id: '',
        regionId: 0,
        typeId: 1,
        confirmPassword: "",
        email: "",
        status: false,
        firstName: "",
        middleName: "",
        lastName: "",
        designation: "",
        roleName: "User",
    }


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {
                if(type?.toLowerCase() == "school"){
                  values.schoolId =  schoolId ?? ""
                  values.regionId =  regionId ?? 0
                  values.typeId =  typeId ?? 0
                }else if(roles?.toLowerCase() == "superadmin"){

                }

                var res  = await editUser(values, {schoolId: schoolId} )
        queryClient.setQueriesData('user', (oldData: any) => {

                   return {
                       ...oldData, data: oldData?.data?.map((school: any) => {
                           if (school.id === values?.id) {
                               console.log(values.roleName, school.role)
                               school.role = values.roleName
                               return school
                           }
                           return school
                       })
                   }
               })
               await queryClient.invalidateQueries("user");


                setSnackbarStore({
                    message: "Successfully update user",
                    severity: "success",
                    open: true,
                })
                handleClose()
                setLoading(false)
            } catch (error: any) {
                setErrorSnackbar(error, setSnackbarStore);
                setLoading(false)
            }finally {
                setLoading(false)
            }


        }
    })

    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit()
    }, [formik])



    useEffect(()=>{
        formik.setFieldValue('schoolId', schoolId )
    }, [])


    const handleChangeRole = (event: SelectChangeEvent) => {
        formik.setFieldValue('roleName', event?.target?.value )
    };

    function handleOnSelectedSchool(value: SearchSchoolResponseType) {
        formik.setFieldValue('schoolId', value?.id ?? "")
        if(value?.region?.id){
            formik.setFieldValue('regionId', value?.region?.id ?? value?.regionId)
        }

    }


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
           // keepMounted
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    Please fill in the details of the user you want to add
                    <br />
                    Note: Fields with <strong>*</strong> are required
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                        paddingTop: 3
                    }}
                >


                    <div   style={{flex: 1, display: "flex", flexDirection: "row"}}>
                        <div  style={{ flex: 0.5, display: "flex", alignItems: "center"}} >
                            <div style={{width: "100%", }}  >

                                <div   style={{ flex: 1, display: "flex", flexDirection: "row"}}  >
                                    {  roles?.toLowerCase() == "superadmin" || roles?.toLowerCase() == "admin" ?   <FormControl fullWidth>
                                        <div style={{marginRight: 6, flex: roles?.toLowerCase() == "superadmin" ?  0.5 : 1, display: "flex"}}>
                                            <InputLabel id="user-select-role">Role</InputLabel>
                                            <Select
                                                inputProps={{
                                                    'id': `user-select-role`,
                                                }}
                                                SelectDisplayProps={{
                                                    // @ts-ignore
                                                    "id": `user-select-role`
                                                }}
                                                id="user-select-role"
                                                labelId="demo-simple-select-label"
                                                size='small'
                                                fullWidth
                                                variant="outlined"
                                                disabled={(typeId == 2 && roles.toLowerCase() == "user") || typeId == 1 && roles.toLowerCase() == "admin"}
                                                margin="dense"
                                                value={formik.values.roleName }
                                                label="Role"
                                                aria-label={"role-select"}
                                                onChange={handleChangeRole}

                                            >

                                                    <MenuItem value={"User"}>User</MenuItem>
                                                { !(typeId == 1 && (roles.toLowerCase() == "admin" ||roles.toLowerCase() == "user" )) ?
                                                    <MenuItem value={"Admin"}>Admin</MenuItem> : null
                                                }


                                            </Select>
                                        </div>
                                        {/* <div style={{ justifyContent: "center", marginRight: 6, flex: 0.5, display: "flex"}} >
                            <FormControlLabel
                                label="Status"
                                control={
                                    <Checkbox
                                        value={checked}
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                }
                            />

                        </div>*/}


                                    </FormControl> : null
                                    }


                                </div>

                            </div>
                        </div>


                        {  roles?.toLowerCase() == "superadmin"  || roles?.toLowerCase() == "admin" ?
                            <div style={{ flexDirection: "row", justifyContent: "center", marginRight: 6, flex: 0.5, display: "flex"}}>
                                <div style={{ flexDirection: "column", justifyContent: "center", marginRight: 6, flex: 0.5, display: "flex"}} >
                                    <FormControl style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <FormLabel id="typeId">Account Type</FormLabel>
                                        <RadioGroup
                                            row
                                            onChange={(event) => formik.setFieldValue('typeId', event.target.value)}
                                            value={formik.values.typeId}
                                            aria-labelledby="typeId"

                                            defaultValue={1}
                                            name="radio-buttons-group"
                                        >

                                            <FormControlLabel disabled={true} checked={formik.values.typeId == 1} value={1} control={<Radio />} label="School" />
                                            <FormControlLabel disabled={true} checked={formik.values.typeId == 2 } value= {2} control={<Radio />} label="CHED" />
                                        </RadioGroup>

                                    </FormControl>
                                </div>
                            </div>



                            : null}


                    </div>





                    <TextField
                        disabled={true}
                        label="Email"
                        type={"email"}
                       variant="outlined"
                        margin="dense"
                        size='small'

                        fullWidth
                        id='usereditemail'
                        name='email'
                            value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}

                    />



                        <TextField

                        label="First Name"
                       variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        id='usereditfirstName'
                        name='firstName'
                        value={formik.values?.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched?.firstName && Boolean(formik.errors?.firstName)}
                        helperText={formik.touched?.firstName && formik.errors?.firstName}

                    />

                    <TextField
                       // disabled={roles?.toLowerCase() == "superadmin" || roles?.toLowerCase() == "admin"  ? true : false}
                        label="Middle Name"
                       variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        id='usereditmiddleName'
                        name='middleName'
                        value={formik.values?.middleName}
                        onChange={formik.handleChange}
                        error={formik.touched?.middleName && Boolean(formik.errors?.middleName)}
                        helperText={formik.touched?.middleName && formik.errors?.middleName}

                    />
                    <TextField
                        //disabled={roles?.toLowerCase() == "superadmin" || roles?.toLowerCase() == "admin"  ? true : false}
                        label="Last Name"
                       variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        id='usereditlastName'
                        name='lastName'
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched?.lastName && Boolean(formik.errors?.lastName)}
                        helperText={formik.touched?.lastName && formik.errors?.lastName}

                    />

                    <TextField

                        label="Designation"
                       variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        id='usereditdesignation'
                        name='designation'
                        value={formik.values?.designation}
                        onChange={formik.handleChange}
                        error={formik.touched?.designation && Boolean(formik.errors?.designation)}
                        helperText={formik.touched?.designation && formik.errors?.designation}
                        required={true}
                    />


                </Box>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelUser"} onClick={handleClose}>Cancel</Button>
                <Button id={"editUser"} variant='contained' disabled={loading} onClick={handleSubmit}><Box>
                    {"Edit"}
                </Box></Button>
            </DialogActions>
        </Dialog>
    )
})

export default EditUser
