import * as React from "react";
const ProgramSvg = (props: any) => (
    <svg
        fill="#ffff"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="31px"
        height="31px"
        viewBox="0 0 2.919 2.045"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path
                d="M1.501 1.36c-0.035 0 -0.069 -0.009 -0.095 -0.026L0.577 0.895v0.679c0 0.047 0.038 0.086 0.085 0.086 0 0 0.438 -0.073 0.822 -0.073 0.384 0 0.78 0.073 0.78 0.073a0.085 0.085 0 0 0 0.085 -0.086V0.93L1.592 1.335c-0.025 0.016 -0.057 0.025 -0.091 0.025"/>
            <path
                d="M0.444 1.682a0.052 0.052 0 0 0 -0.036 -0.025V0.687L1.458 1.244l0.002 0.001c0.019 0.013 0.059 0.013 0.076 0.002l0.004 -0.002 1.378 -0.736L1.484 0.014l-0.005 -0.004c-0.019 -0.013 -0.059 -0.013 -0.076 -0.002l-0.006 0.004 -0.007 0.002L0 0.471l0.304 0.161v1.025a0.051 0.051 0 0 0 -0.036 0.025l-0.149 0.264a0.052 0.052 0 0 0 0.09 0.051L0.304 1.829v0.164a0.052 0.052 0 0 0 0.104 0v-0.164l0.095 0.168a0.052 0.052 0 0 0 0.071 0.02 0.052 0.052 0 0 0 0.02 -0.071z"/>
        </g>
    </svg>
);
export default ProgramSvg;
