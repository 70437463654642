import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Container,
  Divider,
  List,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {ArrowDropDown, BookmarkRounded, FileUploadRounded} from "@mui/icons-material";
import TabPanel from "../components/TabPanel";
import useUploadHEIDialog from "../components/dialogs/UploadHEI.dialog";
import CurriculumTable from "../components/Tables/CurriculumTable";
import CourseTable from "../components/Tables/CourseTable";
import ProgramTable from "../components/Tables/ProgramTable";
import {
  setClear,
  setSchoolSelectorById,
  setSelectedSettingRegion,
  setSelectedSettingSchool,
  setTabValue
} from "../redux/features/school";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../redux/store";
import DisciplineTable from "../components/Tables/DisciplineTable";
import ReportTable from "../components/Tables/ReportTable";
import {useSchoolQuery} from "../hooks/useSchoolQuery";
import {useRegionSelectQuery} from "../hooks/useRegionQuery";
import {getSchoolsById} from "../requests/school.request";
import Button from "@mui/material/Button";
import MajorTable from "../components/Tables/MajorTable";
import {useLocation} from "react-router";
import {useHistory} from "react-router-dom";
import ConfigurationRequirementTable from "../components/Tables/ConfigurationRequirementTable";

const SettingPage = () => {
  const dispatch = useDispatch();
  const tabValue = useAppSelector((store) => store?.school.tabValue);
  const [selectedRegionId, setSelectedRegionId] = React.useState<any>( {
    id: undefined,
    name: "All"
  });

  const navigate = useHistory();
  const location = useLocation();
  const { Component: UploadHEI, handleClickOpen } = useUploadHEIDialog();
  useEffect(() => {
    var query = new URLSearchParams(location.search)
    const page = query.get('page') || '1';
    dispatch(setClear());
    dispatch(setTabValue(page));
    return () =>{
      dispatch(setSelectedSettingRegion({name: "All", id: undefined}));
    }
  }, []);
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
    | "User"
    | "Admin"
    | "SuperAdmin";
  const disciplineName = useAppSelector(
    (store) => store?.school.disciplineName
  );
  const programName = useAppSelector((store) => store?.school.programName);
  const curriculumName = useAppSelector(
    (store) => store?.school.curriculumName
  );
  const regionSetting = useAppSelector((store) => store?.school.regionSetting);
  const {data: dataSchools, isLoading} = useSchoolQuery({
    pageSize: "1000",
    search: '',
    ...(regionSetting?.id && {regionId: regionSetting?.id})
  });
  const schoolSetting = useAppSelector((store) => store?.school.schoolSetting);
  const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);


  const schoolSelector = useAppSelector(
      (store) => store?.auth?.user?.school
  );
  useEffect(() => {
    getSchoolsById(schoolSelector?.code).then(c => dispatch(setSchoolSelectorById(c?.[0])))
  }, [schoolSelector])
  const {data: regions} = useRegionSelectQuery()
  return (
      <>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <Box
            component="main"
            sx={{flexGrow: 1, pt: 10}}
            style={{paddingLeft: " 20px", paddingRight: "20px"}}
      >
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >

            <Stack direction="row" spacing={1}>
              {roles === "SuperAdmin" && (
                  <Button
                      variant="contained"
                      onClick={handleClickOpen}
                      startIcon={<FileUploadRounded/>}
                  >
                    Import HEI Excel
                  </Button>
              )}
            </Stack>
          </Box>

          <Paper sx={{ my: 1 }}>
            <List
                dense
                subheader={
                  <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                    >
                      <div>
                        <Typography variant="h5" fontWeight="bold">
                          Setting
                        </Typography>
                      </div>

                      <div>
                        <div style={{flex: 1, display: "flex", }}>
                          {((roles?.toLowerCase() == "superadmin" || typeId == 3)
                              && roles?.toLowerCase() != "user"
                          ) ? <form onSubmit={(event) => event.preventDefault()}>
                            <>
                              <Autocomplete
                                  popupIcon={<ArrowDropDown
                                      id={"ArrowDropDown-" + "settingdashboardschoolyear"}/>}
                                  id={"settingdashboardschoolyear"}
                                  disabled={isLoading}
                                  disableClearable
                                  size="small"
                                  value={regionSetting}
                                  onChange={(event: any, newValue: string | null) => {
                                          dispatch(setSelectedSettingRegion(newValue ? newValue : {}));
                                      }}
                                  getOptionLabel={(option) => {
                                          return option.name
                                      }
                                  }
                                  renderOption={(props, option) => (
                                      <li {...props} id={"setting" + option.id} key={option.id}>
                                        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                          {option.name}</div>
                                      </li>
                                  )}
                                  options={[{name: "All", id: undefined}, ...(regions || [])] || []}
                                  style={{width: 300, marginLeft: 6}}
                                  renderInput={(params) => (
                                      <TextField {...params} label="Select Region"/>
                                  )}
                              />
                            </>
                          </form> : null}
                          {((roles?.toLowerCase() == "superadmin" || typeId == 2 || typeId == 3)
                              /* && roles?.toLowerCase() != "user"*/
                          ) ? <Box sx={{paddingLeft: 1}} display="flex" alignItems="center" gap={2}>
                            <form onSubmit={(event) => event.preventDefault()}>
                              <>
                                <Autocomplete popupIcon={<ArrowDropDown
                                    id={"ArrowDropDown-" + "settingschooldashboardschoolyear"}/>}
                                              id={"settingschooldashboardschoolyear"}
                                              disabled={isLoading}
                                              disableClearable
                                              size="small"
                                              value={schoolSetting}
                                              onChange={(event: any, newValue: string | null) => {
                                                      dispatch(setSelectedSettingSchool(newValue ? newValue : {}));
                                                  }}
                                              renderOption={(props, option) => (
                                                      <li {...props} id={"settingschool" + option.id} key={option.id}>
                                                          <div style={{
                                                              flex: 1,
                                                              display: "flex",
                                                              flexDirection: "column"
                                                          }}>
                                            { option.name ?? option.displayName}
                                            <p  style={{fontStyle: "italic", margin: 0,fontSize: 12}}>{option?.institutionalCode ? option.institutionalCode + " - " : "" }{option?.address ? option.address : (option?.address ?  option.address + " - " : "") + (option?.province?.name ?  option.province.name + " - " : "") + (option?.region?.name ?  option.region.name + "" : "")}</p>
                                          </div>
                                        </li>
                                    )}
                                              getOptionLabel={(option) => option.name}
                                              options={[{
                                                name: "All",
                                                id: undefined
                                              }, ...(dataSchools?.data || [])] || []}
                                              sx={{width: 500}}
                                              renderInput={(params) => (
                                        <TextField {...params}  id={"heisettingselect"} label="Select HEI Profile" />
                                    )}
                                />
                              </>
                            </form>
                          </Box> : null}
                        </div>

                      </div>

                    </Box>
                    <Divider />
                  </>
                }
            ></List>
            <Breadcrumbs sx={{ p: 2 }}>
              {disciplineName ? (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <BookmarkRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                  {disciplineName}
                </Typography>
              ) : null}
              {programName ? (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <BookmarkRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                  {programName}
                </Typography>
              ) : null}

              {curriculumName ? (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <BookmarkRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                  {curriculumName}
                </Typography>
              ) : null}
            </Breadcrumbs>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                  value={tabValue}
                  onChange={(event: React.SyntheticEvent, newValue: number) => {
                    const newParams = { page: newValue }
                    const searchParams = new URLSearchParams(location.search);

                    dispatch(setClear());
                    dispatch(setTabValue(newValue));
                    Object.keys(newParams).forEach(key => {
                      if ((newParams as any)[key] === null) {
                        searchParams.delete(key);
                      } else {
                        searchParams.set(key, (newParams as any)[key]);
                      }
                    });


                    navigate.replace(`${location.pathname}?${searchParams.toString()}`, { replace: true });
                  }}
              >
                  <Tab id={"Majors"} label="Majors" value={"6"}/>
                  <Tab id={"Disciplines"} label="Disciplines" value={"0"}/>
                <Tab id="Programs" label="Programs" value={"1"}/>
                <Tab id="Curriculums" label="Curriculums" value="curriculum"/>
                  <Tab id="Courses" label="Courses" value={"2"}/>
                  {/*{
              access.indexOf(roles) > -1 ? <Tab label="Users" value={3}/> : null
            }*/}
                  <Tab label="Download" value={"4"}/>
                  {/*   <Tab label="Pricing Rules" value={5}/>*/}
                {
                  typeId == 2 && roles.toLowerCase() == "admin" ?
                      <Tab label="Configuration Requirements" value={"7"}/>: null
                }

              </Tabs>
            </Box>
              <TabPanel value={tabValue} index={"6"}>
                  <MajorTable setting={true} title="Majors Table"/>
              </TabPanel>
              <TabPanel value={tabValue} index={"0"}>
                  <DisciplineTable setting={true} title="Disciplines Table"/>
              </TabPanel>
              <TabPanel value={tabValue} index={"1"}>
                  <ProgramTable setting={true} title="Programs Table"/>
              </TabPanel>
              <TabPanel value={tabValue} index="curriculum">
                  <CurriculumTable setting={true} title="Curriculums Table"/>
              </TabPanel>
              <TabPanel value={tabValue} index={"2"}>
              <CourseTable setting={true} title="Courses Table"/>
            </TabPanel>
            { typeId == 2 && roles.toLowerCase() == "admin" ?
            <TabPanel value={tabValue} index={"7"}>
              <ConfigurationRequirementTable title="Configuration Requirements"/>
            </TabPanel> : null }
            {/*{
            access.indexOf(roles) > -1 ? <TabPanel value={tabValue} index={3}>
            <UserTable title="Users Table" />
            </TabPanel> : null
          }*/}
            <TabPanel value={tabValue} index={"4"}>
              <ReportTable title="Download Reports"/>
            </TabPanel>


          </Paper>
          {UploadHEI}
        </Container>
      </Box>
    </>
  );
};

export default SettingPage;
