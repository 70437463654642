// create a custom hook to get the screen dimensions
import { useState, useEffect } from 'react';

export const useScreenDimension = () => {
    const [screenDimension, setScreenDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenDimension({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return screenDimension;
}