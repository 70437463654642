import React, {useState, useEffect, useCallback} from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "react-query";
import {deleteWorkspace, updateWorkspace} from "../../requests/workspace.request";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": { padding: theme.spacing(2) },
    "& .MuiDialogActions-root": { padding: theme.spacing(1) },
}));

interface IPreferenceModal {
    initialValue: { id: string; name: string };
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function PreferenceModal({
                                            initialValue,
                                            open,
                                            setOpen,
                                        }: IPreferenceModal) {
    const {workspaceId} = useParams() as {
        workspaceId: any
    };
    const [name, setName] = useState(initialValue?.name);
    const [error, setError] = useState("");
    const queryClient = useQueryClient();
    const history = useHistory()
    const pushTo = useCallback((path: string) => {
        history.push(path)
    }, [history])
    const dispatch = useDispatch();
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);
    const { mutate: saveWorkspace, isLoading: isSaving } = useMutation(
        async ({ id, name }: { id: string; name: string }) => {
            await updateWorkspace(id, name)
            return { id, name }
        }, {
            onSuccess: (res) => {
                queryClient.invalidateQueries(["workspaces"]);
                setOpen(false);
                setSnackbarStore({
                    message: `${res?.name} is updated successfully`  ?? "Something went wrong",
                    severity: "success",
                    open: true,
                });
                if(res?.id) history.replace(`/workspace/${res?.id}`)

            },
            onError: () => setError("Failed to update workspace"),
        });

    const { mutate: removeWorkspace, isLoading: isDeleting } = useMutation(
        (id: string) => deleteWorkspace(id), {
            onSuccess: async() => {

                await queryClient.invalidateQueries(["workspaces"]);
                pushTo('/workspaces')
                setSnackbarStore({
                    message: `${initialValue?.name} is delete successfully`  ?? "Something went wrong",
                    severity: "success",
                    open: true,
                });

                setOpen(false);
            },
            onError: () => setError("Failed to delete workspace"),
        });

    useEffect(() => {
        setName(initialValue?.name);
        setError("");
    }, [initialValue, workspaceId]);

    const handleSave = () => {
        if (!name?.trim()) {
            setError("Workspace name is required");
            return;
        }
        saveWorkspace({ id: initialValue.id, name });
    };

    const handleDelete = async() => {
        await removeWorkspace(initialValue.id ?? workspaceId);

    };

    const handleClose = () => {
        setOpen(false);
        setError("");
    };

    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Edit Workspace
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <form className={"space-y-4"}>
                    <TextField
                        label="Workspace Name"
                        fullWidth
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            if (error) setError(""); // Clear error on change
                        }}
                        variant="outlined"
                        required
                        error={!!error}
                        helperText={error}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={handleDelete} disabled={isDeleting}>
                    {isDeleting ? "Deleting..." : "Delete"}
                </Button>
                <Button color="primary" onClick={handleSave} disabled={isSaving || !name?.trim()}>
                    {isSaving ? "Saving..." : "Save"}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
