import { Menu as MenuMUI } from '@mui/material';
import { FC, MouseEvent, ReactNode, useState } from 'react';


interface IUseMenuDialog {
    onClose: () => void;
}

export default function useMenuDialog({ onClose }: IUseMenuDialog) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onClose();
    };

    const Component: FC<{ children: ReactNode }> = ({ children }) => {
        return (
            <MenuMUI
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {children}
            </MenuMUI>
        );
    }
    return { Component, handleClick, handleClose }

}