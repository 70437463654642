import * as React from "react";
import {SVGProps} from "react";

const PdfIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24.896}
        height={30.001}
        viewBox="0 0 24.896 30.001"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.3}>
            <path
                opacity={0.3}
                d="M17.385 6.131a2.57 2.57 0 0 1 -2.567 -2.567V0H3.35A3.35 3.35 0 0 0 0 3.35v20.193a3.35 3.35 0 0 0 3.35 3.345h13.769a3.35 3.35 0 0 0 3.35 -3.345V6.131H17.385Z"
                fill="#FF3E4C"
            />
        </g>
        <path
            d="M20.468 6.131H17.385a2.57 2.57 0 0 1 -2.567 -2.567V0l5.649 6.131Z"
            fill="#FF3E4C"
        />
        <path
            d="M16.257 18.777H3.405a0.467 0.467 0 0 1 -0.467 -0.467 0.467 0.467 0 0 1 0.467 -0.465h12.851a0.468 0.468 0 0 1 0.33 0.137 0.465 0.465 0 0 1 0.137 0.33 0.465 0.465 0 0 1 -0.137 0.33 0.465 0.465 0 0 1 -0.33 0.137Z"
            fill="#FF3E4C"
        />
        <path
            d="M16.257 13.518H3.405a0.467 0.467 0 0 1 -0.467 -0.465 0.467 0.467 0 0 1 0.467 -0.467h12.851a0.465 0.465 0 0 1 0.431 0.288 0.468 0.468 0 0 1 -0.102 0.509 0.465 0.465 0 0 1 -0.33 0.137Z"
            fill="#FF3E4C"
        />
        <path
            d="M16.257 16.148H3.405a0.467 0.467 0 0 1 -0.467 -0.467 0.467 0.467 0 0 1 0.467 -0.465h12.851a0.468 0.468 0 0 1 0.33 0.137 0.465 0.465 0 0 1 0.137 0.33 0.465 0.465 0 0 1 -0.137 0.33 0.465 0.465 0 0 1 -0.33 0.137Z"
            fill="#FF3E4C"
        />
        <path
            d="M11.189 10.889H3.405a0.467 0.467 0 0 1 -0.467 -0.465 0.467 0.467 0 0 1 0.467 -0.467h7.782a0.467 0.467 0 0 1 0.465 0.467 0.467 0.467 0 0 1 -0.465 0.465Z"
            fill="#FF3E4C"
        />
        <path
            d="M11.189 8.261H3.405a0.467 0.467 0 0 1 -0.467 -0.465 0.467 0.467 0 0 1 0.467 -0.467h7.782a0.467 0.467 0 0 1 0.465 0.467 0.467 0.467 0 0 1 -0.465 0.465Z"
            fill="#FF3E4C"
        />
        <path
            d="M22.896 21.237H7.332a2 2 0 0 0 -2 2v4.64a2 2 0 0 0 2 2h15.564a2 2 0 0 0 2 -2V23.235a2 2 0 0 0 -2 -2Z"
            fill="#FF3E4C"
        />
        <path d="M10.439 26.561v0.905h-0.936v-0.905h0.936Z" fill="white"/>
        <path
            d="M11.816 26.153v1.314h-0.905v-3.744h1.467c0.444 0 0.785 0.111 1.017 0.332a1.172 1.172 0 0 1 0.348 0.893 1.223 1.223 0 0 1 -0.156 0.623 1.092 1.092 0 0 1 -0.464 0.429 1.631 1.631 0 0 1 -0.747 0.156l-0.561 -0.002Zm1.007 -1.206c0 -0.332 -0.182 -0.498 -0.545 -0.498h-0.461v0.975h0.461c0.363 0.003 0.545 -0.156 0.545 -0.477Z"
            fill="white"
        />
        <path
            d="M17.319 26.564a1.652 1.652 0 0 1 -0.678 0.665 2.171 2.171 0 0 1 -1.041 0.239h-1.413v-3.744h1.413a2.205 2.205 0 0 1 1.043 0.233 1.619 1.619 0 0 1 0.675 0.657 1.958 1.958 0 0 1 0.237 0.974 1.977 1.977 0 0 1 -0.237 0.978Zm-0.974 -0.189a1.041 1.041 0 0 0 0.294 -0.789 1.04 1.04 0 0 0 -0.294 -0.788 1.142 1.142 0 0 0 -0.822 -0.282h-0.429v2.139h0.429a1.146 1.146 0 0 0 0.822 -0.281Z"
            fill="white"
        />
        <path
            d="M20.51 23.723v0.722H18.975v0.821h1.185v0.689h-1.185v1.514h-0.906v-3.744h2.442Z"
            fill="white"
        />
        <path
            d="M16.029 7.328H13.829a0.695 0.695 0 0 0 -0.695 0.695v2.201a0.695 0.695 0 0 0 0.695 0.695h2.201a0.695 0.695 0 0 0 0.695 -0.695V8.022a0.695 0.695 0 0 0 -0.695 -0.695Z"
            fill="#FF3E4C"
        />
    </svg>
);
export default PdfIcon;
