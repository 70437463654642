import {useCallback, useMemo, useRef, useState} from "react";
import Quill from "quill";

import { AlertTriangle, Loader, XIcon } from "lucide-react";
import { differenceInMinutes, format, isToday, isYesterday } from "date-fns";
import {Button} from "@mui/material";
import {toast} from "react-toastify";
import Editor from "./editor";
import Message from "./Message";
import {useInfiniteQuery, useMutation, useQuery} from "react-query";
import {createMessage, getMessage, getMessages} from "../../requests/message.request";
import {GetMessageInputType, MessageInputType} from "../../schemas/major.schema";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../redux/store";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";

const TIME_THRESHOLD = 5;

interface ThreadProps {
    messageId: any;
    onClose: () => void;
};

type CreateMesageValues = {
    channelId: any;
    workspaceId:any;
    parentMessageId: any;
    body: string;
    image:any | undefined;
};

const formatDateLabel = (dateStr: string) => {
    const date = new Date(dateStr);
    if (isToday(date)) return "Today";
    if (isYesterday(date)) return "Yesterday";
    return format(date, "EEEE, MMMM d");
};

export const Thread = ({ messageId, onClose }: ThreadProps) => {
    const {channelId, workspaceId} = useParams() as {
        channelId: string,
        workspaceId: string
    };
    const authStore = useSelector((state: RootState) => state.auth)
    const { data: message, isLoading: loadingMessage, status } = useQuery(["message", messageId], () => getMessage(messageId as unknown as GetMessageInputType));
    const { data: channelData,
        isError: channelIsError,
        error: channelError,
        fetchNextPage,
        hasNextPage, isFetching,
        isLoading: channelIsLoading } =
        useInfiniteQuery({
            queryKey: ["threadmessages", messageId, workspaceId],
            queryFn: ({ pageParam }) => getMessages(
                messageId,
                workspaceId,
                "",
                pageParam,
                "",
                "",
                channelId),
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.length ? allPages.length + 1 : undefined;
            },
        });
    const results = useMemo(() => {
        return channelData?.pages.reduce((acc, page) => {
            return [...acc, ...page];
        }, []);
    }, [channelData]);
    const [editingId, setEditingId] = useState<any | null>(null);
    const [editorKey, setEditorKey] = useState(0);
    const [isPending, setIsPending] = useState(false);
    const editorRef = useRef<Quill | null>(null);
    const dispatch = useAppDispatch();
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);
    const {mutate: onCreateMessage, isLoading} = useMutation({
        mutationKey: "createMessage", mutationFn: async (value: MessageInputType) => {
            return await createMessage(value)
        }
    });
    const handleSubmit = async ({
                                    body,
                                    image
                                }: {
        body: string;
        image: File | null;
    }) => {
        try {
            setIsPending(true)
            await onCreateMessage({
                parentMessageId: messageId,
                workspaceId,
                channelId,
                body
            } as MessageInputType)
            setEditorKey((prevState) => prevState + 1)

        }catch (e:any) {
            setSnackbarStore({
                message: e?.message ?? "Something went wrong",
                severity: "error" ,
                open: true,
            });
        } finally {
            setIsPending(false)
        }

    };


    if (loadingMessage) {
        return (
            <div className="h-full flex flex-col">
                <div className="h-[49px] flex justify-between items-center px-4 border-b">
                    <p className="text-lg font-bold">Thread</p>
                    <Button onClick={onClose} size="small" variant="text">
                        <XIcon className="size-5 stroke-[1.5]" />
                    </Button>
                </div>
                <div className="flex flex-col gap-y-2 h-full items-center justify-center">
                    <Loader className="size-5 animate-spin text-muted-foreground" />
                </div>
            </div>
        );
    }

    if (!message) {
        return (
            <div className="h-full flex flex-col">
                <div className="h-[49px] flex justify-between items-center px-4 border-b">
                    <p className="text-lg font-bold">Thread</p>
                    <Button onClick={onClose} size="small" variant="text">
                        <XIcon className="size-5 stroke-[1.5]" />
                    </Button>
                </div>
                <div className="flex flex-col gap-y-2 h-full items-center justify-center">
                    <AlertTriangle className="size-5 text-muted-foreground" />
                    <p className="text-sm text-muted-foreground">Message not found</p>
                </div>
            </div>
        );
    }
    const groupedMessages = results?.reduce(
        (groups: any, message: any) => {
            const date = new Date(message.dateCreated);
            const dateKey = format(date, "yyyy-MM-dd");
            if (!groups[dateKey]) {
                groups[dateKey] = [];
            }
            groups[dateKey].unshift(message);
            return groups;
        },
        {} as any
    );

    return (
        <div className="h-full flex flex-col">
            <div className="h-[49px] flex justify-between items-center px-4 border-b">
                <p className="text-lg font-bold">Thread</p>
                <Button onClick={onClose} size="small" variant="text">
                    <XIcon className="size-5 stroke-[1.5]"/>
                </Button>
            </div>
            <div className="flex-1 flex flex-col-reverse pb-4 overflow-y-auto messages-scrollbar">
                {Object.entries(groupedMessages || {}).map(([dateKey, messages]) => (
                    <div key={dateKey}>
                        <div className="text-center my-2 relative">
                            <hr className="absolute top-1/2 left-0 right-0 border-t border-gray-300"/>
                            <span
                                className="relative inline-block bg-white px-4 py-1 rounded-full text-xs border border-gray-300 shadow-sm">
                {formatDateLabel(dateKey)}
              </span>
                        </div>
                        {(messages as any[] || []).map((message:any, index) => {
                            const prevMessage = (messages as any)[index - 1];
                            const isCompact =
                                prevMessage &&
                                prevMessage.user?.id === message.user?.id &&
                                differenceInMinutes(
                                    new Date(message.dataCreated),
                                    new Date(prevMessage.dataCreated)
                                ) < TIME_THRESHOLD;

                            return (
                                <Message
                                    key={message.id}
                                    id={message.id}
                                    memberId={message.member?.id}
                                    authorImage={message?.member?.image}
                                    authorName={message?.member?.userName}
                                    isAuthor={authStore?.user?.id == message?.member?.userId}
                                    reactions={message.reactions}
                                    body={message.body}
                                    image={message.image}
                                    updatedAt={message.updatedAt}
                                    createdAt={message.dataCreated}
                                    isEditing={editingId === message.id}
                                    setEditingId={setEditingId}
                                    isCompact={isCompact}
                                    hideThreadButton
                                    threadCount={message.threadCount}
                                    threadImage={message.threadImage}
                                    threadName={message.threadName}
                                    threadTimestamp={message.threadTimestamp}
                                />
                            )
                        })}
                    </div>
                ))}
                <div
                    className="h-1"
                    ref={(el) => {
                        if (el) {
                            const observer = new IntersectionObserver(
                                ([entry]) => {
                                    if (entry.isIntersecting && hasNextPage) {
                                        fetchNextPage();
                                    }
                                },
                                {threshold: 1.0}
                            );

                            observer.observe(el);
                            return () => observer.disconnect();
                        }
                    }}
                />
                {channelIsLoading && (
                    <div className="text-center my-2 relative">
                        <hr className="absolute top-1/2 left-0 right-0 border-t border-gray-300"/>
                        <span
                            className="relative inline-block bg-white px-4 py-1 rounded-full text-xs border border-gray-300 shadow-sm">
              <Loader className="size-4 animate-spin"/>
            </span>
                    </div>
                )}
                <Message
                    hideThreadButton
                    memberId={message.member?.id}
                    authorImage={message?.member?.image}
                    authorName={message?.member?.userName}
                    isAuthor={authStore?.user?.id == message?.member?.userId}
                    body={message.body}
                    image={message.image}
                    createdAt={message.dataCreated}
                    updatedAt={message.updatedAt}
                    id={message.id}
                    reactions={message.reactions}
                    isEditing={editingId === message.id}
                    setEditingId={setEditingId}
                />
            </div>
            <div className="px-4">
                <Editor
                    key={editorKey}
                    onSubmit={handleSubmit}
                    innerRef={editorRef}
                    disabled={isPending}
                    placeholder="Reply.."
                />
            </div>
        </div>
    );
};
