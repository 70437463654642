import * as Yup from "yup";
import { enrollmentSchema, IEnrollmentSchema } from "./enrollment.schema";

export interface IPromotionalReportSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  schoolYear?: string | null;
  schoolName?: string | null;
  semester?: string | null;
  schoolInstitutionalCode?: string | null;
  address?: string | null;
  totalNoOfStudents?: string | null;
  preparedBy?: string | null;
  approvedBy?: string | null;
  status?: string | null;
  enrollments: IEnrollmentSchema[];
  isLocked?: boolean;
  isDeleted?: boolean;
}

export const promotionalReportSchema: Yup.SchemaOf<IPromotionalReportSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    schoolYear: Yup.string().nullable(),
    schoolName: Yup.string().nullable(),
    semester: Yup.string().nullable(),
    schoolInstitutionalCode: Yup.string().nullable(),
    address: Yup.string().nullable(),
    totalNoOfStudents: Yup.string().nullable(),
    preparedBy: Yup.string().nullable(),
    approvedBy: Yup.string().nullable(),
    status: Yup.string().nullable(),
    enrollments: Yup.array(Yup.lazy(() => enrollmentSchema)),
    isLocked: Yup.boolean().optional(),
    isDeleted: Yup.boolean().optional(),
  });

export type PromotionalReportType = Yup.InferType<
  typeof promotionalReportSchema
>;
