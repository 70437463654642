import api from "./config";
import {AxiosRequestConfig} from "axios";
import {GetCurriculumResponseType, GetUsersResponseType} from "../types";
import {registerSchema} from "../schemas/auth.schema";

export const patchUserApplication = async (userApplicationId: any) => {
    const res = await api.patch(`/UserApplications/update-status/${userApplicationId}`)
    return res?.data
}
export const postUserApplication = async (data: any) => {
    const res = await api.post("/UserApplications", {
        userApplications: data.userApplications,
        userId: data.userId
    })
    return res?.data
}
export const getUserApplication = async (userId: string) => {
    if(!userId) return
    const res = await api.get(`/UserApplications/get/${userId}`, );
    const data = res?.data.data ?? [];
    return {
        ...res?.data, data
    };
}
