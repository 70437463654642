import * as React from 'react';
import {useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import {useDebounce} from "../../hooks/useDebounce";
import {useSearchDisciplineQuery} from "../../hooks/useSearchDisciplineQuery";
import {DisciplineType} from "../../types";
import {useAppSelector} from "../../redux/store";

interface ISearchAsYouTypeProps {
    label: string
    onSelected: (value: DisciplineType) => void,
    schoolId: any,
    defaultValue?: any,
    disabled?: any
}

const SearchDisciplineTextField: React.FC<ISearchAsYouTypeProps> = (props) => {


    const {label, onSelected, schoolId, disabled, defaultValue} = props;

    const [name, setName] = React.useState("")
    const debouncedName = useDebounce(name, 500)
    const { data: options , isLoading } = useSearchDisciplineQuery(debouncedName, schoolId)
    const [open, setOpen] = React.useState(false);
    const discipline = useAppSelector((store) => store?.school?.discipline);

    useEffect(()=>{
        onSelected(discipline)
    }, [])



    return (
        <Autocomplete
            id={"searchDiscipline"}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disabled={disabled}
            getOptionLabel={(option) => option?.name }
            options={(options?.data || []) as DisciplineType[]}
            defaultValue={defaultValue ?? discipline}
            loading={isLoading}
            onChange={(e, value) => {
                if (value !== null && value !== undefined) onSelected(value)
            }}
            renderOption={(props, option) => (
                <li {...props} id={"settingschoolinstutional" + option?.id} key={option?.id}>
                    <div id={"searchasyoutypesettingschoolinstutional" + option?.id}
                         style={{flex: 1, display: "flex", flexDirection: "column"}}>
                        {option?.name}
                        <p style={{
                            fontStyle: "italic",
                            margin: 0,
                            fontSize: 12
                        }}>Code: {option?.code}</p>
                        <p style={{
                            fontStyle: "italic",
                            margin: 0,
                            fontSize: 12
                        }}>{option?.school}</p>

                    </div>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    margin="dense"
                    size='small'
                    value={name}
                    onChange={(e) => {

                        if(e?.target?.value) setName(e?.target?.value)
                    }}
                    InputProps={{
                        ...params?.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params?.InputProps?.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}


export default React.memo(SearchDisciplineTextField);
