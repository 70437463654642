import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {Card, CardHeader, Paper} from '@mui/material';
import useChart from "../../hooks/useChart";
import {fNumber} from "../../helper/utils/numbers";
import CircularProgress from "@mui/material/CircularProgress";
import Lottie from "lottie-react";
import pieChart from "../../assets/pie-chart.json";
import React from "react";
// components

// ----------------------------------------------------------------------

const CHART_HEIGHT = 200;
const LEGEND_HEIGHT = 72;

// ----------------------------------------------------------------------
AppCurrentVisits.propTypes = {
    noContent: PropTypes.number,
    loading: PropTypes.bool,
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartColors: PropTypes.arrayOf(PropTypes.string),
    chartData: PropTypes.array,
};



export default function AppCurrentVisits({noContent, loading, title, subheader, chartColors, chartData, ...other }: any) {
    const theme = useTheme();

    const chartLabels = chartData.map((i: { label: any; }) => i.label);

    const chartSeries = chartData.map((i: {value: any}) => i.value);

    const chartOptions = useChart({
        noData: {
            text: "No data",
            align: "center",
            verticalAlign: "middle",
        },
        colors: chartColors,
        labels: chartLabels,
        stroke: { colors: [theme.palette.background.paper] },
        legend: { position: "bottom", floating: false, horizontalAlign: 'center' },
        dataLabels: { enabled: true, dropShadow: { enabled: false } },

        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName: any) => fNumber(seriesName),
                title: {
                    formatter: (seriesName: any) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: { donut: { labels: { show: false } } },
        },
    });
    return (
        !loading ? ( noContent > 0 ?
            <ReactApexChart  type="pie" series={chartSeries} options={chartOptions} height={350} /> :
            <Paper style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center", height: "100%", margin: 12}}>
                <strong>No Data</strong>
            </Paper> ) :
            <CircularProgress />
    );
}
