import {useMemo} from "react";
import _ from "lodash";

export function useStudent(
  student: any,
  value: number,
  semesterValue: number
) {
  return useMemo(() => {
    let _student = student?.data;
    const groupByObject: any = {};


    _student?.forEach((s:any) =>{
      s?.enrollments?.forEach((item: any) => {
        return item?.records?.forEach((r: any) => {
          return r?.grades?.forEach((g: any) => {
            var res = g.curriculum?.name ?? "";
            if (res.trim()) {
              groupByObject[res] = (groupByObject[res] || []).concat({
                ...g,
                school: s?.school,
                yearLevel: item?.yearLevel,
              });
            }
          });
        });
      });
    })




    const propertyNames = Object.keys(groupByObject);
    let groupByProgram = groupByObject[propertyNames[value]] ?? [];

    let result = _.groupBy(groupByProgram, function (item) {
      return item.curriculum?.program?.name;
    });
    const coursePropertyNames = Object.keys(result ?? {}) ?? {};

    let curriculumId = _.groupBy(groupByProgram, function (item) {
      return item.curriculumId;
    });

    const groupByYearLevel = _.groupBy(_student?.enrollments, "yearLevel");

    const curriculumPropertyNames = Object.keys(curriculumId ?? {}) ?? {};

    const groupByProgramCourse = result?.[coursePropertyNames?.[semesterValue]];
    const groupByCourseCurriculum =
      curriculumId?.[curriculumPropertyNames?.[semesterValue]];
    return {
      propertyNames,
      groupByProgram,
      groupByYearLevel,
      coursePropertyNames,
        groupByProgramCourse,
      groupByCourseCurriculum,
    };
  }, [student?.data ? student?.data : student, semesterValue, value]);
}
