import React, {useCallback, useState} from "react";
import { useCreateWorkspaceModal } from "../../store/use-create-workspace-modal";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useDispatch} from "react-redux";
import { createWorkspace } from "../../requests/workspace.request";
import { useMutation } from "react-query";
import {useHistory} from "react-router-dom";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': { padding: theme.spacing(2) },
    '& .MuiDialogActions-root': { padding: theme.spacing(1) },
}));
import {useQueryClient} from "react-query";
export default function CreateWorkspaceModal() {
    const [open, setOpen] = useCreateWorkspaceModal();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const history = useHistory();
    const pushTo = useCallback(
        (path: string) => {
            history.push(path);
        },
        [history]
    );
    const [workspaceName, setWorkspaceName] = useState<string>("");
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);
    const { mutate, isLoading, isError } = useMutation({
        mutationFn: createWorkspace,
        onSuccess: (res:any) => {

            pushTo(`/workspace/${res?.id}`)
            setSnackbarStore({
                message: `${res?.name} is successfully created`  ?? "Something went wrong",
                severity: "success",
                open: true,
            });
            queryClient.setQueriesData('workspaces', (oldData: any) => {
                const _oldData = {
                    ...oldData, data: oldData.data
                }
                _oldData.data.push(res)
                return _oldData
            })
            queryClient.invalidateQueries(["workspaces"]);

            handleClose();
        },
        onError: (error: any) => {
            if (error) {
                setSnackbarStore({
                    message: error?.response?.data?.message ?? "Something went wrong",
                    severity: "error",
                    open: true,
                });
            }
        },
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setWorkspaceName("")
    };

    const handleSave = () => {
        mutate(workspaceName);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Add a workspace
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <form className={"space-y-4"}>
                    <TextField
                        id="name"
                        label={"Workspace name"}
                        placeholder="Workspace name e.g 'Work', 'Personal', 'Home' "
                        variant="outlined"
                        fullWidth
                        disabled={isLoading}
                        value={workspaceName}
                        onChange={(e) => setWorkspaceName(e.target.value)}
                    />
                    {isError && <p style={{ color: 'red' }}>Failed to create workspace. Try again.</p>}
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={(isLoading  && isError)|| !workspaceName}
                >
                    {isLoading ? "Saving..." : "Save changes"}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
