import * as React from "react"

const ActiveSchool = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width={30}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0 10.686v12.522c0 .407.336.736.75.736H4.5V9.213h-3c-.828 0-1.5.66-1.5 1.473Zm16.875-2.21H15.75V6.635a.372.372 0 0 0-.375-.369h-.75a.372.372 0 0 0-.375.369V9.58c0 .203.168.368.375.368h2.25a.372.372 0 0 0 .375-.368v-.737a.372.372 0 0 0-.375-.368Zm6.457-2.944L15.832.62a1.52 1.52 0 0 0-1.664 0l-7.5 4.91a1.483 1.483 0 0 0-.49.531A1.452 1.452 0 0 0 6 6.757v17.187h6v-6.629c0-.407.336-.737.75-.737h4.5c.414 0 .75.33.75.737v6.63h6V6.757c0-.493-.25-.953-.668-1.226ZM15 12.159c-2.07 0-3.75-1.65-3.75-3.683 0-2.034 1.68-3.683 3.75-3.683 2.07 0 3.75 1.65 3.75 3.683 0 2.034-1.68 3.683-3.75 3.683Zm13.5-2.946h-3v14.731h3.75c.414 0 .75-.33.75-.736V10.686c0-.814-.672-1.473-1.5-1.473Z"
            fill="#fff"
        />
    </svg>
)

export default ActiveSchool
