import * as React from "react";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {LoadingButton} from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import {Box, Checkbox, CircularProgress, FormControlLabel, FormGroup} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {useFormik} from "formik";
import {setErrorSnackbar} from "../../helper/utils/helper";
import * as yup from "yup";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import useDialog from "../../hooks/useDialog";
import AddConfirmationDialog from "./AddConfirmation.dialog";
import {Transition} from "../../wrapper/Transition";
import {useRequirementQuery} from "../../hooks/useRequirementQuery";
import {deleteSchoolProgramSoRequirements__, schoolProgramSoRequirements} from "../../requests/requirements.request";
import {useQueryClient} from "react-query";
import {useRequirementSoQuery} from "../../hooks/useRequirementSoQuery";
import {setConfigurationProgram} from "../../redux/features/program";


interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    title: string
}


const initialValues = {
    formType: "dob"
}
const validationSchema = yup.object({
    formType: yup.string(), // userName: yup.string().required('UserName is required'),

})

const ConfigurationRequirement = React.memo((props: IProps) => {
    const {open, handleClose, onSave, title} = props;

    const configurationProgram = useAppSelector((store) => store?.program?.configurationProgram);

    const [loading, setLoading] = useState(false);
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const formik = useFormik({
        initialValues, validationSchema,
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {

                _handleClose()
                setLoading(false)
            } catch (error: any) {

                setErrorSnackbar(error, setSnackbarStore);

            } finally {
                setLoading(false)
            }
        }
    })

    const [_schoolProgramSoRequirements, setSchoolProgramSoRequirements] = useState<{
        sorId: number,
        schoolId: number,
        programId: number,
        checked: boolean
    }[]>([])


    const {
        data: requirements,
        isLoading
    } = useRequirementQuery(configurationProgram?.id, configurationProgram?.schoolId);
    const {
        data: requirementSO,
        isLoading: isLoadingSo
    } = useRequirementSoQuery(configurationProgram?.id, configurationProgram?.schoolId);
    const [cloneRequirements, setCloneRequirements] = useState([])
    const [cloneSoRequirements, setSoCloneRequirements] = useState([])


    useEffect(() => {

    }, [])

    useEffect(() => {
        queryClient.setQueriesData('requirementQuery', (oldData: any) => {

            return {
                ...oldData,
                data: oldData?.data?.map((sor: any) => {
                    if (sor.isRequired) {
                        sor.disabled = true
                    }
                    return sor
                })
            }
        })
        setCloneRequirements(requirements?.data)
        setSoCloneRequirements(requirementSO?.data)
        if (requirements?.data?.length) {
            requirementSO?.data?.forEach((c: any) => {
                queryClient.setQueriesData('requirementQuery', (oldData: any) => {

                    return {
                        ...oldData,
                        data: oldData?.data?.map((sor: any) => {
                            if (c.specialOrderRequirementId == sor.id) {
                                sor.isRequired = true
                            }
                            return sor
                        })
                    }
                })
            })
        }
    }, [requirementSO?.data, requirements?.data])
    const dispatch = useDispatch();
    const files = useSelector((store: RootState) => store.specialOrder?.requirements?.files)

    const handleFormType = (event: any) => {
        formik.setFieldValue("formType", event?.target?.value)
    }

    const {
        handleOpen: handleOpenConfirmation,
        handleClose: handleCloseConfirmation,
        open: openConfirmation,
    } = useDialog();
    const queryClient = useQueryClient();


    const handleChecked = async (event: any, checked: boolean) => {
        var __schoolProgramSoRequirements = _schoolProgramSoRequirements
        var findIndex = _schoolProgramSoRequirements.findIndex(c => c.sorId == event.target?.value)
        if (findIndex == -1) {
            __schoolProgramSoRequirements.push({
                sorId: event.target?.value,
                schoolId: configurationProgram.schoolId,
                programId: configurationProgram.id,
                checked: checked
            })
        } else {
            __schoolProgramSoRequirements[findIndex] = {
                sorId: event.target?.value,
                schoolId: configurationProgram.schoolId,
                programId: configurationProgram.id,
                checked: checked
            }
        }
        /* await schoolProgramSoRequirements({
            sorId: event.target?.value,
            schoolId: configurationProgram.schoolId,
            programId: configurationProgram.id
        })*/
        queryClient.setQueriesData('requirementQuery', (oldData: any) => {
            return {
                ...oldData,
                data: oldData?.data?.map((sor: any) => {
                    console.log(sor.id == event.target?.value, sor.id, event.target?.value, checked)
                    if (sor.id == event.target?.value) {
                        sor.isRequired = checked
                    }
                    return sor
                })
            }
        })
    };

    useEffect(() => {
        _schoolProgramSoRequirements.forEach((_event) => {
            queryClient.setQueriesData('requirementQuery', (oldData: any) => {
                return {
                    ...oldData,
                    data: oldData?.data?.map((sor: any) => {

                        if (sor.id == _event.sorId) {
                            sor.isRequired = _event.checked
                        }
                        return sor
                    })
                }
            })
        })

    }, [requirements?.data])


    useEffect(() => {
        return () => {
            dispatch(setConfigurationProgram({
                id: 0, name: "", schoolId: 0
            }))
        }
    }, [])


    const onConfirmation = () => {
        setLoading(true)
        _schoolProgramSoRequirements.forEach(async (_event) => {
            try {
                var _message = ""
                var _status = false
                if (_event.checked) {
                    var _res = await schoolProgramSoRequirements({
                        sorId: _event.sorId,
                        schoolId: _event.schoolId,
                        programId: _event.programId
                    })
                    _message = _res?.message
                    _status = _res?.succeeded
                } else {
                    var findIndex = requirementSO.data?.findIndex((c: any) => c.specialOrderRequirementId == _event.sorId)
                    var _res = await deleteSchoolProgramSoRequirements__(requirementSO.data[findIndex]?.id)
                    _message = _res?.message
                    _status = _res?.succeeded
                }


                setSnackbarStore({
                    message: _message, severity: _status == true || _res.message == "Request successful! Your changes have been saved.." ? 'success' : "error", open: true
                })
                queryClient.setQueriesData('requirementQuery', (oldData: any) => {
                    return {
                        ...oldData,
                        data: oldData?.data?.map((sor: any) => {

                            if (sor.id == _event.sorId) {
                                sor.isRequired = _event.checked
                            }
                            return sor
                        })
                    }
                })
                dispatch(setConfigurationProgram({
                    id: 0, name: "", schoolId: 0
                }))
                onSave()
                setLoading(false)
                handleCloseConfirmation()
                await _handleClose()

            } catch (e: any) {
                setLoading(false)
                _message = e?.response?.data?.message
                _status = e?.response?.data?.succeeded
                if (_message) {
                    setSnackbarStore({
                        message: _message, severity: _status == true ? 'success' : "error", open: true
                    })
                }


            }

        })

    }


    const _handleClose = async (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick") {
            handleOpenConfirmation()
        }
        queryClient.setQueriesData('requirementQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('requirementSoQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        dispatch(setConfigurationProgram({
            id: 0, name: "", schoolId: 0
        }))

        setSchoolProgramSoRequirements([])
        handleClose()

    }
    return (
        <>

            <Dialog disableEscapeKeyDown
                    open={open}
                    TransitionComponent={Transition}
                // keepMounted
                    onClose={_handleClose}
                    maxWidth="md"
                    aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{fontSize: 16}}>{title} {configurationProgram ? configurationProgram.name : ""}</DialogTitle>
                <DialogContent>
                    {/*<Button onClick={handleOpenAddRequirement}>Add Requirement</Button>*/}
                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 3,
                            }}
                        >

                            <FormGroup>
                                {isLoadingSo || isLoading ? <div
                                        style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <CircularProgress></CircularProgress>
                                    </div> :
                                    requirements?.data?.map((event: any) => {
                                        return <FormControlLabel
                                            name={"applicationIds"}
                                            checked={event.isRequired}
                                            value={event.id}
                                            disabled={event.disabled}
                                            onChange={handleChecked}
                                            control={<Checkbox/>}
                                            label={event.name}
                                        />
                                    })
                                }

                            </FormGroup>
                        </Box>
                    </div>


                </DialogContent>
                <DialogActions>
                    <Button color={"error"} id={"cancelCourse"} onClick={_handleClose}>Cancel</Button>
                    <LoadingButton loading={loading} id={"AddConformation"} onClick={() => {
                        handleOpenConfirmation()
                    }}
                    >
                        Ok
                    </LoadingButton>
                </DialogActions>
                {/*   <AddConfigurationRequirementDialog
                    open={openAddRequirement}
                    handleClose={handleCloseAddRequirement}
                    onSave={(payload: any) => {
                        var _requirements = [...requirements]
                        _requirements.push(payload)
                        setRequirements(_requirements)
                    }
                    }
                ></AddConfigurationRequirementDialog>*/}
            </Dialog>
            <AddConfirmationDialog
                isLoading={loading}
                title={"Do you want to save the changes you made?"}
                open={openConfirmation}
                handleClose={handleCloseConfirmation}
                onSave={onConfirmation}></AddConfirmationDialog>


        </>

    );
});

export default ConfigurationRequirement;
