
import { useState } from "react";
import { TrashIcon } from "lucide-react";
import {Button} from "@mui/material";
import ChannelModal from "./create-workspace-channel-modal";


interface HeaderProps {
  title: string;
    initialValue: any
};

export const Header = ({ title, initialValue }: HeaderProps) => {


  const [value, setValue] = useState(title);
  const [editOpen, setEditOpen] = useState(false);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s+/g, "-").toLowerCase();
    setValue(value);
  };



  const [open, setOpen] = useState(false)

  return (
     <div className="bg-white border-b h-[49px] flex items-center px-4 overflow-hidden">
          <Button variant="text" onClick={event => setOpen(true) }>
              # {title}
          </Button>
         <ChannelModal initialValue={initialValue} open={open} setOpen={setOpen}  ></ChannelModal>
        </div>
  );
};
