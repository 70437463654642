import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { FC } from 'react';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    // width: 22,
    // height: 22,
    // border: `2px solid ${theme.palette.background.paper}`,
}));

interface IAvatarBadgeProps extends AvatarProps {
    src: string;
    alt: string;
}

interface IAvatarCustomBadgeProps extends IAvatarBadgeProps {
    badgeSrc: string;
    badgeAlt: string;
}
export const AvatarCustomBadge: FC<IAvatarCustomBadgeProps> = (props) => {

    const { src, alt, badgeAlt, badgeSrc, sx, variant } = props;

    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
                <SmallAvatar alt={badgeAlt} src={badgeSrc} />
            }
        >
            <Avatar alt={alt} src={src} sx={sx} variant={variant} />
        </Badge>
    );
}

interface IAvatarCustomBadgeProps {
    src: string;
    alt: string;
    badgeSrc: string;
    badgeAlt: string;
}
export const AvatarBadge: FC<IAvatarBadgeProps> = (props) => {

    const { src, alt, sx, variant } = props;

    return (
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
        >
            <Avatar alt={alt} src={src} sx={sx} variant={variant} />
        </StyledBadge>
    )
}