import * as Yup from "yup";
import { curriculumSchema, ICurriculumSchema } from "./curriculum.schema";
import { ISchoolSchema, schoolSchema } from "./school.schema";

export interface ICurriculumSchoolSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  curriculumId?: number | null;
  curriculum: ICurriculumSchema;
  schoolId?: number | null;
  school: ISchoolSchema;
}

export const curriculumSchoolSchema: Yup.SchemaOf<ICurriculumSchoolSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    curriculumId: Yup.number().nullable(),
    curriculum: Yup.lazy(() => curriculumSchema),
    schoolId: Yup.number().nullable(),
    school: Yup.lazy(() => schoolSchema),
  });

export type CurriculumSchoolType = Yup.InferType<typeof curriculumSchoolSchema>;
