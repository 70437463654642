import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { createContext, useMemo, useState, useContext, FC } from 'react';
import { getAppTheme } from '../styles/theme';
import { DARK_MODE_THEME, LIGHT_MODE_THEME } from '../helper/utils/constants';

export interface IThemeModeContext {
	toggleThemeMode: () => void;
	mode: typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME;
}

interface IThemeModeContextProvider {
	children: React.ReactNode;
}

export const ThemeModeContext = createContext<IThemeModeContext>({} as IThemeModeContext);
export const useThemeModeContext = () => useContext(ThemeModeContext);

const ThemeModeContextProvider: FC<IThemeModeContextProvider> = ({ children }: any) => {
	const [mode, setMode] = useState<typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME>(LIGHT_MODE_THEME);

	const themeMode = useMemo(
		() => ({
			mode,
			toggleThemeMode: () => {
				setMode((prevMode) => (prevMode === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME));
			},
		}),
		[mode]
	);
	const theme = useMemo(() => getAppTheme(mode), [mode]);

	return (
		<ThemeModeContext.Provider value={themeMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ThemeModeContext.Provider>
	)
}

export default ThemeModeContextProvider;