import {persistReducer, persistStore} from "redux-persist";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/auth";
import snackbarReducer from "./features/snackbar";
import appReducer from "./features/app";
import promotionalReportReducer from "./features/promotionalReports";
import schoolReducer from "./features/school";
import disciplineReducer from "./features/discipline";
import programReducer from "./features/program";
import studentReducer from "./features/student";
import requirementReducer from "./features/requirements";
import specialOrder from "./features/specialOrder";
import curriculum from "./features/curriculum";
import course from "./features/course";
import major from "./features/major";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const combinedReducers = combineReducers({
    auth: authReducer,
    curriculum: curriculum,
    course: course,
    major: major,
    specialOrder: specialOrder,
    snackbar: snackbarReducer,
    app: appReducer,
    promotionalReport: promotionalReportReducer,
    school: schoolReducer,
    discipline: disciplineReducer,
    program: programReducer,
    student: studentReducer,
    requirement: requirementReducer,
});
const persistedReducer = persistReducer(persistConfig, combinedReducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

// persist store
export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
