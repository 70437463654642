import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Tooltip, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {useDropzone} from "react-dropzone";
import {setFiles} from "../../redux/features/specialOrder";
import {useFileExtension} from "../../hooks/useFileExtension";
import {X} from "lucide-react";
import {deleteStudentSoRequirements} from "../../requests/requirements.request";
import {setLoading} from "../../redux/features/app";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import FilesPreviewDialog from "./FilesPreview.dialog";
import useDialog from "../../hooks/useDialog";
import AddCloseConfirmationDialog from "./AddCloseConfirmation.dialog";

interface IProps {
    requirement: IRequirement,
    fileDialogKey: string,
    filesIndex: number,
    studentId: number | string
}

interface IRequirement {
    dateCreated: string
    id: number
    isDeleted: boolean
    isRequired: boolean
    lastModifiedDate: string | number | undefined
    name: string,
    filesIndex: number
}

const Files = React.memo((props: IProps) => {

    const queryClient = useQueryClient();
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])

    const [title, setTitle] = useState("")
    const [preview, setPreview] = useState("")
    const [fileIndex, setFileIndex] = useState(0)
    const [parentFileIndex, setParentFileIndex] = useState(0)
    const dispatch = useDispatch();
    const files = useSelector((store: RootState) => {
        var __files = [...store.specialOrder?.requirements?.files]

        var ___files: any[] = []
        __files?.forEach((__file: any) => {
            var ____file = {...__file};
            if (____file?.files?.length > 0) {
                ____file.files = [...____file.files.filter((c: any) => c.studentId == props.studentId)]
            }
            ___files.push(____file)
        })
        return ___files
    })

    const filesMemo = useMemo(() => {
        return files?.filter((fnd: any) => {
            return fnd?.id == props?.requirement?.id
        })
    }, [props.studentId, files?.filter((fnd: any) => {
        return fnd?.id == props?.requirement?.id
    })])

    const specialOrderProgramId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderProgramId
    );
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const specialOrderSchoolId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderSchoolId
    );

    const __requirement = useMemo(() => {
        return props.requirement
    }, [props.requirement])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        multiple: true,
        maxFiles: 5,
        accept: {
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".png",
                ".jpg",
                ".pdf"
            ],
        },
        onDropRejected: () => {
            setSnackbarStore({
                message: 'You can only upload a images files and pdf', severity: 'error', open: true
            })
        },
        onDropAccepted: acceptedFiles => {

            var ___files = [...files]

            var filesIndex = ___files.findIndex((c: IRequirement) => {
                return c.id == props.requirement?.id
            })

            if (filesIndex > -1) {
                ___files[filesIndex] = {
                    dateCreated: __requirement.dateCreated,
                    id: __requirement.id,
                    isDeleted: __requirement.isDeleted,
                    isRequired: __requirement.isRequired,
                    lastModifiedDate: __requirement.lastModifiedDate,
                    name: __requirement.name,
                    files: [...(___files?.[filesIndex]?.files && ___files[filesIndex]?.files) ?? [], ...acceptedFiles?.map(file => Object.assign(file, {
                        studentId: props.studentId,
                        preview: URL.createObjectURL(file)
                    })) ?? []]
                }
            } else {
                ___files.push({
                    dateCreated: __requirement.dateCreated,
                    id: __requirement.id,
                    isDeleted: __requirement.isDeleted,
                    isRequired: __requirement.isRequired,
                    lastModifiedDate: __requirement.lastModifiedDate,
                    name: __requirement.name,
                    files: acceptedFiles?.map(file => Object.assign(file, {
                        studentId: props.studentId,
                        preview: URL.createObjectURL(file)
                    }))
                })
            }

            if (___files?.[filesIndex]?.files?.length > 5) {
                setSnackbarStore({
                    message: 'You can only upload a maximum of 5 files', severity: 'error', open: true
                })
                ___files[filesIndex].files = ___files[filesIndex]?.files?.slice(0, 5)
            }

            dispatch(setFiles(___files));

        }

    });


    const onDelete = async (index: number) => {
        var __index = index
        try {

            var ____files = [...files]


            for (const __fnd of ____files) {
                var _fnd = Object.assign({}, __fnd)
                if (_fnd.id == props.requirement.id) {
                    var fndFiles = _fnd?.files?.[index]
                    if (fndFiles.id) {
                        var res = await deleteStudentSoRequirements(fndFiles.programId, fndFiles.schoolId, fndFiles.studentNumber, fndFiles.majorId, fndFiles.id)
                        queryClient.setQueriesData('studentRequirementSoQuery', (oldData: any) => {
                            return {
                                ...oldData, data: oldData.data.filter((c: any) => c.id != res?.data?.id)
                            }
                        })

                    }
                }
            }

            queryClient.setQueriesData('specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId, (oldData: any) => {

                return {
                    ...oldData,
                    data: oldData?.data?.map((sogbs: any) => {
                        sogbs.students = sogbs.students?.map((sogbsStudents: any) => {
                            if (sogbsStudents.id == props.studentId) {

                                sogbsStudents.status = sogbsStudents.status?.map((sogbsStudentsStatus: any, sogbsStudentsStatusIndex: number) => {
                                    var filesIndex = ____files.findIndex((file: any) => file.id == sogbsStudentsStatus?.id)

                                    var isStatusRequirement = props.requirement?.id == sogbsStudentsStatus?.id

                                    if (isStatusRequirement && filesIndex > -1 && ____files?.[filesIndex]?.files?.length == 0) {
                                        sogbsStudentsStatus.status = false
                                        sogbsStudentsStatus.isDeleted = true
                                        sogbsStudents.requirementCompleted = sogbsStudents.status.length == sogbsStudents.status.filter((c: any) => c.status == true)?.length
                                    }
                                    return sogbsStudentsStatus
                                })
                            }
                            return sogbsStudents
                        })
                        return sogbs
                    })
                }
            })

            ____files = ____files?.map((__fnd) => {
                var _fnd = Object.assign({}, __fnd)
                if (_fnd.id == props.requirement.id) {
                    _fnd.files = [..._fnd.files.filter((c: any, _____index: number) => _____index != index)]
                }
                return _fnd
            })

            dispatch(setFiles(____files))

        } catch (e) {


            console.log(e)
        }

    }
    const {
        handleOpen: handleOpenPreview,
        handleClose: handleClosePreview,
        open: openPreview,
    } = useDialog();
    const {
        handleOpen: handleCloseOpenPreview,
        handleClose: handleCloseClosePreview,
        open: closeOpenPreview,
    } = useDialog();

    function handlePreview(_name: string, _preview: string) {
        setTitle(_name)
        setPreview(_preview)
        handleOpenPreview()
    }

    const addCloseConfirmationHandleClose = () => {
        setTitle("")
        setPreview("")
        handleCloseClosePreview()
    }


    const addCloseConfirmationOnSave = async () => {
        dispatch(setLoading(true))
        onDelete(fileIndex).then(() => {
            dispatch(setLoading(false))
            handleCloseClosePreview()
        }).catch(e => {

            dispatch(setLoading(false))
            handleCloseClosePreview()
        })
    }

    const filesPreviewHandleClose = () => {
        setTitle("")
        setPreview("")
        handleClosePreview()
    }


    return (
        <>
            <div>
                <Typography color={"primary.main"} fontWeight={"bold"}
                            variant={"subtitle1"}>{props.requirement?.name ?? ""}</Typography>
                <div
                    {...getRootProps({
                        className: "w-full mt-3 xl:mt-0 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md p-6",
                    })}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Drop it here ...</Typography>
                    ) : (
                        <Typography component="div">
                            Drag 'n' drop <strong>files</strong> file here, or click to
                            select file
                        </Typography>
                    )}
                </div>
                <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5">
                    {
                        filesMemo?.map((f: any, parentIndex: any) => {
                            return !f?.files?.isDeleted && f?.files?.map((file: any, index: number) => {

                                var SvgImager = useFileExtension(f.path);
                                return <div
                                    className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">

                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
                                        <div onClick={() => handlePreview(file.name ?? file.fileName, file.preview)}
                                             className="w-3/5 file__icon file__icon--image mx-auto">

                                            <div
                                                className="flex justify-center content-center  file__icon--image__preview image-fit">
                                                {SvgImager != null ? SvgImager : <img src={file.preview}

                                                    // Revoke data uri after image is loaded
                                                                                      onLoad={() => {
                                                                                      }}/>}

                                            </div>
                                        </div>
                                        <a onClick={() => handlePreview(file.name ?? file.fileName, file.preview)}
                                           className="block font-medium mt-4 text-center truncate">{file.name ?? file.fileName}</a>
                                        <div
                                            className="text-slate-500 text-xs text-center mt-0.5">{((file.size ?? file.fileSize) / 1024).toFixed(2)} kb
                                        </div>
                                        <div className="absolute top-0 right-0 mr-2 mt-3 dropdown ml-auto">
                                            <Tooltip title={"delete"} onClick={() => {
                                                setFileIndex(index)
                                                setParentFileIndex(parentFileIndex)
                                                handleCloseOpenPreview()
                                            }}
                                                     className="dropdown-item">
                                                <div className="w-5 h-5 text-danger">
                                                    <X/>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            })
                        })
                    }
                    <FilesPreviewDialog open={openPreview}
                                        handleClose={filesPreviewHandleClose}
                                        preview={preview}
                                        title={title}/>
                    <AddCloseConfirmationDialog open={closeOpenPreview}
                                                handleClose={addCloseConfirmationHandleClose}
                                                title={"Do you want to delete this file?"}
                                                onSave={addCloseConfirmationOnSave}/>
                </div>
            </div>


        </>

    );
});

export default Files;
