import * as React from "react";
import {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {LoadingButton} from "@mui/lab";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    title: string,
    isLoading?: boolean;
}

const AddConfirmation = React.memo((props: IProps) => {
    const {open, handleClose, onSave, title, isLoading} = props;


    const [names, setName] = useState<{ id: number, courseCode: string }[]>([])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={handleClose}
            maxWidth="sm"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                style={{fontSize: 16}}>{title ?? "Please be advised that the National Office will be informed of these changes."}</DialogTitle>
            {/*<DialogContent>

                <p style={{margin: 0, fontStyle: "italic"}}>Do you want to save the changes you made?</p>*!/

            </DialogContent>*/}
            <DialogActions>
                <Button color={"error"} id={"cancelCourse"} onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={isLoading} id={"AddConformation"} onClick={() => {
                    onSave()
                }}
                >
                    Ok
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
});

export default AddConfirmation;
