import * as React from "react"

const UnvisibleSVG = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L13.2934 9.29237C12.9018 9.10495 12.4631 9 12 9C11.0941 9 10.282 9.40154 9.73188 10.0364C9.81721 10.0127 9.90713 10 10 10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12C9.57566 12 9.21306 11.7357 9.06782 11.3627C9.0234 11.5681 9 11.7813 9 12C9 12.4631 9.10495 12.9018 9.29237 13.2934L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L8.39334 17.0209C9.41615 17.6007 10.6208 18 12 18C15.8632 18 18.3567 14.8671 19.3211 13.4107L19.3639 13.3465C19.5748 13.031 19.8565 12.6098 19.8565 12C19.8565 11.3902 19.5748 10.969 19.3639 10.6535L19.3211 10.5893C18.9071 9.96417 18.2114 9.03014 17.2466 8.16758L19.7071 5.70711ZM15.4462 9.96803L14.7076 10.7066C14.895 11.0982 15 11.5369 15 12C15 13.6569 13.6569 15 12 15C11.5369 15 11.0982 14.895 10.7066 14.7076L9.96803 15.4462C10.5635 15.7981 11.2582 16 12 16C11.6289 16 11.2576 15.9548 10.8904 15.8741C11.2444 15.9551 11.6145 16 12 16C12.3857 16 12.7561 15.9551 13.1103 15.874C12.7429 15.9548 12.3714 16 12 16C14.2091 16 16 14.2091 16 12C16 11.2582 15.7981 10.5635 15.4462 9.96803Z"
            fill="black"
        />
        <path
            d="M12 6C12.8009 6 13.5429 6.13465 14.2247 6.36111L12.5546 8.03119C12.3735 8.01074 12.1886 8 12 8C9.79087 8 8 9.79086 8 12C8 12.1861 8.0127 12.3692 8.03729 12.5485L5.75955 14.8262C5.29473 14.2965 4.93355 13.7952 4.67895 13.4107L4.63614 13.3465C4.42519 13.031 4.14355 12.6098 4.14355 12C4.14355 11.3902 4.42519 10.969 4.63614 10.6535L4.67895 10.5893C5.64331 9.13292 8.13685 6 12 6Z"
            fill="black"
        />
        <path
            d="M12 8C12.1846 8 12.3693 8.01117 12.5534 8.03234L12.5485 8.03729C12.3692 8.01271 12.1861 8 12 8Z"
            fill="black"
        />
    </svg>
)

export default UnvisibleSVG
