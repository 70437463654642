import fetchTransactionCodes from "../requests/fetchTransactionCodes";
import {useQuery} from "react-query";

export const useTransactionCodes = (selectedService: string) => {
    return useQuery({
        queryKey: ['fetchTransactionCodes', selectedService],
        queryFn: () => fetchTransactionCodes(selectedService),
        enabled: true,
    });
};
