import * as React from "react"

const GraduateIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width={28}
        height={30}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.59 18.912 14 24.402l-5.59-5.49c-4.19.178-7.535 3.545-7.535 7.7v.552c0 1.525 1.26 2.762 2.813 2.762h20.625c1.552 0 2.812-1.237 2.812-2.762v-.552c0-4.155-3.346-7.521-7.535-7.7ZM1.672 5.055l.375.087v3.36c-.41.242-.703.662-.703 1.168 0 .484.27.887.65 1.134l-.914 3.585c-.1.397.123.806.445.806h2.45c.322 0 .545-.409.445-.806l-.914-3.585c.38-.247.65-.65.65-1.134 0-.506-.293-.926-.703-1.168V5.475l3.867.915c-.504.99-.82 2.095-.82 3.28 0 4.069 3.357 7.366 7.5 7.366s7.5-3.297 7.5-7.366c0-1.185-.31-2.29-.82-3.28l5.642-1.335c1.067-.253 1.067-1.56 0-1.812L15.166.595a5.122 5.122 0 0 0-2.326 0L1.672 3.237c-1.06.253-1.06 1.565 0 1.818Z"
            fill="#fff"
        />
    </svg>
)

export default GraduateIcon
