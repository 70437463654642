import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        'Content-Type': 'application/json',
        'Provider_Token': `${process.env.REACT_APP_SECRET_KEY}`
    },
});

export default axiosInstance;
