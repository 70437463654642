import * as React from "react";
import {SVGProps} from "react";

const PesosSign = (props: SVGProps<SVGSVGElement>) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="25px"
        height="25px"
        viewBox="0 0 2.56 2.56"
        enableBackground="new 0 0 64 64"
        xmlSpace="preserve"
        {...props}
    >
        <g>
            <path
                fill="none"
                stroke="#000000"
                strokeWidth={0.08}
                strokeMiterlimit={10}
                points="52,62.999 52,0.999 26,0.999 12,14.999  12,63 16,61 20,63 24,61 28,63 32,61 36,63 40,61 44,63 48,61  "
                d="M2.08 2.52V0.04H1.04L0.48 0.6v1.92l0.16 -0.08 0.16 0.08 0.16 -0.08 0.16 0.08 0.16 -0.08 0.16 0.08 0.16 -0.08 0.16 0.08 0.16 -0.08Z"
            />
            <path
                fill="none"
                stroke="#000000"
                strokeWidth={0.08}
                strokeMiterlimit={10}
                points="12,14.999 26,14.999 26,0.999  "
                d="M0.48 0.6h0.56V0.04"
            />
        </g>
        <path
            fill="none"
            stroke="#000000"
            strokeWidth={0.08}
            strokeMiterlimit={10}
            x1={24}
            y1={48}
            x2={24}
            y2={20}
            d="M0.96 1.92V0.8"
        />
        <path
            fill="none"
            stroke="#000000"
            strokeWidth={0.08}
            strokeMiterlimit={10}
            d="M0.96 0.84H1.2S1.602 0.812 1.602 1.16 1.2 1.48 1.2 1.48H0.96"
        />
        <path
            fill="none"
            stroke="#000000"
            strokeWidth={0.08}
            strokeMiterlimit={10}
            x1={20}
            y1={27}
            x2={44}
            y2={27}
            d="M0.8 1.08h0.96"
        />
        <path
            fill="none"
            stroke="#000000"
            strokeWidth={0.08}
            strokeMiterlimit={10}
            x1={20}
            y1={31}
            x2={44}
            y2={31}
            d="M0.8 1.24h0.96"
        />
    </svg>
);
export default PesosSign;
