import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Alert, Box, Grid, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ISnackbarSlice, openSnackbar } from "../../redux/features/snackbar";
import { useQueryClient } from "react-query";
import { patchUser } from "../../requests/user.request";
import { useRegionSelectQuery } from "../../hooks/useRegionQuery";
import { useAppSelector } from "../../redux/store";
import { useAddEntities } from "../../hooks/useAddEntities";
import { useEffect } from "react";
import { setErrorSnackbar } from "../../helper/utils/helper";
import { useTransition } from "../../hooks/useTransitions";
import { register } from "../../requests/auth.request";
import { AxiosError } from "axios";
import { setPatchUser, setUserProfile } from "../../redux/features/auth";
import { LoginResponseType } from "../../schemas/auth.schema";

const validationSchema = yup.object({
  schoolId: yup.string(),
  userName: yup.string().required("UserName is required"),
  //   password: yup.string().required('Password is required'),
  // regionId: yup.number().required('Region is required'),
  email: yup.string().email().required("Email is required"),
  //confirmPassword: yup.string().required('Confirm Password is required'),
  firstName: yup.string().required("First Name is required"),
  middleName: yup.string(),
  lastName: yup.string().required("Last Name is required"),
  designation: yup.string().required("Designation is required"),
});

const initialValues = {
  schoolId: "",
  userName: "",
  password: "",
  regionId: 0,
  confirmPassword: "",
  email: "",
  firstName: "",
  middleName: "",
  lastName: "",
  designation: "",
};

const Transition = useTransition();

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const AddEditUser = React.memo((props: IProps) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { schoolId, institutionalCode, regionId } = useAddEntities();
  const { data: regions } = useRegionSelectQuery();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);
  const userId = useAppSelector((store) => store?.auth?.user?.id);
  const user = useAppSelector((store) => store?.auth?.user);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        if (!userId) return;
        const res = await patchUser(values, userId);
        dispatch(
          setUserProfile({
            designation: values.designation,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
          } as LoginResponseType["user"]["userProfile"])
        );

        setSnackbarStore({
          message: "Successfully update you profile",
          severity: "success",
          open: true,
        });

        dispatch(setPatchUser(values));

        formik.resetForm();
      } catch (error) {
        resetForm();
        let errorMsg = "Something went wrong while creating your acoount. ";
        if (error instanceof AxiosError) {
          errorMsg = error.response?.data?.error[0].description;
        }
        setSnackbarStore({
          message: errorMsg,
          severity: "error",
          open: true,
        });
      } finally {
        handleClose();
      }
    },
  });

  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  function resetForm() {
    formik.setFieldValue("userName", user?.userName);
    formik.setFieldValue("email", user?.email);
    formik.setFieldValue("firstName", user?.userProfile?.firstName);
    formik.setFieldValue("middleName", user?.userProfile?.middleName);
    formik.setFieldValue("lastName", user?.userProfile?.lastName);
    formik.setFieldValue("designation", user?.userProfile?.designation);
  }

  useEffect(() => {
    resetForm();
  }, [user]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // keepMounted
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          Please fill in the details of the user you want to add
          <br />
          Note: Fields with <strong>*</strong> are required
        </Alert>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 3,
          }}
        >
          <TextField
            disabled
            label="Username"
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            name="userName"
            id="userName"
            value={formik.values.userName}
            onChange={formik.handleChange}
            error={formik.touched.userName && Boolean(formik.errors.userName)}
            helperText={formik.touched.userName && formik.errors.userName}
          />

          <TextField
            disabled
            label="Email"
            type={"email"}
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            label="First Name"
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            name="firstName"
            id="firstName"
            value={formik.values?.firstName}
            onChange={formik.handleChange}
            error={
              formik.touched?.firstName && Boolean(formik.errors?.firstName)
            }
            helperText={formik.touched?.firstName && formik.errors?.firstName}
          />

          <TextField
            label="Middle Name"
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            name="middleName"
            id="middleName"
            value={formik.values?.middleName}
            onChange={formik.handleChange}
            error={
              formik.touched?.middleName && Boolean(formik.errors?.middleName)
            }
            helperText={formik.touched?.middleName && formik.errors?.middleName}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            name="lastName"
            id="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched?.lastName && Boolean(formik.errors?.lastName)}
            helperText={formik.touched?.lastName && formik.errors?.lastName}
          />

          <TextField
            label="Designation"
            variant="outlined"
            margin="dense"
            size="small"
            fullWidth
            required={true}
            name="designation"
            id="designation"
            value={formik.values?.designation}
            onChange={formik.handleChange}
            error={
              formik.touched?.designation && Boolean(formik.errors?.designation)
            }
            helperText={
              formik.touched?.designation && formik.errors?.designation
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id={"cancleEditProfile"} onClick={handleClose}>Cancel</Button>
        <Button id={"updateEditProfile"} variant="contained" onClick={handleSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AddEditUser;
