import {Dispatch, useEffect} from "react";
import {AnyAction} from "@reduxjs/toolkit";
import {setFiles} from "../redux/features/specialOrder";
import _ from "lodash";

export function useEffectStudentRequirementSo(files: any, studentrequirementSO: any, specialOrderStudentNumberValue: any, dispatch: Dispatch<AnyAction>) {

    useEffect(() => {
        var ___files = [...files]
        var __requirements = studentrequirementSO?.data
        if (__requirements?.length > 0) {
            __requirements?.forEach((__requirement: any) => {
                var filesIndex = ___files.findIndex((c: any) => {
                    return c.id == __requirement.specialOrderRequirementId
                })
                if (filesIndex > -1) {
                    console.log(__requirement, "__requirement")
                    ___files[filesIndex] = {
                        dateCreated: __requirement.dateCreated,
                        id: __requirement.specialOrderRequirementId,
                        isDeleted: __requirement.isDeleted,
                        isRequired: null,
                        lastModifiedDate: __requirement.lastModifiedDate,
                        name: null,
                        files: [...(___files?.[filesIndex]?.files && ___files[filesIndex]?.files) ?? [], {
                            schoolId: __requirement.schoolId,
                            studentId: __requirement.studentId,
                            studentNumber: specialOrderStudentNumberValue,
                            programId: __requirement.programId,
                            id: __requirement.id,
                            name: __requirement.fileName,
                            size: __requirement.fileSize,
                            preview: __requirement.url
                        }]
                    }
                } else {

                    var ____file: {
                        dateCreated: any,
                        id: any,
                        isDeleted: any,
                        isRequired: any,
                        lastModifiedDate: any,
                        name: any,
                        files: any[]
                    } = {
                        dateCreated: __requirement.dateCreated,
                        id: __requirement.specialOrderRequirementId,
                        isDeleted: __requirement.isDeleted,
                        isRequired: null,
                        lastModifiedDate: __requirement.lastModifiedDate,
                        name: null,
                        files: []
                    }

                    ____file?.files?.push({
                        schoolId: __requirement.schoolId,
                        studentId: __requirement.studentId,
                        studentNumber: specialOrderStudentNumberValue,
                        isDeleted: __requirement.isDeleted,
                        programId: __requirement.programId,
                        id: __requirement.id,
                        preview: __requirement.url,
                        name: __requirement.fileName,
                        size: __requirement.fileSize
                    })
                    ___files.push(____file)
                }
            })
        }

        dispatch(setFiles(___files.map((e: any) => {
            var _e = {...e}
            _e.files = _.uniqBy(_e.files, 'id') ?? []
            return _e
        })));
    }, [studentrequirementSO?.data])
}
