import {AxiosRequestConfig} from "axios";
import {GetUsersResponseType} from "../types";
import api from "./config";

export const getUsers = async (params: AxiosRequestConfig['params']) => {
    const res = await api.get<GetUsersResponseType>("/users", {params});
    const data = res?.data?.data ?? [];
    return {
        ...res?.data, data
    };
}
export const getUserId = async (params: AxiosRequestConfig['params']) => {
    if(!params?.userId) return
    const res = await api.get("/users/" +  params?.userId, {params});
    return res?.data?.data
}
export const deleteUser = async (id: number) => {
    const res = await api.post(`/users/${id}`);
    return res?.data;
}

interface IUpdateUserRequestType {
    schoolId: number;
    userName: string;
    email: string;
    password: string;
}

export const updateUser = async (id: number | string, data: IUpdateUserRequestType) => {
    const res = await api.patch(`/users/${id}`, data);
    return res?.data;
}


export const updateUserStatus = async (id: number | string, status: boolean) => {
    const res = await api.post(`/users/change-status/${id}?status=${status}`,);
    return res?.data;
}
type UserData = {
    id?: string;
    status?: boolean;
    typeId: any;
    roleName: string,
    schoolId: string,
    userName: string
    password: string,
    regionId: number,
    email: string,
    confirmPassword: string,
    firstName: string,
    middleName: string,
    lastName: string,
    designation: string
}

type PatchUser = {
    //typeId: any;

    // schoolId: string,
    userName: string
    //password: string,
    //regionId: number,
    email: string,
        //confirmPassword: string,
        firstName: string,
        middleName: string,
        lastName: string,
        designation: string
}
export const patchUser = async (data: PatchUser, id: number | string | undefined) => {

    const userData = {
        userName: data.userName,
        email: data.email,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        designation: data.designation
    }
    const res = await api.patch("/users/" + id, userData )
    return res?.data
}

export const postUser = async (data: UserData, params: any) => {

    const userData:any = {
        ...( data.schoolId && {schoolId: data.schoolId}),
        roleName: data.roleName,
        userName: data.userName,
        //password: data.password,
        email: data.email,
       // confirmPassword: data.confirmPassword,
        accountType: parseInt(data.typeId),

            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            designation: data.designation
    }

    if( data.regionId > 0){
        userData.regionId =  data.regionId;
    }


    console.log( userData.regionId, data.regionId, "regionId 11")

    const res = await api.post("/users/create-user", userData, {
        params
    } )
    return res?.data
}

export const editUser = async (data: UserData, params: any) => {

    const userData = {

        roleName: data.roleName,
        accountType: parseInt(data.typeId),
        firstName: data.firstName,
        middleName: data.middleName,
        status: data.status,
        lastName: data.lastName,
        designation: data.designation || ""
    }
    const res = await api.patch(`/users/${data.id}`, userData, {
        params
    } )
    return res?.data
}
export const getUserByName = async (search: string, signal: AxiosRequestConfig["signal"], schoolId: any) => {

    const res = await api.get(`/users`, { signal, ...{
                params: {
                    ...(schoolId && schoolId),
                    ...(search && {search: search}),
                }
            }
        }
    );
    return res?.data
}
