import type {
    EnrollmentsResponseType,
    GetPromotionalReportsResponseType,
    UploadEnrollmentResponseType
} from './../types/apiResponse.type';
import {AxiosRequestConfig} from 'axios';
import api from "./config"

export const uploadPromotionalReportExcel = async <T>(body: T, config: AxiosRequestConfig) => {
    const res = await api.post("/promotional-reports/upload/excel", body, config)
    return res?.data
}

export const getPromotionalReport = async (params: AxiosRequestConfig['params']) => {
    const res = await api.get<GetPromotionalReportsResponseType>("/promotional-reports", {params})
    return res?.data
}

type PostPromotionalReportsChangeStatus =  {
    isLocked: boolean
}

export const postPromotionalReportsChangeStatus = async (prId: number) => {
    const res = await api.patch<GetPromotionalReportsResponseType>("/promotional-reports/lock-unlock/" + prId )
    return res?.data
}


export const getPromotionalReportById = async (id: string | number, page?: number, pageSize?: number, search?: string) => {
    const res = await api.get<EnrollmentsResponseType>("/promotional-reports/" + id, {
        params: {
            page,
            pageSize,
            search
        }
    })
    return res?.data
}

export const getNotificationPromotionalReportById = async (id: string | number, header?:string, seen?:boolean) => {
    if(!id) return
    const res = await api.get<EnrollmentsResponseType>(`/notifications/promotional-reports/${id}`, {
        params:{
            header,
            seen
        }

    })
    return res?.data
}
export const getNotificationPromotionalReportByIdCount = async (id: string | number, header?:string) => {
    if(!id) return
    const res = await api.get<EnrollmentsResponseType>(`/notifications/promotional-reports/${id}/count`, {
        params:{
            header
        }

    })
    return res?.data
}
export const deletePromotionalReport = async (id: number) => {
    const res = await api.post(`/promotional-reports/${id}`)
    return res?.data
}

export const getDownloadPromotionalReport = async () => {
    const res = await api({
        url: `/download/pr-template`,
        method: 'GET',
        responseType: 'blob',
    });
    return res?.data
}
export const getDownloadEnrollment = async () => {
    const res = await api({
        url: `/enrollments/download-enrollment-list-template`,
        method: 'GET',
    });


    return res?.data?.data
}
export const getPromotionalReportCount = async (startDate: any, endDate: any, selectedSemester: any, selectedSchoolYear: any, regionId: any) => {

    if (!(startDate && endDate)) {
        return
    }
    const res = await api({
        url: `/dashboard/pr-count`,
        params: {
            ...regionId > 0 && {regionId: regionId},
            ...(
                {dateStart: startDate}),
            ...(
                selectedSemester?.id && {semester: selectedSemester?.id}),
            ...(
                selectedSchoolYear?.name && {schoolYear: selectedSchoolYear?.name}),
            ...(
                {dateEnd: endDate})
        },
        method: 'GET'});
    return res?.data
}


export const getPromotionalReportChartData = async (startDate: any , endDate: any, selectedSemester: any, selectedSchoolYear: any, regionId: any) => {

    if(!(startDate && endDate)){
        return
    }
    const res = await api({
        url: `/dashboard/pr-chart-data`,
        params: {
            ...regionId > 0 && {regionId: regionId},
            ...(
                selectedSemester?.id && {semester: selectedSemester?.id}),
            ...(
                selectedSchoolYear?.name && {schoolYear: selectedSchoolYear?.name}),
            ...(
                {dateStart: startDate}),
            ...(
                {dateEnd: endDate})
        },
        method: 'GET'});
    return res?.data
}
