import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '../../redux/features/app';
import {RootState} from '../../redux/store';
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import {setIsLockedDialog} from "../../redux/features/promotionalReports";
import {setErrorSnackbar} from "../../helper/utils/helper";

const IsLockedDialog = () => {
    const isLockedDialogState = useSelector((state: RootState) => state.promotionalReport?.isLockedDialog);

    const dispatch = useDispatch();
    const queryClient = useQueryClient()
    const handleClose = useCallback(() => {
        dispatch(setIsLockedDialog({
            open: false,
        }))
    }, [])

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const handledIsLocked = async () => {
        if (isLockedDialogState?.data?.id) {
            try {
                dispatch(setLoading(true))
               var result = await isLockedDialogState?.data.handleIsLocked(isLockedDialogState?.data?.id)

                setSnackbarStore({
                    message: (isLockedDialogState?.data?.title as any) + " is successfully " + (isLockedDialogState?.data?.isLocked ? "unlocked" : "locked"),
                    severity: 'success',
                    open: true
                })
                queryClient.setQueriesData(`${isLockedDialogState?.data?.content}`, (oldData: any) => {
                    return {
                        ...oldData,
                        data: oldData.data.map((t: any) => t.id == isLockedDialogState?.data?.id ? {
                            ...t,
                            isLocked: !isLockedDialogState?.data?.isLocked
                        } : t)
                    }
                })
                await queryClient.invalidateQueries(`${isLockedDialogState?.data?.content}`);
                dispatch(setIsLockedDialog({
                    open: false,
                    data: {
                        ...isLockedDialogState?.data,
                        isLocked: !isLockedDialogState?.data?.isLocked
                    }
                }))

            } catch (error: any) {
                console.error({ error })
                handleClose()
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                handleClose()
                dispatch(setLoading(false))
            }
        }
    }





    return (
        <Dialog open={isLockedDialogState?.open ?? false} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{isLockedDialogState?.data?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to {isLockedDialogState?.data?.isLocked ? "Unlocked" : "Locked"} this {isLockedDialogState?.data?.content}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelIsLocked"} color='inherit' onClick={handleClose}>Cancel</Button>
                <Button id={"addIsLocked"} variant='outlined' color='error'  onClick={handledIsLocked}>{isLockedDialogState?.data?.isLocked ? "Unlocked" : "Locked"} </Button>
            </DialogActions>
        </Dialog>

    )
}
export default IsLockedDialog;

