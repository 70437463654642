import React, {useCallback, useRef, useState} from "react";
import Quill from "quill";
import Editor from "./editor";
import {useMutation} from "react-query";
import {MajorInputType, MessageInputType} from "../../schemas/major.schema";
import {patchMajor} from "../../requests/major.request";
import {createMessage} from "../../requests/message.request";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useAppDispatch} from "../../redux/store";




interface ChatInputProps {
    placeholder: string;
    workspaceId: string;
    channelId: string;
};

type CreateMesageValues = {
  channelId: any;
  workspaceId: any;
  body: string;
  image: any;
};

export const ChatInput = ({ placeholder, workspaceId, channelId }: ChatInputProps) => {
    const [editorKey, setEditorKey] = useState(0);
    const [isPending, setIsPending] = useState(false);
    const editorRef = useRef<Quill | null>(null);
    const {mutate: message, isLoading} = useMutation({
        mutationKey: "createMessage", mutationFn: async (value: MessageInputType) => {
            return await createMessage(value)
        }
    });
    const dispatch = useAppDispatch();
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);
    const handleSubmit = async ({
                                    body,
                                    image
                                }: {
        body: string;
        image: File | null;
    }) => {
        try {
            setIsPending(true)
            await message({
                workspaceId,
                channelId,
                body
            } as MessageInputType)
            setEditorKey((prevState) => prevState + 1)

        }catch (e:any) {
            console.log(e)
            setSnackbarStore({
                message: e?.message ?? "Something went wrong",
                severity: "error" ,
                open: true,
            });
        } finally {
            setIsPending(false)
        }

    };

  return (
    <div className="px-5 w-full">
      <Editor
        key={editorKey}
        placeholder={placeholder}
        onSubmit={handleSubmit}
        disabled={isPending}
        innerRef={editorRef}
      />
    </div>
  );
};
