import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { changePassword } from "../../requests/auth.request";
import { ISnackbarSlice, openSnackbar } from "../../redux/features/snackbar";
import {VisibilityOff, VisibilityOffOutlined, VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setErrorSnackbar } from "../../helper/utils/helper";
import VisibleSVG from "../../svg/eye";
import UnvisibleSVG from "../../svg/unvisibleSVG";

const validationSchema = yup.object({
  email: yup.string().email().required("Email is required."),
  oldPassword: yup.string().required("Old Password is required."),
  newPassword: yup
    .string().required('Please set your new password.')
    .min(8, "Password must be 8 characters long")
    .max(16, "Password must be 8-16 characters."),
  confirmPassword: yup
    .string().required()
    .oneOf([yup.ref("newPassword"), null], "New and confirm password does not match"),
});

 const initialValues = {
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const ChangePassword = React.memo((props: IProps) => {
  const { open, handleClose } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();

  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const res = await changePassword(values);


        setSnackbarStore({
          message: res.message ? res.message : res.success ? "Password has been successfully changed" : "",
          severity: res.success  ? "success" : "info",
          open: true,
        });

        handleClose();
        formik.resetForm();
      } catch (error) {
        setErrorSnackbar(error, setSnackbarStore);
      }
    },
  });

  const handleChangePassword = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      formik.handleSubmit();
    }, [formik]);

  const VisibilityTag = useMemo(
    () => (showPassword ? VisibilityRounded : VisibilityOffRounded),
    [showPassword]
  );


  const oldPasswordTextField = () => setShowOldPassword((status) => !status)

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <div>
            <TextField
                label="OldPassword"
                type={showOldPassword ? "text" : "password"}
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                id="oldPassword"
                name="oldPassword"
                InputProps={{
                    endAdornment: (
                        <IconButton
                            onClick={oldPasswordTextField}
                            size="small"
                            aria-label="toggle password visibility"
                        >
                            {showOldPassword ? <UnvisibleSVG /> :  <VisibleSVG/>}
                               </IconButton>
                    ),
                }}
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                error={
                    formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
                }
                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            />

        </div>

        <TextField
          label="New Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          id="newPassword"
          name="newPassword"
          InputProps={{
            endAdornment: (
              <InputAdornment   position="end">
                <IconButton
                    onClick={() => setShowPassword((status) => !status)}
                  size="small"
                  aria-label="toggle password visibility"
                >
                    {showPassword ? <UnvisibleSVG /> :  <VisibleSVG/>}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <TextField
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          variant="outlined"
          margin="dense"
          size="small"
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          InputProps={{
            endAdornment: (
              <InputAdornment  position="end">
                <IconButton
                  size="small"
                  onClick={() => setShowConfirmPassword((status) => !status)}
                >
                    {showConfirmPassword ? <UnvisibleSVG /> :  <VisibleSVG/>}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
      </DialogContent>
      <DialogActions>
        <Button id={"cancelChangePassword"} onClick={handleClose}>Cancel</Button>
        <Button
            id={"addChangePassword"}
          disabled={!(formik.dirty)}
          variant="contained"
          onClick={handleChangePassword}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ChangePassword;
