import api from "./config";
import Axios, {AxiosRequestConfig} from "axios";
import axios from "axios";

let cancelToken = Axios.CancelToken.source()
export const fetchChannelsByWorkspaceId  = async (workspaceId: string) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/channels/workspace/${workspaceId}`, {
        ...{cancelToken: cancelToken?.token}
    });

    return res?.data;
};

export const getChannelById  = async (workspaceId: string, channelId: string) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/channels/${channelId}/workspace/${workspaceId}`, {
        ...{cancelToken: cancelToken?.token}
    });
    console.log(res?.data)
    return res?.data;
};

export const createChannel = async ({ workspaceId, channelName }: { workspaceId: string; channelName: string }) => {
    const response = await api.post(`/channels`, { workspaceId, name: channelName });
    return response.data;
};

export const deleteChannel = async (id: string) => {
    const response = await api.delete(`/channels/${id}`);
    return response.data;
};

export const updateChannel = async ({ id, workspaceId, channelName }: { id: string, workspaceId: string; channelName: string }) => {
    const response = await api.patch(`/channels/${id}`, { workspaceId, name: channelName });
    return response.data;
};