import api from "./config";
import Axios, {AxiosRequestConfig} from "axios";
import axios from "axios";

let cancelToken = Axios.CancelToken.source()
export const fetchMembersByWorkspaceId   = async (workspaceId: string) => {
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/workspaces/${workspaceId}/members`, {
        ...{cancelToken: cancelToken?.token}
    });
    return res?.data;
};
export const getMembersById   = async (memberId: string) => {
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/members/${memberId}`, {
        ...{cancelToken: cancelToken?.token}
    });
    return res?.data;
};
export const addMemberToWorkspace = async ( workspaceId: number, userId: number) => {
    const response = await api.post(`/workspaces/${workspaceId}/members`, {
        userId, workspaceId, role: "member"
    });
    return response.data;
};