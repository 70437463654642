import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {LoadingButton} from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {setUploadRequirement} from "../../redux/features/specialOrder";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import useDialog from "../../hooks/useDialog";
import AddConfirmationDialog from "./AddConfirmation.dialog";
import {QueryClient, useQueryClient} from "react-query";
import {X} from "lucide-react";
import {Box, Checkbox, IconButton, Tooltip, Typography} from "@mui/material";
import {Transition} from "../../wrapper/Transition";
import {useDropzone} from "react-dropzone";
import {uploadReceipt} from "../../requests/special-order.request";
import {useDebounce} from "../../hooks/useDebounce";
import {useAddEntities} from "../../hooks/useAddEntities";
import {MUIDataTableColumn, MUIDataTableOptions} from "mui-datatables";
import {currency} from "../../helper/utils/formatting";
import {useSpecialOrderReceiptGroupByStudentQuery} from "../../hooks/useSpecialOrderReceiptGroupByStudentQuery";
import moment from "moment-timezone";
import FilesPreviewDialog from "./FilesPreview.dialog";
import {setLoading} from "../../redux/features/app";
import TextField from "@mui/material/TextField";
import {useFormik} from "formik";
import {setErrorSnackbar} from "../../helper/utils/helper";
import * as yup from "yup";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    title: string
}


interface IStudentRequirementSO {
    specialOrderRequirementId: number | string | any
    studentId: number | string | any
    url: number | string | any
    schoolId: number | string | any
    programId: number | string | any
    majorId: number | string | any
    id: number | string | any
    fileExtension: number | string | any
    dateCreated: string | string | any
    isDeleted: string | string | any
    lastModifiedDate: string | string | any
    fileSize: string | string | any
    fileName: string | string | any
}

const validationSchema = yup.object({
    totalAmount: yup.string().required("Total Amount is required."),
    receiptNumber: yup.string().required("Required Number is required."),
    datePaid: yup.string().nullable(),
    attachment: yup.string().nullable(),
    bankName: yup.string().required(),
    referenceNumber: yup.string().required(),
});

const initialValues = {
    totalAmount: 0,
    receiptNumber: "",
    datePaid: "",
    attachment: "",
    bankName: "",
    referenceNumber: ""
}

function setQuerieSpecialOrderGroupBySchool(queryClient: QueryClient, specialOrderProgramId: number, _patchStudentsGroupByProgram: {
    data: { studentId: any; };
}, paramStatus?: any) {

    queryClient.setQueriesData(
        `specialOrderGroupBySchool` + (paramStatus ?? ""),
        (oldData: any) => {

            return {
                ...oldData,
                data: oldData?.data?.map((dt: any) => ({
                    ...dt,
                    noStudents: dt?.students?.filter(
                        (t: any) => !(dt.programId == specialOrderProgramId && t.id == _patchStudentsGroupByProgram?.data?.studentId)
                    )?.length,
                    students: dt?.students?.filter(
                            (t: any) => !(dt.programId == specialOrderProgramId && t.id == _patchStudentsGroupByProgram?.data?.studentId)
                        )
                    })
                ),
            };
        }
    );
}

const UploadFileDialog = React.memo((props: IProps) => {
    const dispatch = useDispatch();
    const [files, setFiles] = React.useState<File[]>([]);
    const [progress, setProgress] = React.useState(0);
    const {schoolId, regionId, userInstitutionalName} = useAddEntities();
    const specialOrderMajorId = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorId)
    const queryClient = useQueryClient();
    const specialOrderSchoolId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderSchoolId
    );
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const specialOrderProgramId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderProgramId
    );
    const user = useSelector((store: RootState) => store.auth.user);
    const specialOrderIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderIdValue
    );
    const specialOrderStudentNumberValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentNumberValue
    );
    const specialOrderStudentIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentIdValue
    );
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: undefined,
        name: "All"
    });

    const [searchKey, setSearchKey] = React.useState<any>("");
    const [disableText, setDisableText] = React.useState<any>(false);
    const [increment, setIncrement] = React.useState<any>(0);
    const debouncedSearchKey = useDebounce(searchKey, 500);

    const {open, handleClose, onSave, title} = props;
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])


    const handleUpload = React.useCallback(async () => {
        if (files) {
            handleCloseConfirmation()
            if (files.length == 0 && !(students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0])?.attachment) {
                setSnackbarStore({
                    open: true,
                    message: "Attachment is required",
                    severity: "error",
                });
                setDisableText(false)
                dispatch(setLoading(false))
                return
            }
            setDisableText(true)
            dispatch(setLoading(true))
            for (const c of studentData?.students) {
                const formData = new FormData();
                if (files?.[0]) {
                    formData.append("file", files[0]);
                }
                const res = await uploadReceipt(formData, {
                    params: {
                        totalAmount: formik?.values?.totalAmount?.toString(),
                        receiptNumber: formik.values.receiptNumber,
                        bankName: formik.values?.bankName,
                        majorId: studentData?.majorId,
                        schoolId: studentData?.schoolId,
                        programId: studentData?.programId,
                        datePaid: formik.values.datePaid,
                        studentId: c?.id,
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        let percent = Math.floor((loaded * 100) / total!);
                        if (percent <= 100) setProgress(percent);
                    },
                }, specialOrderIdValue);
                setDisableText(false)

                queryClient.setQueriesData('pending-count', (oldData: any) => {
                    return res?.data?.soPendingCount
                })
                queryClient.setQueriesData('soapprovedCount', (oldData: any) => {
                    return res?.data?.soApprovedCount
                })
                queryClient.setQueriesData('studentsGroupByProgram', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((student: any) => {
                            if (student.id === res?.data?.data.id) {
                                student.status = res?.data?.data.status
                                student.isActive = res?.data?.data.isActive
                                return student
                            }
                            return student
                        })
                    }
                })
                queryClient.setQueriesData(
                    "specialOrderReceiptGroupByStudent" + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId,
                    (oldData: any) => {
                        var newData = {
                            ...oldData, data: oldData?.data?.map((student: any) => {
                                var _student = student
                                _student.students = _student.students.map((c: any) => {
                                    if (c.id == res?.data?.data?.studentId) {
                                        if (res?.data?.data?.receiptFile) {
                                            c.attachment = res?.data?.data?.receiptFile
                                        }
                                    }
                                    return c
                                })
                                return _student
                            })
                        }
                        var stringify = JSON.stringify(newData)
                        return JSON.parse(stringify);
                    }
                );
                setQuerieSpecialOrderGroupBySchool(queryClient, specialOrderProgramId, res?.data, paramsStatus);
                queryClient.setQueriesData(
                    "specialOrderGroupBySchool" + (paramsStatus ?? ""),
                    (oldData: any) => {
                        var newData = {
                            ...oldData, data: oldData.data?.map((specialOrder: any) => {
                                var _specialOrder = specialOrder
                                if (_specialOrder?.id == specialOrderIdValue) {
                                    if (res?.data?.data?.receiptFile) {
                                        _specialOrder.totalAmount = res?.data?.data?.totalAmount
                                        _specialOrder.receiptNumber = res?.data?.data?.receiptNumber
                                        _specialOrder.datePaid = res?.data?.data?.datePaid
                                        _specialOrder.bankName = res?.data?.data?.bankName
                                        _specialOrder.receiptFile = res?.data?.data?.receiptFile
                                    }
                                }
                                return _specialOrder
                            })
                        }
                        var stringify = JSON.stringify(newData)
                        return JSON.parse(stringify);
                    }
                );
                formik.setFieldValue("attachment", res?.data?.data?.receiptFile)
            }


            try {
                queryClient.setQueriesData(
                    `specialOrderGroupBySchool` + paramsStatus,

                    (oldData: any) => {

                        return {
                            ...oldData,
                            data: oldData?.data?.filter(
                                (t: any) => !(t.programId == specialOrderProgramId && t.students.length == 0)
                            ),
                        };
                    }
                );


                await queryClient.invalidateQueries("specialOrderReceiptGroupByStudent" + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId);
                dispatch(setLoading(false))

                const message = "Receipt uploaded successfully";
                setSnackbarStore({
                    open: true,
                    message: message,
                    severity: "success",
                });
                handleCloseConfirmation()
            } catch (error) {
                setDisableText(false)
                dispatch(setLoading(false))
                const message = "Failed to upload Receipt";
                setSnackbarStore({
                    open: true,
                    message: message,
                    severity: "error",
                });
                handleCloseConfirmation()
            } finally {
                dispatch(setLoading(false))
                handleReset();
                handleClose();
            }
        }
    }, [files]);


    const {
        handleOpen: handleOpenConfirmation,
        handleClose: handleCloseConfirmation,
        open: openConfirmation,
    } = useDialog();

    const handleReset = React.useCallback(() => {
        formik.setFieldValue("attachment", "")
        setProgress(0);
    }, []);

    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
    }, []);

    const _handleClose = () => {


        dispatch(setUploadRequirement({
            studentId: 0,
            specialOrderId: 0,
            majorId: specialOrderMajorId,
            schoolId: specialOrderSchoolId,
            programId: specialOrderProgramId,
        }))
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        queryClient.setQueriesData('requirementQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('requirementSoQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        setFiles([]);

        onSave()

        handleClose()
    };
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, multiple: false,
        maxFiles: 1,
        accept: {
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
                ".png",
                ".jpg",
            ],
        },
    });
    const [preview, setPreview] = useState("")
    const specialOrderSchoolName = useSelector((store: RootState) => store.specialOrder?.specialOrderSchoolName)
    const [selectedSchool, setSelectedSchool] = React.useState<any>({name: "All", id: undefined});
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const [selectedYearLevel, setSelectedYearLevel] = React.useState<any>({name: "All", id: undefined});
    const [degree, setDegree] = useState<any>(0)
    const paramsStatus = useSelector((store: RootState) => store.specialOrder?.paramsStatus)
    const [page, setPage] = React.useState(0);
    const rowsSelected = useSelector((store: RootState) => store.specialOrder?.rowsSelected)
    const [selectedProgram, setSelectedProgram] = React.useState<any>({name: "All", id: undefined});
    const {data: students, refetch, isLoading} = useSpecialOrderReceiptGroupByStudentQuery({
        pageSize: "100",
        search: debouncedSearchKey,
        ...specialOrderMajorIdValue && {majorId: specialOrderMajorIdValue},
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
        ...((paramsStatus) && {status: paramsStatus}),
        ...((degree > 0 && degree != 2) && {levelId: degree}),
        ...(selectedYearLevel?.id > 0 && {yearLevel: selectedYearLevel?.id}),
        ...((specialOrderSchoolId > 0 || specialOrderSchoolId) && {schoolId: specialOrderSchoolId || schoolId}),
        ...((specialOrderProgramId > 0 || specialOrderProgramId) && {programId: specialOrderProgramId}),
        ...(selectedProgram?.name ? selectedProgram?.name != "All" && selectedProgram && {programName: selectedProgram?.name} : null),
        ...(page && {page: page + 1}),
    }, specialOrderMajorIdValue, specialOrderSchoolId, specialOrderProgramId);

    const options: MUIDataTableOptions = {
        filter: false,
        search: true,
        elevation: 0,
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        },
        rowsSelected: rowsSelected ?? [],
        selectableRows: "none",
        resizableColumns: false,
        rowsPerPage: 100,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

            return (
                <div>

                </div>

            )
        },

        onTableChange: (action, newTableState) => {
            switch (action) {

                case "search":
                    setSearchKey(newTableState.searchText);
                    break;

            }
        },
    };
    const studentData = useMemo(() => {
        return students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0]
    }, [increment, students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0]])
    useEffect(() => {
        var sum = studentData?.students?.reduce((partialSum: number, a: any) => partialSum + a?.amount, 0)
        if (sum) {
            formik.setFieldValue("totalAmount", sum)
        }
    }, [studentData?.students?.reduce((partialSum: number, a: any) => partialSum + a?.amount, 0)])
    const handleChange = (id: any) => {
        queryClient.setQueriesData(
            "specialOrderReceiptGroupByStudent" + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId,
            (oldData: any) => {
                return {
                    ...oldData, data: oldData.data?.map((student: any) => {
                        student.students = student.students.map((c: any) => {
                            if (c.id == id) {
                                c.isPaid = !c.isPaid
                            }
                            return c
                        })
                        return student
                    })
                };
            }
        );
    };
    const schoolAdminColumns: MUIDataTableColumn[] = [
        {
            name: "isPaid",
            label: "Paid",
            options: {
                display: true,
                filter: false,
                sort: true,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {

                    const __id =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "id")
                            ];

                    return (
                        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
                            <Checkbox
                                checked={value}
                                onChange={() => handleChange(__id)}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        </div>

                    );
                },
            },
        },
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        }, {
            name: "referenceNumber",
            label: "Receipt Number",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        }, {
            name: "programId",
            label: "ProgramId",
            options: {

                display: false,
                filter: false,
                sort: false,
            },
        }, {
            name: "schoolId",
            label: "SchoolId",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        }, {
            name: "specialOrderId",
            label: "SpecialOrderId",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            name: "studentNumber",
            label: "Student Number",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        }, {
            name: "firstName",
            label: "First Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        },
        {
            name: "middleName",
            label: "Middle Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        }, {
            name: "status",
            label: "Requirement Status",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        },
        {
            name: "requirementCompleted",
            label: "Requirement Complete",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        },

        {
            name: "amount",
            label: "Amount",
            options: {
                display: true,
                filter: false,
                sort: true,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {

                    return (
                        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
                            <div>
                                <Box>
                                    <Typography textAlign={"right"}
                                                variant='subtitle2'>{currency(value)}</Typography>
                                </Box>
                            </div>
                        </div>

                    );
                },
            },
        }, {
            name: "createdBy",
            label: "Date Paid",
            options: {
                display: true,
                filter: false,
                sort: true,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    return (
                        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
                            <div>
                                <Box>
                                    <Typography textAlign={"right"}
                                                variant='subtitle2'>{moment.utc(value).tz('Asia/Manila').format('MMMM D, YYYY, h:mm a')}</Typography>
                                </Box>
                            </div>
                        </div>

                    );
                },
            },
        },

    ];
    const {
        handleOpen: handleOpenPreview,
        handleClose: handleClosePreview,
        open: openPreview,
    } = useDialog();

    function handlePreview(_name: string, _preview: string) {

        setPreview(_preview)
        handleOpenPreview()
    }

    const filesPreviewHandleClose = () => {
        setPreview("")
        handleClosePreview()
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {


                handleClose();
                formik.resetForm();
            } catch (error) {
                setErrorSnackbar(error, setSnackbarStore);
            }
        },
    });
    const [calendarOpen, setCalendarOpen] = useState<boolean>()
    const [datePaid, setDatePaid] = useState<Date>(moment.utc().tz('Asia/Manila').toDate());
    useEffect(() => {
        const __date = moment.utc().tz('Asia/Manila')
        setDatePaid(__date.toDate());
        formik.setFieldValue("datePaid", __date.toDate())
    }, [])
    const onChangeHandlerFrom = (_date: Date | null, keyboardInputValue?: string) => {
        if (_date) {
            const __date = moment.utc(_date).tz('Asia/Manila')
            setDatePaid(__date.toDate());
            formik.setFieldValue("datePaid", __date)
        }
    };
    return (
        <>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
                maxWidth="lg"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{fontSize: 16}}>
                    <div className={"flex justify-between align-middle"}>
                        <div>{title}</div>
                        <div><Tooltip title={"close"} onClick={_handleClose}>
                            <IconButton><X/></IconButton>
                        </Tooltip></div>
                    </div>
                </DialogTitle>
                <DialogContent>


                    <div
                        {...getRootProps({
                            style: {
                                border: "3px dashed grey",
                                borderRadius: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: 200,
                            },
                        })}
                    >
                        <input {...getInputProps()} />
                        {files.length > 0 ? (
                            files.map((file: File) => {
                                return (
                                    <Box style={{marginLeft: 10, marginRight: 10}} key={file.name}>
                                        <Typography>File Name: {file.name}</Typography>
                                        <Typography>
                                            Size: {(file.size / 1024).toFixed(2)} kb
                                        </Typography>
                                    </Box>
                                );
                            })
                        ) : isDragActive ? (
                            <div style={{marginLeft: 10, marginRight: 10}}>
                                <Typography>Drop it here ...</Typography>
                            </div>

                        ) : (
                            <div style={{marginLeft: 10, marginRight: 10}}>
                                <Typography component="div">
                                    Drag 'n' drop file here, or click to select
                                    file
                                </Typography>
                            </div>

                        )}
                    </div>

                    {(students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0])?.attachment ?
                        <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5 mb-5">
                            <div
                                className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">

                                <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in">
                                    <div
                                        onClick={() => handlePreview("", (students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0]).attachment)}
                                        className="w-3/5 file__icon file__icon--image mx-auto">
                                        <div
                                            className="flex justify-center content-center  file__icon--image__preview image-fit">
                                            <img
                                                src={(students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0]).attachment}

                                                // Revoke data uri after image is loaded
                                                onLoad={() => {
                                                }}/>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div> : null
                    }


                    <div style={{paddingTop: 10}}>
                        <TextField
                            disabled={disableText}
                            label="Total Amount"
                            type="number"
                            variant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth
                            id="totalAmount"
                            name="totalAmount"
                            value={formik.values.totalAmount}
                            onChange={formik.handleChange}
                            error={formik.touched.totalAmount && Boolean(formik.errors.totalAmount)}
                            helperText={formik.touched.totalAmount && formik.errors.totalAmount}
                        />
                    </div>

                    <TextField
                        disabled={disableText}
                        label="Receipt Number"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        id="receiptNumber"
                        name="receiptNumber"
                        value={formik.values.receiptNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.receiptNumber && Boolean(formik.errors.receiptNumber)}
                        helperText={formik.touched.receiptNumber && formik.errors.receiptNumber}
                    />

                    <TextField
                        disabled={disableText}
                        label="Reference Number"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        id="referenceNumber"
                        name="referenceNumber"
                        value={formik.values.referenceNumber}
                        onChange={formik.handleChange}
                        error={formik.touched.referenceNumber && Boolean(formik.errors.referenceNumber)}
                        helperText={formik.touched.referenceNumber && formik.errors.referenceNumber}
                    />

                    <TextField
                        disabled={disableText}
                        label="Bank Name"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        id="bankName"
                        name="bankName"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                        helperText={formik.touched.bankName && formik.errors.bankName}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <div style={{width: "100%", paddingTop: 10}}>
                            <DatePicker
                                disabled={disableText}
                                label={'Date Paid'} openTo="year"
                                open={calendarOpen}
                                onClose={() => setCalendarOpen(false as any)}
                                onOpen={() => setCalendarOpen(true as any)}
                                value={datePaid}
                                onChange={(newValue) => onChangeHandlerFrom(newValue as any)}
                                renderInput={(params) => <TextField {...params} fullWidth size="small"/>}/>
                        </div>

                    </LocalizationProvider>

                    {/*<MUIDataTable
                        options={options}
                        title={titleMemo}
                        data={studentData?.students as any}
                        columns={schoolAdminColumns}
                    />*/}

                </DialogContent>
                <DialogActions>
                    <Button color={"error"} id={"cancelCourse"} onClick={_handleClose}>Cancel</Button>
                    <LoadingButton
                        disabled={!(formik.isValid && formik.dirty)}
                        id={"AddConformation"} onClick={() => {
                        handleOpenConfirmation()
                    }}
                    >
                        Ok
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <FilesPreviewDialog open={openPreview}
                                handleClose={filesPreviewHandleClose}
                                preview={preview}
                                title={"Preview Receipt"}/>
            <AddConfirmationDialog
                isLoading={isLoading}
                title={"Do you want to save the changes you made?"}
                open={openConfirmation}
                handleClose={handleCloseConfirmation}
                onSave={handleUpload}></AddConfirmationDialog>


        </>

    );
});

export default UploadFileDialog;
