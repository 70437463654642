import {AxiosRequestConfig} from 'axios';
import {MajorResponseType} from "../types"
import api from "./config"
import {MajorInputType} from "../schemas/major.schema";

export const getMajors = async (params: AxiosRequestConfig['params']) => {
    const res = await api.get<MajorResponseType>("/majors", {params})
    return res?.data
}

type MajorData = {
    name: string
}

export const getMajorByName = async (search: string, signal: AxiosRequestConfig["signal"]) => {

    const res = await api.get(`/majors`, {
            signal, ...{
                params: {
                    search: search,
                    pageSize: 30
                }
            }
        }
    );
    return res?.data
}
export const postMajor = async (data: MajorInputType) => {
    const res = await api.post("/majors", data, {
        params: {
            ...(data.schoolId != null && {schoolId: data.schoolId}),
        },
    });
    return res?.data;
};

export const getMajor = async (id: number) => {
    if (!id) return
    const res = await api.get(`/majors/${id}`);
    return res?.data?.data;
};
export const patchMajor = async (data: MajorInputType) => {

    if (!data?.id) return

    const res = await api.patch(`/majors/${data?.id}`, data, {
        params: {
            ...(data.schoolId != null && {schoolId: data.schoolId}),
        },
    });
    return res?.data;
};
export const getMajorByNameList = async (
    search: string,
    signal: AxiosRequestConfig["signal"],
    schoolId: any
) => {
    const res = await api.get(`/majors/all`, {
        signal,
        ...{
            params: {
                ...((typeof schoolId == "number" || typeof schoolId == "string") ? {
                    schoolId: schoolId
                } : schoolId),

                search: search,
                pageSize: 10,
            },
        },
    });
    return res?.data;
};
export const deleteMajor = async (id: number) => {
    const res = await api.post(`/majors/${id}`);
    return res?.data;
};
