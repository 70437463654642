import { useState } from "react";
import { IconButton, Popover, Tooltip } from "@mui/material";
import EmojiPicker, { type EmojiClickData } from "emoji-picker-react";
import { EmojiEmotions } from "@mui/icons-material";

interface EmojiPopoverProps {
    children: React.ReactNode;
    hint?: string;
    onEmojiSelect: (value: string) => void;
}

export const EmojiPopover = ({
                                 children,
                                 hint = "Emoji",
                                 onEmojiSelect,
                             }: EmojiPopoverProps) => {
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setPopoverAnchor(event.currentTarget);
        setTooltipOpen(true);
    };

    const handlePopoverClose = () => {
        setPopoverAnchor(null);
        setTimeout(() => setTooltipOpen(false), 500);
    };

    const onSelect = (value: EmojiClickData) => {
        onEmojiSelect(value.emoji);
        handlePopoverClose();
    };

    const isOpen = Boolean(popoverAnchor);

    return (
        <div style={{ display: "inline-flex", alignItems: "center" }}>
            <Tooltip title={hint} open={tooltipOpen && !isOpen}>
                <IconButton
                    onClick={handlePopoverOpen}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => !isOpen && setTooltipOpen(false)}
                >
                    {children}
                </IconButton>
            </Tooltip>

            <Popover
                open={isOpen}
                anchorEl={popoverAnchor}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <EmojiPicker onEmojiClick={onSelect} />
            </Popover>
        </div>
    );
};
