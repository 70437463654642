import {AxiosRequestConfig} from 'axios';
import {GetDisciplineResponseType} from './../types/apiResponse.type';
import api from "./config";

export const getDisciplines = async (params: AxiosRequestConfig['params']) => {
    const res = await api.get<GetDisciplineResponseType>("/disciplines", {params});
    return res?.data;
}

export const deleteDiscipline = async (id: number) => {
    const res = await api.post(`/disciplines/${id}`)
    return res?.data
}
type DisciplineData =
    {
        schoolId: string,
        code: string,
        name: string
    }

export const postDiscipline = async (data: DisciplineData) => {
    const res = await api.post("/disciplines", data, {
        params: {
            ...(data.schoolId != null && {schoolId: data.schoolId})
        }
    })
    return res?.data
}

export const getCountDisciplines = async (schoolId: any) => {
    const res = await api.get("/disciplines/count", {
        params: (schoolId != null && schoolId)
    })
    return res?.data
}


export const getDisciplineByName = async (search: string, signal: AxiosRequestConfig["signal"], schoolId: any) => {
    const res = await api.get(`/disciplines`, {
            signal, ...{
                params: {
                    ...(schoolId && (typeof schoolId == "string" || typeof schoolId == "number" ? {schoolId: schoolId} : schoolId)),
                    ...(search && {search: search}),
                    pageSize: 30
                }
            }
        }
    );

    return res?.data
}
