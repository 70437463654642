import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Paper
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {APP_TITLE} from "../helper/utils/constants";
import {useScreenDimension} from "../hooks/useScreenDimension";
import Sidebar from "../components/Workspace/sidebar";
import WorkspaceContent from "../components/Workspace/WorkspaceContent";
import Toolbar from "../components/Workspace/Toolbar";
import ChannelContent from "../components/Workspace/ChannelContent";
import RightSidebar from "../components/Workspace/rigthSideBar";
import {usePanel} from "../components/Workspace/use-panel";
const drawerWidth = 240;
const WorkspaceIdPage = () => {
    const workspace: {workspaceId: string, channelId: string} = useParams();
    const { height } = useScreenDimension()
    const { parentMessageId, profileMemberId, onClose } = usePanel();

    const showPanel = !!parentMessageId || !!profileMemberId;
    return (
        <div>
            <Helmet>
                <title>Workspace | {APP_TITLE}</title>
            </Helmet>
            <main
            >
                <Box sx={{
                    flex: 1,
                    display: "flex",
                    paddingTop: 8.5,
                }}>
                    <Toolbar workspaceId={workspace?.workspaceId ?? "Unknown"}></Toolbar>
                </Box>

                    <Box
                        sx={{

                        }}
                    >

                        <Paper
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                height: height -110,
                                backgroundColor: "#E9E9E9",
                            }}
                        >
                            <Sidebar workspaceId={workspace?.workspaceId ?? "Unknown"}/>
                            <Box className="flex-1 w-full">
                                {
                                    workspace?.workspaceId &&  workspace?.channelId ?
                                        <ChannelContent channelId={workspace?.channelId ?? "Unknown"}
                                                        workspaceId={workspace?.workspaceId ?? "Unknown"} ></ChannelContent> :
                                        <WorkspaceContent workspaceId={workspace?.workspaceId ?? "Unknown"} ></WorkspaceContent>
                                }
                            </Box>
                            {showPanel ? <RightSidebar workspaceId={workspace?.workspaceId ?? "Unknown"}/> : null}
                        </Paper>
                    </Box>
            </main>
        </div>

    );
};

export default WorkspaceIdPage;
