import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IMajorSlice {
    isMajor: boolean;
    curriculumCount: number | undefined;
    editMajorsId?: number | undefined;
    editAcademicId?: number | undefined;
    editMajorSchoolId?: number | undefined;
    editMajorSchoolName?: string | undefined;
    coloredRows?: number | string;
}

const initialState: IMajorSlice = {
    isMajor: false,
    editAcademicId: 0,
    coloredRows: -1,
    curriculumCount: 0,
    editMajorsId: 0,
    editMajorSchoolId: 0,
    editMajorSchoolName: ""
};

const curriculumSlice = createSlice({
    name: "curriculum",
    initialState,
    reducers: {

        setEditMajor: (
            state,
            action: PayloadAction<number>
        ) => {
            state.editMajorsId = action.payload;
            return state;
        },
        setColoredRows: (
            state,
            action: PayloadAction<number | string>
        ) => {
            state.coloredRows = action.payload;
            return state;
        },
        editMajorSchoolId: (
            state,
            action: PayloadAction<number>
        ) => {
            state.editMajorSchoolId = action.payload;
            return state;
        },
        editMajorSchoolName: (
            state,
            action: PayloadAction<string>
        ) => {
            state.editMajorSchoolName = action.payload;
            return state;
        },
        setMajorCount: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            state.curriculumCount = action.payload;
            return state;
        },

    },
});

export const {setColoredRows, editMajorSchoolId, editMajorSchoolName, setEditMajor, setMajorCount} =
    curriculumSlice.actions;
export default curriculumSlice.reducer;
