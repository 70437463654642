import * as React from 'react';
import {useCallback, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, Box, TextField} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {useQueryClient} from 'react-query';
import {postDiscipline} from "../../requests/discipline.request";
import {useAppSelector} from "../../redux/store";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {useTransition} from "../../hooks/useTransitions";
import SearchAsYouType from "../SearchAsYouType";
import {SearchSchoolResponseType} from "../../types";

const validationSchema = yup.object({
    code: yup.string().required('Code is required'),
    name: yup.string().required('Name is required'),
    schoolId: yup.string()

})

const initialValues = {
    schoolId: '',
    code: '',
    name: '',
}


const Transition = useTransition();


interface IProps {
    open: boolean
    handleClose: () => void
    setting?: boolean,
    schoolRegionId?: any;
}

const AddDiscipline = React.memo((props: IProps) => {

    const { open, handleClose, setting, schoolRegionId } = props
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const [school, setSchool] = useState<any>()
    const { schoolId, regionId} = useAddEntities();
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                if(!setting){
                    values.schoolId = schoolId;
                }else if(setting){
                    values.schoolId = schoolId ? schoolId : school?.id
                }
                var res = await postDiscipline(values)

                if(res.message){
                    var __message = values?.name + " already exists in our system. Please try a different one."
                    setSnackbarStore({
                        message: res.message,
                        severity: __message  == res.message ? "error" :  "success",
                        open: true,
                    })
                }else{
                    setSnackbarStore({
                        message: "Successfully added discipline",
                        severity: "success",
                        open: true,
                    });
                }


                actions.resetForm()
                queryClient.invalidateQueries('disciplines')

               /* dispatch(setTabValue(1))
                dispatch(setProgramTabState(true))*/
                handleClose()
            } catch (error: any) {
                console.error({ error })
                setErrorSnackbar(error, setSnackbarStore)
            }
        }
    })
    const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit()
    }, [formik])
    const type = useAppSelector((store) => store?.auth?.user?.type?.id);
    const roles = useAppSelector((store) => store?.auth?.user?.roles);

    const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
        setSchool(value)
        formik.setFieldValue('schoolId', value?.id ?? "")
    }, [formik, school])

    const schoolSelectorById = useAppSelector(
        (store) => store?.school.schoolSelectorById
    );
    const schoolSetting = useAppSelector(
        (store) => store?.school.schoolSetting
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Add Discipline</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    Please fill in the details of the discipline you want to add
                    <br />
                    Note: Fields with <strong>*</strong> are required
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}
                >
                    { setting
                   /* || (type == 2 && (roles.toLowerCase() == "admin" || roles.toLowerCase() == "user"))*/
                        ?   <Box paddingBottom={1}>
                        {<SearchAsYouType
                            value={schoolSelectorById ?? schoolSetting}
                            disabled={schoolSelectorById ?? schoolSetting}
                            id={"disciplineselect"}
                            regionNameEnable={!(type == 2 && roles.toLowerCase() == "user")}
                            regionId={(type == 2 && (roles?.toLowerCase() == "user")) ? (regionId) : (type == 2 && roles?.toLowerCase() == "admin" ? schoolRegionId : null)}
                            required={true}
                            error={onErrorSelectedSchool}
                            label='School Institutional Name'
                            onSelected={handleOnSelectedSchool}
                        /> }
                    </Box> : null
                    }
                    <TextField
                        label="Code"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        id='displiplinecode'
                        name='code'
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                    />
                    <TextField
                        label="Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        id='disciplinename'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />


                </Box>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelDiscipline"} onClick={handleClose}>Cancel</Button>
                <Button id={"addDiscipline"}  disabled={!(formik.isValid && formik.dirty)} variant='contained' onClick={handleSubmit}>Add</Button>
            </DialogActions>
        </Dialog>
    )
})

export default AddDiscipline
