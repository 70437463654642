import Axios from 'axios';
import axios, {AxiosRequestConfig} from 'axios';
import {GetRegionsResponseType, GetRegionsSelectResponseType} from "../types";
import api from "./config";

let cancelToken = Axios.CancelToken.source()
export const getRegions = async (params: AxiosRequestConfig['params']) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get<GetRegionsResponseType>("/regions", {
        ...{cancelToken: cancelToken?.token},
        params
    });
    return res?.data;
}

export const getRegionsSelect = async () => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get<GetRegionsSelectResponseType[]>("/regions/select", {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data;
}