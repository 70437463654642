import {AxiosRequestConfig} from "axios";
import {getCourses} from "./../requests/course.request";
import {useQuery} from "react-query";

export const usePrerequisiteQuery = (params: AxiosRequestConfig["params"]) => {
    return useQuery(["prerequisite", params, params?.curriculumCount], () => getCourses(params), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};
