import * as Yup from "yup";

export interface ITypeSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name: string;
}

export const typeSchema: Yup.SchemaOf<ITypeSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  name: Yup.string().required("Name is required"),
});

export type TypesType = Yup.InferType<typeof typeSchema>;
