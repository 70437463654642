import * as Yup from "yup";
import { applicationSchema, IApplicationSchema } from "./application.schema";
import { IUserSchema, userSchema } from "./user.schema.type";
import {programSchoolSchema} from "./programSchool.schema";

export interface IUserApplicationSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  userId?: string | null;
  user: IUserSchema;
  applicationId?: number;
  application: IApplicationSchema;
  isEnabled: boolean;
}

export const userApplicationSchema: Yup.SchemaOf<IUserApplicationSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    userId: Yup.string().nullable(),
    user:Yup.lazy(()=> userSchema),
    applicationId: Yup.number().required("Application Id is required"),
    application: Yup.lazy(() => applicationSchema),
    isEnabled: Yup.boolean().required("Is Enabled is required"),
  });

export type UserApplicationType = Yup.InferType<typeof userApplicationSchema>;
