import {ArrowDropDown, Person, SchoolRounded, SearchRounded} from '@mui/icons-material'
import {
    Autocomplete,
    Avatar,
    Box,
    Chip,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    TableFooter,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from '@mui/material'
import React, {MouseEvent, useCallback} from 'react'
import {Helmet} from 'react-helmet-async'
import {useQueryClient} from 'react-query'
import {useDispatch, useSelector} from 'react-redux'
import {useHistoryLogQuery} from '../hooks/useHistoryLogQuery'
import {ISnackbarSlice, openSnackbar} from '../redux/features/snackbar'
import moment from 'moment-timezone';
import {setErrorSnackbar} from "../helper/utils/helper";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {useDebounce} from "../hooks/useDebounce";
import {TablePaginationActions} from "../components/Table/TablePaginationAction";
import {RootState, useAppSelector} from "../redux/store";
import {yellow} from "@mui/material/colors";
import IsLoading from "../components/Searching";
import {useSchoolQuery} from "../hooks/useSchoolQuery";

const LogsPage = () => {
    const [searchStr, setSearchStr] = React.useState("");
    const [searchSchoolId, setSearchSchoolId] = React.useState<any>({
        id: undefined,
        name: "All"
    });
    const debouncedSearchStr = useDebounce(searchStr, 500);
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs().startOf('day'));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().endOf('day'));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(100);
    const handleStartDateChange = (newValue: Dayjs | null) => {
        setPage(0)
        setStartDate(dayjs(newValue).startOf('day'));
    };

    const handleEndDateChange = (newValue: Dayjs | null) => {
        setPage(0)
        setEndDate(dayjs(newValue).endOf('day'));
    };

    function dateIsValid(date: any) {
        return !Number.isNaN(new Date(date).getTime());
    }

    const {data: historyLogs, isLoading, isFetching} = useHistoryLogQuery({

        ...debouncedSearchStr && {search: debouncedSearchStr},
        ...searchSchoolId && {schoolId: searchSchoolId?.id},
        ...endDate && dateIsValid(endDate) && {dateEnd: endDate?.toISOString()},
        ...startDate && dateIsValid(startDate) && {dateStart: startDate?.toISOString()},
        ...(page && {page: page + 1}),
        ...(rowsPerPage && {pageSize: rowsPerPage})
    })
    const [selectedSchoolId, setSelectedSchoolId] = React.useState<number | null>(null)
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])

    const onRowClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setSelectedSchoolId(+e.currentTarget.value)
    }, [])

    const handleDelete = async () => {
        if (selectedSchoolId) {
            try {
                // await deleteSchool(selectedSchoolId)
                setSnackbarStore({
                    message: 'School deleted successfully',
                    severity: 'success',
                    open: true
                })
                queryClient.setQueriesData('schools', (oldData: any) => {
                    return {
                        ...oldData,
                        data: oldData.data.filter((school: any) => school.id !== selectedSchoolId)
                    }
                })
            } catch (error: any) {
                console.error({ error })
                setErrorSnackbar(error, setSnackbarStore)
            }
        }
    }


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };
    const userId = useSelector((store: RootState) => store.auth?.user?.id)
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
    const roles = useAppSelector((store) => store?.auth?.user?.roles);
    const {data: dataSchools, isLoading: isSchoolLoading} = useSchoolQuery({
        pageSize: "1000",
        search: '',
    });
    return (
        <>
            <Helmet>
                <title>History Logs</title>
            </Helmet>

            <Box
                component='main'
                sx={{flexGrow: 1, pt: 10}}
                style={{
                    paddingLeft: " 20px",
                    paddingRight: "20px"}}

            >
                <Paper>
                    <List dense
                        subheader={
                            <>
                                <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
                                    <Box>
                                        <Typography variant="h5" fontWeight="bold">History Logs</Typography>
                                        {/*<Typography variant="body1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, dolor. Rerum expedita praesentium animi error.</Typography>
                                   */}

                                    </Box>
                                    <Box>
                                        <div style={{flex: 1, flexDirection: "row", display: "flex", }}>
                                            <div style={{marginTop: -8, paddingRight: 6, }}>
                                                <form
                                                    onSubmit={(event) => event.preventDefault()}
                                                    autoComplete={"new-password"}
                                                >
                                                    <TextField
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchRounded />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        size={"small"}
                                                        placeholder="Search Logs"
                                                        label="Search Logs"
                                                        margin="dense"
                                                        value={searchStr}
                                                        onChange={(e) => setSearchStr(e.target.value)}
                                                    />
                                                </form>
                                            </div>

                                            {((roles?.toLowerCase() == "superadmin" || typeId == 2 || typeId == 3)
                                                /* && roles?.toLowerCase() != "user"*/
                                            ) ? <Box sx={{paddingLeft: 1, paddingRight: 1}} display="flex"
                                                     alignItems="center">
                                                <form onSubmit={(event) => event.preventDefault()}>
                                                    <>
                                                        <Autocomplete popupIcon={<ArrowDropDown
                                                            id={"ArrowDropDown-" + "settingschooldashboardschoolyear"}/>}
                                                                      id={"settingschooldashboardschoolyear"}
                                                                      disabled={isSchoolLoading}
                                                                      disableClearable
                                                                      size="small"
                                                                      value={searchSchoolId ?? "0"}
                                                                      onChange={(event: any, newValue: string | null) => {
                                                                          if (newValue != null) {
                                                                              setSearchSchoolId(newValue ?? "")
                                                                          }
                                                                      }}
                                                                      renderOption={(props, option) => (
                                                                          <li {...props}
                                                                              id={"settingschool" + option.id}
                                                                              key={option.id}>
                                                                              <div style={{
                                                                                  flex: 1,
                                                                                  display: "flex",
                                                                                  flexDirection: "column"
                                                                              }}>
                                                                                  {option.name ?? option.displayName}
                                                                                  <p style={{
                                                                                      fontStyle: "italic",
                                                                                      margin: 0,
                                                                                      fontSize: 12
                                                                                  }}>{option?.institutionalCode ? option.institutionalCode + " - " : ""}{option?.address ? option.address : (option?.address ? option.address + " - " : "") + (option?.province?.name ? option.province.name + " - " : "") + (option?.region?.name ? option.region.name + "" : "")}</p>
                                                                              </div>
                                                                          </li>
                                                                      )}
                                                                      getOptionLabel={(option) => option.name}
                                                                      options={[{
                                                                          name: "All",
                                                                          id: undefined
                                                                      }, ...(dataSchools?.data || [])] || []}
                                                                      sx={{width: 300}}
                                                                      renderInput={(params) => (
                                                                          <TextField {...params} id={"heisettingselect"}
                                                                                     label="Select HEI Profile"/>
                                                                      )}
                                                        />
                                                    </>
                                                </form>
                                            </Box> : null}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                                                    <div style={{paddingRight: 6}}>
                                                        <DatePicker label="From" onChange={handleStartDateChange}
                                                                    value={startDate}
                                                                    renderInput={(params) => <TextField
                                                                        size={"small"}   {...params}
                                                                        error={dayjs(endDate) <= dayjs(startDate)}/>}/>
                                                    </div>

                                                    <DatePicker label="To" onChange={handleEndDateChange} value={endDate}  renderInput={(params) => <TextField  size={"small"} {...params} />}/>

                                                </div>

                                            </LocalizationProvider>
                                        </div>

                                    </Box>

                                </Box>
                                <Divider />
                            </>
                        }
                    >
                        <Box
                            sx={{
                                overflowY: "scroll",
                                height: 'calc(100vh - 200px)'
                            }}
                        >
                            {
                                historyLogs?.data?.map((log) => {
                                    return (
                                        <React.Fragment key={log.id}>
                                            <ListItem
                                                disablePadding
                                                secondaryAction={
                                                    <Box sx={{
                                                        display: "flex",
                                                        gap: 2,
                                                    }}>
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography textAlign={"right"} variant='subtitle2'>{moment.utc(log.dateCreated).tz('Asia/Manila').fromNow()}</Typography>
                                                            <Typography variant='caption'>{moment.utc(log.lastModifiedDate).tz('Asia/Manila').format('MMMM D, YYYY, h:mm a')}</Typography>
                                                        </Box>
                                                        {/* <IconButton onClick={onRowClick} value={log.id}>
                                                            <MoreHoriz />
                                                        </IconButton> */}
                                                    </Box>
                                                }
                                            >
                                                <ListItemButton>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <SchoolRounded/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<div
                                                            style={{flex: 1, borderRadius: 6, display: "flex"}}>
                                                            <Typography variant="inherit"
                                                                        bgcolor={log.action == "The institutional profile has been updated." ? yellow[500] : undefined}
                                                                        fontSize={14}
                                                                        fontWeight="bold">{log.action == "You have successfully logged in." && log.userId == userId ? "You have successfully logged in." : (log.action == "You have successfully logged in." ? ((log.firstName ?? "") + " " + (log.lastName ?? "") + " " + "have successfully login.") : log.action)}</Typography>
                                                        </div>}
                                                        secondary={<div
                                                            style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                            <Typography fontSize={12}
                                                                        fontStyle={"italic"}>{log?.type || log?.role ? ((log?.type == "CHED" ? "CHED REGIONAL" : (log?.type == "NATIONAL" ? "CHED NATIONAL" : log.type)) + " - ") + (log?.role ?? "") : " - "} {log.regionName ? "" : ""} {log.regionName ?? ""}</Typography>
                                                            <Typography fontSize={12}
                                                                        fontStyle={"italic"}> {log.school ? "" : ""} {log.school ?? ""}</Typography>
                                                            {log.firstName ?
                                                                <div style={{paddingTop: 5}}><Chip size={"small"}
                                                                                                   variant={"outlined"}
                                                                                                   icon={<Person/>}
                                                                                                   label={<Typography
                                                                                                       fontSize={10}>{(log.firstName ?? "") + " " + (log.lastName ?? "")}</Typography>}/>
                                                                </div> : <></>}
                                                        </div>}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    )
                                })
                            }

                            {
                                (historyLogs?.data?.length === 0 || !historyLogs) && (
                                    <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems="center" p={3}>
                                        <IsLoading loading={isLoading} fetching={isFetching}/>
                                        <Typography variant="body1">
                                            {isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."}
                                        </Typography>
                                        <Typography color={'error.main'} variant="body1">{(dayjs(endDate) <= dayjs(startDate)) ? "The start date must be earlier than the end one." : ""}</Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    </List>
                    {(historyLogs?.data ?? [])?.length > 0 ? <div style={{flex: 1, display: "flex", flexDirection: "row-reverse"}}>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={3}
                                    count={(historyLogs?.totalCount) ?? 10}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelDisplayedRows={(page) =>
                                        `${page.from}-${page.to === -1 ? page.count : page.to} of ${
                                            page.count
                                        }`
                                    }
                                    ActionsComponent={TablePaginationActions}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </div> : null}
                </Paper>

            </Box>
        </>
    )
}

export default LogsPage
