import {AddRounded, DeleteRounded, EditRounded, FilterAltRounded, ViewColumnRounded,} from "@mui/icons-material";
import {Box, Button, IconButton, Stack, Tooltip,} from "@mui/material";
import MUIDataTable, {
    MUIDataTableColumn,
    MUIDataTableOptions,
    MUIDataTableToolbar,
    TableFilterList,
    TableToolbar,
} from "mui-datatables";
import React, {FC, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDeleteDialog} from "../../redux/features/app";
import {RootState, useAppSelector} from "../../redux/store";
import {setMajorTabState,} from "../../redux/features/school";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useDebounce} from "../../hooks/useDebounce";
import {usePager} from "../../hooks/usePager";
import {useQueryClient} from "react-query";
import useDialog from "../../hooks/useDialog";
import {setColoredRows,} from "../../redux/features/curriculum";
import {useStyles} from "../../styles/fade";
import {deleteMajor} from "../../requests/major.request";
import {useSearchMajorQuery} from "../../hooks/useMajorQuery";
import AddMajor from "../dialogs/AddMajor.dialog";
import {editMajorSchoolId, editMajorSchoolName, setEditMajor} from "../../redux/features/major";
import EditMajor from "../dialogs/EditMajor.dialog";

interface IMajorTableProps {
    title: string;
    setting?: boolean;
    schoolRegionId?: any;
}

const MajorTable: FC<IMajorTableProps> = ({title, setting, schoolRegionId}) => {
    const dispatch = useDispatch();
    const {schoolId, regionId} = useAddEntities();
    const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const programId = useAppSelector((store) => store?.school?.programId);
    const [name, setName] = React.useState<any>("");
    const debouncedName = useDebounce(name, 500);
    const queryClient = useQueryClient();
    const [selectedProgram, setSelectedProgram] = useState<any>();

    const handleDeleteMajor = (id: string | number, name: string) => {
        dispatch(setDeleteDialog({
            open: true, data: {
                id,
                title: `Delete Major ${name}?`,
                content: "majors",
                body: `Before deleting this Major, please note that by clicking the "Delete" button, all associated entities will be removed from the system. Are you sure you want to proceed?`,
                onDelete: async () => {
                    var res = await deleteMajor(+id)
                    await queryClient.invalidateQueries("majors");
                    return res
                },
            },
        }));
        //
    };

    const regionSetting = useAppSelector((store) => store?.school.regionSetting);
    const coloredRows = useAppSelector((store) => store?.curriculum.coloredRows);

    const {data: majors, isLoading, refetch,} = useSearchMajorQuery(debouncedName, {
        pageSize: 100, ...((typeof schoolId == "number" || typeof schoolId == "string") && {schoolId: schoolId}), ...(regionSetting?.id && {regionId: regionSetting?.id}), ...(regionId?.id && {regionId: regionId?.id}), ...(typeof regionId == "number" && {regionId: regionId}), ...(debouncedName && {search: debouncedName}), ...(pageSize && {pageSize: pageSize}), ...(page && {page: page + 1}), ...((programId || selectedProgram?.id > 0) && {programId: selectedProgram?.id || programId})
    }, selectedProgram);
    const [highlightedRows, setHighlightedRows] = useState<number[]>([]);

    useEffect(() => {
        const timeoutRefs: NodeJS.Timeout[] = [];
        const newHighlightedRows = majors?.data?.map((row, index) => {
            if (row?.curriculumId === coloredRows) {
                const timeoutRef = setTimeout(() => {
                    setHighlightedRows(prev => prev.filter(idx => idx !== index));
                }, 6000);
                timeoutRefs.push(timeoutRef);
                return index;
            }
            return -1;
        }).filter(index => index !== -1);

        setHighlightedRows(newHighlightedRows || []);
        dispatch(setColoredRows(-1))
        return () => {
            timeoutRefs.forEach(clearTimeout);
        };
    }, [majors?.data?.length, coloredRows]);
    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);


    const totalCount = useMemo(() => {
        return (majors?.totalCount)
    }, [majors?.totalCount])

    const totalRowPage = useMemo(() => {
        return (majors?.pageSize) ?? 10
    }, [majors?.pageSize])
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const roles = useSelector((store: RootState) => store.auth?.user?.roles)
    const classes = useStyles();
    const options: MUIDataTableOptions = {
        filterType: "checkbox",
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        },
        setRowProps: (row, dataIndex) => {
            if (highlightedRows.includes(dataIndex)) {
                return {
                    className: classes.highlightedRow
                };
            } else {
                return {
                    className: classes.fadedRow
                };
            }
            return {};
        },
        serverSide: true,
        count: totalCount,
        rowsPerPage: totalRowPage, //selectableRowsOnClick: true,
        tableBodyMaxHeight: "600px",
        tableId: "majortable",
        selectableRows: "none",
        print: false,
        download: type?.id != 1,
        onTableChange: (action, newTableState) => {
            switch (action) {
                case "changeRowsPerPage":
                    changeRowsPerPage(newTableState.rowsPerPage);
                    break;

                case "changePage":
                    changePage(newTableState.page, (newTableState.data?.length ?? 100));
                    break;
                case "search":
                    setName(newTableState.searchText);
                    setPage(0)
                    break;
            }
        },
        isRowExpandable: () => true,
    };

    const {
        handleOpen: handleOpenEditMajor, handleClose: handleCloseEditMajor, open: openEditMajor,
    } = useDialog();


    const columns: MUIDataTableColumn[] = [{
        name: "id", label: "ID", options: {
            display: false, filter: false, sort: false,
        },
    }, {
        name: "name", label: "Name", options: {
            filter: true, sort: false, display: true,
        },
    }, {
        name: "code", label: "Code", options: {
            filter: true,
            sort: false,
            display: true
        },
    }, {
        name: "schoolId", label: "SCHOOL Id", options: {
            filter: false, sort: false, display: false,

        },
    },
        {
            name: "id",
            label: "ACTION",
            options: {
                display: type?.id == 1 || type?.id == 2 || type?.id == 3,
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const _schoolId = tableMeta.rowData[columns.findIndex((x) => x.name === "schoolId")];
                    const _schoolName = tableMeta.rowData[columns.findIndex((x) => x.name === "schoolName")];

                    const name =
                        tableMeta.rowData[columns.findIndex((x) => x.name === "name")];


                    return (
                        <Stack direction="row" spacing={1}>
                            {(type?.id == 2 || type?.id == 1) && ["Admin"].indexOf(roles) > -1 ?
                                <Tooltip title="Edit" arrow>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        value={value}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(setEditMajor(value))
                                            dispatch(editMajorSchoolId(_schoolId))
                                            dispatch(editMajorSchoolName(_schoolName))
                                            handleOpenEditMajor()
                                        }}
                                    >
                                        <EditRounded/>
                                    </IconButton>
                                </Tooltip> : null}
                            <Tooltip title="Delete" arrow>
                                <IconButton
                                    color="error"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteMajor(value, name);
                                    }}
                                >
                                    <DeleteRounded/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    );
                },
            },
        },
    ];

    const majorTableTabOpen = useAppSelector((store) => store?.school.majorTableTabOpen);

    function handleCloseAddMajor() {
        dispatch(setMajorTabState(false));
    }

    function handleOpenAddMajor() {

        dispatch(setMajorTabState(true));
    }

    return (<>
        <MUIDataTable
            title={title}
            data={majors?.data as any}
            columns={columns}
            options={options}
            components={{
                TableFilterList: (props) => <TableFilterList {...props} />,
                TableToolbar: useCallback((props: MUIDataTableToolbar) => {
                    return (<>
                        <TableToolbar {...props} />
                        <Box
                            sx={{
                                display: "flex", alignItems: "center", justifyContent: "flex-end", mx: 3, my: 1,
                            }}
                        >
                            {(type?.id == 1 || type?.id == 2) && ["Admin"].indexOf(roles) > -1 ?
                                <Stack gap={1} direction="row">
                                    <Button

                                        startIcon={<AddRounded/>}
                                        variant="contained"
                                        onClick={handleOpenAddMajor}
                                    >
                                        Add Major
                                    </Button>
                                </Stack> : null}
                        </Box>
                    </>);
                }, []),
                icons: {
                    FilterIcon: FilterAltRounded as unknown as ReactNode,
                    ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
                },
            }}
        />

        <AddMajor
            schoolRegionId={schoolRegionId}
            setting={setting}
            open={majorTableTabOpen}
            handleClose={handleCloseAddMajor}
        />

        <EditMajor
            setting={setting}
            open={openEditMajor}
            handleClose={handleCloseEditMajor}
        />
    </>);
};

export default MajorTable;
