
import { useQuery } from 'react-query';
import {getProgramByName} from "../requests/program.request";
import {useCallback} from "react";
import {ProgramResponseType} from "../types";
import flat from "flat";


export const useSearchProgramQuery = (str: string, schoolId: any) => {
    return useQuery(['program', schoolId?.status, schoolId?.schoolId , schoolId?.pageSize, schoolId?.page, str], ({ signal }) => getProgramByName(str, signal, schoolId, schoolId?.status), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: useCallback((data: ProgramResponseType) => {
            return (
                {
                    ...data,
                    data: data.data.map(el => ({ ...el, ...flat(el, { safe: true }) as object })) // flatten the object // not type-safe
                }
            )
        }, []),
    });
}
