import {GetHistoryLogsResponseType} from "../types";
import api from "./config";

export const getHistoryLog = async (params: {
    schoolId?: string | number | undefined;
    page?: string | number;
    search?: string;
    dateStart?: string | undefined;
    pageSize?: string | number;
    dateEnd?: string | undefined
}) => {
    const res = await api.get<GetHistoryLogsResponseType>(`/api/logHistory`, {
        params: {
            ...params.schoolId && {schoolId: params.schoolId},
            ...params.search && {search: params.search},
            ...params.dateStart && {dateStart: params.dateStart},
            ...params.dateEnd && {dateEnd: params.dateEnd},
            ...params.page && {page: params.page},
            ...params.pageSize && {pageSize: params.pageSize},
        }
    });
    return res?.data;
}
