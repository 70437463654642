import React, {useCallback} from 'react';
import {Icon, ListItemIcon, Menu, MenuItem} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../redux/store';
import {useHistory} from 'react-router-dom';
import {onLogout} from '../../../redux/features/auth';
import {AccountCircleRounded, LightModeRounded, LogoutRounded, ModeNightRounded} from '@mui/icons-material';
import {useThemeModeContext} from '../../../contexts/ThemeModeContext';

interface MobileMenuProps {
	isMenuOpen: boolean;
	handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const MobileMenu = ({ isMenuOpen, handleMenuOpen, handleMenuClose, anchorEl }: MobileMenuProps) => {
    const theme = useTheme();
    const {toggleThemeMode} = useThemeModeContext()
    const dispatch = useDispatch();
    const authStore = useSelector((store: RootState) => store.auth)
    const history = useHistory();

    const handleSignOutClick = useCallback(async () => {
		const localStorageRefreshToken = localStorage.getItem('session');
		if (localStorageRefreshToken) {
			var refreshToken = JSON.parse(localStorageRefreshToken);
			refreshToken.refreshToken = ""
			refreshToken.isAuth = false
			refreshToken.token = ""
			localStorage.setItem("session", JSON.stringify(refreshToken));
		}
		const cacheName = 'v18'; // Replace with your actual cache name
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			try {
				await navigator.serviceWorker.controller.postMessage({action: 'clearCache', cacheName});
				console.log('Service worker cache cleared successfully.');
			} catch (error) {
				console.error('Error clearing service worker cache:', error);
			}
		}
		dispatch(onLogout());
	}, []);


    const pushTo = useCallback((path: string) => {
        history.push(path);
    }, [history]);
    return (
        <Menu
            anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id='primary-search-account-menu-mobile'
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{/* <Box sx={{ textAlign: 'center' }}> */}

			{/* <MenuItem onClick={handleMenuClose}>
					<Messages total={15} disableTooltip />
					Messages
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<Notifications total={20} disableTooltip />
					Notifications
				</MenuItem> */}
			<MenuItem onClick={() => pushTo('/account-settings')}>
				<ListItemIcon >
					<AccountCircleRounded />
				</ListItemIcon>
				{authStore.user?.userName}
			</MenuItem>
			<MenuItem onClick={toggleThemeMode}>
				<ListItemIcon >
					<Icon component={theme.palette.mode === 'light' ? LightModeRounded : ModeNightRounded} />
				</ListItemIcon>
				Toggle Theme
			</MenuItem>
			<MenuItem
				onClick={handleSignOutClick}
				sx={{
					color: 'error.main'
				}}
			>
				<ListItemIcon >
					<LogoutRounded color='error' />
				</ListItemIcon>
				Sign Out
			</MenuItem>
			{/* </Box> */}
		</Menu>
	);
};
