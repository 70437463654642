import { Box, CircularProgress } from '@mui/material'
import { useIsFetching } from 'react-query'

export default function GlobalLoadingIndicator() {
    const isFetching = useIsFetching()

    return (
        <Box sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'fixed',
            top: 80,
            right: 20
        }}>
            {
                isFetching
                    ? <CircularProgress color='info' thickness={5} />
                    : null
            }
        </Box>
    )


}