import * as Yup from "yup";
import { courseSchema, ICourseSchema } from "./course.schema";
import { curriculumSchema, ICurriculumSchema } from "./curriculum.schema";
import { enrollmentSchema, IEnrollmentSchema } from "./enrollment.schema";
import { IRecordSchema, recordSchema } from "./record.schema";
import { ISubjectSchema, subjectSchema } from "./subject.schema";

export interface IGradeSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  courseId: number;
  courseUuid?: string | null;
  course: ICourseSchema;
  enrollment: IEnrollmentSchema;
  enrollmentId?: number;
  enrollmentUuid?: string | null;
  record: IRecordSchema;
  recordId?: number | null;
  recordUuid?: string | null;
  yearStart?: string | null;
  yearEnd?: string | null;
  grades: string;
  remarks?: string | null;
  subjectId?: number | null;
  subjectUuid?: string | null;
  subject: ISubjectSchema;
  curriculumId?: number | null;
  curriculum: ICurriculumSchema;
}

export const gradeSchema: Yup.SchemaOf<IGradeSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  courseId: Yup.number().required("CourseId is required"),
  courseUuid: Yup.string().nullable(),
  course: Yup.lazy(() => courseSchema),
  enrollment: Yup.lazy(() => enrollmentSchema),
  enrollmentId: Yup.number().required("EnrollmentId is required"),
  enrollmentUuid: Yup.string().nullable(),
  record: Yup.lazy(() => recordSchema),
  recordId: Yup.number().nullable(),
  recordUuid: Yup.string().nullable(),
  yearStart: Yup.string().nullable(),
  yearEnd: Yup.string().nullable(),
  grades: Yup.string().required("Grades is required"),
  remarks: Yup.string().nullable(),
  subjectId: Yup.number().nullable(),
  subjectUuid: Yup.string().nullable(),
  subject: Yup.lazy(() => subjectSchema),
  curriculumId: Yup.number().nullable(),
  curriculum: Yup.lazy(() => curriculumSchema),
});

export type GradeType = Yup.InferType<typeof gradeSchema>;
