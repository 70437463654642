import Axios from 'axios';
import axios, {AxiosRequestConfig} from 'axios';
import {LevelResponseType,} from "../types"
import api from "./config"

let cancelToken = Axios.CancelToken.source()
export const getLevels = async (params: AxiosRequestConfig['params']) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get<LevelResponseType>("/levels", {
        ...{cancelToken: cancelToken?.token},
        params
    })
    return res?.data
}

type LevelData = {
    name: string
}

export const getLevelByName = async (search: string, signal: AxiosRequestConfig["signal"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/levels`, {
            signal, ...{
                params: {
                    ...{cancelToken: cancelToken?.token},
                    ...(search && {search: search}),
                    pageSize: 30
                }
            }
        }
    );
    return res?.data
}
export const getLevel = async (signal: AxiosRequestConfig["signal"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/levels`, {
            signal, ...{
                params: {
                    ...{cancelToken: cancelToken?.token},
                    pageSize: 30
                }
            }
        }
    );
    return res?.data
}
