import axios, {AxiosRequestConfig} from 'axios';
import {ProgramResponseType, ProgramSelectResponseType} from "../types"
import api from "./config"

let cancelToken = axios.CancelToken.source();
export const getPrograms = async (params: AxiosRequestConfig['params']) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api.get<ProgramResponseType>("/programs", {
        ...{cancelToken: cancelToken?.token},
        params
    })
    return res?.data
}

type ProgramData = {
    name: string,
    majorId:  string | number | undefined | null,
    disciplineId:  string | number | undefined | null,
    levelId:  string | number | undefined | null,
    schoolId: string | number |undefined| null
}
export const getCountPrograms = async (schoolId: any) => {
    const res = await api.get("/programs/count", {
        params: (schoolId != null && schoolId)
    })
    return res?.data
}
export const postProgram = async (data:  ProgramData) => {
    const res = await api.post("/programs", {
        ...(data.name && {name: data.name}),
        ...(data.majorId && {majorId: data.majorId }),
        ...(data.disciplineId && {disciplineId: data.disciplineId }),
        ...(data.levelId && {levelId: data.levelId}),
        ...(data.schoolId && {schoolId: data.schoolId}),
    }, {
        params: {
            ...(data.schoolId != null && {schoolId: data.schoolId}),
        }

    })
    return res?.data
}


export const patchProgram = async (data: ProgramData, programId: number) => {
    const res = await api.patch(`/programs/${programId}`, {
        ...(data.name && {name: data.name}),
        ...(data.majorId && {majorId: data.majorId}),
        ...(data.disciplineId && {disciplineId: data.disciplineId}),
        ...(data.levelId && {levelId: data.levelId}),
        ...(data.schoolId && {schoolId: data.schoolId}),
    }, {
        params: {
            ...(data.schoolId != null && {schoolId: data.schoolId}),
        }

    })
    return res?.data
}

export const getProgramsSelect = async (params: AxiosRequestConfig['params']) => {
    const res = await api.get<ProgramSelectResponseType[]>("/programs/select", {params})
    return res?.data
}
export const isApprovedProgram = async (userId: number, isApproved: boolean) => {
    const res = await api.patch(`/programs/IsApproved/${userId}`, {}, {
        ...{
            params: {
                ...(isApproved != null && {isApproved: isApproved}),
            }
        }
    })
    return res?.data
}

export const changeStatus = async (programId: any, status: any, schoolId:any) => {
    const res = await api.patch(`programs/Change/Status/${programId}`, {}, {
        ...{
            params: {
                ...(status != null && {status: status}),
                ...(programId != null && {programId: programId}),
                ...(schoolId != null && {school: schoolId}),
            }
        }
    })
    return res?.data
}


export const deleteProgram = async (id: number) => {
    const res = await api.post(`/programs/${id}`)
    return res?.data
}

export const getProgramById = async (id: number) => {
    const res = await api.get(`/programs/${id}`)
    return res?.data
}
export const getProgramByName = async (search: string, signal: AxiosRequestConfig["signal"], schoolId: any, status?:string) => {

    const res = await api.get(`/programs`, {
            signal, ...{
                params: {
                    ...(status && {status: status}),
                    ...(schoolId && schoolId),
                    ...(search && {search: search}),
                }
            }
        }
    );
    return res?.data
}
