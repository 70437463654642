import * as React from 'react';
import {memo, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Alert, Autocomplete, Box, TextField} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {useQueryClient} from 'react-query';
import {setErrorSnackbar} from "../../helper/utils/helper";
import {postSchools} from "../../requests/school.request";
import {useRegionSelectQuery} from "../../hooks/useRegionQuery";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useAppSelector} from "../../redux/store";

const validationSchema = yup.object({
    schoolId: yup.string().required('School Institutional Code is required'),
    schoolName: yup.string().required('School Name is required'),
    schoolAddress: yup.string().required('School Address is required'),
    schoolRegionId: yup.number().required('School Region is required'),

})



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    open: boolean
    handleClose: () => void
}

const AddSchool = React.memo((props: IProps) => {

    const { open, handleClose } = props
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const {data: regions, isLoading} = useRegionSelectQuery()
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>(regions?.[0]);
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const userregionId = useAppSelector((store) => store?.auth?.user?.region?.id);
    const {schoolId, regionId} = useAddEntities();
    const initialValues = {
        schoolId: '',
        schoolName: '',
        schoolAddress: '',
        schoolRegionId:  regionId || "",
    }


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                var school = await postSchools(values)
                await queryClient.invalidateQueries("schools");
                setSnackbarStore({
                    message: school?.message?? "Successfully added school",
                    severity: school.succeeded ? "success" : "warning",
                    open: true,
                })
                formik.setFieldValue("schoolId", "")
                formik.setFieldValue("schoolName", "")
                formik.setFieldValue("schoolAddress", "")
                formik.setFieldValue("regionId", regionId)


                handleClose()
            } catch (error: any) {
                setErrorSnackbar(error, setSnackbarStore)
            }
        }
    })

    const handleSubmit = React.useCallback((event: any) => {
        formik.handleSubmit()

    }, [formik])


    useEffect(() => {

        if (userregionId && typeId == 2) {
            const _schools = (regions || []).find(c => ((c.id == userregionId)) ?? (c.value == (regionId ?? userregionId)));

            formik.setFieldValue("schoolRegionId", _schools?.id)
            setSelectedRegionId(_schools)
        } else {
            const _schools = regions?.[0];

            formik.setFieldValue("schoolRegionId", _schools?.id)
            setSelectedRegionId(regions?.[0])
        }

        return () => {
            setSelectedRegionId(0)
            formik.setFieldValue("schoolRegionId", 0)
        }
    },[regionId, regions])

    const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
    const roles = useAppSelector((store) => store?.auth?.user?.roles)
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            //keepMounted
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Add School</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    Please fill in the details of the school you want to add
                    <br />
                    Note: Fields with <strong>*</strong> are required
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}
                >
                    <TextField
                        label="School Institutional Code"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        name='schoolId'
                        value={formik.values.schoolId}
                        onChange={formik.handleChange}
                        error={formik.touched.schoolId && Boolean(formik.errors.schoolId)}
                        helperText={formik.touched.schoolId && formik.errors.schoolId}
                    />

                    <TextField
                        label="School Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        name='schoolName'
                        value={formik.values.schoolName}
                        onChange={formik.handleChange}
                        error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
                        helperText={formik.touched.schoolName && formik.errors.schoolName}
                    />
                    <TextField
                        label="School Address"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        name='schoolAddress'
                        value={formik.values.schoolAddress}
                        onChange={formik.handleChange}
                        error={formik.touched.schoolAddress && Boolean(formik.errors.schoolAddress)}
                        helperText={formik.touched.schoolAddress && formik.errors.schoolAddress}
                    />
                    <div style={{marginTop: 6}}>
                        <form onSubmit={(event) => event.preventDefault()}>
                            <>
                                <Autocomplete
                                    id={"Regionautocomplete"}
                                    disabled={isLoading || (typeId == 2 && (roles.toLowerCase() == "user" || roles.toLowerCase() == "admin"))}
                                    disableClearable
                                    size="small"
                                    defaultValue={selectedRegionId}
                                    value={selectedRegionId}
                                    onChange={(event: any, newValue: any) => {
                                        formik.setFieldValue("schoolRegionId", newValue?.id)
                                        if(newValue?.id){
                                            setSelectedRegionId( newValue);
                                        }

                                    }}
                                    getOptionLabel={(option) => {
                                        return option.name
                                    }
                                    }
                                    renderOption={(props, option) => (
                                        <li {...props}  id={"settingrgeion" + option.id} key={option.id}>
                                            <div  style={{flex: 1, display: "flex", flexDirection: "column" }}>
                                                { option.name }</div>
                                        </li>
                                    )}
                                    options={[ ...(regions || [])] || []}


                                    renderInput={(params) => (
                                        <TextField {...params} label="Select Region" />
                                    )}
                                />
                            </>
                        </form>
                    </div>

                 {/*   <Grid container gap={2} >
                        <Grid item flex={1}>
                            <TextField
                                label="Username"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                name='userName'
                                value={formik.values.userName}
                                onChange={formik.handleChange}
                                error={formik.touched.userName && Boolean(formik.errors.userName)}
                                helperText={formik.touched.userName && formik.errors.userName}
                            />
                        </Grid>
                        <Grid item flex={1}>
                            <TextField
                                label="Email"
                                type="email"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                name='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                    </Grid>

                    <Grid container gap={2} >
                        <Grid item flex={1}>

                            <TextField
                                label="Password"
                                type="password"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                name='password'
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item flex={1}>

                            <TextField
                                label="Confirm Password"
                                type="password"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                name='confirmPassword'
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                        </Grid>
                    </Grid>*/}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelSchool"} onClick={handleClose}>Cancel</Button>
                <Button id={"addSchool"} variant='contained' onClick={handleSubmit}>Add</Button>
            </DialogActions>
        </Dialog>
    )
})

export default memo(AddSchool)
