import {useCallback} from "react";
import {useQuery} from "react-query";
import {getStudentEvaluation} from "../requests/student.request";

export const useStudentEvaluationQuery = ({
                                              studentId,
                                              curriculumId,
                                              programId,
                                              schoolId,
                                              regionId
                                          }: {
    studentId: number | string;
    curriculumId?: number | string;
    programId?: number | string;
    schoolId?: number | string;
    regionId?: number | string;
}) => {
    return useQuery("studentEvaluation", async () => await getStudentEvaluation(studentId, schoolId, regionId), {
        select: useCallback((data: any) => {
            return data;
        }, []),
    });
};
