import {PaletteMode, Theme} from '@mui/material';
import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {COLOR_PRIMARY, COLOR_SECONDARY, DARK_MODE_THEME, LIGHT_MODE_THEME} from '../helper/utils/constants';

export const getAppTheme = (mode: typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME): Theme => {

	const getDesignTokens = (mode: PaletteMode) => ({
		components: {
			MuiTableHead:  {
		styleOverrides: {
			root: {
				th:{
				...(mode == "light" ? {backgroundColor:  "rgb(240, 240, 240)"} : {})
				}

			}
		}
	},
			MuiTableCell: {
				styleOverrides: {
					root: {
						cursor: "pointer"
					}
				}
			},
		},
		palette: {
			mode,
			...(mode === 'light'
				? {
					// palette values for light mode
					primary: {
						main: COLOR_PRIMARY,
					},
					secondary: {
						main: COLOR_SECONDARY,
					}
				}
				: {}),
		},
	});
	let theme = createTheme(getDesignTokens(mode));
	theme = responsiveFontSizes(theme);
	return theme;
};
