import * as yup from "yup";
import { ISchoolSchema, schoolSchema } from "./school.schema";

export interface IAcademicYearSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  schoolId?: number | null;
  school: ISchoolSchema;
  first: string;
  second: string;
  third?: string | null;
  remark?: string | null;
}

export const academicYearSchema: yup.SchemaOf<IAcademicYearSchema> = yup
  .object()
  .shape({
    id: yup.number().required("Id is required"),
    uuid: yup.string().nullable(),
    dateCreated: yup.string().nullable(),
    createdBy: yup.string().nullable(),
    lastModifiedDate: yup.string().nullable(),
    lastModifiedBy: yup.string().nullable(),
    schoolId: yup.number().nullable(),
    school: yup.lazy(() => schoolSchema),
    first: yup.string().required("First is required"),
    second: yup.string().required("Second is required"),
    third: yup.string().nullable(),
    remark: yup.string().nullable(),
  });

export type AcademicYearType = yup.InferType<typeof academicYearSchema>;
