import { SyntheticEvent } from "react";
import { useAppDispatch } from "./../redux/store";
import { setDeleteDialog } from "../redux/features/app";
import {useQueryClient} from "react-query";

export function useDelete(title: string, content: string, onDelete: any, refetch?: any) {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const handleDelete = (e: SyntheticEvent<HTMLButtonElement>, fullName?: string, customMessage?:string) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    }catch (e) {

    }
    const id = e?.currentTarget?.value;
    const name = e?.currentTarget?.name;
    dispatch(
      setDeleteDialog({
        open: true,
        data: {
          id: id,
          customMessage: customMessage,
          body: "Are you sure you want to delete: " + (fullName || ""),
          title: `${title || ""} ${name || ""}`,
          content: `${content || "user"}`,
            onDelete:  async () => {
              await onDelete(id)
              queryClient.invalidateQueries(content);
            },
          //   handleDelete: () => onDelete(id),
        },
      })
    );
  };
  return handleDelete;
}
