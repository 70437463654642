import {
    Alert, AlertTitle,
    Badge,
    Box,
    Breadcrumbs,
    Button, Collapse,
    Container, Grid, IconButton,
    Link,
    Menu,
    MenuItem,
    MenuProps,
    Paper,
    Stack, Tab, TextField,
    Typography,
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {APP_TITLE} from "../helper/utils/constants";
import {
    CancelRounded, Close,
    DownloadRounded,
    GrainRounded,
    ManageAccounts,
    SummarizeRounded,
    UploadRounded,
} from "@mui/icons-material";
import useUploadPromotionalReportDialog from "../components/dialogs/UploadPromotionalReport.dialog";
import {matchPath, useHistory, useParams} from "react-router-dom";
import PromotionalReportTable from "../components/Tables/PromotionalReportTable";
import EnrollmentTable from "../components/Tables/EnrollmentTable";
import {RootState, useAppSelector} from "../redux/store";
import IsExistingDialog from "../components/dialogs/IsExisting.dialog";
import React, {useEffect, useState} from "react";
import {alpha, styled} from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import UnsuccessfulPRTable from "../components/Tables/UnsuccessfulPRTable";
import {useLocation} from "react-router";
import {BookIcon, FilesIcon, LucideFileText, User2, Users2, Users2Icon} from "lucide-react";
import {useNotificationPromotionReportCountQuery} from "../hooks/useNotificationPromotionReportCountQuery";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
const EnrollmentPage = () => {
    const {
        ErrorComponent,
        Component: UploadDialog,
        handleClickOpen,
        handleDownloadPrTemplate,
        handleDownloadEnrollmentTemplate,
        handleClose,
        openIsExisting,
        handleCloseIsExisting,
        handleUpload,
        isExistingTitle,
        isExistingsData,
        isExistingsLocal,
        handleReset,
        handleCloseUploadError,
        setImportLabel,
        unSuccessfullyUpload,
        successfullyUpload,
        open1,
        open2,
        setOpen1,
        setOpen2
    } = useUploadPromotionalReportDialog();

  const history = useHistory<any>();
  const pageMatch = matchPath(history.location.pathname, {
    path: "/enrollments/:enrollmentId",
    exact: true,
  });
    const type = useAppSelector((store) => store?.auth?.user?.type);
    const roles = useAppSelector((store) => store?.auth?.user?.roles);


    const onSave = () => {
        handleCloseIsExisting()
        handleClickOpen()
        handleUpload()
    }

    const location = useLocation();
    useEffect(() => {
        var query = new URLSearchParams(location.search)
        const page = query.get('tab') || '1';
        setValue(page)
    }, [location.search]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handlehandleImportButtonCloseClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleImportButtonClose = () => {
        setAnchorEl(null);
    };


    const [anchorDownloadEl, setAnchorDownloadEl] = useState<null | HTMLElement>(null);
    const openDownload = Boolean(anchorDownloadEl);
    const handlehandleDownloadButtonCloseClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorDownloadEl(event.currentTarget);
    };
    const handleDownloadButtonClose = () => {
        setAnchorDownloadEl(null);
    };
    const [value, setValue] = React.useState('1');
    const [elpr, setElpr] = React.useState('el');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
const handleChangePR = (event: React.SyntheticEvent, newValue: string) => {
    setElpr(newValue);
    if(newValue == "el"){
        history.push(`/enrollments`, {

            title: `ENROLLMENT LIST`
        });
    }else{
        history.push(`/enrollments`, {

            title: `PROMOTIONAL REPORT`
        });
    }

    };


    const {enrollmentId} = useParams() as {
        enrollmentId: string
    };
    const { data: enrollmentList, isLoading:isEnrollmentListLoading } = useNotificationPromotionReportCountQuery({
        pageSize: 100,
        enrollmentId,
        header: "ENROLLMENT LIST"
    });
    const { data: promotionalList, isLoading: iPromotionalListLoading } = useNotificationPromotionReportCountQuery({
        pageSize: 100,
        enrollmentId,
        header: "PROMOTIONAL REPORT"
    });



    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

























    return (
        <>
            <Helmet>
                <title>Enrollment | {APP_TITLE}</title>
            </Helmet>

            <Box
                component="main"
                sx={{flexGrow: 1, pt: 10}}
        style={{ paddingLeft: " 20px", paddingRight: "20px" }}
      >
        <Container>
          <Stack
            sx={{ mb: 2 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
              <Box>
                  <Typography variant="h5" fontWeight="bold">
                      Enrollment
                  </Typography>

              </Box>

              <Box sx={{display: "flex", gap: 1}}>
                  {!(type?.id == 2 && (roles.toLowerCase() == "admin" || roles.toLowerCase() == "user")) ?
                      <Box>

                          <Button
                              id="download-button"
                          aria-controls={openDownload ? 'download-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openDownload ? 'true' : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handlehandleDownloadButtonCloseClick}
                          endIcon={<KeyboardArrowDownIcon/>}
                      >
                          Import
                      </Button>
                      <StyledMenu
                          id="download-menu"
                          MenuListProps={{
                              'aria-labelledby': 'download-button',
                          }}
                          anchorEl={anchorDownloadEl}
                          open={openDownload}
                          onClose={handleDownloadButtonClose}
                      >
                          <MenuItem onClick={() => {
                              setImportLabel("Import Enrollment")
                              handleClickOpen()
                              handleDownloadButtonClose()
                          }} disableRipple>
                              <UploadRounded/>
                              Import Enrollment
                          </MenuItem>
                          <MenuItem onClick={() => {
                              setImportLabel("Import Promotional Report")
                              handleClickOpen()
                              handleDownloadButtonClose()
                          }} disableRipple>
                              <UploadRounded/>
                              Import PR
                          </MenuItem>
                      </StyledMenu>

                  </Box> : null
              }
              {!(type?.id == 2 && (roles.toLowerCase() == "admin" || roles.toLowerCase() == "user")) ?

                  <>
                      <Button
                          id="import-button"
                          aria-controls={open ? 'import-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          variant="contained"
                          disableElevation
                          onClick={handlehandleImportButtonCloseClick}
                          endIcon={<KeyboardArrowDownIcon/>}
                      >
                          Download Template
                      </Button>
                      <StyledMenu
                          id="import-menu"
                          MenuListProps={{
                              'aria-labelledby': 'import-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleImportButtonClose}
                      >
                          <MenuItem onClick={() => {
                              handleDownloadEnrollmentTemplate()
                              handleImportButtonClose()
                          }} disableRipple>
                              <DownloadRounded/>
                              Download Enrollment template
                          </MenuItem>
                          <MenuItem onClick={() => {
                              handleDownloadPrTemplate()
                              handleImportButtonClose()
                          }} disableRipple>
                              <DownloadRounded/>
                              Download PR template
                          </MenuItem>
                      </StyledMenu>
                  </>

                  : null
              }

            </Box>

          </Stack>
          <Paper sx={{ mb: 2 }}>
            <Breadcrumbs sx={{ p: 2 }}>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/schools"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(history.location.state.from);
                }}
              >
                <SummarizeRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                Enrollments
              </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <GrainRounded sx={{ mr: 0.5 }} fontSize="inherit" />
                {history?.location?.state?.title}
              </Typography>


            </Breadcrumbs>
          </Paper>
            {successfullyUpload ?
                <Typography style={{paddingBottom: "6px"}} variant="subtitle2">
                    <Collapse in={open1}>
                    <Alert action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen1(false);
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    } severity="success">
                        <AlertTitle>Success</AlertTitle>
                        {successfullyUpload}
                    </Alert>
                    </Collapse>
                </Typography>  : null
            }
            {unSuccessfullyUpload ?
                <Typography style={{paddingBottom: "6px"}} variant="subtitle2">
                    <Collapse in={open2}>
                    <Alert action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen2(false);
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    } severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        {unSuccessfullyUpload}
                    </Alert>
                    </Collapse>
                </Typography> : null
            }

          {!!pageMatch ? (
              <Paper sx={{ mb: 2 }}>
                  <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab  iconPosition="start" icon={<BookIcon />} label="Enrollments List" value="1" />
                              <Tab   iconPosition="start" icon={<Badge badgeContent={(enrollmentList?.data as any) ?? 0} color="error">
                                  <LucideFileText />
                              </Badge>} label="Unsuccessful Enrollment" value="2" />
                              <Tab  iconPosition="start" icon={<Badge badgeContent={(promotionalList?.data as any) ?? 0} color="error">
                                  <FilesIcon />
                              </Badge>} label="Unsuccessful PR" value="3" />
                          </TabList>
                      </Box>
                      <TabPanel value="1">
                          <EnrollmentTable title="Enrollments List"/>
                      </TabPanel>
                      <TabPanel value="2">
                          <UnsuccessfulPRTable title="ENROLLMENT LIST" />
                      </TabPanel>
                      <TabPanel value="3">
                          <UnsuccessfulPRTable title="PROMOTIONAL REPORT" />
                      </TabPanel>
                  </TabContext>
              </Paper>

          ) : (
              <Paper sx={{ mb: 2 }}>
                  <TabContext value={elpr} >
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList  onChange={handleChangePR} aria-label="lab API tabs example">
                              <Tab  icon={<Users2Icon/>} iconPosition="start" label="ENROLLMENT LIST" value="el" />
                              <Tab   icon={<FilesIcon/>} iconPosition="start" label="PROMOTIONAL REPORT" value="pr" />
                          </TabList>
                      </Box>
                      <TabPanel value="el">
                          <PromotionalReportTable type="ENROLLMENT LIST" title="Enrollment List"/>

                      </TabPanel>
                      <TabPanel value="pr">
                          <PromotionalReportTable type="PROMOTIONAL REPORT" title="Promotional Report List"/>
                      </TabPanel>
                  </TabContext>
              </Paper>

          )}
        </Container>
        </Box>
        <IsExistingDialog
            data={isExistingsData}
            local={isExistingsLocal}
            open={openIsExisting}
            handleClose={() => {
              handleReset()
              handleCloseIsExisting()
            }}
            onSave={onSave}
            title={isExistingTitle}
        />
        {UploadDialog}
        {ErrorComponent}
      </>
  );
};

export default EnrollmentPage;
