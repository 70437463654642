import * as React from "react";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, Box, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useAddEntities} from "../../hooks/useAddEntities";
import {RootState, useAppSelector} from "../../redux/store";
import {useMutation, useQueryClient} from "react-query";
import {SaveRounded} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {Transition} from "../../wrapper/Transition";
import {patchMajor} from "../../requests/major.request";
import {MajorInputType} from "../../schemas/major.schema";
import {useGetMajor} from "../../hooks/useGetMajorQuery";

const validationSchema = yup.object({
    name: yup.string().required("Major Name is required"),
});

const initialValues = {
    schoolId: "", id: "", code: "", name: "",
};

interface IProps {
    open: boolean;
    handleClose: () => void;
    setting?: boolean;
}

const EditMajor = (props: IProps) => {
    const {open, handleClose, setting} = props;
    const dispatch = useDispatch();
    const [school, setSchool] = useState<any>()

    const editMajorsId = useAppSelector((store) => store?.major?.editMajorsId);
    const editMajorSchoolId = useAppSelector((store) => store?.major?.editMajorSchoolId);
    const editMajorSchoolName = useAppSelector((store) => store?.major?.editMajorSchoolName);

    const {data: major} = useGetMajor(editMajorsId);


    useEffect(() => {
        if (major?.name) {
            formik.setFieldValue("name", major.name ?? "", true);
        }
        if (major?.id) {
            formik.setFieldValue("id", major.id ?? "", true);
        }

        if (major?.code) {
            formik.setFieldValue("code", major.code ?? "", true);
        }

    }, [major])

    const queryClient = useQueryClient();

    const {mutate: updateMajor, isLoading} = useMutation({
        mutationKey: "updateMajor", mutationFn: async (value: MajorInputType) => {
            return await patchMajor(value)
        }
    });

    const {schoolId, regionId} = useAddEntities();
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        onSubmit: async (values, actions) => {

            values.schoolId = editMajorSchoolId ? editMajorSchoolId : (schoolId ? schoolId : school?.id)
            void updateMajor(values as unknown as MajorInputType, {
                onSuccess: (res) => {

                    if (res?.message) {
                        var __message = res?.data?.name + " already exists in our system. Please try a different one."
                        setSnackbarStore({
                            message: res.message, severity: __message == res.message ? "error" : "success", open: true,
                        })
                    } else {
                        setSnackbarStore({
                            message: "Successfully update major", severity: "success", open: true,
                        });
                    }
                    queryClient.setQueriesData(['majors'], (oldData: any) => {
                        return {
                            ...oldData, data: oldData.data.map((t: any) => {
                                var _major = {...t}
                                if (values?.id == t?.id) {
                                    _major.name = values.name
                                    _major.code = values.code
                                }
                                return _major
                            })
                        }
                    })
                    handleClose();
                }, onError: (res) => {
                    setSnackbarStore({
                        message: "Failed to add major", severity: "error", open: true,
                    });
                },
            });
        },
    });

    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit();
    }, [formik]);
    const authStore = useSelector((store: RootState) => store.auth);

    return (<Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle>Edit Major <p
            style={{
                margin: 0, fontSize: 12, fontStyle: "italic"
            }}>({authStore?.user?.school?.name ?? editMajorSchoolName ?? ""})</p>
        </DialogTitle>
        <DialogContent>
            <Alert severity="info">
                Please fill in the details of the major you want to add
                <br/>
                Note: Fields with <strong>*</strong> are required
            </Alert>
            <Box
                sx={{
                    display: "flex", flexDirection: "column", mb: 3,
                }}
            >

                <TextField
                    label="Major Name"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                    id="majorName"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik?.touched?.name && Boolean(formik?.errors?.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                    label="Major Code"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                    id="majorCode"
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    error={formik?.touched?.code && Boolean(formik?.errors?.code)}
                    helperText={formik.touched.code && formik.errors.code}
                />

            </Box>

        </DialogContent>

        <DialogActions>
            <Button id={"cancelMajor"} onClick={handleClose}>Cancel</Button>
            <LoadingButton
                loading={isLoading}
                disabled={formik.values.name.length < 1}
                id={"loadingAdding"}
                loadingPosition="start"
                variant="contained"
                startIcon={<SaveRounded/>}
                onClick={handleSubmit}
            >
                {isLoading ? "Updating" : "Update"}
            </LoadingButton>
        </DialogActions>
    </Dialog>);
};

export default EditMajor;
