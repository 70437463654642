import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import {COLOR_SECONDARY} from "../../helper/utils/constants";
import React, {useMemo, useState} from "react";
import {CloseRounded} from "@mui/icons-material";
import {EnrollmentType} from "../../types";
import {useUploadQuery} from "../../hooks/useUploadQuery";
import {FormSheetField} from "../FormSheetField";
import {StyledTableRow} from "../Table/StylesTableRow";
import {StyledTableCell} from "../Table/StylesTableCell";
// import { useStudent } from "../../hooks/useStudent";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
import _ from "lodash";
import {alpha} from "@mui/material/styles";

interface IStudentInfoDialogProps {
  open: boolean;
  handleClose: () => void;
  student: EnrollmentType | undefined;
}

const StudentInfoDialog = ({
  open,
  handleClose,
  student,
}: IStudentInfoDialogProps) => {



  const { data: uploadedData, isLoading } = useUploadQuery({ pageSize: 100 });
  const [value, setValue] = React.useState(0);
  const [semesterValue, setSemesterValue] = React.useState(0);
  const emptyCourseForm = {
    id: "",
    courseCode: "",
    courseDescription: "",
    noOfUnits: "",
    grades: "",
    remarks: "",
  };

  const [coursesForm, setCoursesForm] = useState([emptyCourseForm]);

  // const handleAddCourse = useCallback(() => {
  //   setCoursesForm((coursesForm) => [...coursesForm, emptyCourseForm]);
  // }, []);

  // const handleRemoveCourse = useCallback(
  //   (i: number) => {
  //     let clonedForm = [...coursesForm];
  //     clonedForm.splice(i, 1);
  //     setCoursesForm(clonedForm);
  //   },
  //   [coursesForm]
  // );

  // const handleChangeCourseForm = useCallback(
  //   (i: number, e: any) => {
  //     let clonedForm = [...coursesForm];
  //     type key = keyof typeof emptyCourseForm;
  //     clonedForm[i][e.target.name as key] = e.target.value;
  //     setCoursesForm(clonedForm);
  //   },
  //   [coursesForm]
  // );
  // const handleChange = (event: SelectChangeEvent) => {
  //   setSemesterValue(0);
  //   setValue(+event.target.value);
  // };
  // const handleSemesterChange = (event: SelectChangeEvent) => {
  //   setSemesterValue(+event.target.value);
  // };
  var studentMemo = useMemo(() => {
    const groupByObject: any = {};
    student?.records?.forEach((r: any) => {
      return r?.grades.forEach((g: any) => {
        var res = g.curriculum?.name ?? "";
        if (res?.trim()) {
          groupByObject[res] = (groupByObject[res] || []).concat({
            ...g,
          });
        }
      });
    });
    const propertyNames = Object.keys(groupByObject);

    var groupByProgram = groupByObject[propertyNames[value]] ?? [];

    var result = _.groupBy(groupByProgram, function (item) {
      return item.curriculum?.program?.name ?? "";
    });

    var major = _.groupBy(groupByProgram, function (item) {
      return item.curriculum?.program?.major?.name ??  "";
    });

    const coursePropertyNames = Object.keys(result ?? {}) ?? {};
    const majorPropertyNames = Object.keys(major ?? {}) ?? {};

    const groupByProgramCourse = result?.[coursePropertyNames?.[semesterValue]];
    return {
      propertyNames,
      groupByProgram,
      coursePropertyNames,
      groupByProgramCourse,
      majorPropertyNames,
    };
  }, [student, semesterValue, value]);



  const StudentInfoTable = (props: any) => {
    let columns = [
      {id: "code", label: "COURSE CODE", minWidth: 170, align: "left"},
      {id: "description", label: "DESCRIPTION", minWidth: 170, align: "left"},
      {id: "noOfUnits", label: "NO. OF UNITS", minWidth: 170, align: "left"},
      {id: "grades", label: "GRADES", minWidth: 170, align: "left"},
      {id: "remarks", label: "REMARKS", minWidth: 170, align: "left"},
    ];
    const mappedColor = {
      PASSED: "#62C36F",
      FAILED: "#EE1519",
      INCOMPLETE: "#F5672B",
      DROPPED: "#EE1519",
      undefined: "#ffffff",
    };

    return (
        <div>
          <TableContainer sx={{borderRadius: 2}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  {columns.map((column) => (
                      <StyledTableCell
                          key={column.id}
                          style={{minWidth: column.minWidth}}
                      >
                        {column.label}
                      </StyledTableCell>
                  ))}
                </StyledTableRow>
            </TableHead>
            <TableBody>
              {props?.records?.map((row: any) => {

                return (
                  <StyledTableRow hover role="checkbox" key={row.id}>
                    {columns.map((column) => {

                      const subjectUnits = row?.subject?.["subjectUnits"]?.find(() => true)
                      const mappedValue = {
                        code: row?.subject?.["courseCode"],
                        description: row?.subject?.["description"],
                        noOfUnits: (+subjectUnits?.unit?.lecture ?? 0) + (+subjectUnits?.unit?.laboratory ?? 0) ,
                        grades: row.grades,
                        remarks: row.remarks,
                      };
                      const value =
                        mappedValue[column.id as keyof typeof mappedValue];
                      return (
                        <StyledTableCell
                          key={column.id}

                          sx={{
                            p: 1,
                            bgcolor: alpha(
                                mappedColor?.[
                                    ["PASSED", "FAILED", "INCOMPLETE", "DROPPED", "undefined"].indexOf(row.remarks as any) > -1 ?
                                        ((row.remarks ??
                                            "undefined") as keyof typeof mappedColor) : "undefined"
                                    ],
                                0.25
                            ),
                          }}
                        >
                          {value}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
              {
                // show empty row if no data
                (props?.records ?? []).length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={5} align="center">
                      Sorry, No Records Found.
                    </StyledTableCell>
                  </StyledTableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const StudentInfo = () => {
    return (
      <Grid container>
        <Grid item xs={12} md={3}>
          <Stack
            gap={2}
            sx={{
              backgroundColor: COLOR_SECONDARY,
              mr: 2,
              padding: 3,
              borderRadius: 2,
              maxHeight: 500,
              overflow: "auto",
            }}
          >
            <FormSheetField
              label="Academic Year"
              value={uploadedData?.schoolYear ?? ""}
            />
            <FormSheetField
              label="Course"
              value={studentMemo.coursePropertyNames[value] ?? ""}
            />
            <FormSheetField
              label="Major"
              value={studentMemo.majorPropertyNames[value] ?? ""}
            />
            <FormSheetField
              label="Year Level"
              value={student?.yearLevel ?? ""}
            />
            <FormSheetField
              label="STUDENT NO."
              value={student?.student.studentNumber ?? ""}
            />
            <FormSheetField
              label="LAST Name"
              value={student?.student?.lastName ?? ""}
            />
            <FormSheetField
              label="First Name"
              value={student?.student?.firstName ?? ""}
            />
            <FormSheetField
              label="MIDDLE Name"
              value={student?.student?.middleName ?? ""}
            />
            <FormSheetField
              label="EXT. Name"
              value={student?.student?.extName ?? ""}
            />
            <FormSheetField label="Sex" value={student?.student?.sex} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          {/* <div style={{ paddingBottom: 20 }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Curriculums
                </InputLabel>
                <Select
                  onChange={handleChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Curriculums"
                  value={value?.toString()}
                >
                  {studentMemo.propertyNames.map((c, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {c}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="demo-simple-select-label">Programs</InputLabel>
                <Select
                  onChange={handleSemesterChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Programs"
                  value={semesterValue?.toString()}
                >
                  {studentMemo.coursePropertyNames.map((c, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {c}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div> */}
          <StudentInfoTable records={studentMemo?.groupByProgramCourse} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open={open} maxWidth="xl" fullWidth onClose={handleClose}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        component="div"
      >
        <Typography variant="h6" fontWeight="bold">
          Student Information
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <StudentInfo />
      </DialogContent>
      <DialogActions>
        <Button id={"cancelschoolInfoDetail"} onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StudentInfoDialog;
