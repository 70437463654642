import * as React from "react";
import {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {LoadingButton} from "@mui/lab";
import {Transition} from "../../wrapper/Transition";

interface IProps {
    open: boolean;
    handleClose: () => void;
    title: string,
    onSave: () => void
}

const AddCloseConfirmation = React.memo((props: IProps) => {
    const {open, onSave, handleClose, title} = props;


    const [names, setName] = useState<{ id: number, courseCode: string }[]>([])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={handleClose}
            maxWidth="sm"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                style={{fontSize: 16}}>{title ?? "Do you want to save the changes you made?"}</DialogTitle>
            {/*<DialogContent>

                <p style={{margin: 0, fontStyle: "italic"}}>Do you want to save the changes you made?</p>

            </DialogContent>*/}
            <DialogActions>
                <Button color={"error"} id={"cancelCourse"} onClick={handleClose}>Cancel</Button>
                <LoadingButton id={"AddConformation"} onClick={() => {
                    onSave()
                }}
                >
                    Ok
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
});

export default AddCloseConfirmation;
