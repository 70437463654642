import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useCallback } from "react";
import { AxiosError } from "axios";
import { setDeleteDialog, setLoading } from "../../redux/features/app";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ISnackbarSlice, openSnackbar } from "../../redux/features/snackbar";
import { useQueryClient } from "react-query";

const DeleteDialog = () => {
  const deleteDialogState = useAppSelector((state) => state.app.deleteDialog);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const handleClose = useCallback(() => {
    dispatch(
      setDeleteDialog({
        open: false,
      })
    );
  }, []);

  const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);
  const handleDelete = async () => {

    if (deleteDialogState?.data?.id) {
      try {
        dispatch(setLoading(true));
        const res = await deleteDialogState?.data.onDelete();


        queryClient.setQueriesData(
            `${deleteDialogState?.data?.content}`,
            (oldData: any) => {
              return {
                ...oldData,
                data: oldData.data.filter(
                    (t: any) => t.id !== deleteDialogState?.data?.id
                ),
              };
            }
        );
        await queryClient.invalidateQueries("user");
        setSnackbarStore({
          message: deleteDialogState?.data?.customMessage ?? res?.response?.message ?? res?.message ?? res?.data?.message ?? "You have successfully deleted",
          severity: "success",
          open: true,
        });
      } catch (error) {
        console.error({ error });
        if (error instanceof AxiosError) {
          setSnackbarStore({
            open: true,
            message: error?.response?.data?.message,
            severity: "error",
          });
        }
      } finally {
        dispatch(setLoading(false));
        handleClose();
      }
    }
  };

  return (
    <Dialog
      open={deleteDialogState?.open ?? false}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {deleteDialogState?.data?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: "error.main" }}>
          {deleteDialogState?.data?.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button id={"cancelDelete"} color="inherit" onClick={handleClose}>
          Cancel
        </Button>
        <Button id={"addDelete"} variant="outlined" color="error" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteDialog;
