import api from "./config";

export const postSONumber = async (id: number, soNumber: string, so: string) => {
    const res = await api.post(`/special-orders/SONumber/${id}`, {}, {
            params: {
                so: soNumber,
                soNumber: so
            }
        }
    );
    return res?.data;
};
