import api from "./config";
import axios from "axios";
import Axios from "axios";

let cancelToken = Axios.CancelToken.source()
export const getRequirements = async (search?:string) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get("/special-order-requirements", {
        params: {
            ...(search && {SearchKey: search})
        },
        cancelToken: cancelToken?.token
    });

    return res?.data;
};

export const postRequirements = async (body: {
    "name": string,
    "isRequired": boolean
}) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api.post(`/special-order-requirements`, {
        ...{cancelToken: cancelToken?.token},
        ...body
    });
    return res?.data;
};

export const patchRequirements = async (body: {
    "id": number | string | undefined,
    "name": string,
    "isRequired": boolean
}) => {
    if (!body?.id) return
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api.patch(`/special-order-requirements/${body?.id}`, {
        ...{cancelToken: cancelToken?.token},
        ...body
    });
    return res?.data;
};

export const getSoRequirements = async (programId: any, schoolId: any) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api({
        url: `/school-program-so-requirements`,
        method: "GET",
        cancelToken: cancelToken?.token,
        params: {
            programId,
            schoolId
        }
    });

    return res?.data;
};


export const getStudentSoRequirements = async (programId: any, schoolId: any, studentNumber?: string | number | undefined, majorId?: number | string | undefined, id?: number | string | undefined) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api({
        url: `/file-uploads/${schoolId}/${studentNumber}/special-order-requirement-files`,
        method: "GET",
        cancelToken: cancelToken?.token,
        params: {
            programId,
            schoolId,
            majorId,
            isDeleted: false,
            studentId: id
        }
    });

    return res?.data;
};
export const deleteStudentSoRequirements = async (programId: any, schoolId: any, studentNumber?: string | number | undefined, majorId?: number | string | undefined, id?: string | number | any) => {
    const res = await api({
        url: `/file-uploads/${schoolId}/${studentNumber}/special-order-requirement-files/${id}`,
        method: "patch",
        params: {
            programId,
            schoolId,
            majorId,
            studentId: id
        }
    });

    return res?.data;
};

export const deleteSchoolProgramSoRequirements = async (id: any) => {

    const res = await api({
        url: `/special-order-requirements/${id}`,
        method: "DELETE",
    });

    return res?.data;
};

export const deleteSchoolProgramSoRequirements__ = async (id: any) => {
    /*DELETE
    /school-program-so-requirements/{id}*/
    const res = await api({
        url: `/school-program-so-requirements/${id}`,
        method: "DELETE",
    });

    return res?.data;
};

export interface IPostFileUploadsSpecialOrderRequirementFiles {
    schoolId?: number,
    programId?: number | string,
    studentNumber: string | number | undefined,
    specialOrderRequirementId: string | number | undefined,
    specialOrderRequirementsName: string | number | undefined,
    fileUploadType?: number,
    formFiles?: any[],
    uploadedBy?: string | undefined
    majorId?: string | number | undefined,
    studentId?: string | number | undefined
}

export const PostFileUploadsSpecialOrderRequirementFiles = async (postFiles: IPostFileUploadsSpecialOrderRequirementFiles) => {
    const formData = new FormData();
    postFiles?.formFiles?.forEach(c => {
        if (c.id == null) {
            formData.append("FormFiles", c);
        }
    })
    formData.append("fileUploadType", `${1}`);
    formData.append("uploadedBy", postFiles?.uploadedBy ?? "");

    const res = await api.post(`/file-uploads/${postFiles.schoolId}/${postFiles.studentNumber}/special-order-requirement-files`, formData, {
        params: {
            programId: postFiles.programId,
            majorId: postFiles.majorId,
            specialOrderRequirementId: postFiles.specialOrderRequirementId,
            studentId: postFiles.studentId
        },
        headers: {
            "Content-Type": "multipart/form-data"
        },
    });

    return res?.data;
};

export const schoolProgramSoRequirements = async (params: {
    "sorId": number | string | undefined,
    "schoolId": number | string | undefined,
    "programId": number | string | undefined
}) => {
    const res = await api.post(`/school-program-so-requirements`, params);

    return res?.data;
};
