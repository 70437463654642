import Axios from "axios";
import axios, {AxiosRequestConfig} from "axios";
import api from "./config";

let cancelToken = Axios.CancelToken.source()
export const getWorkspaces = async (params: AxiosRequestConfig["params"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get("/workspaces", {
        ...{cancelToken: cancelToken?.token},
        params
    });
    return res?.data;
};
export const getWorkspaceById = async (id: number | string) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/workspaces/${id}`, {
        ...{cancelToken: cancelToken?.token}
    });
    return res?.data;
};
interface Workspace {
    id: string;
    name: string;
}

export const createWorkspace = async (name: string): Promise<Workspace> => {
    const response = await api.post<Workspace>("/workspaces", { name });
    return response.data;
};
export const updateWorkspace = async (id:  string, name: string): Promise<Workspace> => {
    const response = await api.put<Workspace>(`/workspaces/${id}`, { id, name });
    return response.data;
};

export const deleteWorkspace = async (id:  string) => {
    const response = await api.post(`/workspaces/${id}`);
    return response.data;
};