// InviteMemberModal.js
import React, {useCallback, useState} from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box } from '@mui/material';
import SearchMajorTextField from "../SearchTextField/SearchMajorTextField";
import SearchUserTextField from "../SearchTextField/SearchUserTextField";
import {SearchMajorResponseType} from "../../types";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useMutation, useQueryClient} from 'react-query';
import {addMemberToWorkspace} from "../../requests/member.request";

interface IInviteMember {
    joinCode: string;
    name: string;
    open: boolean;
    setOpen: any,
    workspaceId: number
}

const InviteMemberModal = ({ joinCode, workspaceId,name, open, setOpen  }: IInviteMember) => {


    const [defaultValue, setDefaultValue] = useState<any>({id: 0})
    const handleInvite = async () => {

        addMemberMutation.mutate({workspaceId, userId: defaultValue.userId})
    };
    const handleOnSelectedValue = useCallback((value: any) => {
        setDefaultValue(value)
    }, [])

    const onClose = () => {
        setOpen(false)
    }
    const [error, setError] = useState("")
    const queryClient = useQueryClient();
    const addMemberMutation = useMutation(
        (variables:any) => addMemberToWorkspace(variables.workspaceId, variables.userId),
        {
            onSuccess: () => {
                console.log(workspaceId, "workspaceId")
                queryClient.invalidateQueries(['members']);
                onClose();
                setError("");
            },
            onError: (error) => {
                console.error('Failed to add member:', error);
                setError('Failed to send invitation. Please try again.');
            }
        }
    );

    const {schoolId, regionId} = useAddEntities();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Invite people to {`${name}`}</DialogTitle>
            <DialogContent>
                <SearchUserTextField
                    label='User'
                    defaultValue={defaultValue}
                    onSelected={handleOnSelectedValue}
                    schoolId={schoolId}
                    />
                {error && <Box color="red" mt={2}>{error}</Box>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleInvite} color="primary" variant="contained" disabled={addMemberMutation.isLoading}>
                    {addMemberMutation.isLoading ? 'Inviting...' : 'Send Invite'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InviteMemberModal;
