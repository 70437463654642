import {useEffect, useState} from 'react';
import {Collapse, Divider, List} from '@mui/material';

import {RouteItem} from './RouteItem';

import {routes} from '../../../config';
import {Route} from '../../../types';
import {useAppSelector} from "../../../redux/store";
import jwt_decode from "jwt-decode";

export const Routes = () => {
	const roles = useAppSelector((store) => store?.auth?.user?.roles)
	const token = useAppSelector((store) => store?.auth?.token)
	var decoded: any = jwt_decode(token);
	const [routesState, setRoutesStage] = useState<Route[]>(routes.filter(c => (roles != null && (c?.roles?.indexOf(roles.toLowerCase()) != -1))) );

	useEffect(() => {


		if (decoded?.UserApplications) {
			var userApplications = JSON.parse(decoded?.UserApplications)

			if (JSON.parse(decoded?.UserApplications)) {

				var _userApp = userApplications.filter((c: any) => c.IsEnabled).map((c: any) => c.Name)
				if (_userApp?.length > 0) {
					if (_userApp.findIndex((c: string) => c == "Special Order") == -1) {
						_userApp.push("Special Order")
					}

					setRoutesStage(routes.filter(c => (_userApp.indexOf(c.title) > -1)))
				}

			}
		} else {
			var _routesState = routesState
			if (_routesState.findIndex((c: any) => c.key == "specialOrder") == -1) {
				_routesState.push({
					key: "specialOrder",
					isEnabled: true, title: "Special Order"
				})
			}

			setRoutesStage(_routesState)
		}
	}, [])


	const handleMenuClick = (route: Route) => {
		const items = routesState.map((item) => {
			if (item.key === route.key) {
				item.expanded = !item.expanded;
			}
			return item;
		});
		setRoutesStage(items);
	};

	return (
		<>
			<List component='nav' sx={{ height: '100%' }}>
				{routesState.map((route: Route) => {
					if (route.hidden) return null;
					return (
						<div key={route.key}>
							{route.subRoutes ? (
								<>
									<RouteItem key={`${route.key}`} route={route} hasChildren handleMenuClick={handleMenuClick} />
									<Collapse in={route.expanded} timeout='auto' unmountOnExit>
										<List component='div' disablePadding>
											{route.subRoutes.map((sRoute: Route) => (
												<RouteItem key={`${sRoute.key}`} route={sRoute} nested />
											))}
										</List>
									</Collapse>
								</>
							) : (
								<RouteItem key={route.key} route={route} nested={false} />
							)}
							{route.appendDivider && <Divider />}

						</div>
					)
				})}
				{/*<SignOutRoute />*/}
			</List>
			{/* <SignOutRoute /> */}
		</>
	);
};
