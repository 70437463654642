import { AxiosRequestConfig } from "axios";
import api from "./config";
import {useProgramCourseSankeyQuery} from "../hooks/useProgramCourseSankey";
import {removeCycles} from "../helper/utils/helper";


export const getProgramCourseSankey = async () => {
  const res = await api.get(`/dashboard/program-course-sankey`);
  if(res?.data?.data ){
    res.data.data = removeCycles(res.data.data)
  }
  return res?.data;
};


export const getCourseCount = async () => {
  const res = await api.get(`/dashboard/course-count`);
  return res?.data;
};

export const getProgramCount = async (status?: string) => {
  const res = await api.get(`/dashboard/program-count`, {
    params: {
      status
    }
  });
  return res?.data;
};

export const getCurriculumCount = async () => {
  const res = await api.get(`/dashboard/curriculum-count` );
  return res?.data;
};