import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useDropzone } from "react-dropzone";
import { Box, LinearProgress, Typography } from "@mui/material";
import { uploadHeiExcel } from "../../requests/hei.request";
import { useDispatch } from "react-redux";
import { ISnackbarSlice, openSnackbar } from "../../redux/features/snackbar";
import { useQueryClient } from "react-query";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function useUploadHEIDialog() {
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState<File[] | null>(null);
  const [progress, setProgress] = React.useState(0);
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = React.useCallback(() => {
    setFiles(null);
    setProgress(0);
  }, []);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xls",
        ".csv",
      ],
    },
    maxFiles: 1,
  });

  const handleUpload = React.useCallback(async () => {
    if (files) {
      const formData = new FormData();
      formData.append("file", files[0]);
      try {
        const res = await uploadHeiExcel(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total!);
            if (percent <= 100) setProgress(percent);
          },
        });
        queryClient.invalidateQueries("programs");
        const message = "HEI uploaded successfully";
        setSnackbarStore({
          open: true,
          message: message,
          severity: "success",
        });
      } catch (error) {
        console.error({ error });
        const message = "Failed to upload HEI";
        setSnackbarStore({
          open: true,
          message: message,
          severity: "error",
        });
      } finally {
        handleReset();
        handleClose();
      }
    }
  }, [files]);

  const Component = (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Import HEI</DialogTitle>
      <DialogContent>
        <div
          {...getRootProps({
            style: {
              border: "3px dashed grey",
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            },
          })}
        >
          <input {...getInputProps()} />
          {files ? (
            files.map((file: File) => {
              return (
                <Box key={file.name}>
                  <Typography>File Name: {file.name}</Typography>
                  <Typography>
                    Size: {(file.size / 1024).toFixed(2)} kb
                  </Typography>
                </Box>
              );
            })
          ) : isDragActive ? (
            <Typography>Drop it here ...</Typography>
          ) : (
            <Typography component="div">
              Drag 'n' drop <strong>.xlsx</strong> file here, or click to select
              file
            </Typography>
          )}
        </div>
        <Box sx={{ my: 2, display: progress > 0 ? "block" : "none" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="overline">Uploading</Typography>
            <Typography variant="overline">{progress}%</Typography>
          </Box>
          <LinearProgress sx={{ borderRadius: "10%" }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id={"cancelHie"} onClick={handleClose}>Cancel</Button>
        {files && (
          <>
            <Button
                id={"handleResethei"}
              variant="outlined"
              color="warning"
              onClick={handleReset}
              disabled={!!progress}
            >
              Reset
            </Button>
            <Button
                id={"handleUploadhei"}
              variant="contained"
              onClick={handleUpload}
              disabled={!!progress}
            >
              Upload
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );

  return { Component, handleClickOpen, handleClose };
}
