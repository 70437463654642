import {Box, Card, Typography} from "@mui/material";
import React from "react";

export function TabCard(props: {
    id?: string,
    onClick?: () => void,
    count: any,
    title: string,
    color: string,
    children: any
}) {
    return <Card id={props?.id} onClick={props.onClick} style={{
        cursor: "pointer",

        flex: 1, width: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: props.color,
    }}>
        <Box
            style={{
                cursor: "pointer",

                padding: 30,
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box style={{cursor: "pointer", padding: 10}}>
                {props.children}
            </Box>

            <Box>
                <Typography
                    textAlign={"right"}
                    style={{cursor: "pointer", lineHeight: "25px"}}
                    fontWeight={600}
                    fontSize={25}
                    color={"#fff"}
                >
                    {props.count ?? "0"}
                </Typography>

                <Typography
                    textAlign={"right"}
                    fontSize={12}
                    color={"#fff"}
                    textTransform="uppercase"
                >
                    {props.title}
                </Typography>
            </Box>
        </Box>
    </Card>;
}
