import {Info, Search} from "lucide-react"
import {Button} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "react-query";
import {getWorkspaceById} from "../../requests/workspace.request";
import {IWorkspaceItem, WorkspaceContentProps} from "./WorkspaceContent";

const Toolbar: React.FC<WorkspaceContentProps> = ({workspaceId}) => {
    const [open, setOpen] = useState(false);
    const { data, isLoading, isError, error } = useQuery<IWorkspaceItem, Error>(
        ['workspace', workspaceId],
        () => getWorkspaceById(workspaceId),
        {
            enabled: !!workspaceId,
        }
    );

    return (
        <div className="flex-1">
            <nav className="bg-[#4d84ad] flex items-center justify-between h-10 p-1.5">
                <div className="flex-1"/>
                <div className="min-w-[280px] max-[642px] grow-[2] shrink">
                    <Button onClick={() => setOpen(true)} size="small"
                            className="bg-accent/25 hover:bg-accent-25 w-full justify-start h-7 px-2">
                        <Search className="size-4 text-white mr-2"/>
                        <span className="text-white text-xs">
            Search {data?.name}
          </span>
                    </Button>

                </div>
                <div className="ml-auto flex-1 flex items-center justify-end">
                    <Button variant="text" size="small">
                        <Info className="size-5 text-white"/>
                    </Button>
                </div>
            </nav>
        </div>

    )
}

export default Toolbar