import * as Yup from "yup";
import { enrollmentSchema, IEnrollmentSchema } from "./enrollment.schema";
import { gradeSchema, IGradeSchema } from "./grade.schema";

export interface IRecordSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  enrollment: IEnrollmentSchema;
  enrollmentId?: number | null;
  enrollmentUuid?: string | null;
  grades: IGradeSchema[];
  semCode?: string | null;
  semDescription?: string | null;
}

export const recordSchema: Yup.SchemaOf<IRecordSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  enrollment: Yup.lazy(() => enrollmentSchema),
  enrollmentId: Yup.number().nullable(),
  enrollmentUuid: Yup.string().nullable(),
  grades: Yup.array(Yup.lazy(() => gradeSchema)),
  semCode: Yup.string().nullable(),
  semDescription: Yup.string().nullable(),
});

export type EnrollmentType = Yup.InferType<typeof recordSchema>;
