import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { useDebounce } from "../../hooks/useDebounce";
import { useSearchCurriculumQuery } from "../../hooks/useSearchCurriculumQuery";
import { CurriculumType } from "../../types";
import { useAppSelector } from "../../redux/store";

interface ISearchAsYouTypeProps {
    label: string;
    onSelected: (value: CurriculumType) => void;
    schoolId: any;
    required?: any;
    curriculumId?: any;
    defaultValue?: any;
    disableClearable?: any;
}

const SearchCurriculumTextField: React.FC<ISearchAsYouTypeProps> = (props) => {
    const {
        label,
        onSelected,
        schoolId,
        required,
        curriculumId,
        disableClearable,
        defaultValue,
    } = props;

    const [select, setSelected] = React.useState<any>();
    const [name, setName] = React.useState("");
    const debouncedName = useDebounce(name, 500);
    const { data: options, isLoading } = useSearchCurriculumQuery(
        debouncedName,
        schoolId
    );

    const [open, setOpen] = React.useState(false);
    const curriculum = useAppSelector((store) => store?.school?.curriculum);

    const renderSelectedOption = (option: any) => (
        <div id={"searchasyoutypesettingschoolinstutional" + option?.id} style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {option?.curriculumName}
            <p style={{ fontStyle: "italic", margin: 0, fontSize: 12 }}>
                {option?.programName ? option.programName + " - " : ""}
                {option?.majorName ? option.majorName : ""}
            </p>
        </div>
    );
    const getOptionLabel = (option: any) => {
        const parts = [option?.curriculumName, option?.programName, option?.majorName].filter(Boolean);
        return parts.join(' - ');
    };

    return (
        <Autocomplete
            id={"searchCurriculum"}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disableClearable={disableClearable}

            getOptionLabel={(select ) => getOptionLabel(select) || ""}
            options={(options?.data || []) as CurriculumType[]}
            defaultValue={defaultValue ?? curriculum}
            loading={isLoading}
            onChange={(e, value) => {
                if (value !== undefined) onSelected(value);
                if (value !== undefined) setSelected(value);
            }}
            inputValue={name}
            onInputChange={(event, newInputValue) => {
                setName(newInputValue);
            }}
            renderOption={(props, option) => (
                <li {...props} id={"settingschoolinstutional" + option?.id} key={option?.id}>
                    {renderSelectedOption(option)}
                </li>
            )}
            renderInput={(params) => {
                return (
                <TextField
                    {...params}
                    label={label}
                    margin="dense"
                    size="small"
                    required={required}

                    InputProps={{
                        ...params.InputProps,
                        style: {whiteSpace: 'pre-wrap' },
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}}
        />
    );
};

export default React.memo(SearchCurriculumTextField);
