import * as Yup from "yup";

export interface IProvinceSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
}

export const provinceSchema: Yup.SchemaOf<IProvinceSchema> = Yup.object().shape(
  {
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    name: Yup.string().nullable(),
  }
);

export type ProvinceType = Yup.InferType<typeof provinceSchema>;
