import * as React from "react";
import {useMemo} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import StudentPage from "../../pages/Student.page";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    title: string,
    studentId: string | number | undefined
}

const AddConfirmation = React.memo((props: IProps) => {
    const {open, handleClose, onSave, title, studentId} = props;
    const dispatch = useDispatch();
    const files = useSelector((store: RootState) => store.specialOrder?.requirements?.files)

    const _studentId = useMemo(() => {
        return studentId
    }, [studentId])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={handleClose}
            maxWidth="xl"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Transcript of Records"}</DialogTitle>
            <DialogContent>

                {studentId ? <StudentPage disableEvaluation={false} disableRequirement={false} tbValue={1} header={false}
                                          student_Id={_studentId}/> : null}

            </DialogContent>
            <DialogActions>
                <Button color={"error"} id={"TranscriptofRecordscancelCourse"} onClick={handleClose}>Cancel</Button>
                <Button id={"TranscriptofRecordsAddConformation"} onClick={() => {
                    onSave()
                    handleClose()
                }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default AddConfirmation;
