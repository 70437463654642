import * as React from "react";
import {Avatar, Box, DialogContent, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {Transition} from "../../wrapper/Transition";
import {Banknote} from "lucide-react";
import {Add, DateRangeRounded, DocumentScannerRounded} from "@mui/icons-material";

interface IProps {
    open: boolean;
    handleClose: () => void;
    title: string,
    info?: object | any,
    preview: string
}


const FilesPreview = React.memo((props: IProps) => {

    const {open, handleClose, title, preview, info} = props;
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{fontSize: 16}}>{title ?? ""}</DialogTitle>
                <DialogContent>
                    <Box style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                        <img src={preview}/>
                    </Box>

                    {info ? <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DocumentScannerRounded/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Receipt Number" secondary={info?.receiptNumber ?? "-"}/>


                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeRounded/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Date Paid" secondary={info?.datePaid ?? "-"}/>


                        </ListItem>

                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Add/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Total Amount" secondary={info?.totalAmount ?? "-"}/>


                        </ListItem><ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <Banknote/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Bank Name" secondary={info?.bankName ?? "-"}/>


                    </ListItem>

                    </List> : null}


                </DialogContent>
                <DialogActions>
                    <Button color={"error"} id={"cancelCourse"} onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

        </>

    );
});

export default FilesPreview;
