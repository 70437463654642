import * as React from "react"

const EnrollmentIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width || 31}
        height={props.height || 30}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.563 6.664c0-1.654.633-3.24 1.761-4.41A5.91 5.91 0 0 1 10.578.429a5.91 5.91 0 0 1 4.254 1.827 6.354 6.354 0 0 1 1.762 4.41c0 1.653-.634 3.24-1.762 4.41a5.91 5.91 0 0 1-4.254 1.826 5.91 5.91 0 0 1-4.254-1.827 6.354 6.354 0 0 1-1.761-4.41Zm14.218 3.402c0-.67.128-1.334.375-1.953a5.12 5.12 0 0 1 1.067-1.655c.457-.474 1-.85 1.597-1.106a4.771 4.771 0 0 1 3.767 0c.597.256 1.14.632 1.596 1.106.457.474.82 1.036 1.067 1.655.248.62.375 1.283.375 1.953a5.2 5.2 0 0 1-1.442 3.608 4.835 4.835 0 0 1-3.48 1.494 4.835 4.835 0 0 1-3.48-1.494 5.2 5.2 0 0 1-1.442-3.608ZM.188 25.94c0-2.857 1.094-5.597 3.043-7.617 1.948-2.02 4.591-3.155 7.347-3.155 2.756 0 5.399 1.135 7.347 3.155 1.949 2.02 3.044 4.76 3.044 7.617v.005l-.002.18c-.003.192-.053.38-.146.547a1.116 1.116 0 0 1-.383.405 18.55 18.55 0 0 1-9.86 2.831c-3.605 0-6.98-1.034-9.858-2.831a1.116 1.116 0 0 1-.384-.405 1.165 1.165 0 0 1-.147-.548l-.002-.184Zm22.968.005-.001.217c-.008.504-.124 1-.34 1.451a14.269 14.269 0 0 0 7.38-1.526c.176-.09.327-.228.435-.399.107-.171.169-.37.177-.573a7.597 7.597 0 0 0-.77-3.66 7.289 7.289 0 0 0-2.406-2.794 6.892 6.892 0 0 0-6.97-.518 13.298 13.298 0 0 1 2.494 7.797l.001.005Z"
            fill={props.color ||"#fff"}
        />
    </svg>
)

export default EnrollmentIcon
