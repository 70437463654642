import { useQuery } from "react-query";
import { getRegions, getRegionsSelect } from "../requests/region.request";

export const useRegionQuery = (params: { [key: string]: any }) => {
    return useQuery("regions", () => getRegions(params));
}
export const useRegionSelectQuery = () => {
    return useQuery(["regions", "select"], getRegionsSelect, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        cacheTime: Infinity,
    });
}