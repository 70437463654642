// create slice for requirement
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IRequirementSlice {
  editRequirements: {
    id: number | string | undefined,
    name: string,
    isRequired: boolean
  };
}

const initialState: IRequirementSlice = {
    editRequirements: {
        id: 0,
        name: '',
        isRequired: false
    }
};

const requirementSlice = createSlice({
  name: "requirement",
  initialState,
  reducers: {

    setEditRequirementValue: (state, action: PayloadAction<any>) => {
      state.editRequirements = action.payload;
      return state;
    },
  },
});

export const {setEditRequirementValue} = requirementSlice.actions;
export default requirementSlice.reducer;
