import React, {FC, SyntheticEvent, useCallback, useState} from "react";
import {Box, Button, Container, Divider, Grid, Paper, TextField, Typography,} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {APP_TITLE} from "../helper/utils/constants";
import {useScreenDimension} from "../hooks/useScreenDimension";
import {Redirect, useHistory} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import {forgotpassword} from "../requests/auth.request";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import {setLoading} from "../redux/features/app";
import {useAppDispatch, useAppSelector} from "../redux/store";
import {setErrorSnackbar} from "../helper/utils/helper";
import Lottie from "lottie-react";
import edcs from "../assets/edcs.json";
import PRCLogo from "../svg/prc";
import {LoadingButton} from "@mui/lab";

const validationSchema = yup.object({
    email: yup.string().required("Email is required")
});
const ForgotPasswordPage: FC = () => {
    const dispatch = useAppDispatch();
    const authStore = useAppSelector((store) => store.auth);
    const {height} = useScreenDimension();
    const history = useHistory();
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);

    const handleLoading = useCallback((loading: boolean) => {
        dispatch(setLoading(loading));
    }, []);

    const pushTo = useCallback(
        (path: string) => {
            history.push(path);
        },
        [history]
    );
    const [submitLoading, setSubmitLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                setSubmitLoading(true)
                handleLoading(true);
                const res = await forgotpassword(values);
                setSnackbarStore({
                    message: `Forgot password portal to ${values.email} is successfully sent`,
                    severity: 'success',
                    open: true
                })
                setSubmitLoading(false)
            } catch (error: any) {
                setSubmitLoading(false)
                try {
                    setErrorSnackbar(error, setSnackbarStore);
                } catch (e) {
                }
            } finally {
                setSubmitLoading(false)
                handleLoading(false);
            }
        },
    });

    const handleLogin = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();

            formik.handleSubmit();
        },
        [formik]
    );
    if (authStore.isAuth) return <Redirect to="/"/>;

    return (
        <>
            <Helmet>
                <title>Forgot Password - {APP_TITLE}</title>
            </Helmet>
            <main
                style={{
                    backgroundColor: "#034F8B",
                }}
            >
                <Container>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: height,
                        }}
                    >
                        <Paper
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",

                                borderRadius: 3,
                                backgroundColor: "#E9E9E9",
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    borderRadius: {
                                        xs: "inherit",
                                        md: 0,
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderStartStartRadius: "inherit !important",
                                    borderEndStartRadius: "inherit !important",
                                    backgroundColor: "white",
                                    p: 6,
                                }}
                            >
                                <Box
                                    component={"form"}
                                    onSubmit={handleLogin}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        flex: 1,
                                        mb: 3,
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        fontWeight="bold"
                                        component="p"
                                        sx={{
                                            mb: 2,
                                        }}
                                    >
                                        Forgot Password
                                    </Typography>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        fullWidth
                                        type={"email"}
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.email && Boolean(formik.errors.email)
                                        }
                                        helperText={
                                            formik.touched.email && formik.errors.email
                                        }
                                        InputLabelProps={{shrink: true}}
                                    />

                                    <LoadingButton
                                        type="submit"
                                        loading={submitLoading}
                                        color="primary"
                                        variant="contained"
                                        onClick={handleLogin}
                                        fullWidth
                                        sx={{
                                            mt: 2,
                                        }}
                                    >
                                        Submit
                                    </LoadingButton>
                                    <Divider
                                        sx={{
                                            my: 2,
                                        }}
                                    >
                                        or
                                    </Divider>
                                    <Button
                                        variant="outlined"
                                        fullWidth

                                        onClick={() => pushTo("/login")}
                                    >
                                        Login
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: {
                                        xs: "none",
                                        md: "flex",
                                    },
                                    flex: 1,
                                    borderRadius: "inherit",
                                    p: 4,
                                }}
                                style={{
                                    backgroundColor: "#E9E9E9",
                                }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        alignItems: "space-between",
                                        justifyContent: "space-between",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div style={{
                                        marginBottom: 50,
                                        flexDirection: "column",
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <div style={{marginBottom: 15}}>
                                            <PRCLogo/>
                                        </div>
                                        <div>
                                            <div style={{
                                                display: "flex",
                                                flex: 1,
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <img src="/assets/svg/prctext.svg"/>
                                            </div>
                                        </div>


                                    </div>

                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                    }}>
                                        <Lottie style={{height: "130px"}} loop={false} animationData={edcs}/>


                                        {/*    <img alt="EDC Logo" src={"/assets/logo.png"} height="130px" />*/}

                                        <Typography
                                            textTransform={"uppercase"}
                                            variant="subtitle2"
                                            marginTop={"10px"}
                                            color="primary.main"
                                        >
                                            {APP_TITLE}
                                        </Typography>
                                    </div>

                                    <div>
                                        <Typography textAlign={"center"} marginTop={"60px"} fontSize={14}>
                                            Operated and Maintained by CHED Regional Office 10
                                        </Typography>
                                        <Typography fontStyle={"italic"} textAlign={"center"} fontWeight={"bold"}
                                                    fontSize={12}>
                                            Powered by
                                        </Typography>
                                        <Typography fontStyle={"italic"} textAlign={"center"} fontWeight={"bold"}
                                                    fontSize={12}>
                                            USTP
                                        </Typography>
                                        <Typography fontStyle={"italic"} textAlign={"center"} fontWeight={"bold"}
                                                    fontSize={12}>
                                            Copyright © 2023
                                        </Typography>
                                    </div>


                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </main>
        </>
    );
};

export default ForgotPasswordPage;
