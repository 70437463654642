import {useSelector} from "react-redux";
import {Box, Button, Container, Grid, Paper, Stack, TextField, Typography,} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {APP_TITLE} from "../helper/utils/constants";
import {FileUploadRounded} from "@mui/icons-material";
import {RootState} from "../redux/store";
import useUploadPromotionalReportDialog from "../components/dialogs/UploadPromotionalReport.dialog";
import {useUploadQuery} from "../hooks/useUploadQuery";
import PromotionalReportTable from "../components/Tables/EnrollmentTable";

const PromotionalReportPage = () => {
    const {
        ErrorComponent,
        Component: UploadDialog,
        handleClickOpen,
        handleClose,
    } = useUploadPromotionalReportDialog();
    const authStore = useSelector((store: RootState) => store.auth);
    const {data: uploadedData, isLoading} = useUploadQuery({pageSize: 100});
    const {user} = authStore;
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const roles = useSelector((store: RootState) => store.auth?.user?.roles)


    return (
        <>
            <Helmet>
                <title>Promotional Report | {APP_TITLE}</title>
            </Helmet>

            <Box
                component="main"
                sx={{flexGrow: 1, pt: 10}}
                style={{paddingLeft: " 20px", paddingRight: "20px"}}
            >
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h5" fontWeight="bold">
                Upload Promotional Report
              </Typography>
              <Typography variant="subtitle2">
                {/*Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolores minus facilis ducimus tempora suscipit!
                 */}{" "}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>

              {!(type?.id == 2 && (roles.toLowerCase() == "admin" || roles.toLowerCase() == "user")) ? <Box>
                <Button
                  variant="contained"
                  startIcon={<FileUploadRounded />}
                  onClick={handleClickOpen}
                >
                  Import PR
                </Button>


              </Box> : null}
              {/* <Button
							startIcon={<SaveRounded />}
							color="primary"
							variant="contained">
							SAVE
						</Button> */}
            </Box>
          </Stack>
          <Paper sx={{ p: 2, my: 2 }}>
            <Typography>School Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <TextField
                    required
                    id="schoolInstutionCode"
                    name="schoolInstutionCode"
                    label="School Institutional Code"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={user?.school?.code ?? ""}
                    InputProps={{
                        "aria-readonly": true,
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    required
                    id=""
                    name="totalStudents"
                    label="Total No. Students"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={uploadedData?.totalNoOfStudent || 0}
                    InputProps={{
                        "aria-readonly": true,
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    required
                    id="schoolEmail"
                    name="schoolEmail"
                    label="School Email"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={user?.email ?? ""}
                    InputProps={{
                        "aria-readonly": true,
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <TextField
                    required
                    id="schoolName"
                    name="schoolName"
                    label="School Name"
                    fullWidth
                    margin="normal"
                    size="small"
                    value={user?.school?.name ?? ""}
                    InputProps={{
                        "aria-readonly": true,
                        readOnly: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                      required
                      id="schoolAddress"
                      name="schoolAddress"
                      label="School Address"
                      fullWidth
                      margin="normal"
                      size="small"
                      value={
                          (user?.school?.address || uploadedData?.address) ?? ""
                      }
                      InputProps={{
                          "aria-readonly": true,
                          readOnly: true,
                      }}
                      InputLabelProps={{shrink: true}}
                  />
                    <TextField
                        required
                        id="shoolContactNumber"
                        name="shoolContactNumber"
                        label="School Contact Number"
                        fullWidth
                        value={
                            (user?.school?.contactNumber || uploadedData?.address) ?? ""
                        }
                        InputProps={{
                            "aria-readonly": true,
                            readOnly: true,
                        }}
                        margin="normal"
                        size="small"
                    />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <TextField
                    required
                    id="preparedBy"
                    name="preparedBy"
                    label="Prepared By"
                    fullWidth
                    InputProps={{
                        "aria-readonly": true,
                        readOnly: true,
                    }}
                    margin="normal"
                    size="small"
                    value={uploadedData?.preparedBy ?? ""}
                  />
                  <TextField
                    required
                    id="approvedBy"
                    name="approvedBy"
                    label="Approved By"
                    fullWidth
                    InputProps={{
                        "aria-readonly": true,
                        readOnly: true,
                    }}
                    margin="normal"
                    size="small"
                    value={uploadedData?.approvedBy ?? ""}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
          <PromotionalReportTable
            //title={`Enrolled Student in ${uploadedData?.semester} of ${uploadedData?.schoolYear} - #${uploadedData?.id}`}
            title={`Enrolled Student in ${uploadedData?.semester} of ${uploadedData?.schoolYear}`}
          />
        </Container>
      </Box>
      {ErrorComponent}
      {UploadDialog}
    </>
  );
};

export default PromotionalReportPage;
