import {AddRounded, CopyAllOutlined, DeleteRounded, FilterAltRounded, ViewColumnRounded,} from "@mui/icons-material";
import {Box, Button, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import * as React from "react";
import {FC, ReactNode, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteDiscipline} from "../../requests/discipline.request";
import AddDiscipline from "../dialogs/AddDiscipline.dialog";
import {RootState, useAppSelector} from "../../redux/store";
import {
    setDiscipline,
    setDisciplineBreadcrumb,
    setDisciplineId,
    setDisciplineTabState,
    setSelectedSettingSchoolId,
    setTabValue,
} from "../../redux/features/school";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useDebounce} from "../../hooks/useDebounce";
import {useSearchDisciplineQuery} from "../../hooks/useSearchDisciplineQuery";
import {setDeleteDialog} from "../../redux/features/app";
import {usePager} from "../../hooks/usePager";
import {openSnackbar} from "../../redux/features/snackbar";
import {textClipboard} from "../../helper/utils/formatting";

interface IDisciplineTableProps {
  title: string;
  setting?: boolean;
  schoolRegionId?: any;
}



const DisciplineTable: FC<IDisciplineTableProps> = (props) => {

  const dispatch = useDispatch();
  const { title, setting, schoolRegionId } = props;
  const { schoolId, regionId} = useAddEntities();

  const [name, setName] = React.useState<any>("");
  const debouncedName = useDebounce(name, 500);
  const type = useSelector((store: RootState) => store.auth?.user?.type)
  const roles = useSelector((store: RootState) => store.auth?.user?.roles)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const regionSetting = useAppSelector((store) => store?.school.regionSetting);

  const {data: disciplineData, isFetching, isLoading, refetch} = useSearchDisciplineQuery(debouncedName, {
    ...(schoolId  && {schoolId: schoolId }),
    ...(regionSetting?.id && {regionId: regionSetting?.id}),
    ...(regionId?.id && {regionId: regionId?.id}),
    ...(typeof regionId == "number" && {regionId: regionId}),
      ...(debouncedName && {search: debouncedName}),
      ...(pageSize && {pageSize: pageSize}),
      ...(page && {page: page}),
  });


    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);
    const totalCount = useMemo(() => {
        return (disciplineData?.totalCount)
    }, [disciplineData?.totalCount])

    const totalRowPage = useMemo(() => {
        return (disciplineData?.pageSize)
    }, [disciplineData?.pageSize])
    const handleDeleteProgram = (id: string | number, name: string) => {
        dispatch(
            setDeleteDialog({
                open: true,
                data: {
                    id,
                    title: `Delete Discipline - ${name}?`,
                    body: `Before deleting this discipline, please note that by clicking the "Delete" button, all associated entities will be removed from the system. Are you sure you want to proceed?`,
                    content: "discipline",
                    onDelete: async () => await deleteDiscipline(+id),
        },
      })
    );
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },

    {
      name: "school",
      label: "SCHOOL",
      options: {
          display: type?.id == 3 || type?.id == 2 || roles?.toLowerCase() == "superadmin",
          filter: true,
          sort: false,
          customBodyRender: (value: any, tableMeta, updateValue: any) => {
              const name =
                  tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
              return (
                  <Typography fontSize={15} sx={{wordBreak: "break-word"}}>
                      {value}
                  </Typography>
              );
        }
      },
    },
    {
      name: "name",
      label: "Name of Discipline".toUpperCase(),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta, updateValue: any) => {
          const name =
              tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
          return (
             <Typography fontSize={15}    sx={{ wordBreak: "break-word" }} >
                  {value}<Tooltip title="Copy to clipboard" arrow>
                  <IconButton onClick={(e) => {
                    e.stopPropagation();
                    try {
                      navigator.clipboard.writeText(name)
                      dispatch(
                          openSnackbar({
                            open: true,
                            message: "Copy To Clipboard",
                            severity: "success",
                          })
                      );
                    }catch (e) {
                        textClipboard(name)
                    }
                  }}>
                    <CopyAllOutlined/>
                  </IconButton>
                </Tooltip>
                </Typography>


          );
        },
      },
    },
    {
      name: "code",
      label: "Discipline Code".toUpperCase(),
      options: {
          display: (type?.id == 2 && roles?.toLowerCase() == "user"),
          filter: true,
          sort: false,
      },
    },
    {
      name: "dateCreated",
      label: "DATE CREATED",
      options: {
        display: false,
        filter: true,
        sort: false,
      },
    },

    {
      name: "lastModifiedDate",
      label: "DATE UPDATED",
      options: {
        display: false,
        filter: true,
        sort: false,
      },
    },
    {
      name: "id",
      label: "ACTION",
      options: {
          display: type?.id == 2 || type?.id == 3,
          filter: false,
          sort: false,
          viewColumns: false,
          customBodyRender: (value: any, tableMeta, updateValue: any) => {
              const name =
                  tableMeta.rowData[columns.findIndex((x) => x.name === "name")];
              return (
                  <Stack direction="row" spacing={1}>
                      {/*<Tooltip title="Copy to clipboard" arrow>
                <IconButton
                    color="error"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      try {
                        navigator.clipboard.writeText(name)
                      }catch (e) {

                      }
                    }}
                >
                  <CopyAllOutlined/>
                </IconButton>
              </Tooltip>*/}
              <Tooltip title="Delete" arrow>
                <IconButton
                  color="error"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteProgram(value, name);
                  }}
                >
                  <DeleteRounded />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    },
  ];



  const handleRowClick = (rowData: any, rowMeta: any) => {

    dispatch(setSelectedSettingSchoolId(disciplineData?.data?.[rowMeta.rowIndex]?.schoolId));
    const name = rowData[columns.findIndex((x) => x.name === "name")];
    const school = rowData[columns.findIndex((x) => x.name === "school")];
    dispatch(setTabValue("1"));
    dispatch(setDisciplineId(rowData[0]));
    dispatch(setDiscipline(disciplineData?.data?.[rowMeta.rowIndex]));
    dispatch(setDisciplineBreadcrumb((school?.props?.children ?? school)+ " / " + (name?.props?.children?.[0] ?? name)));
  };

  const disciplineTabOpen = useAppSelector(
    (store) => store?.school.disciplineTabOpen
  );

  const options: MUIDataTableOptions = useMemo(() => {
      return {
          textLabels: {
              body: {

                  noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
              }
          },
          //selectableRowsOnClick: true,
          filter: false,
          download: type?.id != 1,
          tableId: "disciplinetable",
          print: false,
          searchText: name,
          count: totalCount,
          rowsPerPage: totalRowPage ?? pageSize,
          page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
          serverSide: true,
          selectableRows: "none",

          onTableChange: (action, newTableState) => {
              switch (action) {
                  case "changeRowsPerPage":
                      changeRowsPerPage(newTableState.rowsPerPage);
                      break;
                  case "changePage":
            changePage(newTableState.page, (newTableState.data?.length ?? 100));
            break;
          case "search":
            setPage(0)
            setName(newTableState.searchText);
            break;
        }
      },

        responsive: "vertical",
      onRowClick: handleRowClick,
    };
  }, [name, totalCount, pageSize]);

  function handleCloseAddDiscipline() {
    dispatch(setDisciplineTabState(false));
  }

  function handleOpenAddDiscipline() {
    dispatch(setDisciplineTabState(true));
  }

  useEffect(() => {
    refetch();
  }, [schoolId, disciplineTabOpen]);



  return (
    <>
      <MUIDataTable

        title={title}
        data={disciplineData?.data as any}
        columns={columns}
        options={options}
        components={{
          TableFilterList: (props) => {
            return <TableFilterList {...props} />;
          },
          TableToolbar: (props) => {
            return (
              <>
                <TableToolbar {...props} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mx: 3,
                    my: 1,
                  }}
                >
                  <Box>
                    {(type?.id == 2 || type?.id == 3) ? <Button

                        startIcon={<AddRounded/>}
                        variant="contained"
                        onClick={handleOpenAddDiscipline}
                    >
                        Add Discipline
                    </Button> : null}
                  </Box>
                </Box>
              </>
            );
          },
          icons: {
            FilterIcon: FilterAltRounded as unknown as ReactNode,
            ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
          },
        }}
      />
      <AddDiscipline
          schoolRegionId={schoolRegionId}
        setting = {setting}
        open={disciplineTabOpen}
        handleClose={handleCloseAddDiscipline}
      />
    </>
  );
};

export default DisciplineTable;
