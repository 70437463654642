// create hooks for search school using useQuery

import { useQuery } from "react-query";
import { getSchoolsByInstituteCode } from "../requests/school.request";
import { SearchSchoolResponseType } from "../types";

export const useSearchSchoolQuery = (
  str: string,
  {
    enable,
  }: {
    enable: boolean;
  },
  regionId?: string,
) => {
  enable;




  return useQuery<SearchSchoolResponseType[]>(
    ["schools", str, regionId],
    ({ signal }) => getSchoolsByInstituteCode(str, signal, regionId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: enable,
    }
  );
};
