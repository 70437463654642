import api from "./config";
import Axios from "axios";
import axios from "axios";

let cancelToken = axios.CancelToken.source()
export const createOrGet   = async (memberId: string, workspaceId: string) => {
    if(!(memberId ||  workspaceId)){
        return
    }

    cancelToken = axios?.CancelToken?.source()
    const res = await api.post(`/conversations/createOrGet`, {
        ...(memberId && {memberId: memberId}),
        ...(workspaceId && {workspaceId: workspaceId}),
    }, {
        ...(cancelToken?.token && {cancelToken: cancelToken?.token}),
        params:{
            ...(memberId && {memberId: memberId}),
            ...(workspaceId && {workspaceId: workspaceId}),
        }
    });
    return res?.data;
};