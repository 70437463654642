import type { SchoolYearSelectType, SchoolYearType } from "../types";
import api from "./config";

export const setSchoolYear = async () => {
    const res = await api.post<SchoolYearType[]>("/schoolYears")
    return res?.data
}

export const getSchoolYearsSelect = async () => {
    const res = await api.get<SchoolYearSelectType[]>("/schoolYears/select")
    return res?.data
}