import api from "./config";


export const getPricingRulesGroupBySchool = async (params: any) => {
    const res = await api({
        url: `/rules`,
        params: params,
        method: "GET",
    });

    return res?.data;
};
export const deleteSchoolProgramPricingRules = async (id: any) => {
    const res = await api({
        url: `/rules/${id}`,
        method: "DELETE",
    });

    return res?.data;
};

export const createPricingRules = async (params: any, schools: any) => {
    const res = await api.post("/rules", {
        ...params,
        schools: schools
    });

    return res?.data?.data;
};

export const patchPricingRules = async (params: any, schools: any) => {
    const res = await api.patch(`/rules/${params.id}`, {
        ...params
    });

    return res?.data?.data;
};
export const effectiveDatePricingRules = async (params: any) => {
    const res = await api.get("/rules/effective-date", params);

    return res?.data?.data;
};
