import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {Alert, MenuItem, OutlinedInput, Select} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {postPrerequisiteByCourse} from "../../requests/course.request";
import FormControl from "@mui/material/FormControl";
import {usePrerequisiteQuery} from "../../hooks/usePrerequisiteQuery";
import {useAppSelector} from "../../redux/store";
import {usePrerequisiteByCourseIdQuery} from "../../hooks/usePrerequisiteByCourseIdQuery";
import {useQueryClient} from "react-query";
import {CheckRounded, CloseRounded} from "@mui/icons-material";
import {setPrerequisiteId, setPrerequisiteName} from "../../redux/features/school";

const validationSchema = yup.object({
  courseId: yup.number(),
});

const initialValues = {
  courseId: 0,
  prerequisitesCourseCodes: []
};


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const AddPrerequisite = React.memo((props: IProps) => {
  const { open, handleClose } = props;
  const prerequisiteName = useAppSelector((store) => store?.school?.prerequisiteName);
  const prerequisiteId = useAppSelector((store) => store?.school?.prerequisiteId);


  const dispatch = useDispatch();
  const { data: prerequisiteData, refetch } = usePrerequisiteQuery({
    pageSize: 100,
    ...prerequisiteName && {curriculumName: prerequisiteName},
  });


  const { data: prerequisiteByCourseIdData } = usePrerequisiteByCourseIdQuery({
    ...prerequisiteId &&  {courseId: prerequisiteId},
  });



  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const [previousNames , setPreviousNames] = useState<string[]>([])

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue('prerequisitesCourseCodes', typeof value === 'string' ? value.split(',') : value)

  };

  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        values.courseId = prerequisiteId
        var res = await postPrerequisiteByCourse(values);
        dispatch(setPrerequisiteName(""))
        dispatch(setPrerequisiteId(0))
        setName([])
        setPreviousNames([])
        formik.setFieldValue('prerequisitesCourseCodes', [])

        actions.resetForm();
        handleClose();

        setSnackbarStore({
          message: "Successfully added prerequisite",
          severity: "success",
          open: true,
        });

      } catch (error: any) {
        setErrorSnackbar(error, setSnackbarStore);
      }
    },
  });

  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [names , setName] = useState<{id: number, courseCode: string}[]>([])

  useEffect(() => {

      if (prerequisiteData?.data) {
          setName(prerequisiteData?.data)
      }
      return () => {
          setName([])
      }
  }, [prerequisiteData, prerequisiteByCourseIdData, prerequisiteName])



  useEffect(() => {
    if(prerequisiteByCourseIdData?.prerequisitesCourseCodes){
    setPreviousNames( prerequisiteByCourseIdData?.prerequisitesCourseCodes?.split(","))
      formik.setFieldValue('prerequisitesCourseCodes', prerequisiteByCourseIdData?.prerequisitesCourseCodes?.split(","))
    }
    return () =>{
      setPreviousNames( [])
      formik.setFieldValue('prerequisitesCourseCodes',[])
    }
  }, [prerequisiteByCourseIdData, prerequisiteName])

  const prerequisites = useMemo(() => names?.map((c: any) => {
    return {
      value: c.courseCode,
      id: c.id
    }
  }), [names, prerequisiteData?.data, prerequisiteByCourseIdData]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >

      <DialogTitle>
       <div style={{alignItems: "center", justifyContent: "space-between", flex: 1, display: "flex", flexDirection: "row"}}>
         <div>
           Configure Prerequisite
         </div>
         <div onClick={()=>{
           dispatch(setPrerequisiteName(""))
           dispatch(setPrerequisiteId(0))
           setName([])
           formik.setFieldValue('prerequisitesCourseCodes', [])
          setPreviousNames([])
           handleClose()
         }}>
           <CloseRounded/>
         </div>
       </div>
      </DialogTitle>
      <div>
        <Alert severity='info'>
          Please provide the required information in the form provided below.
        </Alert>
      </div>

      <DialogContent style={{flex: 1, display: "flex", flexDirection: "row"}}>
        <FormControl sx={{  width: "100%",}}>
          <Select  aria-label={"prerequisitesCourseCodes-select"}
              inputProps={{
                'id': `prerequisitesCourseCodes-select-role`,
                'aria-label': 'Without label'
              }}
              SelectDisplayProps={{
                // @ts-ignore
                "id": `prerequisitesCourseCodes-select-role`
              }}
              id="prerequisitesCourseCodes-select-role"
              multiple
              displayEmpty
              value={formik.values.prerequisitesCourseCodes}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Select Courses</em>;
                }

                return selected.join(', ');
              }}
              MenuProps={MenuProps}
          >
            <MenuItem disabled value="">
              <em>Select Course</em>
            </MenuItem>
            {prerequisites?.sort((a, b) => {
              let fa = a?.value?.toLowerCase(),
                  fb = b?.value?.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            })?.map((name) => {

              return (
                  <MenuItem
                      key={name.id}
                      value={name.value}

                  >
                    <div style={{justifyContent: "space-between", flex: 1, display: "flex", flexDirection: "row"}}>
                      <div>
                        {name.value}
                      </div>
                      {formik.values.prerequisitesCourseCodes.findIndex((c:any) => c == name.value) > -1 ? <div>
                        <CheckRounded color={"success"}/>
                      </div> : null}
                    </div>


                  </MenuItem>
              )
            })}
          </Select>
        </FormControl>
          <Button id={"updateprequisite"}
              style={{marginLeft: 10}}
              disabled={ ((formik?.values?.prerequisitesCourseCodes?.toString() == previousNames?.toString()))}
              variant="contained"
              onClick={handleSubmit}
          >
            Update
          </Button>

      </DialogContent>
      <DialogActions>
        <Button id={"cancelprequisite"} onClick={() => {
          dispatch(setPrerequisiteName(""))
          dispatch(setPrerequisiteId(0))
          setName([])
          formik.setFieldValue('prerequisitesCourseCodes', [])

          handleClose()
        }}>Cancel</Button>

      </DialogActions>
    </Dialog>
  );
});

export default AddPrerequisite;
