import React, {useEffect} from 'react';
import { Box, Typography } from "@mui/material";
import { useMutation } from 'react-query';
import {getWorkspaceById} from "../../requests/workspace.request";

export interface WorkspaceContentProps {
    workspaceId: string;
}
export interface IWorkspaceItem {
    id: number;
    dateCreated: string;
    name: string;
    createdBy: string | null;
    lastModifiedDate: string;
    joinCode: string | null;
    lastModifiedBy: string | null;
    userId: string;
}
const WorkspaceContent: React.FC<WorkspaceContentProps> = ({ workspaceId }) => {
    const { mutate, data, isLoading, isError, error } = useMutation<IWorkspaceItem, Error, string>(
        (id) => getWorkspaceById(id)
    );
    useEffect(() => {
        mutate(workspaceId)

    }, [workspaceId])



    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: "white",
                padding: 6,
                overflowY: "auto",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Workspace {data?.name ?? ""}
            </Typography>
            <Typography variant="body1">Workspace ID: {workspaceId}</Typography>
        </Box>
    );
}

export default WorkspaceContent;
