import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useQuery } from 'react-query';
import {getUserId, getUsers} from '../requests/user.request';
import { GetUsersResponseType } from '../types';
import flat from 'flat';


export const useUserIdQuery = (params?: AxiosRequestConfig['params']) => {
    return useQuery(['userId', params.userId], () => getUserId(params));
}
