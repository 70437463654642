    import React, {useEffect, useMemo, useState} from 'react';
    import {useInfiniteQuery, useMutation, useQuery} from 'react-query';
    import {getChannelById} from "../../requests/channel";
    import {Header} from "./header";
    import {ChatInput} from "./chat-input";
    import {getMessages} from "../../requests/message.request";
    import {MessageList} from "../../MessageList";
    import { format } from 'date-fns';

    const ChannelContent: React.FC<any> = ({ workspaceId, channelId }) => {
        const [page, setPage] = useState(1);
        const { data, isLoading, isError, error } = useQuery(["channels", workspaceId, channelId], () => getChannelById(workspaceId, channelId));

        const { data: channelData,
            isError: channelIsError,
            error: channelError,
            fetchNextPage,
            hasNextPage, isFetching,
            isLoading: channelIsLoading } =
            useInfiniteQuery({
                queryKey: ["messages", channelId],
                queryFn: ({ pageParam }) => getMessages( "",
                    workspaceId,
                    "",
                    pageParam,
                    "",
                    "",
                    channelId),
                getNextPageParam: (lastPage, allPages) => {
                    return lastPage.length ? allPages.length + 1 : undefined;
                },
            });
        const channels = useMemo(() => {
            return channelData?.pages.reduce((acc, page) => {
                return [...acc, ...page];
            }, []);
        }, [channelData]);
        return (
            <div className="bg-white  flex flex-col h-full">
                <Header initialValue={data?.data} title={data?.data?.name}></Header>
                <MessageList channelCreationTime={data?.data?.creationTime}
                             channelName={data?.data?.name}
                             loadMore={fetchNextPage}
                             data={channels}
                             isLoadingMore={channelIsLoading}
                             canLoadMore={hasNextPage}/>

                <ChatInput channelId={channelId} workspaceId={workspaceId} placeholder={""}/>
            </div>
        );
    }

    export default ChannelContent;
