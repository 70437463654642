import {Loader} from "lucide-react";

import {useParams} from "react-router-dom";
import {useInfiniteQuery, useQuery} from "react-query";
import {getMembersById} from "../../../../requests/member.request";
import {getMessages} from "../../../../requests/message.request";
import React, {useMemo} from "react";
import {Header} from "./header";
import {MessageList} from "../../../../MessageList";
import {ChatInput} from "./chat-input";


interface ConversationProps {
  id: any;
};

export const Conversation = ({ id }: ConversationProps) => {
   const {memberId} = useParams() as { memberId: string}

  const { data: member, isLoading: memberLoading } = useQuery(["member", memberId, id], () => getMembersById(memberId))
    const { data: channelData,
        isError: channelIsError,
        error: channelError,
        fetchNextPage,
        hasNextPage, isFetching,
        isLoading: channelIsLoading } =
        useInfiniteQuery({
            queryKey: ["messages", id],
            queryFn: ({ pageParam }) => getMessages( "",
                "",
                id,
                pageParam),
            getNextPageParam: (lastPage, allPages) => {
                return lastPage.length ? allPages.length + 1 : undefined;
            },
        });
    const channels = useMemo(() => {
        return channelData?.pages.reduce((acc, page) => {
            return [...acc, ...page];
        }, []);
    }, [channelData]);



    if (memberLoading) {
        return (
            <div className="h-full flex items-center justify-center">
                <Loader className="size-6 animate-spin text-muted-foreground" />
            </div>
        )
    }
  return (
      <div className="bg-white  flex flex-col h-full">

          <Header memberName={[member.data.member?.user?.userProfile?.firstName,  member.data.member?.user?.userProfile?.middleName, member.data.member?.user?.userProfile?.lastName].filter(c => c != null).join(" ")}></Header>
          <MessageList
              data={channels}
              variant="conversation"
              memberName={[member.data.member?.user?.userProfile?.firstName,  member.data.member?.user?.userProfile?.middleName, member.data.member?.user?.userProfile?.lastName].filter(c => c != null).join(" ")}
              loadMore={fetchNextPage}
              isLoadingMore={channelIsLoading}
              canLoadMore={hasNextPage}/>
          <ChatInput
              placeholder={`Message ${[member.data.member?.user?.userProfile?.firstName,  member.data.member?.user?.userProfile?.middleName, member.data.member?.user?.userProfile?.lastName].filter(c => c != null).join(" ")}`}
              conversationId={id}
          />
      </div>
  );
};
