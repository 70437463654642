import api from "./../../src/requests/config";

import Axios, {AxiosRequestConfig} from "axios";
import axios from "axios";
import {
    GetMessageInputType,
    MessageInputType,
    RemoveMessageInputType,
    ToggleReactionMessageInputType,
    UpdateMessageInputType
} from "../schemas/major.schema";

let cancelToken = Axios.CancelToken.source()
export const getMessages  = async (
    parentMessageId: string,
    workspaceId?: string,
    conversationId?: string,
    page?: number,
    pageSize?: string,
    search?: string,
    channelId?: string,  ) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/messages`, {
        ...{cancelToken: cancelToken?.token},
        params: {
            ...(channelId && {channelId: channelId}),
            ...(parentMessageId && {parentMessageId: parentMessageId}),
            ...(conversationId && {conversationId: conversationId}),
            ...(workspaceId && {workspaceId: workspaceId}),
            ...(page && {page: page}),
            ...(pageSize && {pageSize: pageSize}),
            ...(search && {search: search})
        }
    });
    return res?.data;
};

export const createMessage  = async (
    value: MessageInputType) => {

    cancelToken = axios.CancelToken.source()
    const res = await api.post(`/messages`, {
        ...{
            "body":value.body,
            ...(value.workspaceId && {"workspaceId": value.workspaceId}),
            ...(value.channelId && {"channelId": value.channelId}),
            ...(value.conversationId && {"conversationId": value.conversationId}),
            ...(value.parentMessageId && {"parentMessageId": value.parentMessageId}),
            ...(value.image && {"image": value.image})
        }
    }, {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data;
};
export const updateMessage  = async (
    value: UpdateMessageInputType) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.patch(`/messages/${value.id}`, value, {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data;
};
export const getMessage  = async (
    value: GetMessageInputType) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    console.log(value, "parentMessageId")
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/messages/${value}`, {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data;
};

export const removeMessage  = async (
    value: RemoveMessageInputType) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.post(`/messages/${value.id}`, value).catch(c => {
        console.log(c, "c")
    });

    return res?.data;
};

export const toggleReaction  = async (
    value: ToggleReactionMessageInputType) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.post(`/messages/toggle`, value, {
        params: {
        ...value
    }
    }).catch(c => {
        console.log(c, "c")
    });

    return res?.data;
};