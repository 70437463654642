import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, DialogContent, IconButton, Step, StepLabel, Stepper, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {SpecialOrderSchoolsPage} from "../../pages/SpecialOrderSchools.page";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import Review from "../Checkout/Review";
import {styled} from "@mui/material/styles";
import {Previews} from "../FileUpload/Preview";
import {
    approveStudentsGroupByProgram,
    patchStudentsGroupByProgram,
    postStudentsGroupByProgram
} from "../../requests/student.request";
import {QueryClient, useQueryClient} from "react-query";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {setFiles, setRowsSelected, updatesSo, updatesSoStatus} from "../../redux/features/specialOrder";
import Lottie from "lottie-react";
import noDataAnimation from "../../assets/ty.json";
import {LoadingButton} from "@mui/lab";
import {Transition} from "../../wrapper/Transition";
import AddConfirmationDialog from "./AddConfirmation.dialog";
import useDialog from "../../hooks/useDialog";
import {X} from "lucide-react";
import {setLoading} from "../../redux/features/app";
import {useEffectiveDateQuery} from "../../hooks/useEffectiveDate";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setErrorSnackbar} from "../../helper/utils/helper";

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave?: () => void;
    title?: string
}

const useStyles = makeStyles((theme: any) => ({
    appBar: {
        position: "relative"
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing?.unit * 2,
        marginRight: theme.spacing?.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing?.unit * 2 * 2)]: {
            width: 600,
            marginLeft: "auto",
            marginRight: "auto"
        }
    },
    paper: {
        marginTop: theme.spacing?.unit * 3,
        marginBottom: theme.spacing?.unit * 3,
        padding: theme.spacing?.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing?.unit * 3 * 2)]: {
            marginTop: theme.spacing?.unit * 6,
            marginBottom: theme.spacing?.unit * 6,
            padding: theme.spacing?.unit * 3
        }
    },
    stepper: {
        padding: `${theme.spacing?.unit * 3}px 0 ${theme.spacing?.unit * 5}px`
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing?.unit * 3,
        marginLeft: theme.spacing?.unit
    }
}));

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function setQuerieSpecialOrderGroupBySchool(queryClient: QueryClient, specialOrderProgramId: number, _patchStudentsGroupByProgram: {
    data: { studentId: any; };
}, queryKeyParams?: string, ____status?: string) {
    queryClient.setQueriesData(
        `specialOrderGroupBySchool` + (queryKeyParams ?? ""),
        (oldData: any) => {
            console.log(queryKeyParams + " 1")
            return {
                ...oldData,
                data: oldData?.data?.map((dt: any) => ({
                        ...dt,
                        noStudents: dt?.students?.filter(
                            (t: any) => !(dt.programId == specialOrderProgramId && t.id == _patchStudentsGroupByProgram?.data?.studentId)
                        )?.length,
                        ...____status && {status: ____status},
                        students: dt?.students?.filter(
                            (t: any) => !(dt.programId == specialOrderProgramId && t.id == _patchStudentsGroupByProgram?.data?.studentId)
                        )
                    })
                ),
            };
        }
    );
    if (queryKeyParams == "pending") {
        queryClient.setQueriesData(
            `specialOrderGroupBySchool` + "approved",
            (oldData: any) => {
                console.log(queryKeyParams + " 2")
                return {
                    ...oldData,
                    data: oldData?.data?.map((dt: any) => ({
                            ...dt,
                            noStudents: dt?.students?.length + 1,
                            students: [...dt?.students, ...dt?.students?.filter(
                                (t: any) => (dt.programId == specialOrderProgramId && t.id == _patchStudentsGroupByProgram?.data?.studentId)
                            )]
                        })
                    ),
                };
            }
        );
    }


}

const SpecialOrderView = React.memo((props: IProps) => {
    const dispatch = useDispatch();
    const sos = useSelector((store: RootState) => store.specialOrder?.sos)
    const [referenceNumbers, setReferenceNumbers] = useState<{ name: any, referenceNumber: any }[]>([])
    const [loading, _setLoading] = useState<boolean>(false)
    const queryClient = useQueryClient();
    const {open, handleClose, onSave, title} = props;
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const files = useSelector((store: RootState) => store.specialOrder?.requirements?.files)
    const roles = useAppSelector((store) => store?.auth?.user?.roles) as
        | "User"
        | "Admin"
        | "SuperAdmin";
    const {schoolId} = useAddEntities();
    const paymentGateway = useSelector((store: RootState) => store.specialOrder?.paymentGateway)
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const [activeStep, setActiveStep] = useState(0)
    const specialOrderMajorId = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorId)
    const specialOrderSchoolId = useSelector((store: RootState) => store?.specialOrder.specialOrderSchoolId)
    const specialOrderIdValue = useSelector((store: RootState) => store?.specialOrder.specialOrderIdValue)
    const specialOrderProgramId = useSelector((store: RootState) => store?.specialOrder.specialOrderProgramId)
    const specialOrderProgramName = useSelector((store: RootState) => store.specialOrder?.specialOrderProgramName)
    const specialOrderMajorName = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorName)
    const {data: effectiveDate} = useEffectiveDateQuery({
        ...(schoolId && {schoolId: schoolId})
    });
    const paramsStatus = useSelector((store: RootState) => store.specialOrder?.paramsStatus)
    const styles = useStyles();
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const steps = ["Candidates", "Payments", "Review"];
    const queryKey = useAppSelector(
        (store) => store?.specialOrder?.queryKey
    );

    async function handleSelectSubmit(_sos: any[]) {
        _setLoading(true)
        var _referenceNumbers: { name: any, referenceNumber: any }[] = [];
        try {
            for (let i = 0; i < _sos?.length; i++) {
                var __sos = _sos?.[i]
                if (type?.id == 1 && roles.toLowerCase() == "admin") {
                    try {
                        if (__sos.studentId > -1) {
                            dispatch(updatesSoStatus(__sos.studentId))
                        }
                        var _patchStudentsGroupByProgram = await postStudentsGroupByProgram(__sos, paymentGateway)
                        setQuerieSpecialOrderGroupBySchool(queryClient, specialOrderProgramId, _patchStudentsGroupByProgram, paramsStatus);

                        queryClient.setQueriesData('pending-count', (oldData: any) => {
                            return _patchStudentsGroupByProgram.soPendingCount
                        })
                        _referenceNumbers.push({
                            name: __sos?.name,
                            referenceNumber: _patchStudentsGroupByProgram?.data?.referenceNumber
                        })
                    } catch (e) {
                        setErrorSnackbar(e, setSnackbarStore);
                    }
                } else if ((type?.id == 2 || type?.id == 4) && roles.toLowerCase() == "admin") {
                    try {
                        var _patchStudentsGroupByProgram = await patchStudentsGroupByProgram(__sos)
                        setQuerieSpecialOrderGroupBySchool(queryClient, specialOrderProgramId, _patchStudentsGroupByProgram, paramsStatus);

                        queryClient.setQueriesData('pending-count', (oldData: any) => {
                            return _patchStudentsGroupByProgram.soPendingCount
                        })
                        queryClient.setQueriesData('soapprovedCount', (oldData: any) => {
                            return _patchStudentsGroupByProgram.soApprovedCount
                        })
                        _referenceNumbers.push({
                            name: __sos?.name,
                            referenceNumber: _patchStudentsGroupByProgram?.data?.referenceNumber
                        })
                    } catch (e) {
                        setErrorSnackbar(e, setSnackbarStore);
                    }
                }
                else if (type?.id == 7 && roles.toLowerCase() == "admin") {
                    try {
                        var _patchStudentsGroupByProgram = await approveStudentsGroupByProgram(__sos)
                        setQuerieSpecialOrderGroupBySchool(queryClient, specialOrderProgramId, _patchStudentsGroupByProgram, paramsStatus, "approved");

                        queryClient.setQueriesData('pending-count', (oldData: any) => {
                            return _patchStudentsGroupByProgram.soPendingCount
                        })
                        queryClient.setQueriesData('soapprovedCount', (oldData: any) => {
                            return _patchStudentsGroupByProgram.soApprovedCount
                        })
                        _referenceNumbers.push({
                            name: __sos?.name,
                            referenceNumber: _patchStudentsGroupByProgram?.data?.referenceNumber
                        })
                    } catch (e) {
                        setErrorSnackbar(e, setSnackbarStore);
                    }
                }
                var __queryKey = 'specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId
                queryClient.setQueriesData(
                    __queryKey,
                    (oldData: any) => {
                        var newData = {
                            ...oldData,
                            data: oldData?.data?.map(
                                (t: any) => {
                                    var _t = t
                                    _t.students = _t.students.filter((f: any) => !(f.id == __sos.studentId))
                                    return _t
                                })
                        }
                        var stringify = JSON.stringify(newData)
                        return JSON.parse(stringify);
                    }
                );
                queryClient.setQueriesData(
                    `studentsGroupByProgram`,
                    (oldData: any) => {
                        return {
                            ...oldData,
                            data: oldData?.data?.filter(
                                (t: any) => !(t.id == __sos.studentId &&
                                    t.schoolId == __sos.schoolId &&
                                    t.programId == __sos.programId)
                            ),
                        };
                    }
                );
            }
            queryClient.setQueriesData(
                `specialOrderGroupBySchool` + (paramsStatus ?? ""),

                (oldData: any) => {

                    return {
                        ...oldData,
                        data: oldData?.data?.filter(
                            (t: any) => !(t.programId == specialOrderProgramId && t.students.length == 0)
                        ),
                    };
                }
            );

            setReferenceNumbers(_referenceNumbers)
            await queryClient.invalidateQueries()
            _setLoading(false)

        } catch (e) {
            _setLoading(false)
        }
    }


    const {
        handleOpen: handleTransactionOpenConfirmation,
        handleClose: handleTransactionCloseConfirmation,
        open: openTransactionConfirmation,
    } = useDialog();

    const handleClearStudent = () => {

    }

    const handleNext = async (_sos?: any) => {
        if ((type?.id == 2 && roles.toLowerCase() == "admin") && !(activeStep === steps.length)) {
            dispatch(setLoading(true))
            await handleSelectSubmit(sos)
            dispatch(setLoading(false))
            dispatch(setRowsSelected([]))
            handleClearStudent()
            dispatch(updatesSo([]))
            setActiveStep(steps.length)
        } else if ((type?.id == 7 && roles.toLowerCase() == "admin") && !(activeStep === steps.length)) {
            dispatch(setLoading(true))
            await handleSelectSubmit(sos)
            dispatch(setLoading(false))
            dispatch(setRowsSelected([]))
            handleClearStudent()
            dispatch(updatesSo([]))
            setActiveStep(steps.length)
        } else if (activeStep === steps.length - 1) {
            await handleSelectSubmit(sos)
            handleClearStudent()
            dispatch(setRowsSelected([]))
            dispatch(updatesSo([]))
            setActiveStep(as => as + 1)
        } else if (typeMemo && !(activeStep === steps.length)) {
            setActiveStep(as => as + 1)
        } else if (activeStep === steps.length) {
            handleClearStudent()
            dispatch(setRowsSelected([]))
            dispatch(updatesSo([]))
            handleReset()
            _handleClose()

        } else {
            if (onSave) {
                onSave()
            }
            handleClearStudent()
            _handleClose()
        }


    };

    const handleBack = () => {
        if (activeStep === 1) {
            handleTransactionOpenConfirmation()

        } else if (activeStep === steps.length || activeStep === 0) {
            _handleClose()

        } else if (typeMemo) {
            setActiveStep(as => as - 1)
        } else {
            _handleClose()
        }

    };

    const handleReset = () => {
        setActiveStep(0)
        dispatch(setFiles([]));

    };

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <SpecialOrderSchoolsPage handleNext={handleNext}/>;
            case 1:
                return <Review/>;
            case 2:
                return <Previews/>;
            default:
                return <div/>;
        }
    }

    const typeMemo = useMemo(() => {
        return type?.id != 3 &&
            (
                (type?.id == 1 && roles.toLowerCase() == "admin" && (paramsStatus == null)) ||
                (type?.id == 2 && roles.toLowerCase() == "admin" && (paramsStatus == "pending"))
            )
    }, [type?.id, roles.toLowerCase(), paramsStatus])


    useEffect(() => {
        handleReset()
    }, [specialOrderProgramId])
    useEffect(() => {
        _handleClose()
    }, [])


    const _handleClose = (event?: any, reason?: any) => {
        if (reason && reason == "backdropClick")
            return;
        dispatch(updatesSo([]))
        dispatch(setRowsSelected([]))

        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        dispatch(setFiles([]));

        handleClose()


    }

    return (
        <>
            <Dialog
                disableEscapeKeyDown
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={_handleClose}
                maxWidth="xl"
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{fontSize: 16}}>
                    <div className={"flex justify-between items-center"}>
                        <div className={"flex flex-col "}>
                            <div>{title ?? ""}</div>
                            <Typography fontStyle={"italic"}
                                        variant={"subtitle1"}>{specialOrderProgramName ?? ""}</Typography>
                            <Typography fontStyle={"italic"}
                                        variant={"subtitle2"}>{(specialOrderMajorName ? specialOrderMajorName : "") ?? ""}</Typography>
                        </div>
                        <Tooltip title={"close"} onClick={_handleClose}>
                            <IconButton><X/></IconButton>
                        </Tooltip>

                    </div>


                </DialogTitle>

                <DialogContent>
                    <div style={{
                        borderBottomWidth: "1.2px",
                        borderTopWidth: "1.2px",
                        borderStyle: "solid",
                        borderColor: "#E0E0E0"
                    }}>
                        {(typeMemo && type?.id != 2) ? <div style={{paddingTop: 10}}>
                            <Stepper activeStep={activeStep}>
                                {steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div> : null}
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <div style={{paddingTop: 12}}>
                                        <Box display="flex" flexDirection={"column"} justifyContent="center"
                                             alignItems="center"
                                             p={3}>
                                            <Lottie loop={false} animationData={noDataAnimation}/>
                                            <Typography variant="subtitle1">
                                                References numbers: {referenceNumbers.map(c => {
                                                return c.name + " #" + c.referenceNumber + ", "
                                            })}
                                            </Typography>
                                        </Box>


                                    </div>

                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}

                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </div>

                </DialogContent>

                <DialogActions>
                    <div className={styles.buttons}>
                        {((activeStep !== 0 || !typeMemo || (activeStep == 0)) && !(activeStep === steps.length)) && (
                            <Button disabled={loading}

                                    onClick={handleBack}
                                    className={styles.button}
                            >
                                {(typeMemo && activeStep > 0) ? "Cancel" : "Close"}
                            </Button>
                        )}
                        {(typeMemo && !(type?.id == 2 && roles.toLowerCase() == "admin")) || activeStep === steps.length ?
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                color="primary"
                                disabled={(effectiveDate?.amount == 0 || effectiveDate?.amount != 0) && typeMemo && (sos?.length == 0 && activeStep == 0)
                                    /*|| (files?.length == 0 && activeStep == 1)) */
                                    && !(activeStep === steps.length)}
                                onClick={handleNext}
                                className={styles.button}
                            >
                                {((activeStep === steps.length - 1))

                                    ? "Submit" : (activeStep === steps.length) ? "Close" : "Next"}
                            </LoadingButton> : null
                        }

                    </div>
                </DialogActions>
            </Dialog>
            <AddConfirmationDialog
                isLoading={loading}
                title={"Do you want cancel your transaction?"}
                open={openTransactionConfirmation}
                handleClose={handleTransactionCloseConfirmation}
                onSave={() => {
                    dispatch(setRowsSelected([]))
                    dispatch(updatesSo([]))
                    setActiveStep(as => as - 1)
                    handleTransactionCloseConfirmation()
                }}></AddConfirmationDialog>

        </>

    );
});

export default SpecialOrderView;
