import * as Yup from "yup";

export interface ICourseSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  code: string;
  description: string;
  numberOfUnits?: string | null;
}

export const courseSchema: Yup.SchemaOf<ICourseSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  code: Yup.string().required("Code is required"),
  description: Yup.string().required("Description is required"),
  numberOfUnits: Yup.string().nullable(),
});

export type CourseType = Yup.InferType<typeof courseSchema>;
