import {Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {AddRounded} from "@mui/icons-material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, MUIDataTableToolbar, TableToolbar} from "mui-datatables";
import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useDebounce} from "../../hooks/useDebounce";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {useSchoolQuery} from "../../hooks/useSchoolQuery";
import {useAddEntities} from "../../hooks/useAddEntities";
import useDialog from "../../hooks/useDialog";
import {setEditPricingRuleValue, updatesSo} from "../../redux/features/specialOrder";
import {useLevelQuery} from "../../hooks/useLevelQuery";
import {usePricingRulesGroupBySchoolQuery} from "../../hooks/usePricingRulesGroupBySchoolQuery";
import CreatePricingRulesDialog from "../dialogs/CreatePricingRules.dialog";
import moment from "moment-timezone";
import {usePager} from "../../hooks/usePager";
import {Edit, Trash} from "lucide-react";
import {setDeleteDialog} from "../../redux/features/app";
import {deleteSchoolProgramPricingRules} from "../../requests/rules.request";
import UpdatePricingRules from "../dialogs/UpdatePricingRules.dialog";
import {useQueryClient} from "react-query";

interface IReportTableProps {
    title: string;
}

export const SpecialOrderPricingRuleTable: FC<IReportTableProps> = (prop) => {
    const dispatch = useDispatch();
    const {schoolId, regionId, userInstitutionalName} = useAddEntities();
    const [selectedSchool, setSelectedSchool] = React.useState<any>({name: "All", id: undefined});
    const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [selectedProgram, setSelectedProgram] = React.useState<any>({name: "All", id: undefined});
    const [selectedYearLevel, setSelectedYearLevel] = React.useState<any>({name: "All", id: undefined});
    const user = useAppSelector((store) => store?.auth?.user)
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: undefined,
        name: "All"
    });
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const queryClient = useQueryClient();
    const paramsStatus = useSelector((store: RootState) => store.specialOrder?.paramsStatus)
    const [searchProgram, setSearchProgram] = React.useState("");
    const [degree, setDegree] = useState<any>(0)
    const debouncedSearchProgram = useDebounce(searchProgram, 500);
    const roles = useAppSelector((store) => store?.auth?.user?.roles) as
        | "User"
        | "Admin"
        | "SuperAdmin";
    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    const rowSelectionChange = (newTableState: any) => {
        var _updates: any[] = []
        newTableState?.selectedRows?.data?.forEach((c: { index: number, dataIndex: number }) => {
            var _data = newTableState?.data?.[c.dataIndex]?.data

            _updates.push({
                studentId: _data?.[9],
                specialOrderId: _data?.[3],
                schoolId: _data?.[2],
                programId: _data?.[1]
            })
            return c
        })
        dispatch(updatesSo(_updates))
    }


    const {data: dataSchools} = useSchoolQuery({
        pageSize: "1000",
        search: '',
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
    });
    const {data: levels} = useLevelQuery();
    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);

    const regionSetting = useAppSelector((store) => store?.school.regionSetting);

    const {data: pricingRules, refetch, isLoading} = usePricingRulesGroupBySchoolQuery({
        pageSize: "10",
        search: debouncedSearchKey,
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
        ...(regionSetting?.id && {regionId: regionSetting?.id}),
        ...(regionId?.id && {regionId: regionId?.id}),
        ...(typeof regionId == "number" && {regionId: regionId}),
        ...((paramsStatus) && {status: paramsStatus}),
        ...((degree > 0 && degree != 2) && {levelId: degree}),
        ...(selectedYearLevel?.id > 0 && {yearLevel: selectedYearLevel?.id}),
        ...((selectedSchool?.id > 0 || schoolId) && {schoolId: selectedSchool?.id || schoolId}),
        ...(selectedProgram?.name ? selectedProgram?.name != "All" && selectedProgram && {programName: selectedProgram?.name} : null),
        ...(page && {page: page + 1}),
    });
    const totalCount = useMemo(() => {
        return pricingRules?.totalCount
    }, [pricingRules?.totalCount])
    const _pageSize = useMemo(() => {
        return pricingRules?.pageSize
    }, [pricingRules?.pageSize])

    const options: MUIDataTableOptions = {
        filter: false,
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        }, serverSide: true,
        search: true,
        elevation: 0, page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
        count: totalCount,
        selectableRows: 'none',
        resizableColumns: false,
        rowsPerPage: pageSize,
        onTableChange: (action, newTableState) => {
            switch (action) {
                case "search":
                    setSearchKey(newTableState.searchText);
                    break;
                case "rowSelectionChange":
                    rowSelectionChange(newTableState);
                    break;
                case "changeRowsPerPage":
                    changeRowsPerPage(newTableState.rowsPerPage);
                    break;

                case "changePage":
                    changePage(newTableState.page, (newTableState.data?.length ?? 100));
                    break;

            }
        },
    };


    const handleDelete = (id: string | number, name: string | undefined, schoolName: string | undefined) => {
        dispatch(
            setDeleteDialog({
                open: true,
                data: {
                    id,
                    title: `Delete Pricing Rule`,
                    content: "pricingRulesGroupBySchool",
                    body: `Are you sure you want to delete this requirement ${name} that belong to ${schoolName ?? "-"}?`,
                    onDelete: async () => {
                        try {
                            var res = await deleteSchoolProgramPricingRules(id)
                            queryClient.invalidateQueries("pricingRulesGroupBySchool");
                            return res
                        } catch (error) {
                            throw error
                        }
                    },
                },
            })
        );
    };

    const {
        handleOpen: handleOpenEditPricingRule,
        handleClose: handleCloseEditPricingRule,
        open: openEditPricingRule,
    } = useDialog();

    const schoolAdminColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        }, {
            name: "name",
            label: "Rule Name"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: false,
            },
        }, {
            name: "schoolId",
            label: "SchoolId"?.toUpperCase(),
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        }, {
            name: "schoolName",
            label: "School Name"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: false,
            },
        }, {
            name: "amount",
            label: "Amount"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: false,
            },
        }, {
            name: "createdByName",
            label: "Created By"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: false,
            },
        },
        {
            name: "effectiveDateFrom",
            label: "Effective Date"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: true,
                customBodyRender: (data: any) => {
                    return (
                        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
                            <strong>{moment.utc(data).tz('Asia/Manila').format('MMMM D, YYYY')}</strong>
                            <p style={{margin: 0}}>{moment.utc(data).tz('Asia/Manila').format("h:mm a")}</p>
                        </div>

                    );
                },
            },
        },
        {
            name: "id",
            label: "Action",
            options: {
                filter: true,
                sort: true,

                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const _id =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "id")
                            ];
                    const _name =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "name")
                            ];
                    const _schoolName =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "schoolName")
                            ];
                    const _schoolId =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "schoolId")
                            ];

                    const _amount =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "amount")
                            ];

                    const _effectiveDateFrom =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "effectiveDateFrom")
                            ];
                    const _effectiveDateTo =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "effectiveDateTo")
                            ];


                    return (
                        <Stack direction="row" spacing={1}>
                            <Tooltip title="Delete" arrow>
                                <IconButton
                                    color="error"
                                    size="small"
                                    value={value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(value, _name, _schoolName);
                                    }
                                    }
                                >
                                    <Trash/>
                                </IconButton>
                            </Tooltip>
                            <div style={{flex: 1, display: "flex",}}>
                                <div style={{paddingRight: 6}}>
                                    <Tooltip title="Update" arrow>
                                        <IconButton color={"primary"} id={"view" + tableMeta?.rowIndex} onClick={() => {
                                            dispatch(setEditPricingRuleValue({
                                                id: _id,
                                                name: _name,
                                                amount: _amount,
                                                effectiveDateFrom: _effectiveDateFrom,
                                                effectiveDateTo: _effectiveDateTo,
                                                schoolId: _schoolId,
                                                schoolName: _schoolName
                                            }))
                                            handleOpenEditPricingRule()
                                        }}>
                                            <Edit/>
                                        </IconButton>
                                    </Tooltip>

                                </div>


                            </div>
                        </Stack>


                    );
                },
            },
        }
    ];
    useEffect(() => {
        if (dataSchools?.data && user?.school?.id) {
            setSelectedSchool(user?.school?.id ? dataSchools?.data?.find(c => c.id == user?.school?.id) : {
                name: "All",
                id: 0
            });
        } else if (!selectedSchool) {
            setSelectedSchool({name: "All", id: undefined})
        }
    }, [dataSchools]);
    const {
        handleOpen: handleOpenAddCreatePricingRule,
        handleClose: handleCloseAddCreatePricingRule,
        open: openAddCreatePricingRule,
    } = useDialog();
    return <>
        <Helmet>
            <title>Pricing Rules</title>
        </Helmet>

        <div>
            <MUIDataTable
                options={options}
                components={{
                    TableToolbar: useCallback((props: MUIDataTableToolbar) => {
                        return (
                            <>
                                <TableToolbar {...props} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        mx: 3,
                                        my: 1,
                                    }}
                                >
                                    <Stack direction="row" gap={1}>

                                    </Stack>

                                    {type?.id == 2 && ["Admin"].indexOf(roles) > -1 ?
                                        <Stack spacing={1} direction="row">
                                            <Button
                                                onClick={handleOpenAddCreatePricingRule}
                                                startIcon={<AddRounded/>}
                                                variant="contained"
                                            >
                                                Add Pricing Rule
                                            </Button>
                                        </Stack> : null}
                                </Box>
                            </>
                        );
                    }, []),
                }}
                title={
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div style={{display: "flex", flex: 1}}>
                            <Typography variant={"h6"}>{prop?.title}</Typography>
                        </div>

                    </div>

                }
                data={pricingRules?.data as any}
                columns={schoolAdminColumns}
            />
        </div>
        <CreatePricingRulesDialog
            title={"Pricing Rules"}
            open={openAddCreatePricingRule}
            handleClose={handleCloseAddCreatePricingRule}
        />
        <UpdatePricingRules
            title={"Edit Pricing Rules"}
            open={openEditPricingRule}
            handleClose={handleCloseEditPricingRule}
        />
    </>
}


