import { AxiosRequestConfig } from 'axios';
import { useQuery } from "react-query";
import {getSchoolById, getSchools} from "../requests/school.request";
import { getSchoolYearsSelect } from '../requests/schoolYear.request';

export const useSchoolIdQuery = (params: any) => {
    return useQuery(["schoolId"], () => getSchoolById(params), {
        refetchOnWindowFocus: false,
    });
}
