import {useParams} from "react-router-dom";
import {useAppSelector} from "../redux/store";
import {useLocation} from "react-router";

export function useAddEntities() {
    const {schoolId} = useParams() as {
        schoolId: string;
        institutionalCode: string;
    };

    const {state} = useLocation() as { state: { institutionalCode: string } };
    const userInstitutionalCode = useAppSelector(
        (store) => store?.auth?.user?.school?.code
    );

    const userInstitutionalName = useAppSelector(
        (store) => store?.auth?.user?.school?.name
    );


    const schoolSetting = useAppSelector((store) => store?.school.schoolSetting);
    const type = useAppSelector((store) => store?.auth?.user?.type?.name);
    const roles = useAppSelector((store) => store?.auth?.user?.roles);
    const userregionId = useAppSelector((store) => store?.auth?.user?.region?.id);
    const regionSetting = useAppSelector((store) => store?.school.regionSetting);
    const usersSchoolId = useAppSelector(
        (store) => store?.auth?.user?.school?.id
    );


    let schoolIdParams =
        type?.toLowerCase() == "ched" || type?.toLowerCase() == "national" || roles?.toLowerCase() == "superadmin"
            ? (schoolId ?? schoolSetting?.id)
            : usersSchoolId != null
                ? usersSchoolId.toString()
                : "";
    let institutionalCodeParams =
        type?.toLowerCase() == "ched" || type?.toLowerCase() == "national" || roles?.toLowerCase() == "superadmin"
            ? state?.institutionalCode
            : userInstitutionalCode != null
                ? userInstitutionalCode
                : "";

  return {
      regionId: (type?.toLowerCase() == "ched" || type?.toLowerCase() == "national" || type?.toLowerCase() == "school") && (roles?.toLowerCase() == "user" || roles?.toLowerCase() == "admin") ? userregionId : regionSetting,
      schoolId: schoolIdParams,
      institutionalCode: institutionalCodeParams,
      type,
      usersSchoolId,
      userInstitutionalName,
  };
}
