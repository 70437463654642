import api from "./config";

export const downloadExcelCourseTemplate = async () => {
  const res = await api.get("/download/add-course", {
    responseType: "blob",
  });
  return res?.data;
};
export const downloadExcelCourseLinkTemplate = async () => {
  const res = await api.get("/courses/download-course-template");
  return res?.data;
};
export const downloadExcelCurriculumLinkTemplate = async () => {
  const res = await api.get("/curriculums/download-curriculum-template");
  return res?.data;
};
export const downloadExcelCourseCurriculumLinkTemplate = async () => {
  const res = await api.get("/courses/download-course-curriculum-template");
  return res?.data;
};
