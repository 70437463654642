import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '../../redux/features/app';
import {RootState} from '../../redux/store';
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {setIsApproveDialog} from "../../redux/features/program";

const IsApproveDialog = () => {
    const isApproveDialogState = useSelector((state: RootState) => state.program?.isApproveDialog);

    const dispatch = useDispatch();
    const queryClient = useQueryClient()
    const handleClose = useCallback(() => {
        dispatch(setIsApproveDialog({
            open: false,
        }))
    }, [])

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const handledIsApprove = async () => {
        if (isApproveDialogState?.data?.id) {
            try {
                dispatch(setLoading(true))
                var result = await isApproveDialogState?.data.handleIsApprove(isApproveDialogState?.data?.id, !isApproveDialogState?.data?.isApprove)

                setSnackbarStore({
                    message: (isApproveDialogState?.data?.title as any) + " is successfully " + (!isApproveDialogState?.data?.isApprove ? "disabled" : "approved"),
                    severity: 'success',
                    open: true
                })
                queryClient.setQueriesData(`${isApproveDialogState?.data?.content}`, (oldData: any) => {

                    return {
                        ...oldData,
                        data: oldData.data.map((t: any) => t.id == isApproveDialogState?.data?.id ? {
                            ...t,
                            isApprove: !isApproveDialogState?.data?.isApprove
                        } : t)
                    }
                })

                dispatch(setIsApproveDialog({
                    open: false,
                    data: {
                        ...isApproveDialogState?.data,
                        isApprove: isApproveDialogState?.data?.isApprove
                    }
                }))
                await queryClient.invalidateQueries(`${isApproveDialogState?.data?.content}`);
            } catch (error: any) {
                console.error({error})
                handleClose()
                setErrorSnackbar(error, setSnackbarStore)
            } finally {
                handleClose()
                dispatch(setLoading(false))
            }
        }
    }


    return (
        <Dialog open={isApproveDialogState?.open ?? false} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{isApproveDialogState?.data?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want
                    to {!isApproveDialogState?.data?.isApprove ? "Disabled" : "Approve"} this {isApproveDialogState?.data?.content}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelIsApprove"} color='inherit' onClick={handleClose}>Cancel</Button>
                <Button id={"addIsApprove"} variant='outlined'
                        color={isApproveDialogState?.data?.isApprove ? 'success' : "inherit"}
                        onClick={handledIsApprove}>{!isApproveDialogState?.data?.isApprove ? "Disabled" : "Approve"} </Button>
            </DialogActions>
        </Dialog>

    )
}
export default IsApproveDialog;

