import * as React from "react";
import {useMemo} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {useDropzone} from "react-dropzone";
import {Box, IconButton, LinearProgress, MenuItem, Stack, TextField, Typography,} from "@mui/material";
import PostAddRounded from "@mui/icons-material/PostAddRounded";
import {useDispatch} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useMutation, useQueryClient} from "react-query";
import {DeleteRounded, SaveRounded} from "@mui/icons-material";
import * as xlsx from "xlsx";
import _ from "lodash";
import {ExcelCourseType} from "../../types";
import {uploadCourses as uploadCoursesRequest, uploadCoursesCurriculum} from "../../requests/course.request";
import LoadingButton from "@mui/lab/LoadingButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function UploadCourseCurriculumDialog() {
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState<File[] | null>(null);
  const [courses, setCourses] = React.useState<ExcelCourseType[]>([]);
  const [progress, setProgress] = React.useState(0);
  const queryClient = useQueryClient();
  const { mutate: uploadCourses, isLoading } = useMutation({
    mutationKey: "uploadCourses",
    mutationFn: (data: ExcelCourseType[]) => uploadCoursesCurriculum(data),
  });

  const dispatch = useDispatch();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleReset();
    setOpen(false);
  };

  const handleReset = React.useCallback(() => {
    setFiles(null);
    setProgress(0);
  }, []);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {

    setFiles(acceptedFiles);
  }, []);

  React.useEffect(() => {
    if (files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = reader.result;
        const workbook = xlsx.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        const newCourses = json.map((course: any) => {
          return _.mapKeys(course, (value, key) => {
            return _.camelCase(key);
          });
        });


        setCourses(newCourses as ExcelCourseType[]);
      };
      reader.readAsArrayBuffer(files[0]);
    } else {
      setCourses([]);
    }
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xls",
        ".csv",
      ],
    },
    maxFiles: 1,
  });
  const handleSave = React.useCallback(async () => {

    if (courses.length > 0) {
      courses.map(c => {
        try {
          var cprerequisiteCourseCodes = c.prerequisiteCourseCodesExampleEng1Eng2Eng3?.toString()?.split(/\s*(?:,|$)\s*/)
          if (cprerequisiteCourseCodes?.length > 0) {
            c.prerequisiteCourseCodes = cprerequisiteCourseCodes
          } else {
            c.prerequisiteCourseCodes = []
          }
        } catch (e) {

        }
        c.programName = c.programName?.toString()
        c.majorName = c.major?.toString()
        c.curriculumName = c.curriculumName?.toString()
        c.courseName = c.courseName?.toString()
        c.courseCode = c.courseCode?.toString()
        c.courseDescription = c.courseDescription?.toString()
        c.laboratory = c.noOfLaboratory || c.noOfLaboratoryUnits
        c.lecture = c.noOfLecture || c.noOfLectureUnits
        return c
      });
      void uploadCourses(courses, {
        onSuccess: (res) => {
          queryClient.invalidateQueries("courses");
          queryClient.invalidateQueries("curriculums");
          setSnackbarStore({
            open: true,
            message: res.message,
            severity: "success",
          });
          handleClose();
        },
        onError: (error) => {
          handleReset()
          let errorMsg = (error as any)?.response?.data?.message ?? "Something went wrong while uploading courses";
          setSnackbarStore({
            open: true,
            message: errorMsg,
            severity: "error",
          });
        },
      });
    }
  }, [courses]);

  const handleDeleteCourse = React.useCallback(
    (index: number) => {
      const newCourses = [...courses];
      newCourses.splice(index, 1);
      setCourses(newCourses);
    },
    [courses]
  );

  const handleUpdateCourse = React.useCallback(
    (index: number, key: keyof ExcelCourseType, value: any) => {
      const newCourses = [...courses];
      newCourses[index][key] = value;
      setCourses(newCourses);
      //validateError(newCourses)
    },
    [courses]
  );

  const validateForm = useMemo(() => {
    return courses?.filter(c => c?.error == true ||  c?.prerequisiteError == true ).length
  }, [courses?.filter(c => c?.error == true ||  c?.prerequisiteError == true )])





  return (
    <>
      <Button
        variant="contained"
        component="label"
        id={"bulkAddCourse"}
        startIcon={<PostAddRounded />}
        onClick={handleClickOpen}
      >
        Bulk Curriculum and Course
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Import Course Excel</DialogTitle>
        <DialogContent>
          <div
            {...getRootProps({
              style: {
                border: "3px dashed grey",
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              },
            })}
          >
            <input {...getInputProps()} />
            {files ? (
              files.map((file: File) => {
                return (
                  <Box key={file.name}>
                    <Typography>File Name: {file.name}</Typography>
                    <Typography>
                      Size: {(file.size / 1024).toFixed(2)} kb
                    </Typography>
                  </Box>
                );
              })
            ) : isDragActive ? (
              <Typography>Drop it here ...</Typography>
            ) : (
              <Typography component="div">
                Drag 'n' drop <strong>.xlsx</strong> file here, or click to
                select file
              </Typography>
            )}
          </div>
          <Box sx={{ my: 2, display: progress > 0 ? "block" : "none" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="overline">Uploading</Typography>
              <Typography variant="overline">{progress}%</Typography>
            </Box>
            <LinearProgress sx={{ borderRadius: "10%" }} />
          </Box>
          <Stack spacing={1}>
            {courses.map((course, index) => {

              return (
                <Box key={index} sx={{ my: 1 }}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        size={"small"}
                        label="Program Name"
                        sx={{ width: "15%" }}
                        value={course.programName}
                        onChange={(e) =>
                            handleUpdateCourse(
                                index,
                                "programName",
                                e.target.value
                            )
                        }
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        size={"small"}
                        label="Major Name"
                        sx={{ width: "15%" }}
                        value={course.major}
                        onChange={(e) =>
                            handleUpdateCourse(
                                index,
                                "major",
                                e.target.value
                            )
                        }
                    />

                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        size={"small"}
                      label="Curriculum"
                      sx={{ width: "15%" }}
                      value={course.curriculumName}
                      onChange={(e) =>
                        handleUpdateCourse(
                          index,
                          "curriculumName",
                          e.target.value
                        )
                      }
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        error={Boolean(course.error || false)}
                       helperText={Boolean(course.error || false) ? "" : ""}
                        size={"small"}
                      sx={{ width: "10%" }}
                      label="Course Code"
                      value={course.courseCode}
                      onChange={(e) =>{
                        handleUpdateCourse(index, "courseCode", e.target.value)
                      }}
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        sx={{ width: "10%" }}
                        size={"small"}
                      label="Name"
                      value={course.courseName}
                      onChange={(e) =>
                        handleUpdateCourse(index, "courseName", e.target.value)
                      }
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        sx={{ width: "10%" }}
                        size={"small"}
                      label="Description"
                      value={course.courseDescription}
                        onChange={(e) =>
                            handleUpdateCourse(index, "courseDescription", e.target.value)
                        }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        size={"small"}
                      label="Year Level"
                      sx={{ width: "10%" }}
                      select
                      value={course.yearLevel}
                      onChange={(e) =>
                        handleUpdateCourse(index, "yearLevel", e.target.value)
                      }
                    >
                      <MenuItem
                                 value={1}><div style={{fontSize: 14}}>1st Year</div></MenuItem>
                      <MenuItem  sx={{fontSize: 14}}
                                 value={2}><div style={{fontSize: 14}}>2nd Year</div></MenuItem>
                      <MenuItem  sx={{fontSize: 14}}
                                 value={3}><div style={{fontSize: 14}}>3rd Year</div></MenuItem>
                      <MenuItem  sx={{fontSize: 14}}
                                 value={4}><div style={{fontSize: 14}}>4th Year</div></MenuItem>
                      <MenuItem  sx={{fontSize: 14}}
                                 value={5}><div style={{fontSize: 14}}>5th Year</div></MenuItem>
                    </TextField>

                    <TextField
                        inputProps={{style: {fontSize: 14}}}

                        size={"small"}
                      label="Semester"
                      sx={{ width: "10%" }}
                      select
                      value={course.semester}
                      onChange={(e) =>
                        handleUpdateCourse(index, "semester", e.target.value)
                      }
                    >
                      <MenuItem value={1}><div style={{fontSize: 14}}>1st Semester</div></MenuItem>
                      <MenuItem value={2}><div style={{fontSize: 14}}>2nd Semester</div></MenuItem>
                      <MenuItem value={3}><div style={{fontSize: 14}}>Mid Year/Summer</div></MenuItem>
                    </TextField>
                    <TextField
                        inputProps={{style: {fontSize: 14}}}

                        size={"small"}
                      sx={{ width: "5%" }}
                      label="Lec"
                      value={ course.noOfLectureUnits}
                      onChange={(e) =>
                        handleUpdateCourse(index, "noOfLectureUnits", e.target.value)
                      }
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}

                        size={"small"}
                      sx={{ width: "5%" }}
                      label="lab"
                      value={course.noOfLaboratoryUnits}
                      onChange={(e) =>
                        handleUpdateCourse(index, "noOfLaboratoryUnits", e.target.value)
                      }
                    />
                    <TextField
                        inputProps={{style: {fontSize: 14}}}
                        size={"small"}
                        error={course.prerequisiteError}
                        sx={{ width: "15%" }}

                        label="Prerequisite Course Codes"
                        value={course.prerequisiteCourseCodesExampleEng1Eng2Eng3}
                       /* onBlur={(e) =>
                            postCheckCourse( {
                              curriculumName: courses[index]['curriculumName'],
                              prerequisiteCourseCodes: e.target.value.split(',')
                            })
                        }*/
                        onChange={(e) =>{
                          var courseCodes = courses.map(c => c.courseCode)
                          var values = e.target.value.split(/\s*(?:,|$)\s*/);

                          const count = values.length
                          var _findIndex = -1
                          let i = 0;
                          for (i = 0; i < count ; i++) {
                            _findIndex = courseCodes.findIndex(c => c == values[i] );
                            if(_findIndex == -1){
                              break;
                            }

                          }
                          if(_findIndex == -1){
                            handleUpdateCourse(index, "prerequisiteError", true)
                          }else{
                            handleUpdateCourse(index, "prerequisiteError", false)
                          }
                          handleUpdateCourse(index, "prerequisiteCourseCodesExampleEng1Eng2Eng3", e.target.value)

                        }

                        }
                    />
                    <Box>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteCourse(index)}
                      >
                        <DeleteRounded />
                      </IconButton>
                    </Box>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id={"cancelAddCourse"} onClick={handleClose}>Cancel</Button>
          {files && (
            <>
              <Button
                  id={"resetCourse"}
                  variant="outlined"
                  color="warning"
                  onClick={handleReset}
                  disabled={isLoading}
              >
                Reset
              </Button>
              <LoadingButton id={"uploadCourseId"}
                             disabled={validateForm > 0}
                             loading={isLoading}
                             loadingPosition="start"
                             variant="contained"
                             startIcon={<SaveRounded/>}
                             onClick={handleSave}
              >
                {isLoading ? "Saving" : "Save"}
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
