import * as React from "react";
import {useEffect, useMemo} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {LoadingButton} from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {setFiles, setUploadRequirement} from "../../redux/features/specialOrder";
import {useFormik} from "formik";
import {setErrorSnackbar} from "../../helper/utils/helper";
import * as yup from "yup";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import useDialog from "../../hooks/useDialog";
import AddConfirmationDialog from "./AddConfirmation.dialog";
import {useRequirementSoQuery} from "../../hooks/useRequirementSoQuery";
import {useRequirementQuery} from "../../hooks/useRequirementQuery";
import {useQueryClient} from "react-query";
import FilesDialog from "./Files.dialog";
import {X} from "lucide-react";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import {
    IPostFileUploadsSpecialOrderRequirementFiles,
    PostFileUploadsSpecialOrderRequirementFiles
} from "../../requests/requirements.request";
import {useStudentRequirementSoQuery} from "../../hooks/useStudentRequirementSoQuery";
import _ from "lodash";
import {setLoading} from "../../redux/features/app";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    title: string
}


const initialValues = {
    formType: "dob"
}
const validationSchema = yup.object({
    formType: yup.string(), // userName: yup.string().required('UserName is required'),

})


interface IStudentRequirementSO {
    specialOrderRequirementId: number | string | any
    studentId: number | string | any
    url: number | string | any
    schoolId: number | string | any
    programId: number | string | any
    majorId: number | string | any
    id: number | string | any
    fileExtension: number | string | any
    dateCreated: string | string | any
    isDeleted: string | string | any
    lastModifiedDate: string | string | any
    fileSize: string | string | any
    fileName: string | string | any
}

const FileManagerDialog = React.memo((props: IProps) => {
    const dispatch = useDispatch();

    const specialOrderMajorId = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorId)
    const queryClient = useQueryClient();
    const specialOrderSchoolId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderSchoolId
    );
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const specialOrderProgramId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderProgramId
    );
    const user = useSelector((store: RootState) => store.auth.user);
    const specialOrderIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderIdValue
    );
    const specialOrderStudentNumberValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentNumberValue
    );
    const specialOrderStudentIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentIdValue
    );


    const {open, handleClose, onSave, title} = props;
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])

    const files = useSelector((store: RootState) => store.specialOrder?.requirements?.files)

    const formik = useFormik({
        initialValues, validationSchema,
        onSubmit: async (values, actions) => {
            dispatch(setLoading(true))

            try {
                var _____files = [...files]
                var retry = 0
                let body: IPostFileUploadsSpecialOrderRequirementFiles = {
                    formFiles: [],
                    specialOrderRequirementId: 0,
                    specialOrderRequirementsName: 0,
                    studentNumber: "",
                    schoolId: 0,
                    programId: 0,
                    fileUploadType: 1,
                    majorId: 0,
                    uploadedBy: user.id
                };
                handleCloseConfirmation()
                for await (const c of _____files) {
                    body = {
                        studentId: specialOrderStudentIdValue,
                        formFiles: c.files,
                        specialOrderRequirementId: c.id,
                        specialOrderRequirementsName: c.name,
                        studentNumber: specialOrderStudentNumberValue,
                        schoolId: specialOrderSchoolId,
                        programId: specialOrderProgramId,
                        fileUploadType: 1,
                        majorId: specialOrderMajorId,
                        uploadedBy: user.id
                    };
                    try {
                        if (c.files?.filter((c: any) => !c?.id && !c.schoolId && !c.programId)?.length > 0) {
                            var _PostFileUploadsSpecialOrderRequirementFiles = await PostFileUploadsSpecialOrderRequirementFiles(body)
                            _____files.map((file: any) => {
                                if (c?.id == file?.id) {
                                    var _______file = JSON.parse(JSON.stringify(file))
                                    _______file.files = _______file?.files?.filter((c: any) => c.id)
                                    _PostFileUploadsSpecialOrderRequirementFiles.data?.forEach((____file: any) => {
                                        _______file?.files.push({
                                            schoolId: specialOrderSchoolId,
                                            programId: specialOrderProgramId,
                                            studentNumber: specialOrderStudentNumberValue,
                                            preview: ____file
                                        })
                                    })
                                }
                                return _______file
                            })

                        }

                    } catch (e) {
                        dispatch(setLoading(false))
                        setErrorSnackbar(e, setSnackbarStore);
                    }


                }
                queryClient.setQueriesData('specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId, (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((sogbs: any) => {
                            sogbs.students = sogbs?.students?.map((sogbsStudents: any) => {
                                sogbsStudents.status = sogbsStudents.status.map((sogbsStudentsStatus: any) => {
                                    var filesIndex = files.findIndex((file: any) => file?.id == (sogbsStudentsStatus?.id))
                                    if (filesIndex > -1 &&
                                        sogbsStudents.id == specialOrderStudentIdValue &&
                                        sogbsStudentsStatus?.isDeleted != true &&
                                        files?.[filesIndex]?.files?.length > 0) {
                                        sogbsStudentsStatus.status = true
                                    } else if (sogbsStudents.id == specialOrderStudentIdValue) {
                                        sogbsStudentsStatus.status = false
                                    }
                                    return sogbsStudentsStatus
                                })

                                sogbsStudents.requirementCompleted = sogbsStudents.status.length == sogbsStudents.status.filter((c: any) => c.status == true)?.length
                                return sogbsStudents
                            })
                            return sogbs
                        })
                    }
                })
                dispatch(setLoading(false))
                queryClient.setQueriesData('requirementQuery', (oldData: any) => {
                    return {
                        ...oldData,
                        data: []
                    }
                })
                queryClient.setQueriesData('requirementSoQuery', (oldData: any) => {
                    return {
                        ...oldData,
                        data: []
                    }
                })
                files.forEach((file: any) => URL.revokeObjectURL(file.preview));
                dispatch(setFiles([]));
                onSave()

                _handleClose()


            } catch (error: any) {
                handleCloseConfirmation()
                dispatch(setLoading(false))
                setErrorSnackbar(error, setSnackbarStore);

            }


        }
    })


    const {
        data: requirements,
        isLoading,
        refetch: refetch
    } = useRequirementQuery(specialOrderProgramId, specialOrderSchoolId, specialOrderStudentIdValue);

    const requirementMemo = useMemo(() => {
        return requirements?.data?.filter((c: any) => {
            return c.isRequired && !c.isDeleted
        })
    }, [requirements?.data?.filter((c: any) => {
        return c.isRequired && !c.isDeleted
    }), requirements?.data, specialOrderMajorIdValue, specialOrderSchoolId, specialOrderProgramId])


    const {
        data: requirementSO,
        isLoading: isLoadingSo,
        refetch: refetchSo
    } = useRequirementSoQuery(specialOrderProgramId, specialOrderSchoolId, specialOrderStudentIdValue);


    const {
        data: studentrequirementSO,
        isLoading: isLoadingStudentRequirementSO,
        refetch: refetchStudentRequirementSO
    } = useStudentRequirementSoQuery(specialOrderProgramId, specialOrderSchoolId, specialOrderStudentIdValue, specialOrderStudentNumberValue, specialOrderMajorId);


    useEffect(() => {
        if (requirements?.data?.length) {
            requirementSO?.data?.forEach((c: any) => {
                queryClient.setQueriesData('requirementQuery', (oldData: any) => {

                    return {
                        ...oldData,
                        data: oldData?.data?.map((sor: any) => {
                            if (c.specialOrderRequirementId == sor.id) {
                                sor.isRequired = true
                            }
                            return sor
                        })
                    }
                })
            })
        }


    }, [requirementSO?.data, requirements?.data])

    useEffect(() => {
        var ___files = [...files]
        var __requirements = studentrequirementSO?.data
        if (__requirements?.length > 0) {
            console.log("useEffect", __requirements)
            console.log("useEffect files", ___files)
            __requirements?.forEach((__requirement: IStudentRequirementSO) => {
                var filesIndex = ___files.findIndex((c: any) => {
                    return c.id == __requirement.specialOrderRequirementId
                })
                if (filesIndex > -1) {
                    ___files[filesIndex] = {
                        dateCreated: __requirement.dateCreated,
                        id: __requirement.specialOrderRequirementId,
                        isDeleted: __requirement.isDeleted,
                        isRequired: null,
                        lastModifiedDate: __requirement.lastModifiedDate,
                        name: null,
                        files: [...(___files?.[filesIndex]?.files && ___files[filesIndex]?.files) ?? [], {
                            schoolId: __requirement.schoolId,
                            studentId: __requirement.studentId,
                            studentNumber: specialOrderStudentNumberValue,
                            programId: __requirement.programId,
                            id: __requirement.id,
                            preview: __requirement.url,
                            name: __requirement.fileName,
                            size: __requirement.fileSize
                        }]
                    }
                } else {


                    var ___file: {
                        dateCreated: any,
                        id: any,
                        isDeleted: any,
                        isRequired: any,
                        lastModifiedDate: any,
                        name: any,
                        files: any[]
                    } = {
                        dateCreated: __requirement.dateCreated,
                        id: __requirement.specialOrderRequirementId,
                        isDeleted: __requirement.isDeleted,
                        isRequired: null,
                        lastModifiedDate: __requirement.lastModifiedDate,
                        name: null,
                        files: []
                    }

                    ___file?.files?.push({
                        schoolId: __requirement.schoolId,
                        studentId: __requirement.studentId,
                        studentNumber: specialOrderStudentNumberValue,
                        isDeleted: __requirement.isDeleted,
                        programId: __requirement.programId,
                        id: __requirement.id,
                        preview: __requirement.url,
                        name: __requirement.fileName,
                        size: __requirement.fileSize
                    })
                    ___files.push(___file)
                }
            })
        }

        dispatch(setFiles(___files.map((e: any) => {
            var _e = {...e}
            _e.files = _.uniqBy(_e.files, 'id') ?? []
            return _e
        })));


    }, [studentrequirementSO?.data])


    const {
        handleOpen: handleOpenConfirmation,
        handleClose: handleCloseConfirmation,
        open: openConfirmation,
    } = useDialog();


    const _handleSave = () => {
        formik.handleSubmit()

    }
    const _handleClose = () => {


        dispatch(setUploadRequirement({
            studentId: 0,
            specialOrderId: 0,
            majorId: specialOrderMajorId,
            schoolId: specialOrderSchoolId,
            programId: specialOrderProgramId,
        }))
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        queryClient.setQueriesData('requirementQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('requirementSoQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        dispatch(setFiles([]));

        onSave()

        handleClose()
    };

    return (
        <>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{fontSize: 16}}>
                    <div className={"flex justify-between align-middle"}>
                        <div>{title}</div>
                        <div><Tooltip title={"close"} onClick={_handleClose}>
                            <IconButton><X/></IconButton>
                        </Tooltip></div>
                    </div>
                </DialogTitle>
                <DialogContent>

                    <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                        {requirementMemo ?
                            requirementMemo?.map((c: any, index: number) => {
                                return <FilesDialog
                                    studentId={specialOrderStudentIdValue}
                                    filesIndex={index}
                                    fileDialogKey={`${specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId}`}
                                    requirement={c}/>
                            }) : null
                        }

                    </div>


                    {requirements?.data?.length == 0 || requirements == null ?
                        <div className={" flex justify-center align-middle"}>
                            <div>
                                <CircularProgress/>
                            </div>
                        </div> : null}


                </DialogContent>
                <DialogActions>
                    <Button color={"error"} id={"cancelCourse"} onClick={_handleClose}>Cancel</Button>
                    <LoadingButton disabled={files?.length == 0} id={"AddConformation"} onClick={() => {
                        handleOpenConfirmation()
                    }}
                    >
                        Ok
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <AddConfirmationDialog
                isLoading={isLoading}
                title={"Do you want to save the changes you made?"}
                open={openConfirmation}
                handleClose={handleCloseConfirmation}
                onSave={_handleSave}></AddConfirmationDialog>


        </>

    );
});

export default FileManagerDialog;
