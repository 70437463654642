import { RefreshRounded } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Container>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100vh"
                    }}>
                        <Box sx={{
                            textAlign: "center",
                        }}>
                            <img src="/assets/svg/fixing_bug.svg" />
                            <Typography variant="h3" align="center">
                                Oops! Something went wrong.
                            </Typography>
                            <Typography variant="h6" align="center">
                                Please try again later.
                            </Typography>
                            <Button
                                onClick={() => window.location.reload()}
                                variant="outlined" sx={{ mt: 2 }}
                                startIcon={<RefreshRounded />}
                            >
                                Reload
                            </Button>
                        </Box>
                    </Box>

                </Container>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
