import * as React from "react";
import {useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {LoadingButton} from "@mui/lab";
import {Transition} from "../../wrapper/Transition";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import {QueryClient, useMutation} from 'react-query';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {postSONumber} from "../../requests/so.request";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave?: (soNumber: string) => void;
    title: string;
    isLoading?: boolean;
}

const AddSONumberDialog = React.memo((props: IProps) => {
    const { open, handleClose, title } = props;
    const dispatch = useDispatch()
    const [soNumber, setSONumber] = useState("");
    const [so, setSO] = useState("");
    const specialOrderProgramId = useSelector((store: RootState) => store?.specialOrder.specialOrderProgramId)
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const queryClient = new QueryClient();
    const mutation = useMutation({
        mutationFn: (data) => postSONumber(specialOrderProgramId, soNumber, so),
        onSuccess: async (data) => {

            if (props.onSave) {
                props.onSave(soNumber);
            }

        },
        onError: (error) => {

        }
    });

    const handleSave = () => {
        if (!(soNumber.trim() && soNumber.length == 6)) {
            alert("The SO Number (6 digit) must be provided and must be a valid number.");
            return;
        }
        if (!(so.trim() && so.length == 2)) {
            alert("The SO must be provided and must be a valid number.");
            return;
        }
        // @ts-ignore
        mutation.mutate(soNumber, so);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            maxWidth="sm"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle style={{fontSize: 16}}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the Special Order (SO) Number below.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="so"
                    label="SO (2 digit)"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={so}
                    onChange={(e) => setSO(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="soNumber"
                    label="SO Number (6 digit)"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={soNumber}
                    onChange={(e) => setSONumber(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button color={"error"} id={"cancelCourse"} onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={mutation.isLoading} id={"AddConformation"} onClick={handleSave}>
                    Ok
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
});

export default AddSONumberDialog;
