import * as yup from "yup";

export interface IUnitSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  lecture?: string | null;
  laboratory?: string | null;
  noOfUnits?: string | null;
}

export const unitSchema: yup.SchemaOf<IUnitSchema> = yup.object().shape({
  id: yup.number().required("Id is required"),
  uuid: yup.string().nullable(),
  dateCreated: yup.string().nullable(),
  lastModifiedDate: yup.string().nullable(),
  lastModifiedBy: yup.string().nullable(),
  lecture: yup.string().nullable(),
  laboratory: yup.string().nullable(),
  noOfUnits: yup.string().nullable(),
});

export type UnitType = yup.InferType<typeof unitSchema>;
