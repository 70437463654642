import * as React from "react"

const SubmittedIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
height={18}
fill="none"
xmlns="http://www.w3.org/2000/svg"
{...props}
>
<path
    d="M11.438 5.094v-.938A.157.157 0 0 0 11.28 4h-7.5a.157.157 0 0 0-.156.156v.938c0 .086.07.156.156.156h7.5c.086 0 .156-.07.156-.156ZM3.78 6.812a.157.157 0 0 0-.156.157v.937c0 .086.07.157.156.157h3.594c.086 0 .156-.07.156-.157V6.97a.157.157 0 0 0-.156-.157H3.781Zm7.344 2.266a4.219 4.219 0 1 0 0 8.437 4.219 4.219 0 0 0 0-8.437Zm2.1 6.319a2.95 2.95 0 0 1-2.1.869 2.95 2.95 0 0 1-2.1-.87 2.95 2.95 0 0 1-.869-2.1c0-.792.309-1.538.87-2.099a2.95 2.95 0 0 1 2.099-.869c.793 0 1.54.309 2.1.87.56.56.869 1.306.869 2.099a2.95 2.95 0 0 1-.87 2.1Zm-.362-3.585h-.865a.154.154 0 0 0-.127.065l-1.24 1.715-.451-.623a.155.155 0 0 0-.127-.065H9.19a.157.157 0 0 0-.127.248l1.442 1.995c.062.085.19.085.252 0l2.23-3.087a.156.156 0 0 0-.125-.248Zm-6.27 3.829h-4.53V1.89H13v6.718c0 .086.07.157.156.157h1.094c.086 0 .156-.07.156-.157v-7.5a.624.624 0 0 0-.625-.625h-12.5a.624.624 0 0 0-.625.625v15.313c0 .346.28.625.625.625h5.313c.086 0 .156-.07.156-.156v-1.094a.157.157 0 0 0-.156-.156Z"
fill="#36A2EB"
    />
    </svg>
)

export default SubmittedIcon
