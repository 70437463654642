import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import {useDebounce} from "../../hooks/useDebounce";
import {useSearchLevelQuery} from "../../hooks/useSearchLevelQuery";
import {LevelType} from "../../types";
import {useAppSelector} from "../../redux/store";
import {setSearchLevelName} from "../../redux/features/school";
import {useDispatch} from "react-redux";

interface ISearchAsYouTypeProps {
    label: string
    onSelected: (value: LevelType) => void
    defaultValue?: any
}

const SearchLevelTextField: React.FC<ISearchAsYouTypeProps> = (props) => {
    const {label, onSelected, defaultValue} = props;
    const searchLevelName = useAppSelector((store) => store?.school?.searchLevelName)
    const dispatch = useDispatch();

    const debouncedName = useDebounce(searchLevelName, 500)
    const { data: options , isLoading } = useSearchLevelQuery(debouncedName)
    const [open, setOpen] = React.useState(false);


    return (
        <Autocomplete
            id={"searchLevel"}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option?.name}
            options={(options?.data || []) as LevelType[]}
            defaultValue={defaultValue}
            loading={isLoading}
            onChange={(e, value) => {
                if (value !== undefined) onSelected(value)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    margin="dense"
                    size='small'
                    value={searchLevelName}
                    onChange={(e) => {
                        if(e?.target?.value) dispatch(setSearchLevelName(e?.target?.value))
                    }}
                    InputProps={{
                        ...params?.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params?.InputProps?.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}


export default React.memo(SearchLevelTextField);
