import {AxiosRequestConfig} from "axios";
import {getPrerequisiteByCourse} from "./../requests/course.request";
import {useQuery} from "react-query";

export const usePrerequisiteByCourseIdQuery = (params: AxiosRequestConfig["params"]) => {
    return useQuery(["prerequisiteByCourseId", params, params?.curriculumCount], () => getPrerequisiteByCourse(params), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};
