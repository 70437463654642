// create loading Backdrop component
// this component will be used to show loading indicator when data is being fetched from server
import { Backdrop, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../redux/store';
import {useEffect} from "react";
import {dispatch} from "d3";
import {setLoading} from "../redux/features/app";

export default function LoadingBackdrop() {
    const dispatch = useDispatch();
    const appState = useSelector((state: RootState) => state.app)
    useEffect(()=>{
        dispatch(setLoading(false))
    }, [])
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
            open={appState.loading}
        >
            <CircularProgress color="inherit" />
            <Typography variant="overline" sx={{ color: '#fff', marginLeft: '1rem' }}>
                Loading...
            </Typography>
        </Backdrop>
    );
}
