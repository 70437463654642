import { AxiosRequestConfig } from "axios";
import api from "./config";

type AcademicCalendar = {
  remark: string;
  firstSemStart: string;
  firstSemEnd: string;
  secondSemStart: string;
  secondSemEnd: string;
  thirdSemStart: string;
  thirdSemEnd: string;
  schoolYear: string
};

export const deleteAcademicCalendar = async (academicId: any) => {
  const res = await api.post(`academicCalendar/${academicId}`, {
    params: { academicId }
  });
  return res;
};

export const postAcademicCalendar = async (params: AcademicCalendar) => {
  const res = await api.post(`/academicCalendar`, {
    ...params,
    thirdSemStart: params.thirdSemStart || null,
    thirdSemEnd: params.thirdSemEnd || null,
  });
  return res;
};
export const editAcademicCalendar = async (params: AcademicCalendar) => {
  const res = await api.patch(`/academicCalendar`, {
    ...params,
    thirdSemStart: params.thirdSemStart || null,
    thirdSemEnd: params.thirdSemEnd || null,
  });
  return res;
};

export const getAcademicCalendar = async (queryParams: AxiosRequestConfig) => {
  const res = await api.get(`/academicCalendar`, { params: queryParams });
  return res?.data;
};
export const getAcademicCalendarId = async (id: number) => {
  if(!id) return
  const res = await api.get(`/academicCalendar/` + id );
  return res?.data;
};
