import * as React from "react";
import {useEffect} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {useDebounce} from "../hooks/useDebounce";
import {useSearchSchoolQuery} from "../hooks/useSearchSchoolQuery";
import {SchoolType} from "../types";
import {useAppSelector} from "../redux/store";
import {ArrowDropDown} from "@mui/icons-material";

interface ISearchAsYouTypeProps {
  label: string;
  required?: boolean,
  error?: any,
    id?:any,
    regionNameEnable?:boolean,
  value?: any,
    regionId?: any, disabled?:boolean,
  onSelected: (value: SchoolType) => void;
}

const SearchAsYouType: React.FC<ISearchAsYouTypeProps> = (props) => {



    const { label, onSelected, required, error, regionId, regionNameEnable = true , id} = props;
  const [schoolInstitutionCode, setSchoolInstitutionCode] = React.useState("");
  const debouncedSchoolInstitutionCode = useDebounce(
    schoolInstitutionCode,
    500
  );


  const { data: options, isLoading } = useSearchSchoolQuery(
    debouncedSchoolInstitutionCode,

    {
      enable: !!debouncedSchoolInstitutionCode.length,
    },
      regionId,
  );
  const [open, setOpen] = React.useState(false);
  const institutionalCode = useAppSelector(
    (store) => store?.school?.institutionalCode
  );



  useEffect(() => {
    onSelected(institutionalCode || "");
  }, []);
  return (
      <Autocomplete
          popupIcon={<ArrowDropDown
              id={"ArrowDropDown-" + "searchasyoutype"}/>}
          open={open}
          onOpen={() => {
              setOpen(true);
          }}
          id={"searchAsYouType"}
          disabled={props?.disabled}
          onClose={() => {
              setOpen(false);
          }}
          defaultValue={props?.value ?? ''}
        isOptionEqualToValue={(option, value) =>
        option?.institutionalCode === value?.institutionalCode
      }

          getOptionLabel={(option) =>  option?.displayName ?? option?.name ?? ""}
      options={options ?? []}
      loading={isLoading}
      renderOption={(props, option) => (
          <li {...props} id={"settingschoolinstutional" + option?.id} key={option?.id}>
              <div id={"searchasyoutypesettingschoolinstutional" + option?.id}
                   style={{flex: 1, display: "flex", flexDirection: "column"}}>
                  {option?.name ?? option?.displayName}
                  <p style={{
                      fontStyle: "italic",
                      margin: 0,
                      fontSize: 12
                  }}>{option?.institutionalCode ? option.institutionalCode + " - " : ""}{option?.address ? option.address : (option?.address ? option.address + " - " : "") + (option?.province?.name ? option.province.name + (regionNameEnable ? " - " : "") : "") + (regionNameEnable ? (option?.region?.name ? option.region.name + "" : "") : "")}</p>
              </div>
          </li>
      )}
      filterOptions={(x) => x}
      onChange={(e, value) => {
        if (value !== null && value !== undefined) onSelected(value || "");
      }}
      renderInput={(params) => (
        <TextField
            {...params}
            id={id ?? "someid"}
            label={label}
            style={{borderWidth: 0}}
            aria-labelledby={"searchasyoutype"}
            margin="dense"
            size="small"
            error={error ?? false}
            required={required ? true : false}
            value={schoolInstitutionCode}
            onChange={(e) => setSchoolInstitutionCode(e.target.value)}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <React.Fragment>
                        {isLoading ? (
                            <CircularProgress color="inherit" size={20}/>
                        ) : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
        />
      )}
    />
  );
};

export default React.memo(SearchAsYouType);
