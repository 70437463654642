import * as React from "react";
import {useCallback, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {LoadingButton} from "@mui/lab";
import {Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {blue} from "@mui/material/colors";
import DialogContent from "@mui/material/DialogContent";
import {patchStudent} from "../../requests/student.request";
import {setLoading} from "../../redux/features/app";
import {useDispatch} from "react-redux";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    title: string
    data: any,
    local: any
}

const IsExistingDialog = React.memo((props: IProps) => {
    const {open, handleClose, onSave, title, data, local} = props;
    const [isLoadingDisabled, setIsLoadingDisabled] = useState(false)
    const dispatch = useDispatch()

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);

    const asyncStudentData = async (_data: any) => {
        dispatch(setLoading(true))
        setIsLoadingDisabled(true)
        try {
            await patchStudent(data?.id, _data)
            onSave()

        } catch (error: any) {
            try {
                setErrorSnackbar(error, setSnackbarStore);
            } catch (e) {
                console.log(e);
            }
        }
        setIsLoadingDisabled(false)
        dispatch(setLoading(false))
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={handleClose}
            maxWidth="sm"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                style={{fontSize: 16}}>{title ?? "The student number you entered is already registered in our system."}</DialogTitle>
            <DialogContent>
                <List sx={{pt: 0}}>

                    <ListItem style={{"borderWidth": "1px", borderStyle: "solid", borderColor: blue[100], padding: 6}}
                              disableGutters>
                        <ListItemButton onClick={() => asyncStudentData(data)}>
                            <ListItemAvatar>
                                <Avatar sx={{bgcolor: blue[100], color: blue[600]}}>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${data?.studentNumber}-${data?.firstName} ${data?.middleName} ${data?.lastName}`}/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem style={{"borderWidth": "1px", borderStyle: "solid", borderColor: blue[100], padding: 6}}
                              disableGutters>
                        <ListItemButton onClick={() => asyncStudentData(local)}>
                            <ListItemAvatar>
                                <Avatar sx={{bgcolor: blue[100], color: blue[600]}}>

                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${local?.studentNumber}-${local?.firstName} ${local?.middleName} ${local?.lastName}`}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </DialogContent>

            <DialogActions>
                <Button color={"error"} id={"cancelIsExisting"} onClick={handleClose}>Cancel</Button>
                <LoadingButton disabled={isLoadingDisabled} id={"isExisting"} onClick={() => {
                    onSave()
                    handleClose()
                }}
                >
                    Ok
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
});

export default IsExistingDialog;
