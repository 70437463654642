import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    highlightedRow: {
        backgroundColor: '#99dfff',
        animation: '$fadeOut 12s forwards'
    },
    '@keyframes fadeOut': {
        '0%': {
            backgroundColor: '#99dfff'
        },
        '100%': {
            backgroundColor: 'transparent'
        }
    },
    fadedRow: {
        backgroundColor: 'transparent'
    }
});
