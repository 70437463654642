import * as yup from "yup";
export interface IApplicationSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
  isActive?: boolean;
}

export const applicationSchema: yup.SchemaOf<IApplicationSchema> = yup
  .object()
  .shape({
    id: yup.number().required("Id is required"),
    uuid: yup.string().nullable(),
    dateCreated: yup.string().nullable(),
    createdBy: yup.string().nullable(),
    lastModifiedDate: yup.string().nullable(),
    lastModifiedBy: yup.string().nullable(),
    name: yup.string().nullable(),
    isActive: yup.boolean().optional(),
  });

export type ApplicationType = yup.InferType<typeof applicationSchema>;
