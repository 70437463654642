import {ExcelCurriculumType} from "./../types/curriculum.type";
import api from "./config";
import {AxiosRequestConfig} from "axios";
import {GetCurriculumResponseType, UploadCurriculumType} from "../types";
import {CurriculumInputType} from "../schemas/curriculum.schema";

export const deleteCurriculum = async (id: number) => {
  const res = await api.post(`/curriculums/${id}`);
  return res?.data;
};
export const getCurriculums = async (params: AxiosRequestConfig["params"]) => {
  const res = await api.get<GetCurriculumResponseType>("/curriculums", {
    params,
  });
  return res?.data;
};

export const getCurriculum = async (id: number) => {
  if (!id) return
  const res = await api.get(`/curriculums/${id}`);
  return res?.data?.data;
};
export const postCurriculum = async (data: CurriculumInputType) => {
  const res = await api.post("/curriculums", data, {
    params: {
      ...(data.schoolId != null && {schoolId: data.schoolId}),
    },
  });
  return res?.data;
};
export const patchCurriculum = async (data: CurriculumInputType) => {
  if (!data?.id) return
  const res = await api.patch(`/curriculums/${data?.id}`, data, {
    params: {
      ...(data.schoolId != null && {schoolId: data.schoolId}),
    },
  });
  return res?.data;
};

export const postCheckCurriculum = async (data: { curriculum: string}) => {
  const res = await api.post("/curriculums/check-name/" + data.curriculum);
  return res?.data;
};
export const getCurriculumByName = async (
  search: string,
  signal: AxiosRequestConfig["signal"],
  schoolId: any
) => {
  const res = await api.get(`/curriculums`, {
    signal,
    ...{
      params: {
          ...((typeof schoolId == "number" || typeof schoolId == "string") ? {
              schoolId: schoolId
          } : schoolId),

          search: search,
          pageSize: 10,
      },
    },
  });
  return res?.data;
};

export const uploadCurriculum = async (data: ExcelCurriculumType[]) => {
  const res = await api.post<UploadCurriculumType>(
    "/curriculums/load-curriculums",
    data
  );
  return res?.data;
};
