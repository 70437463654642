import { ISchoolSchema, schoolSchema } from "./school.schema";
import * as Yup from "yup";
import { IStudentSchema, studentSchema } from "./student.schema";

export interface ISchoolStudentSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  schoolId: number;
  school: ISchoolSchema;
  studentUuId?: string | null;
  studentId?: number | null;
  student: IStudentSchema;
  studentUuid?: string | null;
}

export const schoolStudentSchema: Yup.SchemaOf<ISchoolStudentSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    schoolId: Yup.number().required("SchoolId is required"),
    school: Yup.lazy(() => schoolSchema),
    studentUuId: Yup.string().nullable(),
    studentId: Yup.number().nullable(),
    student: Yup.lazy(() => studentSchema),
    studentUuid: Yup.string().nullable(),
  });

export type SchoolStudentType = Yup.InferType<typeof schoolStudentSchema>;
