import React, { useState } from "react";
import { Loader } from "lucide-react";
import { differenceInMinutes, format, isToday, isYesterday } from "date-fns";
import Message  from "./components/Workspace/Message";
import {useSelector} from "react-redux";
import type {RootState} from "./redux/store";
import { ChannelHero } from "./components/Workspace/channel-hero";
import { ConversationHero } from "./components/Workspace/conversation-hero";




const TIME_THRESHOLD = 5;


interface MessageListProps {
    memberName?: string;
    memberImage?: string;
    channelName?: string;
    channelCreationTime?: any;
    variant?: "channel" | "thread" | "conversation";
    data: any | undefined;
    loadMore: () => void;
    isLoadingMore: boolean;
    canLoadMore: any;
};

const formatDateLabel = (dateStr: string) => {
    const date = new Date(dateStr);
    if (isToday(date)) return "Today";
    if (isYesterday(date)) return "Yesterday";
    return format(date, "EEEE, MMMM d");
};

export const MessageList = ({
                                memberName,
                                memberImage,
                                channelName,
                                channelCreationTime,
                                data,
                                variant = "channel",
                                loadMore,
                                isLoadingMore,
                                canLoadMore,
                            }: MessageListProps) => {
    const [editingId, setEditingId] = useState<null | null>(null);
    const groupedMessages: any = (data || []).reduce(
        (groups: any, message: any) => {
            const date = new Date(message.dateCreated);
            const dateKey = format(date, "yyyy-MM-dd");

            if (!groups[dateKey]) {
                groups[dateKey] = [];
            }
            groups[dateKey].unshift(message); // Adds the message to the beginning of the array

            return groups;
        },
        {}
    ) as any;
    const authStore = useSelector((state: RootState) => state.auth)

    return (
        <div className="flex-1 flex flex-col-reverse pb-4 overflow-y-auto messages-scrollbar">
            {Object.entries(groupedMessages || {}).map(([dateKey, messages]) => (
                <div key={dateKey}>
                    <div className="text-center my-2 relative">
                        <hr className="absolute top-1/2 left-0 right-0 border-t border-gray-300"/>
                        <span
                            className="relative inline-block bg-white px-4 py-1 rounded-full text-xs border border-gray-300 shadow-sm">
              {formatDateLabel(dateKey)}
            </span>
                    </div>
                    {(messages as any[]).map((message: any, index: number) => {
                        const prevMessage = (messages as any)[index - 1];
                        const isCompact =
                            prevMessage &&
                            prevMessage.user?.id === message.user?.id &&
                            differenceInMinutes(
                                new Date(message.dateCreated),
                                new Date(prevMessage.dateCreated)
                            ) < TIME_THRESHOLD;
                        return (
                            <Message
                                key={message.id}
                                id={message.id}
                                memberId={message.member?.id}
                                authorImage={message?.member?.image}
                                authorName={message?.member?.userName}
                                isAuthor={authStore?.user?.id == message?.member?.userId}
                                reactions={message?.reactions}
                                body={message.body}
                                image={message.image}
                                updatedAt={message.isEdited}
                                createdAt={message.dateCreated}
                                isEditing={editingId == message?.id}
                                setEditingId={setEditingId}
                                isCompact={isCompact}
                                hideThreadButton={variant === "thread"}
                                threadCount={message.threadCount}
                                threadImage={message.threadImage}
                                threadName={message.threadName}
                                threadTimestamp={message.threadTimestamp}
                            />
                        )
                    })}


                </div>
            ))}
            <div
                className="h-1"
                ref={(el) => {
                    if (el) {
                        const observer = new IntersectionObserver(
                            ([entry]) => {
                                if (entry.isIntersecting && canLoadMore &&  !isLoadingMore) {
                                    loadMore();
                                }
                            },
                            {threshold: 1.0}
                        );

                        observer.observe(el);
                        return () => observer.disconnect();
                    }
                }}
            />
            {isLoadingMore && (
                <div className="text-center my-2 relative">
                    <hr className="absolute top-1/2 left-0 right-0 border-t border-gray-300"/>
                    <span
                        className="relative inline-block bg-white px-4 py-1 rounded-full text-xs border border-gray-300 shadow-sm">
            <Loader className="size-4 animate-spin"/>
          </span>
                </div>
            )}
            {variant === "channel" && channelName && channelCreationTime && (
                <ChannelHero
                    name={channelName}
                    creationTime={channelCreationTime}
                />
            )}
            {variant === "conversation" && (
                <ConversationHero
                    name={memberName}
                    image={memberImage}
                />
            )}
        </div>
    );
};
