import {AddRounded, DeleteRounded, EditRounded, FilterAltRounded, ViewColumnRounded,} from "@mui/icons-material";
import {Box, Button, IconButton, Stack, Tooltip,} from "@mui/material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, TableFilterList, TableToolbar,} from "mui-datatables";
import React, {FC, ReactNode, useEffect} from "react";
import {deleteAcademicCalendar} from '../../requests/academicCalendar.request'
import {useAcademicCalendaryQuery} from "../../hooks/useAcademicCalendar";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useDebounce} from "../../hooks/useDebounce";
import useDialog from "../../hooks/useDialog";
import AcademicCalendar from "../dialogs/AddAcademicCalendar.dialog";
import moment from 'moment-timezone';
import {RootState, useAppDispatch, useAppSelector} from "../../redux/store";
import {setDeleteDialog} from "../../redux/features/app";
import {useSelector} from "react-redux";
import {setEditAcademic} from "../../redux/features/auth";
import EditAcademicCalendar from "../dialogs/EditAcademicCalendar.dialog";

interface IUserTableProps {
  title: string;
}

const AcademicCalendarTable: FC<IUserTableProps> = (props) => {
  const { title } = props;
  const { schoolId , regionId, userInstitutionalName} = useAddEntities();
  const dispatch = useAppDispatch();
  const type = useSelector((store: RootState) => store.auth?.user?.type)
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";





  const [searchKey, setSearchKey] = React.useState<any>("");
  const debouncedSearchKey = useDebounce(searchKey, 500);

    const {data: academicCalendar, isLoading, refetch} = useAcademicCalendaryQuery({
      schoolId,
      pageSize: 100,
      ...(debouncedSearchKey && {search: debouncedSearchKey}),
      direction: "desc",
      orderBy: "DateCreated",
    });

    const handleDelete = (id: string | number) => {
      dispatch(
        setDeleteDialog({
          open: true,
          data: {
            id,
            title: `Delete Calendar`,
            content: "academic-calendar",
            body: "Are you sure you want to delete this calendar?",
            onDelete: async () => {
              try {
                var res = await deleteAcademicCalendar(id)
                await refetch()
                return res
              } catch (error) {
                throw error
              }
            },
          },
        })
      );
    };

  const columns: MUIDataTableColumn[] =     [
    {
      name: "academicCalendar",
      label: "ACADEMIC CALENDAR",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data: any) => {
          return (
            <>
              <div>
                First:{" "}
                <b>
                  {" "}
                  {moment(data.firstSemStart).format("MMMM D, YYYY")} -{" "}
                  {moment(data.firstSemEnd).format("MMMM D, YYYY")}{" "}
                </b>
              </div>
              <div>
                Second:{" "}
                <b>
                  {moment(data.secondSemStart).format("MMMM D, YYYY")} -{" "}
                  {moment(data.secondSemEnd).format("MMMM D, YYYY")}
                </b>
              </div>
              {(data.thirdSemStart && data.thirdSemEnd) && (
                <div>
                  Third:{" "}
                  <b>
                    {moment(data.thirdSemStart).format("MMMM D, YYYY")} -
                    {moment(data.thirdSemEnd).format("MMMM D, YYYY")}
                  </b>
                </div>
              )}
            </>
          );
        },
      },
    },
    {
      name: "schoolName",
      label: "SCHOOL NAME",
      options: {
        filter: true,
        display: type?.id != 2,
        sort: true,
      },
    },{
      name: "schoolYear",
      label: "Academic Year",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "remarks",
      label: "REMARKS",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastDatedDate",
      label: "UPDATED DATE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data: any) => {
          return (
              <div style={{flex: 1, display: "flex", alignItems: "center",  flexDirection: "column",  justifyContent: "center"}}>
                <strong>{moment.utc(data).tz('Asia/Manila').format('MMMM D, YYYY')}</strong>
                <p style={{margin: 0}}>{moment.utc(data).tz('Asia/Manila').format("h:mm a")}</p>
              </div>

          );
        },
      },
    },
    {
      name: "id",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: string) => {
          return (
            <Stack direction="row" spacing={0.2}>
              {(type?.id == 2 && roles?.toLowerCase() != "admin") || (type?.id == 1 && roles?.toLowerCase() != "user")   ? <Tooltip title="Edit Academic" arrow>
                <IconButton
                    color="info"
                    size="small"
                    value={value}
                    onClick={(event) => {
                      if(event.currentTarget.value){
                        dispatch(setEditAcademic(event?.currentTarget?.value))
                      }
                      handleEditAcademicCalendar()
                    }}
                >
                  <EditRounded />
                </IconButton>
              </Tooltip> : null}
              <Tooltip title="Delete" arrow>
                <IconButton
                  color="error"
                  size="small"
                  value={value}
                  onClick={(e) =>
                    {
                       e.stopPropagation();
                       handleDelete(value);
                    }
                   }
                >
                  <DeleteRounded />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
      filterType: "dropdown",
      onTableChange: (action, newTableState) => {
          switch (action) {
              case "search":
                  setSearchKey(newTableState.searchText);
                  break;
          }
      },
      tableId: "academiccalendartable",
      filter: false,
      print: false,
      rowsPerPage: 100,
      resizableColumns: false,
      textLabels: {
          body: {
              noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
          }
      },
  };

  const {
    handleOpen: handleAddAcademicCalendar,
    handleClose: handleCloseAcademicCalendar,
    open: openAcademicCalendar,
  } = useDialog();


  const {
    handleOpen: handleEditAcademicCalendar,
    handleClose: handleCloseEditAcademic,
    open: openEditAcademic,
  } = useDialog();



  useEffect(() => {
    refetch();
  }, [openAcademicCalendar]);

  return (
    <>
      <MUIDataTable
        title={title}
        data={academicCalendar?.data || ([] as any)}
        columns={columns}
        options={options}
        components={{
          TableFilterList: (props) => {
            return <TableFilterList {...props} />;
          },
          TableToolbar: (props) => {
            return (
              <>
                <TableToolbar {...props} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mx: 3,
                    my: 1,
                  }}
                >
                  <Stack direction="row" gap={1}></Stack>
                  <Box>
                    <Button
                        id={"addAcademic"}
                      startIcon={<AddRounded />}
                      variant="contained"
                      onClick={handleAddAcademicCalendar}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </>
            );
          },
            icons: {
                FilterIcon: FilterAltRounded as unknown as ReactNode,
                ViewColumnIcon: ViewColumnRounded as unknown as ReactNode,
            },
        }}
      />
        <EditAcademicCalendar open={openEditAcademic} handleClose={handleCloseEditAcademic}/>
        <AcademicCalendar
            open={openAcademicCalendar}
            handleClose={handleCloseAcademicCalendar}
        />
    </>
  );
};

export default AcademicCalendarTable;
