import * as yup from "yup";
import { ISubjectUnitSchema, subjectUnitSchema } from "./subjectUnit.schema";

export interface ISubjectSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
  courseCode?: string | null;
  description?: string | null;
  yearLevel?: number | null;
  subject_Program: any[];
  subjectUnits: ISubjectUnitSchema[];
}

export const subjectSchema: yup.SchemaOf<ISubjectSchema> = yup.object().shape({
  id: yup.number().required("Id is required"),
  uuid: yup.string().nullable(),
  dateCreated: yup.string().nullable(),
  createdBy: yup.string().nullable(),
  lastModifiedDate: yup.string().nullable(),
  lastModifiedBy: yup.string().nullable(),
  name: yup.string().nullable(),
  courseCode: yup.string().nullable(),
  description: yup.string().nullable(),
  yearLevel: yup.number().nullable(),
  subject_Program: yup.array(yup.object()),
  subjectUnits: yup.array(yup.lazy(() => subjectUnitSchema.optional())),
});

export type SubjectType = yup.InferType<typeof subjectSchema>;
