import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {useDropzone} from 'react-dropzone';
import {Box, DialogContentText, LinearProgress, MenuItem, Typography} from '@mui/material';
import {
    getDownloadEnrollment,
    getDownloadPromotionalReport,
    uploadPromotionalReportExcel
} from '../../requests/promotionalReport.request';
import {useQueryClient} from 'react-query';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {openSnackbar} from '../../redux/features/snackbar';
import flat from 'flat';
import {setLoading} from "../../redux/features/app";
import Lottie from "lottie-react";
import uploadFileAnimation from "./../../assets/upload-file.json";
import {getDownload, onDownloadDocument, pluralize} from "../../helper/utils/helper";
import {useAppSelector} from "../../redux/store";
import * as xlsx from "xlsx";
import _ from "lodash";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import useDialog from "../../hooks/useDialog";
import {Dictionary} from "@reduxjs/toolkit";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IError {
    response: {
        data: {
            message: string
        }
    }
}

function validatePromotionalEnrollmentList(label: string, _validateError: Dictionary<{}>[], setStartSchoolYear: (value: (((prevState: string) => string) | string)) => void, setEndSchoolYear: (value: (((prevState: string) => string) | string)) => void, setSchoolYear: (value: (((prevState: string) => string) | string)) => void) {
    var regex = /([0-9]{0,4})(?:[-\s]([0-9]{0,4}))?$/.exec(_validateError?.[0]?.[label] as any)
    setStartSchoolYear(regex?.[1] as any)
    setEndSchoolYear(regex?.[2] as any)
    setSchoolYear(regex?.[0] as any)
}

function extractedSchoolYear(label: string, _validateError: Dictionary<{}>[], setSemester: (value: (((prevState: string) => string) | string)) => void) {
    var semester = 0;
    var firstSem = "FIRST SEMESTER";
    var secondSem = "SECOND SEMESTER";
    var Sem1st = "1ST SEMESTER";
    var Sem2nd = "2ND SEMESTER";
    var summer = "SUMMER";
    switch ((_validateError?.[1]?.[label] as string)?.toLowerCase()) {
        case firstSem.toLowerCase():
            semester = 1
            break;
        case secondSem.toLowerCase():
            semester = 2
            break;
        case Sem1st.toLowerCase():
            semester = 1
            break;
        case Sem2nd.toLowerCase():
            semester = 2
            break;
        case summer.toLowerCase():
            semester = 3
            break;
    }

    setSemester(semester as any)
}

export default function useUploadPromotionalReportDialog() {
    const [open1, setOpen1] = React.useState(true);
    const [open2, setOpen2] = React.useState(true);

    const [open, setOpen] = React.useState(false);
    const [files, setFiles] = React.useState<File[] | null>(null)
    const [progress, setProgress] = React.useState(0)
    const [unSuccessfullyUpload, setUnSuccessfullyUpload] = React.useState<string | null>("")
    const [successfullyUpload, setSuccessfullyUpload] = React.useState<string | null>("")
    const [schoolYear, setSchoolYear] = React.useState("")
    const [startSchoolYear, setStartSchoolYear] = React.useState("")
    const [endSchoolYear, setEndSchoolYear] = React.useState("")
    const [semester, setSemester] = React.useState("")
    const queryClient = useQueryClient()
    const [isExistingTitle, setIsExistingTitle] = useState("")
    const [isExistingsData, setIsExistingsData] = useState({})
    const [isExistingsLocal, setIsExistingsLocal] = useState({})
    // const [isExistingSubTitle, setIsExistingSubTitle] = useState("")
    const institutionalCode = useAppSelector((store) => store?.auth?.user?.school?.code);
    const {
        handleOpen: handleOpenIsExisting,
        handleClose: handleCloseIsExisting,
        open: openIsExisting,
    } = useDialog();
    const [prData, setPrData] = useState<any>([])
    React.useEffect(() => {
        if (files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = reader.result;
                const workbook = xlsx.read(data, {type: "binary"});
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                const newCourses = json.map((course: any) => {
                    return _.mapKeys(course, (value, key) => {
                        return _.camelCase(key);
                    });
                });
                var _validateError = (newCourses);

                if (_validateError?.[0]?.["promotionalReportOrEnrollmentList"]) {
                    validatePromotionalEnrollmentList("promotionalReportOrEnrollmentList", _validateError, setStartSchoolYear, setEndSchoolYear, setSchoolYear);
                }
                if (_validateError?.[0]?.["promotionalReport"]) {
                    validatePromotionalEnrollmentList("promotionalReport", _validateError, setStartSchoolYear, setEndSchoolYear, setSchoolYear);
                }

                if (_validateError?.[0]?.["enrollmentList"]) {
                    validatePromotionalEnrollmentList("enrollmentList", _validateError, setStartSchoolYear, setEndSchoolYear, setSchoolYear);
                }
                if (_validateError?.[1]?.["promotionalReportOrEnrollmentList"]) {
                    extractedSchoolYear("promotionalReportOrEnrollmentList", _validateError, setSemester);
                }
                if (_validateError?.[1]?.["promotionalReport"]) {
                    extractedSchoolYear("promotionalReport", _validateError, setSemester);
                }
                if (_validateError?.[1]?.["enrollmentList"]) {
                    extractedSchoolYear("enrollmentList", _validateError, setSemester);
                }
                setPrData(_validateError?.slice(0, 8));
            };

            reader.readAsArrayBuffer(files?.[0]);
        } else {
            setPrData([]);
        }
        return () => {
            setPrData([]);
        }


    }, [files]);
    React.useEffect(() => {
        return () => {
            setFiles(null);
        };
    }, []);
    const history = useHistory()
    const dispatch = useDispatch()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseBackdrop: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpen(false);


    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleReset = React.useCallback(() => {
        setErrorMessage(false)
        setFiles(null)
        setProgress(0)
    }, [])

    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        setFiles(acceptedFiles)
    }, [])

    const {getRootProps, getInputProps, isDragActive, open: openDropzone} = useDropzone({
        onDrop, multiple: false, accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls', '.csv'],
        }, maxFiles: 1
    })
    const [openUploadError, setOpenUploadError] = React.useState(false);

    const handleClickOpenUploadError = () => {
        setOpenUploadError(true);
    };

    const handleCloseUploadError  = (event?:any, reason?:any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenUploadError(false);

    };
    const [errorMessage, setErrorMessage] = useState(false)
    const handleUpload = React.useCallback(async (fileOverride: boolean = false) => {

        if (files) {
            const formData = new FormData()
            formData.append("file", files[0])
            try {
               // dispatch(setLoading(true))
                setErrorMessage(false)
                // const controller=new AbortController();
                const res = await uploadPromotionalReportExcel(formData, {
                    params: {
                        fileOverride: fileOverride,
                        ...startSchoolYear && {startSchoolYear: startSchoolYear},
                        ...endSchoolYear && {endSchoolYear: endSchoolYear},
                        ...schoolYear && {schoolYear: schoolYear},
                        ...semester && {semester: semester}
                    },
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    //  signal:controller.signal,
                    onUploadProgress: (progressEvent) => {
                        const {loaded, total} = progressEvent;
                        let percent = Math.floor((loaded * 100) / total!)
                        if (percent <= 100) setProgress(+percent)
                    }
                })
                queryClient.setQueriesData('upload', (data: any) => {
                    const _res = (res as any)?.data
                    return {
                        ..._res,
                        enrollments: [
                            ..._res.enrollments?.map((enrollment: any) => {
                                return {
                                    ...enrollment,
                                    ...flat(enrollment, {safe: true}) as object
                                }
                            })!
                        ]
                    }
                })
                dispatch(openSnackbar({
                    message: "Uploaded successful" + "\n" + (res?.message != null ? res?.message : ""),
                    severity: "success",
                    open: true
                }))
                queryClient.invalidateQueries('enrollments')
                queryClient.invalidateQueries('notification-promotion-report')
                await queryClient.invalidateQueries('notification-promotion-report-countENROLLMENT LIST')
                await queryClient.invalidateQueries('notification-promotion-report-countPROMOTIONAL REPORT')
                dispatch(setLoading(false))
                handleClose()
                setOpenUploadError(false);
                handleReset()
                if( (res as  any)?.data?.id){
                    const noOfUnsuccessfulStudent = (res as any)?.data?.noOfUnsuccessfulStudent;
                    const totalNoOfStudent = (res as any)?.data?.totalNoOfStudent;
                    history.push('/enrollments/' + (res as  any)?.data?.id + "?tab=" + (noOfUnsuccessfulStudent > 0 ? (importLabel == "Import Enrollment" ? "2" : "3" )  : "1"), {
                        enrollmentId: (res as  any)?.data?.id,
                        from: "/enrollments",
                        title: `${(res as any)?.data?.schoolName ?? ""} - ${(res as any)?.data?.semester ?? ""} - ${(res as any)?.data?.schoolYear ?? ""}`,
                        uuid: (res as any).data?.uuid,
                    })
                    try {


                        const unsuccessfulMsg = `${noOfUnsuccessfulStudent} ${pluralize(noOfUnsuccessfulStudent, 'Enrollee')} unsuccessfully uploaded`;
                        const successfulMsg = `${totalNoOfStudent} ${pluralize(totalNoOfStudent, 'Enrollee')} successfully uploaded`;

                        setUnSuccessfullyUpload( noOfUnsuccessfulStudent > 0 ? unsuccessfulMsg : null);
                        setSuccessfullyUpload(totalNoOfStudent > 0 ?  successfulMsg : null);
                        if(totalNoOfStudent > 0) setOpen1(true)
                        if(noOfUnsuccessfulStudent > 0) setOpen2(true)
                    }catch (e) {

                    }



                }

            } catch (error: unknown) {
                var err: any = error
                dispatch(setLoading(false))
                if (err?.response?.data?.error?.findIndex((c: any) => c.code == "409") > -1) {
                    handleClose()
                    handleClickOpenUploadError()
                }else if (err?.response?.data?.error?.findIndex((c: any) => c.code == "302") > -1) {
                    setErrorMessage(false)
                    setProgress(0)
                    setErrorMessage(true)
                } else if (err?.response?.data?.message?.includes("The student number you entered is already registered in our system")) {
                    handleOpenIsExisting()
                    setIsExistingsData(err?.response?.data?.data)
                    setIsExistingsLocal(err?.response?.data?.local)
                    setIsExistingTitle(err?.response?.data?.message)
                    handleClose()
                } else {
                    handleReset()
                    handleClose()
                    dispatch(openSnackbar({
                        message:  err?.response?.data?.message || "Your request is being processed. We will notify you by email or you can check the history tab to see the progress of your request once it's complete" || "Something went wrong while uploading file",
                        severity: err?.response?.data?.message ? "error" : "warning",
                        open: true
                    }))
                }
            } finally {

            }
        }

    }, [files, startSchoolYear, endSchoolYear, schoolYear, semester])

    const currentYear = useMemo(() => {
        var currentYear = new Date().getFullYear()
        var years = [];
        var startYear = 2010;
        for (let i = startYear; i <= currentYear; i++) {
            startYear++
            var startYear = startYear
            var lastYear = (startYear) + 1
            var _year = (startYear - 1) + "-" + (lastYear - 1);
            years.push({
                label: _year,
                value: _year,
            });
        }

        return years?.reverse()

    }, [])


    const handleDownloadPrTemplate = async () => {
        dispatch(setLoading(true))
        setErrorMessage(false)
        try {
            await getDownloadPromotionalReport().then(response => {
                getDownload(response, (institutionalCode ?? "") + "_FORMAT_PR_TEMPLATE_" + new Date().getFullYear());
            });

        } catch (error: any) {
            const errorMsg = error.message || 'Something went wrong while deactivating school.'
            dispatch(openSnackbar({
                message: "Something went wrong while uploading file",
                severity: "error",
                open: true
            }))
        } finally {
            setErrorMessage(false)
            dispatch(setLoading(false))
        }
    };
    const handleDownloadEnrollmentTemplate = async () => {
        dispatch(setLoading(true))
        setErrorMessage(false)
        try {
            await getDownloadEnrollment().then(response => {
                onDownloadDocument(response, (institutionalCode ?? "") + "_ENROLLMENT_LIST_TEMPLATE.xlsx_" + new Date().getFullYear());
            });

        } catch (error: any) {
            const errorMsg = error.message || 'Something went wrong while deactivating school.'
            dispatch(openSnackbar({
                message: "Only school admin can download enrollment list template.",
                severity: "error",
                open: true
            }))
        } finally {
            setErrorMessage(false)
            dispatch(setLoading(false))
        }
    };


    const ErrorComponent = <Dialog
        open={openUploadError}
        onClose={handleCloseUploadError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Confirmation Required: Overriding PR?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to override this promotional report or enrollment reports with 'pending' status can be overridden, not those that have been submitted.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button id={"handleUploadYes"} color={"primary"} onClick={() => {
                handleUpload(true)
                handleCloseUploadError({}, null)
                setOpen(true)

            }} autoFocus>
                Yes
            </Button>
            <Button id={"handleUploadNo"} color={"error"} onClick={() => {
                handleReset()
                handleCloseUploadError()
            }
            }>No</Button>
        </DialogActions>
    </Dialog>

    const handleSchoolYearChange = (event: any) => {
        setSchoolYear(event.target.value)
    }

    const handleSemesterChange = (event: any) => {
        setSemester(event.target.value)
    }

    /*async function getTheFile() {

        try {
            // @ts-ignore
            window.showOpenFilePicker();
        } catch (err) {
            if ('showOpenFilePicker' in window) {
                console.log("Save As button in addition to a Save button");
            }
        }

    }*/

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            (event as any).preventDefault();
            (event as any).returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);


        return () => {
            handleReset()
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [])
    const [importLabel, setImportLabel] = useState<string>("Import Promotional Report");
    const Component = <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={handleCloseBackdrop}
        maxWidth="sm"
        fullWidth
    >
        <DialogTitle>{importLabel}</DialogTitle>
        <DialogContent>
            <div  {...getRootProps({

                style: {
                    border: '3px dashed grey',
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200
                }
            })}>
                <input  {...getInputProps()} />
                {
                    files
                        ? files.map((file: File) => {
                            return <Box  style={{
                                position: "absolute",
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%"
                            }} key={file.name}>
                                <div style={{width: 100}}>
                                    <Lottie animationData={uploadFileAnimation}/>
                                </div>

                                <Typography>File Name: {file.name}</Typography>
                                <Typography>Size: {(file.size / 1024).toFixed(2)} kb</Typography>
                            </Box>
                        })
                        : isDragActive ?
                            <Typography>Drop it here ...</Typography> :
                            <div style={{
                                position: "absolute",
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%"
                            }}>
                                <div style={{width: 100}}>
                                    <Lottie animationData={uploadFileAnimation}/>
                                </div>

                                <div>
                                    <Typography component="div">Drag 'n' drop <strong>.xlsx</strong> file here, or click to
                                        select file</Typography>
                                </div>
                            </div>

                }
            </div>

            {files  ? <div style={{flex: 1, flexDirection: "row", display: "flex", paddingTop: 10}}>
                <FormControl style={{flex: 1, display: "flex",  paddingRight: 5}}>
                    <InputLabel id="schoolYear-select-role-label">Academic Year</InputLabel>
                    <Select disabled={!!progress} aria-label={"schoolYear-select-role-label"}
                            inputProps={{
                                'id': `schoolYear-select-role`,
                            }}
                            SelectDisplayProps={{
                                // @ts-ignore
                                "id": `schoolYear-select-role`
                            }}
                            id="schoolYear-select-role"
                            onChange={handleSchoolYearChange}
                            labelId="demo-simple-select-label"
                        label="Academic Year"
                        value={schoolYear?.toString()}
                    >
                        <MenuItem disabled value="">
                            <em>Select Academic Year</em>
                        </MenuItem>
                        {
                            currentYear?.map((sy: any) => {
                                return <MenuItem value={sy?.value}>
                                    <em>{sy?.label}</em>
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>

                <FormControl style={{flex: 1, display: "flex"}}>
                    <InputLabel id="demo-simple-select-label">School Semester</InputLabel>
                    <Select disabled={!!progress} aria-label={"school-semester-select-role-label"}
                            onChange={handleSemesterChange}
                            labelId="demo-simple-school-semester-select-label"
                            id="demo-simple-school-year-semester-label"
                            label="School Semester"
                            value={semester?.toString()}
                    >
                        <MenuItem disabled value="">
                            <em>Select School Semester</em>
                        </MenuItem>
                        <MenuItem value={1}>
                            <div style={{fontSize: 14}}>FIRST SEMESTER</div>
                        </MenuItem>
                        <MenuItem value={2}>
                            <div style={{fontSize: 14}}>SECOND SEMESTER</div>
                        </MenuItem>
                        <MenuItem value={3}>
                            <div style={{fontSize: 14}}>MID YEAR/SUMMER</div>
                        </MenuItem>
                    </Select>
                </FormControl>
            </div> : null}

            <Box sx={{my: 2, display: progress > 0 ? "block" : "none"}}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant='overline'>Uploading</Typography>
                    <Typography variant='overline'>{progress}%</Typography>
                </Box>
                <LinearProgress sx={{borderRadius: "10%"}}/>
            </Box>
            {
                errorMessage ?
                <Typography color={"red"} fontStyle={"italic"} variant="overline" >
                    {"PROMOTIONAL REPORT SY " + schoolYear + " | " + (semester == "1" ? "1st semester" : (semester == "2" ? "2nd semester" : "mid year/summer")) + " IS ALREADY EXISTS."}
                </Typography> : null
            }
        </DialogContent>
        <DialogActions>
            <Button disabled={!!progress} id={"handleUploadpr"} onClick={handleClose}>Cancel</Button>
            {
                files &&
                <>
                    <Button variant="outlined" color="warning" onClick={handleReset}
                            disabled={!!progress}>Reset</Button>
                    <Button variant="contained" onClick={() => {
                        handleUpload(false)
                    }} disabled={!!progress || !(semester && schoolYear)}>Upload</Button>
                </>
            }
        </DialogActions>
    </Dialog>


    return {
        ErrorComponent,
        Component,
        handleClickOpen,
        handleDownloadPrTemplate,
        handleDownloadEnrollmentTemplate,
        handleClose,
        openIsExisting,
        handleCloseIsExisting,
        handleUpload,
        isExistingTitle,
        isExistingsData,
        isExistingsLocal,
        handleReset,
        handleCloseUploadError,
        setImportLabel,
        unSuccessfullyUpload,
        successfullyUpload,
        open1,
        open2,
        setOpen1,
        setOpen2
    }
}
