import * as React from "react";
import {SyntheticEvent, useCallback, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Checkbox,
    DialogContent,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import {useDispatch} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {Transition} from "../../wrapper/Transition";
import TextField from "@mui/material/TextField";
import {useFormik} from "formik";
import {setErrorSnackbar} from "../../helper/utils/helper";
import * as yup from "yup";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import SearchAsYouType from "../SearchAsYouType";
import {SearchSchoolResponseType} from "../../types";
import {CloseOutlined} from "@mui/icons-material";
import dayjs from "dayjs";
import {createPricingRules} from "../../requests/rules.request";
import moment from "moment/moment";
import {setLoading} from "../../redux/features/app";
import {useQueryClient} from "react-query";


interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave?: () => void;
    title?: string
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const validationSchema = yup.object({
    amount: yup.number().min(0).moreThan(0),
    name: yup.string().required("Name is required."),
    effectiveDateTo: yup.date().required("Effective Date To is required."),
    effectiveDateFrom: yup.date().required("Effective Date From is required."),
    applyAll: yup.bool()
});

const initialValues = {
    amount: "0",
    effectiveDateTo: moment.utc().tz('Asia/Manila').add(1, 'years').toDate(),
    effectiveDateFrom: moment.utc().tz('Asia/Manila').toDate(),
    applyAll: true,
    name: ""
}


function getLocalizationProvider<T, T_1>(formik: any, calendarOpen: boolean | undefined, setCalendarOpen: (value: (((prevState: (boolean | undefined)) => (boolean | undefined)) | boolean | undefined)) => void, handleOnSelectedSchool: (value: SearchSchoolResponseType) => void, handleSchools: any, onChangeHandlerFrom: any, onChangeHandlerTo: any, dateFrom: any, dateTo: any, checked: any, setChecked: any, handleChecked: any) {


    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
            <div style={{paddingTop: 6}}>
                <TextField
                    type={"text"}
                    label="Name"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    aria-valuemin={0}
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.name && Boolean(formik.errors.name)
                    }
                    helperText={formik.touched.name && formik.errors.name}
                />
            </div>
            <div style={{paddingTop: 6}}>
                <TextField
                    type={"number"}
                    label="Amount"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    aria-valuemin={0}
                    inputMode={"decimal"}
                    id="amount"
                    name="amount"
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                />
            </div>
            <div style={{paddingTop: 10}}>
                <DatePicker
                    onChange={(newValue) => onChangeHandlerFrom(newValue as any)}

                    label={'Effective Date From'}
                    open={calendarOpen}

                    onClose={() => setCalendarOpen(false as any)}
                    onOpen={() => setCalendarOpen(true as any)}
                    value={dateFrom}
                    renderInput={(params) => <TextField {...params} style={{flex: 1, display: "flex"}}
                                                        error={
                                                            (dayjs(formik.values.effectiveDateTo) <= dayjs(formik.values.effectiveDateFrom))
                                                        }
                                                        helperText={(dayjs(formik.values.effectiveDateTo) <= dayjs(formik.values.effectiveDateFrom)) ? "The start date must be earlier than the end one." : ""}
                                                        id={"effectiveDateFrom"}
                                                        size="small"/>}/>
            </div>
            {/*<div style={{paddingTop: 10}}>
                <DatePicker

                    onChange={(newValue) => onChangeHandlerTo(newValue as any)}
                    label={'Effective Date To'}
                    open={calendarOpen}
                    onClose={() => setCalendarOpen(false as any)}
                    onOpen={() => setCalendarOpen(true as any)}
                    value={dateTo}
                    renderInput={(params) => <TextField {...params}
                                                        error={
                                                            formik.touched.effectiveDateTo && Boolean(formik.errors.effectiveDateTo)
                                                        }
                                                        style={{flex: 1, display: "flex"}}
                                                        id={"effectiveDateTo"} size="small"/>}/>
            </div>*/}
            <div>
                <Checkbox
                    checked={checked}
                    onChange={handleChecked}

                />
                Apply to all
            </div>
            {!checked ?
                <div style={{flex: 1, display: "flex", alignItems: "center"}}>
                    <div style={{paddingRight: 6, width: "80%"}}>
                        <SearchAsYouType
                            id={"pricingRulesSelect"}
                            required={true}
                            label="School Institutional Name"
                            onSelected={handleOnSelectedSchool}
                        />
                    </div>

                    <div style={{flex: 1, display: "flex", justifyContent: "center"}}>
                        <Button onClick={handleSchools} size={"small"} variant="contained" disableElevation>
                            Add School
                        </Button>
                    </div>

                </div> : null
            }


        </div>

    </LocalizationProvider>;
}

const SpecialOrderPricingRules = React.memo((props: IProps) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const [schools, setSchools] = useState<any[]>([])
    const [dateFrom, setDateFrom] = useState<Date>(moment.utc().tz('Asia/Manila').toDate());
    const [dateTo, setDateTo] = useState<Date>(moment.utc().tz('Asia/Manila').add(1, 'years').toDate());
    const [checked, setChecked] = React.useState(true);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                dispatch(setLoading(true))
                values.amount = values?.amount?.toString()
                const res = await createPricingRules(values, schools);

                setSchools([])
                dispatch(setLoading(false))
                handleClose();
                formik.setFieldValue("applyAll", false)
                setChecked(false)
                formik.resetForm();
                if (/*values.applyAll*/ true) {
                    queryClient.invalidateQueries("pricingRulesGroupBySchool");
                }

                setSnackbarStore({
                    message: "Successfully added Pricing rules",
                    severity: "success",
                    open: true,
                });

            } catch (error) {
                dispatch(setLoading(false))
                setErrorSnackbar(error, setSnackbarStore);
            }
        },
    });
    const handleChangeChecked = (event: any) => {
        setChecked(event.target.checked);
        formik.setFieldValue("applyAll", event.target.checked)
    };
    const onChangeHandlerFrom = (_date: Date | null, keyboardInputValue?: string) => {
        if (_date) {
            const __date = moment.utc(moment(_date).format("YYYY-MM-DD")).tz('Asia/Manila').toDate()
            setDateFrom(__date)
            formik.setFieldValue("effectiveDateFrom", __date)
        }
    };
    const onChangeHandlerTo = (_date: Date | null, keyboardInputValue?: string) => {
        if (_date) {
            const __date = moment.utc(moment(_date).format("YYYY-MM-DD")).tz('Asia/Manila').toDate()
            setDateTo(__date)
            formik.setFieldValue("effectiveDateTo", __date)
        }
    };
    const [addSchool, setAddSchool] = useState<any>()
    const {open, handleClose, onSave, title} = props;

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])

    const [calendarOpen, setCalendarOpen] = useState<boolean>()

    const handleSchools = () => {
        var _schools = [...schools]

        var existing = _schools.findIndex((c: any) => {
            return c.id == addSchool?.id
        })

        if (existing > -1) return
        if (addSchool?.length > 0) {
            _schools.push(createData(addSchool?.id, addSchool?.name, addSchool?.institutionalCode, addSchool?.region?.name, addSchool?.province?.name))
            setSchools(_schools)
        } else {
            try {
                var _localStorage = localStorage.getItem("pricingRulesSelect")
                var __localStorage = JSON.parse(_localStorage ?? "{}")
                if (__localStorage) {
                    _schools.push(createData(__localStorage?.id, __localStorage?.name, __localStorage?.institutionalCode, __localStorage?.region?.name, __localStorage?.province?.name))
                    setSchools(_schools)
                }
            } catch (e) {
            }
        }


    }

    function createData(
        id: number,
        name: string,
        institutionalCode: string,
        regionName: string,
        provinceName: string
    ) {
        return {id, name, institutionalCode, regionName, provinceName};
    }

    useEffect(() => {
        setSchools([])
        setAddSchool([])
    }, [])
    const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
        localStorage.setItem("pricingRulesSelect", JSON.stringify(value))
        setAddSchool(value)
    }, [])

    const handleCreate = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();
            formik.handleSubmit();
        },
        [formik]
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            maxWidth="lg"
            fullWidth={true}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                style={{fontSize: 16}}>{title ?? ""}</DialogTitle>

            <DialogContent style={{
                borderBottomWidth: "1.2px",
                borderTopWidth: "1.2px",
                borderStyle: "solid",
                borderColor: "#E0E0E0",

            }}>
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid item xs={!checked ? 6 : 12}>
                        {getLocalizationProvider(formik, calendarOpen, setCalendarOpen, handleOnSelectedSchool, handleSchools, onChangeHandlerFrom, onChangeHandlerTo, dateFrom, dateTo, checked, setChecked, handleChangeChecked)}
                    </Grid>
                    {!checked ? <Grid item xs={6}>
                        <Paper variant="outlined" style={{marginTop: 6}}>
                            <List sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                borderColor: "primart.main",
                                maxHeight: 300,
                                '& ul': {padding: 0},
                            }}
                                  subheader={<li/>}>
                                <ListItemButton sx={{backgroundColor: "#f5f5f5"}} component="a">
                                    <ListItemText
                                        sx={{my: 0}}

                                        primary="School Institutional Name List"
                                        primaryTypographyProps={{
                                            fontSize: 20,
                                            fontWeight: 'medium',
                                            letterSpacing: 0,
                                        }}
                                    />
                                </ListItemButton>
                                {schools.length == 0 ? <ListItem>
                                    <ListItemText primary={"Sorry, no records yet."}/>

                                </ListItem> : null}
                                {schools.map((row,) => (
                                    <React.Fragment>
                                        <ListItem id={row.id}>
                                            <ListItemText primary={row.name ?? row.displayName}
                                                          secondary={
                                                              <React.Fragment>
                                                                  <Typography
                                                                      sx={{display: 'inline', fontStyle: "italic"}}
                                                                      component="span"
                                                                      variant="body2"
                                                                      color="text.primary"
                                                                  >
                                                                      {`${row.institutionalCode} ${row.regionName} ${row.provinceName}`}
                                                                  </Typography>
                                                              </React.Fragment>
                                                          }
                                            />
                                            <ListItemButton
                                                style={{flex: 1, display: "flex", justifyContent: "flex-end"}}
                                                onClick={() => {
                                                    var index = schools.findIndex(c => c.id == row.id);
                                                    if (index > -1) {
                                                        var _schools = [...schools]
                                                        _schools.splice(index, 1)
                                                        setSchools(_schools)
                                                    }
                                                }
                                                }>
                                                <CloseOutlined/>
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider/>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Paper>

                    </Grid> : null}
                </Grid>


            </DialogContent>
            <DialogActions>
                <div>
                    <Button
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
                <div>
                    <Button disabled={(!checked ? schools?.length == 0 : false)}
                            onClick={handleCreate}
                            variant={"contained"}
                    >
                        Submit
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
});

export default SpecialOrderPricingRules;
