
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

//MuiTablePagination-displayedRows


const container = document.getElementById('root')!;
ReactDOM.createRoot(container).render(
	<React.StrictMode>
			<App />
	</React.StrictMode >
);



