import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import {getProgramByName, getPrograms, getProgramsSelect} from '../requests/program.request';
import { ProgramResponseType } from '../types';
import flat from 'flat'

export const useProgramQuery = (params: AxiosRequestConfig['params']) => {
  return useQuery(['programs', params.search, params?.schoolId], () => getPrograms(params), {

    select: useCallback((data: ProgramResponseType) => {
      return (
        {
          ...data,

          data: data.data.map((el, index) => ({ ...el, ...flat(el, { safe: true }) as object })) // flatten the object // not type-safe
        }
      )
    }, []),
  })
};

export const useProgramSelectQuery = (params: AxiosRequestConfig['params']) => {
  return useQuery(['programs', 'select'],() =>  getProgramsSelect(params))
}
