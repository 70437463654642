import { Tooltip } from "@mui/material";
import { MdOutlineAddReaction } from "react-icons/md";


import { EmojiPopover } from "./emoji-popover";


interface ReactionsProps {
  data: any[];
  onChange: (value: string) => void;
    currentMemberId: any
};


export const Reactions = ({
  data,
  onChange,
    currentMemberId
}: ReactionsProps) => {

  if (data.length === 0 || !currentMemberId) {
    return null;
  }

  return (
      <div className="flex items-center gap-1 mt-1 mb-1">

        {data.map((reaction) => (
            <Tooltip
                key={reaction.id}
                title={`${reaction.count} ${reaction.count === 1 ? "person" : "people"} reacted with ${reaction.value}`}
            >
              <button
                  onClick={() => onChange(reaction.value)}
                  className={
                    `${reaction.memberIds.includes(currentMemberId) && "bg-blue-100/70 border-blue-500 text-white"} h-6 px-2 rounded-full bg-slate-200/70 border border-transparent text-slate-800 flex items-center gap-x-1`
                  }
              >
                {reaction.value}
                <span
                    className={(
                        `text-xs font-semibold text-muted-foreground ${reaction.memberIds.includes(currentMemberId) && "text-blue-500"}`

                    )}
                >{reaction.count}</span>
              </button>
            </Tooltip>
        ))}

        <EmojiPopover
            hint="Add reaction"
            onEmojiSelect={(emoji) => onChange(emoji)}
        >
          <button
              className="h-7 px-3 rounded-full bg-slate-200/70 border border-transparent hover:border-slate-500 text-slate-800 flex items-center gap-x-1">
            <MdOutlineAddReaction className="size-4"/>
          </button>
        </EmojiPopover>
      </div>
  );
};

