import * as React from 'react';
import {SyntheticEvent, useCallback, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, Box, Checkbox, FormControlLabel, TextField} from '@mui/material';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {useQueryClient} from 'react-query';
import {useTransition} from "../../hooks/useTransitions";
import {useFormik} from "formik";
import {setErrorSnackbar} from "../../helper/utils/helper";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {postRequirements} from "../../requests/requirements.request";
import {LoadingButton} from "@mui/lab";

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    isRequired: yup.boolean()

})

const initialValues = {
    name: "",
    isRequired: false,
}


const Transition = useTransition();


interface IProps {
    open: boolean
    handleClose: () => void
    onSave: (payload: any) => void
}

const AddConfigurationRequirementDialog = React.memo((props: IProps) => {
    const dispatch = useDispatch()
    const {open, handleClose, onSave} = props
    const [loading, setLoading] = useState(false);
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const queryClient = useQueryClient();
    const formik = useFormik({
        initialValues, validationSchema,
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {
                const res = await postRequirements(values)
                queryClient.setQueriesData(
                    `requirementQuery`,
                    (oldData: any) => {
                        return {
                            ...oldData,
                            data: [{
                                id: res.data,
                                name: values.name,
                                isRequired: values.isRequired
                            }, ...oldData?.data],
                        };
                    }
                );
                setSnackbarStore({
                    message: res.message,
                    severity: "success",
                    open: true,
                })
                handleClose()
                setLoading(false)
            } catch (error: any) {

                setErrorSnackbar(error, setSnackbarStore);
                setLoading(false)
            } finally {
                setLoading(false)
            }


        }
    })
    const handleSubmit = useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();

            formik.handleSubmit();
        },
        [formik]
    );
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Add Configuration</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    Please fill in the details of the requirement you want to add
                    <br/>
                    Note: Fields with <strong>*</strong> are required
                </Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}
                >

                    <TextField
                        label="Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        id='Name'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <FormControlLabel
                        name={"applicationIds"}
                        checked={formik.values?.isRequired}
                        value={formik.values?.isRequired}
                        onChange={(event: any, checked) => {
                            formik.setFieldValue('isRequired', checked)
                        }}
                        control={<Checkbox/>}
                        label={"Enabled Requirement"}
                    />

                </Box>
            </DialogContent><DialogActions>
            <Button id={"cancelDiscipline"} onClick={handleClose}>Cancel</Button>
            <LoadingButton loading={loading} id={"addDiscipline"} variant='contained'
                           onClick={handleSubmit}>Add</LoadingButton>
        </DialogActions>
        </Dialog>
    )
})

export default AddConfigurationRequirementDialog
