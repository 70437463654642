import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useQuery } from "react-query"
import type { PromotionalReportType, UploadEnrollmentResponseType } from "../types"

export const useUploadQuery = (params: {
    pageSize?: number,
    page?: number,
    filters?: {
        programId: string
    }
}) => {
    const { filters } = params
    return useQuery('upload', () => undefined, {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        select: useCallback((data: UploadEnrollmentResponseType) => {
            if (data) return {
                ...data,
                enrollments: data.enrollments.filter((enrollment) => {
                    if (filters?.programId === "all") return true
                    return enrollment.programId + "" === filters?.programId
                }).map(enrollment => {
                    return {
                        ...enrollment,
                        _data: {
                            ...enrollment,
                            _acadYear: data.schoolYear,
                        },
                    }
                })
            }
        }, [filters])
    })
}