
import { useQuery } from 'react-query';
import {getDisciplineByName} from "../requests/discipline.request";


export const useSearchDisciplineQuery = (str: string, schoolId: any ) => {
    return useQuery(['discipline', str, schoolId, schoolId?.regionId, schoolId?.pageSize, schoolId?.page], ({ signal }) => getDisciplineByName(str, signal, schoolId), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}
