import api from "./config";
import axios from "axios";
let cancelToken = axios.CancelToken.source();
export const markAsSeenRequest = async (notificationId: number) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.post(`/notifications/mark-as-seen`, {}, {
        ...{cancelToken: cancelToken?.token},
    })
    return res?.data
}