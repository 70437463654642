import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useEffectiveDateQuery} from "../../hooks/useEffectiveDate";
import Lottie from "lottie-react";
import noDataAnimation from "../../assets/ty.json";
import {ReviewDialog} from "../dialogs/Review.dialog";
import Student from "../../redux/features/student";
import Button from "@mui/material/Button";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};
interface Student {
    completed: any;
    studentId: any;
    majorId: any;
    schoolId: any;
    programId: any;
    name: string;
    studentNumber: string;
}
interface EffectiveDate {
    amount?: number;
    completed?: boolean
}
interface ExpandableRowProps {
    so: Student;
    effectiveDate: EffectiveDate;
    handleOpen: (program: any) => void;
}
const ExpandableRow: React.FC<ExpandableRowProps> = ({ so, effectiveDate, handleOpen  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        handleOpen({
            programId: so.programId,
            schoolId: so.schoolId,
            studentNumber: so.studentNumber,
            majorId: so.majorId,
            studentId: so.studentId
        })

    };



    return (
        <>
            <tr onClick={toggleExpand}>
                <td className="border-b dark:border-darkmode-400">
                    <div className="font-medium whitespace-nowrap">{so?.name}</div>
                    <div
                        className="text-slate-500 text-sm mt-0.5 whitespace-nowrap">{so?.studentNumber}
                    </div>
                </td>
                <td className="text-right border-b dark:border-darkmode-400 w-32">
                    <div style={{flex: 1, display: "flex"}}>

                        {so?.completed ?
                            <div className="font-medium whitespace-nowrap"><span><Lottie
                                loop={false} animationData={noDataAnimation}/></span>
                            </div> : <div style={{width: 42}}/>}
                        <div className="font-medium whitespace-nowrap">
                           <span>₱ {effectiveDate?.amount ?? 30}</span>

                        </div>
                    </div>

                </td>
                <td className="text-right border-b dark:border-darkmode-400 w-32">
                    <Button id={"cancelacademiccalendar"} onClick={toggleExpand}>View Preview</Button>

                </td>
            </tr>

        </>
    );
};


export function Previews(props: any) {
    const dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const sos = useSelector((store: RootState) => store.specialOrder?.sos)
    const specialOrderProgramName = useSelector((store: RootState) => store.specialOrder?.specialOrderProgramName)
    const specialOrderSchoolName = useSelector((store: RootState) => store.specialOrder?.specialOrderSchoolName)
    const paymentGateway = useSelector((store: RootState) => store.specialOrder?.paymentGateway)
    const {schoolId} = useAddEntities();
    const [studentId, setStudentId] = useState();
    const [majorId, setMajorId] = useState();
    const [programId, setProgramId] = useState();
    const [studentNumber, setStudentNumber] = useState("");
    const [_schoolId, setSchoolId] = useState();
    const {data: effectiveDate} = useEffectiveDateQuery({
        ...(schoolId && {schoolId: schoolId})
    });


    const handleDialogOpen = (so: Student) => {
        setDialogOpen(true);
        setSchoolId(so.schoolId)
        setStudentId(so.studentId)
        setMajorId(so.majorId)
        setStudentNumber(so.studentNumber)
        setProgramId(so.programId)
    };


    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSave = () => {
        // Perform save action
        setDialogOpen(false);
    };

    return (
        <React.Fragment>


            <div className="intro-y box overflow-hidden mt-5">

                <div
                    className="flex flex-col lg:flex-row pt-10 px-5 sm:px-20 sm:pt-20 lg:pb-20 text-center sm:text-left">
                    <div className="font-semibold text-primary text-3xl">SUMMARY</div>

                </div>
                <div
                    className="flex flex-col lg:flex-row border-b px-5 sm:px-20  sm:pb-20 text-center sm:text-left">
                    <div>
                        <div className="text-base text-slate-500">School Details</div>
                        <div className="text-lg font-medium text-primary mt-2">{specialOrderSchoolName ?? ""}</div>
                    </div>
                    <div className="mt-10 lg:mt-0 lg:ml-auto lg:text-right">
                        <div className="text-base text-slate-500">Program Details</div>
                        <div className="text-lg text-primary font-medium mt-2">{specialOrderProgramName ?? ""}</div>
                    </div>
                </div>
                <div className="px-5 sm:px-16 py-10 sm:py-20">
                    <div className="overflow-x-auto">
                        <table className="table">
                            <thead>
                            <tr>
                                <th className="border-b-2 dark:border-darkmode-400 whitespace-nowrap">Name</th>
                                <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">QTY</th>
                                <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                sos?.map(so => {

                                    return <>
                                        <ExpandableRow handleOpen={handleDialogOpen} so={so} effectiveDate={effectiveDate}/>
                                    </>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <ReviewDialog
                    schoolId={_schoolId}
                    majorId={majorId}
                    studentId={studentId}
                    programId={programId}
                    studentNumber={studentNumber}
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    onSave={handleSave} />
                <div className="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row">
                    <div className="text-center sm:text-left mt-10 sm:mt-0">
                        <div
                            className="text-lg text-primary font-medium mt-2">{paymentGateway?.toUpperCase() ?? ""}</div>
                    </div>
                    <div className="text-center sm:text-right sm:ml-auto">
                        <div className="text-base text-slate-500">Total Amount</div>
                        <div
                            className="text-xl text-primary font-medium mt-2">₱ {(sos?.length ? (effectiveDate?.amount ?? 30) * sos?.length : 0)}</div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}