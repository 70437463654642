import React from "react";

export default function useDialog<T>() {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<T>();

    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, [])

    return { handleOpen, handleClose, open, data, setData }
}