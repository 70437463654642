import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, MenuItem, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ISnackbarSlice, openSnackbar } from "../../redux/features/snackbar";
import { setErrorSnackbar } from "../../helper/utils/helper";
import { postAcademicCalendar } from "../../requests/academicCalendar.request";
import dayjs from "dayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useEffect, useRef, useState} from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment-timezone";
import {JSONfn} from "../../helper/utils/formatting";
const validationSchema = yup.object({
  firstSemStart: yup.string().required("Start First Semester is required"),
  firstSemEnd: yup.string().required("End First Semester is required"),
  secondSemStart: yup.string().required("Start Second Semester is required"),
  secondSemEnd: yup.string().required("End Second Semester is required"),
  thirdSemStart: yup.string(),
  thirdSemEnd: yup.string(),
  remark: yup.string(),
  schoolYear: yup.string().required("Please select Academic Year"),
});

const initialValues = {
  remark: "Yearly",
  firstSemStart: "",
  firstSemEnd: "",
  secondSemStart: "",
  secondSemEnd: "",
  thirdSemStart: "",
  thirdSemEnd: "",
  schoolYear: moment().format("YYYY") +"-"+ moment().add(1, "years").format("YYYY"),
};

const academicYear = () => {
  var year = new Date().getFullYear();
  var lastyear = new Date().getFullYear() - 1;
  var range = [];
  var lastrange = [];
  var academicYear = [];
  lastrange.push(lastyear);
  range.push(year);
  for (var i = 1; i < 3; i++) {
    lastrange.push(lastyear + i);
    range.push(year + i);

    var _year = lastrange[i - 1] + "-" + lastrange[i];

    academicYear.push({
      label: _year,
      value: _year,
    });
  }

  return academicYear;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const AddAcademicCalendar = React.memo((props: IProps) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
    const [dateFrom, setDateFrom] = useState<Date>( moment.utc().tz('Asia/Manila').toDate());
    const [dateTo, setDateTo] = useState<Date>(moment.utc().tz('Asia/Manila').add(1, 'years').toDate());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const customInputRef = useRef();
    const [calendarOpen, setCalendarOpen ] = useState<boolean>()
    const [calendarToOpen, setCalendarToOpen ] = useState<boolean>()

  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      try {

          if(dateFrom && dateTo){
              const __date = moment.utc(dateFrom).tz('Asia/Manila')
              const date2 = moment.utc(dateTo).tz('Asia/Manila')
              values.schoolYear =__date.format("YYYY") + "-" + date2.format("YYYY")
              console.log(__date.format("YYYY") + "-" + date2.format("YYYY"))
          }

        var res = await postAcademicCalendar(values);


              setSnackbarStore({
                  message: "Successfully added academic calendar",
                  severity: "success",
                  open: true,
              });
        actions.resetForm();
        handleClose();
      } catch (error: any) {
        setErrorSnackbar(error, setSnackbarStore);
      }
    },
  });

  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

    useEffect(() =>{
        return () => {
            setCalendarOpen(false)
            setCalendarToOpen(false)
        }
    }, [])

    const onChangeHandlerFrom = (_date: Date | null, keyboardInputValue?: string) => {

        if(_date){
            const __date = moment.utc(_date).tz('Asia/Manila')
            const date2 = moment.utc(_date).tz('Asia/Manila').add(1, 'years')
            setDateFrom(__date.toDate());
            setDateTo( date2.toDate());
            formik.setFieldValue("schoolYear", __date.format("YYYY") + "-" + date2.format("YYYY"))
        }
    };
    const onChangeHandlerTo = (_date: Date | null, keyboardInputValue?: string) => {
        if(_date){
            const __date = moment.utc(_date).tz('Asia/Manila')
            const date2 = moment.utc(_date).tz('Asia/Manila').subtract(1, 'years')
            setDateFrom(date2.toDate());
            setDateTo( __date.toDate());

            formik.setFieldValue("schoolYear", date2.format("YYYY") + "-" + __date.format("YYYY"))
        }
    };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Add Academic Calendar</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 3,
            mt: 3,
          }}
        >

        {/*  <TextField
            select
            label="Academic Year"
            margin="dense"
            size="small"
            fullWidth

            value={formik.values.schoolYear}
            onChange={(event) =>
              formik.setFieldValue("schoolYear", event.target.value)
            }
          >
            {academicYear().map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>*/}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{flex: 1,  display: "flex", flexDirection: "row", }}>
                <div  style={{paddingRight: 6}}>
                    <DatePicker

                        label={'Academic Year From'} openTo="year"
                        open={calendarOpen}
                        onClose={() => setCalendarOpen(false as any)}
                        onOpen={() => setCalendarOpen(true as any)}
                        value={dateFrom}
                        views={['year']}
                        onChange={(newValue) => onChangeHandlerFrom(newValue as any)}
                        renderInput={(params) => <TextField {...params} id={"academicYearFrom"}   size="small" />}/>
                </div>
              <div>
                  <DatePicker
                      label={'Academic Year To'} openTo="year"
                      open={calendarToOpen}
                      onClose={() => setCalendarToOpen(false as any)}
                      onOpen={() => setCalendarToOpen(true as any)}
                      value={dateTo}
                      views={['year']}
                      onChange={(newValue) => onChangeHandlerTo(newValue)}
                      renderInput={(params) => <TextField {...params}  id={"academicYearTo"} size="small" />}/>
              </div>

            </div>

            </LocalizationProvider>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
           justifyContent: "space-between",
          }}
        >

            <TextField
                style={{paddingRight: 6}}
                label="Start of First Semester"
                type="date"
                id={"StartofFirstSemester"}
                margin="dense"
                size="small"
                fullWidth
                required
                value={formik.values.firstSemStart}
                onChange={(event) =>
                    formik.setFieldValue("firstSemStart", event.target.value)
                }
                error={
                  (formik.touched.firstSemStart &&
                      Boolean(formik.errors.firstSemStart) || (dayjs(formik.values.firstSemEnd) <= dayjs(formik.values.firstSemStart)))
                }
                helperText={
                    formik.touched.firstSemStart && formik.errors.firstSemStart
                }
                InputLabelProps={{
                  shrink: true,
                }}
            />

  <TextField
      id={"EndofFirstSemester"}
      label="End of First Semester"
      type="date"
      margin="dense"
      size="small"
      fullWidth
      required
      value={formik.values.firstSemEnd}
      onChange={(event) =>
          formik.setFieldValue("firstSemEnd", event.target.value)
      }
      error={

        (formik.touched.firstSemStart &&
            Boolean(formik.errors.firstSemStart) || (dayjs(formik.values.firstSemEnd) <= dayjs(formik.values.firstSemStart)))
      }
      helperText={formik.touched.firstSemEnd && formik.errors.firstSemEnd}
      InputLabelProps={{
        shrink: true,
      }}
  />

        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
            mt: 3,
          }}
        >
          <TextField
              style={{paddingRight: 6}}
              id={"StartofSecondSemester"}
            label="Start of Second Semester"
            type="date"
            margin="dense"
            size="small"
            fullWidth
            required
            value={formik.values.secondSemStart}
            onChange={(event) =>
              formik.setFieldValue("secondSemStart", event.target.value)
            }
            error={
              (formik.touched.secondSemStart &&
              Boolean(formik.errors.secondSemStart)) || (dayjs(formik.values.secondSemEnd) <= dayjs(formik.values.secondSemStart))
            }
            helperText={
              formik.touched.secondSemStart && formik.errors.secondSemStart
            }
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id={"EndofSecondSemester"}
            label="End of Second Semester"
            type="date"
            margin="dense"
            size="small"
            fullWidth
            required
            value={formik.values.secondSemEnd}
            onChange={(event) =>
              formik.setFieldValue("secondSemEnd", event.target.value)
            }
            error={
              formik.touched.secondSemEnd && Boolean(formik.errors.secondSemEnd)
            }
            helperText={
              formik.touched.secondSemEnd && formik.errors.secondSemEnd
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
            mt: 3,
          }}
        >
          <TextField  style={{paddingRight: 6}}
            label="Start of Third Semester"
            id="StartofThirdSemester"
            type="date"
            margin="dense"
            size="small"
            fullWidth
            value={formik.values.thirdSemStart}
            onChange={(event) =>
              formik.setFieldValue("thirdSemStart", event.target.value)
            }
            error={
              formik.touched.thirdSemStart &&
              Boolean(formik.errors.thirdSemStart)
            }
            helperText={
              formik.touched.thirdSemStart && formik.errors.thirdSemStart
            }
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField

            id="EndofThirdSemester"
            label="End of Third Semester"
            type="date"
            margin="dense"
            size="small"
            fullWidth
            value={formik.values.thirdSemEnd}
            onChange={(event) =>
              formik.setFieldValue("thirdSemEnd", event.target.value)
            }
            error={
              formik.touched.thirdSemEnd && Boolean(formik.errors.thirdSemEnd)
            }
            helperText={formik.touched.thirdSemEnd && formik.errors.thirdSemEnd}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 3,
            mt: 3,
          }}
        >
          <TextField
            id="ac-remark-selectt"
            label="Remark"
            margin="dense"
            size="small"
            fullWidth
            defaultValue={"Yearly"}
            value={formik.values.remark}
            onChange={(event) =>
              formik.setFieldValue("remark", event.target.value)
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id={"cancelAcademic"} onClick={handleClose}>Cancel</Button>
        <Button
            id={"saveAcademic"}
          disabled={!(formik.isValid && formik.dirty)}
          variant="contained"
          onClick={handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AddAcademicCalendar;
