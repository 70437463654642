import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Alert,
    Box,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {useQueryClient} from 'react-query';
import {postUser} from "../../requests/user.request";
import {useRegionSelectQuery} from "../../hooks/useRegionQuery";
import {useAppSelector} from "../../redux/store";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setErrorSnackbar} from "../../helper/utils/helper";
import SearchAsYouType from "../SearchAsYouType";
import {SearchSchoolResponseType} from "../../types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {LoadingButton} from "@mui/lab";

const validationSchema = yup.object({
    schoolId: yup.string(), // userName: yup.string().required('UserName is required'),
    // password: yup.string().required('Password is required'),
    regionId: yup.number().nullable(),
    email: yup.string().email().required('Email is required'), //confirmPassword: yup.string().required('Confirm Password is required'),
    firstName: yup.string().required('First Name is required'),
    middleName: yup.string(),
    lastName: yup.string().required('Last Name is required'),
    designation: yup.string().required('Designation is required'),
    typeId: yup.number(),
})


const Transition = React.forwardRef(function Transition(props: TransitionProps & {
    children: React.ReactElement<any, any>;
}, ref: React.Ref<unknown>,) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    open: boolean
    handleClose: () => void
}


const AddUser = React.memo((props: IProps) => {

    const {open, handleClose} = props
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const {schoolId, institutionalCode} = useAddEntities();
    const {data: regions} = useRegionSelectQuery()
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const regionId = useAppSelector((store) => store?.auth?.user?.region?.id);
    const type = useAppSelector((store) => store?.auth?.user?.type?.name);
    const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
    const roles = useAppSelector((store) => store?.auth?.user?.roles)
    const [loading, setLoading] = useState(false);


    const initialValues = {
        schoolId: "",
        userName: '',
        password: '',
        regionId: 0,
        typeId: (roles?.toLowerCase() == "superadmin" || !(typeId == 2 && roles?.toLowerCase() == "user")) ? 2 : 1,
        confirmPassword: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        designation: "",
        roleName: typeId == 2 ? "Admin" : ((typeId == 1 && roles.toLowerCase() == "admin") ? "User" : "User"),
    }
        const resetForm = () => {
            //formik.setFieldValue('schoolId', "")
            formik.setFieldValue('userName', "")
            formik.setFieldValue('password', "")
            formik.setFieldValue('confirmPassword', "")
            formik.setFieldValue('email', "")
            formik.setFieldValue('firstName', "")
            formik.setFieldValue('middleName', "")
            formik.setFieldValue('lastName', "")
            formik.setFieldValue('designation', "")
    }
    const formik = useFormik({
        initialValues, validationSchema,
        onSubmit: async (values, actions) => {
            setLoading(true)
            try {
                if (type?.toLowerCase() == "school") {
                    values.schoolId = schoolId ?? 0
                    values.regionId = regionId ?? 0
                    values.typeId = typeId ?? 0
                } else if (roles?.toLowerCase() == "superadmin") {

                }


                if (formik.values.typeId == 2) {
                    values.regionId = formik.values.regionId
                }


                await postUser(values, {schoolId: schoolId})
                setSnackbarStore({
                    message: "Successfully added user", severity: "success", open: true,
                })
                resetForm()
                handleClose()
                setLoading(false)
            } catch (error: any) {

                setErrorSnackbar(error, setSnackbarStore);

            } finally {
                setLoading(false)
            }


        }
    })

            const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
    const handleSubmit = React.useCallback(() => {

        if(!formik.values.schoolId){
            setOnErrorSelectedSchool(true)
        }
        formik.handleSubmit()
    }, [formik])


    useEffect(() => {
        formik.setFieldValue('schoolId', schoolId)
        if (typeId == 2 && formik.values.typeId == 2 && roles.toLowerCase() == "admin") {
            formik.setFieldValue('roleName', "User")
        }
        if (typeId == 2 && formik.values.typeId == 1 && roles.toLowerCase() == "user") {
            formik.setFieldValue('roleName', "Admin")
        }
        if (typeId == 3 && roles.toLowerCase() == "user") {

            formik.setFieldValue('typeId', "2")
            formik.setFieldValue('roleName', "Admin")
        } else if (typeId && (!(typeId == 2 && roles?.toLowerCase() == "user"))) {
            formik.setFieldValue('typeId', typeId)
        }

        if (typeId && regionId) {
            formik.setFieldValue('regionId', regionId)
        }

    }, [])


    const handleChangeRole = (event: SelectChangeEvent) => {
        formik.setFieldValue('roleName', event?.target?.value)
    };

    function handleOnSelectedSchool(value: SearchSchoolResponseType) {

        formik.setFieldValue('schoolId', value?.id ?? 0)
        setOnErrorSelectedSchool(false)
        if (value?.region?.id) {
            formik.setFieldValue('regionId', value?.region?.id ?? value?.regionId)
        }

    }
    return (<Dialog
            open={open}
            TransitionComponent={Transition}
            // keepMounted
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Add User</DialogTitle>
            <DialogContent>
                <Alert severity='info'>
                    Please fill in the details of the user you want to add
                    <br/>
                    Note: Fields with <strong>*</strong> are required
                </Alert>
                <Box
                    sx={{
                        display: 'flex', flexDirection: 'column', mb: 3, paddingTop: 3
                    }}
                >

                    <div style={{flex: 1, display: "flex", flexDirection: "row-reverse"}}>
                        <div style={{flex: 0.5, display: "flex", alignItems: "center"}}>
                            <div style={{width: "100%",}}>

                                <div style={{flex: 1, display: "flex", flexDirection: "row"}}>
                                    {(roles?.toLowerCase() == "superadmin" || roles?.toLowerCase() == "admin") || ((typeId == 2 || typeId == 3) && roles?.toLowerCase() == "user") ?
                                        <FormControl fullWidth>
                                            <div style={{
                                                marginRight: 6,
                                                flex: roles?.toLowerCase() == "superadmin" ? 0.5 : 1,
                                                display: "flex"
                                            }}>
                                                <InputLabel id="user-select-role">Role</InputLabel>
                                                <Select aria-label={"user-role-select"}
                                                        inputProps={{
                                                            'id': `user-select-role`
                                                    }}
                                                        SelectDisplayProps={{
                                                        // @ts-ignore
                                                        "id": `user-select-role`
                                                    }}
                                                        id="user-select-role"
                                                        labelId="demo-simple-select-label"
                                                        size='small'
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="dense"
                                                        disabled={(typeId == 2 && roles?.toLowerCase() == "user") || (typeId == 1 && roles.toLowerCase() == "admin")}
                                                        value={formik.values.roleName}
                                                        label="Role"
                                                        onChange={handleChangeRole}

                                                >

                                                    {(((!(typeId == 2 && (roles?.toLowerCase() == "user"))) &&
                                                            ((typeId == 1 && (roles?.toLowerCase() == "admin")))) ||
                                                        ((formik.values.typeId == 2) && (typeId == 2) &&
                                                            roles?.toLowerCase() == "admin") || (formik.values.typeId == 3 && (typeId == 3) &&
                                                            roles?.toLowerCase() == "admin") || roles?.toLowerCase() == "superadmin") ?
                                                        <MenuItem value={"User"}>User</MenuItem> : null}
                                                    {((!(typeId == 1 && (roles?.toLowerCase() == "admin")))) &&
                                                    (formik.values.typeId == 2 && typeId == 3 &&
                                                        roles?.toLowerCase() == "user") ||
                                                    (formik.values.typeId == 2 && typeId == 3 &&
                                                        roles?.toLowerCase() == "admin") ||
                                                    (formik.values.typeId == 1 && typeId == 2 &&
                                                        roles?.toLowerCase() == "user") ||
                                                    (formik.values.typeId == 1 && typeId == 2 &&
                                                        roles?.toLowerCase() == "admin") ||
                                                    roles?.toLowerCase() == "superadmin" ?
                                                        <MenuItem value={"Admin"}>Admin</MenuItem> : null
                                                    }


                                                </Select>
                                            </div>
                                            {/* <div style={{ justifyContent: "center", marginRight: 6, flex: 0.5, display: "flex"}} >
                            <FormControlLabel
                                label="Status"
                                control={
                                    <Checkbox
                                        value={checked}
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />

                                }
                            />

                        </div>*/}


                                        </FormControl> : null}


                                </div>

                            </div>
                        </div>


                        {((typeId == 2 || typeId == 3) && roles?.toLowerCase() == "user") || (roles?.toLowerCase() == "superadmin" || roles?.toLowerCase() == "admin") ?
                            <div style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                marginRight: 6,
                                flex: 0.5,
                                display: "flex"
                            }}>
                                <div style={{
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    marginRight: 6,
                                    flex: 1,
                                    alignItems: "center",
                                    display: "flex"
                                }}>
                                    <FormControl
                                        style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <FormLabel id="typeId">Account Type</FormLabel>
                                        <RadioGroup
                                            row
                                            onChange={(event) => {


                                                formik.setFieldValue('typeId', event.target.value)

                                                if (typeId == 3 && event.target.value == "2" && roles?.toLowerCase() == "admin") {
                                                    formik.setFieldValue('roleName', "Admin")
                                                } else if (typeId == 3 && event.target.value == "3" && roles?.toLowerCase() == "admin") {
                                                    formik.setFieldValue('roleName', "User")
                                                } else if (typeId == 2 && event.target.value == "2" && roles?.toLowerCase() == "admin") {
                                                    formik.setFieldValue('roleName', "User")
                                                } else if (typeId == 2 && event.target.value == "1" && roles?.toLowerCase() == "admin") {
                                                    formik.setFieldValue('roleName', "Admin")
                                                } else if (typeId == 3 && event.target.value == "2" && roles?.toLowerCase() == "user") {
                                                    formik.setFieldValue('roleName', "Admin")
                                                }

                                            }
                                            }

                                            value={formik.values.typeId}
                                            aria-labelledby="typeId"

                                            defaultValue={1}
                                            name="radio-buttons-group"
                                            id="radio-buttons-group"
                                        >

                                            {roles?.toLowerCase() == "superadmin" || (typeId == 3 && roles?.toLowerCase() == "admin") ?
                                                <FormControlLabel
                                                    disabled={!((roles?.toLowerCase() == "superadmin") || (typeId == 3 && roles?.toLowerCase() == "admin"))}
                                                    checked={formik.values.typeId == 3} value={3} control={<Radio/>}
                                                    label="National"/> : null}
                                            {!(typeId == 2 && roles?.toLowerCase() == "user") ? <FormControlLabel
                                                disabled={!(roles?.toLowerCase() == "superadmin" || typeId == 2 || typeId == 3)}
                                                checked={formik.values.typeId == 2} value={2} control={<Radio/>}
                                                label="Regional"/> : null}
                                            <FormControlLabel
                                                disabled={!(roles?.toLowerCase() == "superadmin" || typeId == 2 || !(typeId == 3 && (roles?.toLowerCase() == "admin" || roles?.toLowerCase() == "user")))}
                                                checked={formik.values.typeId == 1} value={1} control={<Radio/>}
                                                label="School"/>
                                        </RadioGroup>

                                    </FormControl>
                                </div>
                            </div>


                            : null}


                    </div>



                    {
                        ((formik.values.typeId == 1 || formik.values.roleName == "User") || (formik.values.typeId == 2 || formik.values.roleName == "Admin")) && !(formik.values.typeId == 3) ?
                            <TextField
                                label="Region"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                required
                                select
                                disabled={(typeId == 2 && roles.toLowerCase() == "user") || (typeId == 2 && roles.toLowerCase() == "admin") || typeId == 1 && roles.toLowerCase() == "admin"}
                                name='regionId'
                                id='regionId'
                                value={formik.values.regionId}
                                onChange={formik.handleChange}
                                error={formik.touched.regionId && Boolean(formik.errors.regionId) || !(formik.values.regionId > 0)}
                                helperText={formik.touched.regionId && formik.errors.regionId}
                        >
                            <MenuItem value="" disabled>Select Region</MenuItem>
                            {regions?.map((region) => {

                                return (<MenuItem key={region.id} value={region.value}>
                                        {region.name}
                                    </MenuItem>)
                            })}
                            {regions?.length === 0 && <MenuItem value="" disabled>No Region available</MenuItem>}

                        </TextField> : null}
                    {formik.values.typeId == 1 ? <Box paddingBottom={1}>
                        {(roles?.toLowerCase() == "superadmin" || typeId == 2 || typeId == 3) ? <SearchAsYouType
                            id={"userselect"}
                            regionId={formik.values.regionId}
                            required={true}
                            error={onErrorSelectedSchool}
                            label='School Institutional Name'
                            onSelected={handleOnSelectedSchool}
                        /> : null}
                    </Box> : null

                    }
                    {/*<TextField
                        label="Region"
                        variant="outlined"
                        margin="dense"

                        size='small'
                        fullWidth
                        required
                        select
                        disabled
                        name='regionId'
                        id='regionId'
                        value={regionId}
                        onChange={formik.handleChange}
                        error={formik.touched.regionId && Boolean(formik.errors.regionId)}
                        helperText={formik.touched.regionId && formik.errors.regionId}
                    >
                        <MenuItem value="" disabled>Select Region</MenuItem>
                        {
                            regions?.map((region) => {
                                return (
                                    <MenuItem key={region.id} value={region.value}>
                                        {region.name}
                                    </MenuItem>
                                )
                            })
                        }
                        {
                            regions?.length === 0 && <MenuItem value="" disabled>No Region available</MenuItem>
                        }

                    </TextField>*/}
                    <TextField
                        label="Email"
                        type={"email"}
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth

                        required={true}
                        name='email'
                        id='email'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={(formik.touched.email && formik.errors.email)}

                    />
                    <TextField
                        required={true}
                        label="First Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        name='firstName'
                        id='firstName'
                        value={formik.values?.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched?.firstName && Boolean(formik.errors?.firstName)}
                        helperText={formik.touched?.firstName && formik.errors?.firstName}

                    />

                    <TextField
                        label="Middle Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        name='middleName'
                        id='middleName'
                        value={formik.values?.middleName}
                        onChange={formik.handleChange}
                        error={formik.touched?.middleName && Boolean(formik.errors?.middleName)}
                        helperText={formik.touched?.middleName && formik.errors?.middleName}

                    />
                    <TextField
                        required={true}
                        label="Last Name"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        name='lastName'
                        id='lastName'
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched?.lastName && Boolean(formik.errors?.lastName)}
                        helperText={formik.touched?.lastName && formik.errors?.lastName}

                    />

                    <TextField
                        label="Designation"
                        variant="outlined"
                        margin="dense"
                        size='small'
                        fullWidth
                        required
                        name='designation'
                        id='designation'
                        value={formik.values?.designation}
                        onChange={formik.handleChange}
                        error={formik.touched?.designation && Boolean(formik.errors?.designation)}
                        helperText={formik.touched?.designation && formik.errors?.designation}

                    />


                </Box>
            </DialogContent>
        <DialogActions>
            <Button id={"cancelUser"} onClick={handleClose}>Cancel</Button>
            <LoadingButton loading={loading} id={"addUser"} variant='contained' onClick={handleSubmit}><Box>
                {"Add"}
            </Box></LoadingButton>
        </DialogActions>
    </Dialog>)
})

export default AddUser
