import * as Yup from "yup";
import {enrollmentSchema, IEnrollmentSchema} from "./enrollment.schema";

export interface IStudentSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  studentNumber?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  extName?: string | null;
  sex?: string | null;
  nationality?: string | null;
  isGraduated?: boolean;
  enrollments: IEnrollmentSchema[];
}

export const studentSchema: Yup.SchemaOf<IStudentSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  studentNumber: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
  firstName: Yup.string().nullable(),
  middleName: Yup.string().nullable(),
  extName: Yup.string().nullable(),
  sex: Yup.string().nullable(),
  nationality: Yup.string().nullable(),
  isGraduated: Yup.boolean().optional(),
  enrollments: Yup.array(Yup.lazy(() => enrollmentSchema)),
});

export type StudentType = Yup.InferType<typeof studentSchema>;

export interface IStudentEvaluationSchema {
  message: string;
  succeeded: boolean;
  data: {
      curriculumName: any,
      school: any,
      isGraduated: any,
      curriculum: string;
      subjects: {
          id: number;
          curriculumId: number;
          curriculum: {
              name: string;
              program: string;
          };
          courseSemester: string;
          course: object;
      grades?: string | null;
      remarks?: string | null;
      yearEnd: string;
      yearStart: string;
      courseName: string;
      courseCode: string;
      courseDescription: string;
        coursePrerequisiteCourseCodes: string;
      courseYearLevel: string;
      courseUnit?: number | null;
    }[];
    program: object;
    programName: string;
    semCode: string;
    semDescription: string;
  }[][];
}

export const studentEvaluationSchema: Yup.SchemaOf<IStudentEvaluationSchema> =
  Yup.object().shape({
    message: Yup.string().required("Message is required"),
    succeeded: Yup.boolean().required("Succeeded is required"),
    data: Yup.array().of(
      Yup.array().of(
        Yup.object().shape({
            curriculumName: Yup.string(),
            school: Yup.string(),
            isGraduated: Yup.boolean().nullable(),
            curriculum: Yup.string().required("Curriculum is required"),
            subjects: Yup.array().of(
                Yup.object().shape({
                    id: Yup.number().required("Id is required"),
                    curriculumId: Yup.number().required("CurriculumId is required"),
                    curriculum: Yup.object().shape({
                        name: Yup.string().required("Name is required"),
                        program: Yup.string().required("Program is required"),
                    }),
                    coursePrerequisiteCourseCodes: Yup.string().required("Prerequisite Course Codes  is required"),
              courseSemester: Yup.string().required("SemCode is required"),
              course: Yup.object().required("Course is required"),
              grades: Yup.string().required("Grades is required"),
              remarks: Yup.string().required("Remarks is required"),
              yearEnd: Yup.string().required("YearEnd is required"),
              yearStart: Yup.string().required("YearStart is required"),
              courseName: Yup.string().required("CourseName is required"),
              courseCode: Yup.string().required("CourseCode is required"),
              courseDescription: Yup.string().required(
                "CourseDescription is required"
              ),
              courseYearLevel: Yup.string().required(
                "CourseYearLevel is required"
              ),
              courseUnit: Yup.number().nullable(),
            })
          ),
          program: Yup.object().required("Program is required"),
          programName: Yup.string().required("ProgramName is required"),
          semCode: Yup.string().required("SemCode is required"),
          semDescription: Yup.string().required("SemDescription is required"),
        })
      )
    ),
  });

export type     StudentEvaluationType = Yup.InferType<
    typeof studentEvaluationSchema
>;
