import * as React from "react"

const PendingIcon = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={19}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.167 10A4.168 4.168 0 0 0 7 14.167c0 2.3 1.867 4.166 4.167 4.166s4.166-1.866 4.166-4.166c0-2.3-1.866-4.167-4.166-4.167Zm1.375 6.125-1.792-1.792v-2.666h.833v2.325l1.542 1.541-.583.592ZM12 2.5H9.35A2.509 2.509 0 0 0 7 .833c-1.083 0-2 .7-2.35 1.667H2c-.917 0-1.667.75-1.667 1.667v12.5c0 .916.75 1.666 1.667 1.666h5.092a5.619 5.619 0 0 1-1.184-1.666H2v-12.5h1.667v2.5h6.666v-2.5H12V8.4a5.86 5.86 0 0 1 1.667.5V4.167c0-.917-.75-1.667-1.667-1.667ZM7 4.167a.836.836 0 0 1-.833-.834c0-.458.375-.833.833-.833.458 0 .833.375.833.833A.836.836 0 0 1 7 4.167Z"
            fill="#FF4970"
        />
    </svg>
)

export default PendingIcon
