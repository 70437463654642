//create slice for curriculum

import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ICurriculumSlice {
    isCurriculum: boolean;
    curriculumCount: number | undefined;
    editCurriculumsId?: number | undefined;
    editAcademicId?: number | undefined;
    editCurriculimSchoolId?: number | undefined;
    editCurriculimSchoolName?: string | undefined;
    coloredRows?: number | string;
}

const initialState: ICurriculumSlice = {
    isCurriculum: false,
    editAcademicId: 0,
    coloredRows: -1,
    curriculumCount: 0,
    editCurriculumsId: 0,
    editCurriculimSchoolId: 0,
    editCurriculimSchoolName: ""
};

const curriculumSlice = createSlice({
    name: "curriculum",
    initialState,
    reducers: {

        setEditCurriculum: (
            state,
            action: PayloadAction<number>
        ) => {
            state.editCurriculumsId = action.payload;
            return state;
        },
        setColoredRows: (
            state,
            action: PayloadAction<number | string>
        ) => {
            state.coloredRows = action.payload;
            return state;
        },
        editCurriculimSchoolId: (
            state,
            action: PayloadAction<number>
        ) => {
            state.editCurriculimSchoolId = action.payload;
            return state;
        },
        editCurriculimSchoolName: (
            state,
            action: PayloadAction<string>
        ) => {
            state.editCurriculimSchoolName = action.payload;
            return state;
        },
        setCurriculumCount: (
            state,
            action: PayloadAction<number | undefined>
        ) => {
            state.curriculumCount = action.payload;
            return state;
        },

    },
});

export const {setColoredRows, editCurriculimSchoolId, editCurriculimSchoolName, setEditCurriculum, setCurriculumCount} =
    curriculumSlice.actions;
export default curriculumSlice.reducer;
