import * as React from "react";
import {useCallback, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import {Alert, Box, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {postCurriculum} from "../../requests/curriculum.request";
import SearchTextField from "../SearchTextField/SearchProgramTextField";
import {SearchProgramResponseType, SearchSchoolResponseType} from "../../types";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setProgram, setProgramBreadcrumb, setProgramId, setProgramTemp,} from "../../redux/features/school";
import {RootState, useAppSelector} from "../../redux/store";
import {useMutation, useQueryClient} from "react-query";
import {CurriculumInputType} from "../../schemas/curriculum.schema";
import {SaveRounded} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import SearchAsYouType from "../SearchAsYouType";
import {setColoredRows} from "../../redux/features/curriculum";

const validationSchema = yup.object({
  programId: yup
    .string()
    .required("Program ID is required")
    .min(1, "Program ID must be at least 1 characters"),
  name: yup.string().required("Curriculum Name is required"),
});

const initialValues = {
  schoolId: "",
  programId: "",
  programName: "",
  name: "",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  handleClose: () => void;
  setting?:boolean;
}

const AddCurriculum = (props: IProps) => {
  const { open, handleClose, setting } = props;
  const dispatch = useDispatch();
  const [school, setSchool] = useState<any>()
  const [majorName, setMajorName] = useState<any>()
  const tempProgram = useAppSelector((store) => store?.school?.tempProgram);
  const programName = useAppSelector((store) => store?.auth?.user);
  const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
  const roles = useAppSelector((store) => store?.auth?.user?.roles);

  const queryClient = useQueryClient();

  const { mutate: addCurriculum, isLoading } = useMutation({
    mutationKey: "addCurriculum",
    mutationFn: async (value: CurriculumInputType) =>
      await postCurriculum(value),
  });

  const { schoolId, regionId } = useAddEntities();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);


  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: async (values, actions) => {
      if(!setting){
        values.schoolId = schoolId;
      }else if(setting){
        values.schoolId = schoolId ? schoolId : school?.id
      }
      void addCurriculum(values as unknown as CurriculumInputType, {
        onSuccess: (res) => {
          dispatch(setColoredRows(res?.data?.id))
          if (res.message) {
            var __message = res?.data?.name + " already exists in our system. Please try a different one."
            setSnackbarStore({
              message: res.message,
              severity: __message == res.message ? "error" : "success",
              open: true,
            })
          } else {
            setSnackbarStore({
              message: "Successfully added curriculum",
              severity: "success",
              open: true,
            });
          }
          queryClient.setQueriesData(['curriculums'], (oldData: any) => {
            return {
              ...oldData,
              data: [...oldData?.data, {
                id: res?.data?.id,
                curriculumName: values.name,
                programName: values.programName,
                programId: values.programId,
                timeout: 3,
              }]
            }
          })


          if (programName != null) {
            dispatch(setProgramId(values?.programId));
            dispatch(setProgramBreadcrumb(values?.programName));
            dispatch(setProgram(tempProgram));
          }

          //  dispatch(setCurriculumTableTabOpenCount())
          actions.resetForm();
          /*   dispatch(setTabValue(2))
                    dispatch(setCourseTabState(true))*/

          queryClient.invalidateQueries("curriculums");
          handleClose();
        },
        onError: () => {
          setSnackbarStore({
            message: "Failed to add curriculum",
            severity: "error",
            open: true,
          });
        },
      });
    },
  });

  const handleOnSelected = (value: SearchProgramResponseType) => {
    formik.setFieldValue("programId", value?.id ?? "", true);
    formik.setFieldValue("programName", value?.name ?? "", true);

    setMajorName(value?.major?.name)
    dispatch(setProgramTemp(value));
  };


  const schoolSelectorById = useAppSelector(
      (store) => store?.school.schoolSelectorById
  );


  const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
  const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
    setSchool(value)
    formik.setFieldValue('schoolId', value?.id ?? "")
  }, [formik])
  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  const authStore = useSelector((store: RootState) => store.auth);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Add Curriculum <p style={{margin: 0,fontSize: 12, fontStyle: "italic"}}>({authStore?.user?.school?.name ?? ""})</p></DialogTitle>
      <DialogContent>
        <Alert severity="info">
          Please fill in the details of the curriculum you want to add
          <br />
          Note: Fields with <strong>*</strong> are required
        </Alert>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: 3,
          }}
        >
          { <Box paddingBottom={1}>
            {{/*!(typeId == 1 && roles.toLowerCase() == "admin") */}? <SearchAsYouType
                id={"curriculumselect"}
                value={schoolSelectorById}
                disabled={schoolSelectorById}
                required={(typeId == 1)}
                error={onErrorSelectedSchool}
                label='School Institutional Name'
                onSelected={handleOnSelectedSchool}
            /> : null }
          </Box>
          }

          <SearchTextField
              status={"approved"}
            schoolId={schoolId}
            label="Program"
            onSelected={handleOnSelected}
          />
          {
            majorName ?  <Typography  sx={{ fontWeight: 'bold' }}  variant="caption" color="default">
              {majorName}
            </Typography> : null
          }

          <Typography variant="caption" color="error">
            {formik?.touched?.programId && Boolean(formik?.errors?.programId)}
          </Typography>

          <TextField
              label="Curriculum Name"
              variant="outlined"
              margin="dense"
              size="small"
              fullWidth
              required
              id="curriculumname"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                  formik?.touched?.name && Boolean(formik?.errors?.name)
              }
              helperText="Please enter curriculum name with the correct format (e.g. CEA-BSIT-2023-2024-1)"
          />
        </Box>

      </DialogContent>

      <DialogActions>
        <Button   id={"cancelCurriculum"} onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={isLoading}
          disabled={
            formik.values.name.length < 1 || formik.values.programId.length < 1
          }
          id={"loadingAdding"}
          loadingPosition="start"
          variant="contained"
          startIcon={<SaveRounded />}
          onClick={handleSubmit}
        >
          {isLoading ? "Adding" : "Add"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddCurriculum;
