
import { useQuery } from 'react-query';
import {getLevelByName} from "../requests/level.request";


export const useSearchLevelQuery = (str: string) => {
    return useQuery(['level', str], ({ signal }) => getLevelByName(str, signal), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}
