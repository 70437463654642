import {AccountCircleRounded, LogoutRounded} from '@mui/icons-material';
import {Divider, ListItemIcon, Menu, MenuItem} from '@mui/material';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../../redux/store';
import {setLogoutDialog} from "../../../redux/features/app";
import {useQueryClient} from "react-query";

interface DefaultMenuProps {
	isMenuOpen: boolean;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const DefaultMenu = ({ isMenuOpen, handleMenuClose, anchorEl }: DefaultMenuProps) => {

	const dispatch = useDispatch();
	const authStore = useSelector((store: RootState) => store.auth)
	const history = useHistory();
	const queryClient = useQueryClient();
	const handleSignOutClick = useCallback(() => {

		dispatch(setLogoutDialog({
			open: true,
		}))
		handleMenuClose()

	}, []);




	const pushTo = useCallback((path: string) => {
		history.push(path);
	}, [history]);

	return (

		<Menu anchorEl={anchorEl} id='primary-search-account-menu' keepMounted open={isMenuOpen} onClose={handleMenuClose}>
			<MenuItem onClick={() => pushTo('/account-settings')}>
				<ListItemIcon>
					<AccountCircleRounded />
				</ListItemIcon>
				<div style={{justifyContent: "center", alignItems: "center", display: "flex", flex: 1, flexDirection: "column" }}>
					<div>
						<strong>{authStore.user?.userName}</strong>
					</div>
					<div style={{fontStyle: 'italic', fontSize: 12}}>
						{authStore?.user?.type?.name?.toLowerCase() == "ched" ? `(REGIONAL ${authStore?.user?.roles ? " | " + authStore?.user?.roles?.toUpperCase() : ""})` : authStore?.user?.type?.name?.toLowerCase() == "national" ? `(CHED NATIONAL ${authStore?.user?.roles ? " | " + authStore?.user?.roles?.toUpperCase() : ""})` : `(SCHOOL${authStore?.user?.roles ? " | " + authStore?.user?.roles?.toUpperCase() : ""})`}
					</div>
				</div>

			</MenuItem>
			{/* <MenuItem onClick={handleMenuClose}>
				<ListItemIcon>
					<Settings />
				</ListItemIcon>
				Setting
			</MenuItem> */}
			<Divider />
			<MenuItem
				onClick={handleSignOutClick}
				sx={{
					color: 'error.main'
				}}
			>
				<ListItemIcon >
					<LogoutRounded color='error' />
				</ListItemIcon>
				Sign Out
			</MenuItem>
		</Menu>
	)
};
