//create slice for auth

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoginResponseType} from "../../schemas/auth.schema";

interface IAuthSlice extends LoginResponseType {
  isAuth: boolean;
  editUsersId?: string | undefined;
  editAcademicId?: number | undefined;
}

const initialState: IAuthSlice = {
  isAuth: false,
  editAcademicId: 0,
  editUsersId: "",
  user: {} as LoginResponseType["user"],
  message: "",
  succeeded: false,
  error: null,
  token: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onLogin: (state, action: PayloadAction<IAuthSlice>) => {
      state = action.payload;
      return state;
    },
    onLogout: (state) => {

      state = initialState;
      return state;
    },
    setEditUser: (
        state,
        action: PayloadAction<string>
    ) => {
      state.editUsersId = action.payload;
      return state;
    },
    setEditAcademic: (
        state,
        action: PayloadAction<any>
    ) => {
      state.editAcademicId = action.payload;
      return state;
    },
    getAuth: (state) => {
      return state;
    },
    setUserProfile: (
      state,
      action: PayloadAction<IAuthSlice["user"]["userProfile"]>
    ) => {
      state.user.userProfile = action.payload;
      return state;
    },
    setPatchSchool: (state, action) => {
      var stateSchool = {...state.user?.school};


      if (action?.payload?.institutionalCode) {
        state.user.school.institutionalCode = action.payload.institutionalCode;
      }

      if (action?.payload?.institutionalTypeForOT) {
        state.user.school.institutionalTypeForOT = action.payload.institutionalTypeForOT;
      }

      if (action?.payload?.province?.name) {


        state.user.school.province.name = action.payload.province.name;
      }
      if (action?.payload?.province?.id) {
        state.user.school.province.name = action.payload.province.id;
      }

      if (action?.payload?.street) {
        state.user.school.street = action.payload.street;
      }
        if (action?.payload?.institutionalFormOfOwnership) {
            state.user.school.institutionalFormOfOwnership = action.payload.institutionalFormOfOwnership;
        }
        if (action?.payload?.institutionalTypeId) {
            state.user.school.institutionalTypeId = action.payload.institutionalTypeId;
        }
        if (action?.payload?.municipalityCity) {
        state.user.school.municipalityCity = action.payload.municipalityCity;
      }

      if (action?.payload?.fax) {
        state.user.school.fax = action.payload.fax;
      }

      if (action?.payload?.zipCode) {
        state.user.school.zipCode = action.payload.zipCode;
      }

      if (action?.payload?.institutionalHeadTelephone) {
        state.user.school.institutionalHeadTelephone = action.payload.institutionalHeadTelephone;
      }

      if (action?.payload?.yearEstablished) {
        state.user.school.yearEstablished = action.payload.yearEstablished;
      }
      if (action?.payload?.approvedBy) {
        state.user.school.approvedBy = action.payload.approvedBy;
      }
      if (action?.payload?.approvedDate) {
        state.user.school.approvedDate = action.payload.approvedDate;
      }
      if (action?.payload?.secRegistration) {
        state.user.school.secRegistration = action.payload.secRegistration;
      }

      if (action?.payload?.yearConvertedtoCollegeStatus) {
        state.user.school.yearConvertedtoCollegeStatus = action.payload.yearConvertedtoCollegeStatus;
      }


      if (action?.payload?.yearConvertedtoUniversityStatus) {
        state.user.school.yearConvertedtoUniversityStatus = action.payload.yearConvertedtoUniversityStatus;
      }
      if (action?.payload?.nameofInstitutionalHead) {
        state.user.school.nameofInstitutionalHead = action.payload.nameofInstitutionalHead;
      }
      if (action?.payload?.titleofHeadofInstitution) {
        state.user.school.titleofHeadofInstitution = action.payload.titleofHeadofInstitution;
      }

      if (action?.payload?.longitude) {
        state.user.school.longitude = action.payload.longitude;
      }

      if (action?.payload?.latitude) {
        state.user.school.latitude = action.payload.latitude;
      }

      if (action?.payload?.highestEducationalAttainmentoftheHead) {
        state.user.school.highestEducationalAttainmentoftheHead = action.payload.highestEducationalAttainmentoftheHead;
      }
      return state;
    },
    setPatchUser: (state, action) => {
      if (action.payload?.email) {
        state.user!.email = action.payload.email;
      }
      if (action.payload?.userName) {
        state.user!.userName = action.payload.userName;
      }
      if (action?.payload?.userProfile?.firstName) {
        state.user!.userProfile.firstName =
          action.payload.userProfile.firstName;
      }
      if (action?.payload?.userProfile?.middleName) {
        state.user!.userProfile.middleName =
          action.payload.userProfile.middleName;
      }
      if (action?.payload?.userProfile?.lastName) {
        state.user!.userProfile.lastName = action.payload.userProfile.lastName;
      }
      if (action?.payload?.userProfile?.designation) {
        state.user!.userProfile.designation =
          action.payload.userProfile.designation;
      }

      return state;
    },
  },
});

export const { setEditAcademic, setEditUser, setPatchSchool, setPatchUser, onLogin, onLogout, getAuth, setUserProfile } =
  authSlice.actions;
export default authSlice.reducer;
