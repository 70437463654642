import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Grid, Tab, Tabs, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ISnackbarSlice, openSnackbar } from '../../redux/features/snackbar';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import type { SchoolType } from '../../types';
import {setErrorSnackbar} from "../../helper/utils/helper";

const validationSchema = yup.object({
    schoolId: yup.string().required('School ID is required'),
    schoolName: yup.string().required('School Name is required'),
    schoolAddress: yup.string().required('School Address is required'),
    userName: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
    confirmPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IUseSchoolDetailDialog {
    onClose: () => void;
}
interface ISchoolDetail {
    school?: SchoolType

}
export default function useSchoolDetailDialog({ onClose }: IUseSchoolDetailDialog) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const Component: React.FC<ISchoolDetail> = (props) => {
        const { school } = props
        const history = useHistory()
        const dispatch = useDispatch()
        const [tabValue, setTabValue] = React.useState(0);

        const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
            dispatch(openSnackbar(payload))
        }, [])

        const formik = useFormik({
            initialValues: {
                schoolId: school?.institutionalCode ?? "",
                schoolName: school?.name ?? "",
                schoolAddress: school?.address ?? "",
                userName: '',
                password: '',
                confirmPassword: '',
            },
            validationSchema,
            onSubmit: async (values, actions) => {
                try {
                    setSnackbarStore({
                        message: "Successfully added school",
                        severity: "success",
                        open: true,
                    })
                    actions.resetForm()
                    history.replace('/login')
                } catch (error: any) {
                    console.error({ error })
                    setErrorSnackbar(error, setSnackbarStore)
                }
            }
        })

        const handleSubmit = React.useCallback(() => {
            formik.handleSubmit()
        }, [formik])

        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>School Institutional Profile</DialogTitle>
                <DialogContent>

                    <Tabs value={tabValue}
                        onChange={(e, newValue) => setTabValue(newValue)}
                        sx={{ mb: 2 }}
                    >
                        <Tab label="Basic Information"  {...a11yProps(0)} />
                        <Tab label="Security"  {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 3,
                            }}
                        >
                            <TextField
                                label="School ID"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                name='schoolId'
                                value={formik.values.schoolId}
                                onChange={formik.handleChange}
                                error={formik.touched.schoolId && Boolean(formik.errors.schoolId)}
                                helperText={formik.touched.schoolId && formik.errors.schoolId}
                            />

                            <TextField
                                label="School Name"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                name='schoolName'
                                value={formik.values.schoolName}
                                onChange={formik.handleChange}
                                error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
                                helperText={formik.touched.schoolName && formik.errors.schoolName}
                            />
                            <TextField
                                label="School Address"
                                variant="outlined"
                                margin="dense"
                                size='small'
                                fullWidth
                                name='schoolAddress'
                                value={formik.values.schoolAddress}
                                onChange={formik.handleChange}
                                error={formik.touched.schoolAddress && Boolean(formik.errors.schoolAddress)}
                                helperText={formik.touched.schoolAddress && formik.errors.schoolAddress}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 3,
                            }}
                        >
                            <Grid container >
                                <TextField
                                    label="Username"
                                    variant="filled"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    name='userName'
                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.userName && Boolean(formik.errors.userName)}
                                    helperText={formik.touched.userName && formik.errors.userName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    name='password'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />

                                <TextField
                                    label="Confirm Password"
                                    type="password"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    name='confirmPassword'
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                />
                            </Grid>

                        </Box>
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Button id={"schoolDetail"} onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog >
        )
    }
    return { Component, handleClickOpen, handleClose }
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
