import { Box, Drawer as MuiDrawer, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Routes } from './Routes';

import { DRAWER_WIDTH } from '../../helper/utils/constants';
import { navClosedMixin, navOpenedMixin } from '../../styles/mixins';

interface NavigationProps {
	open: boolean | undefined;
	handleClose: () => void;
}

export const Navigation = ({ open, handleClose }: NavigationProps) => {
	const mobile = useMediaQuery('(min-width:800px)');
	const Tag = mobile ? StyledDrawer : MuiDrawer;
	return (
		<Tag variant={mobile ? 'permanent' : "temporary"} open={open} anchor="left" onClose={handleClose}>
			<DrawerHeader />
			<Routes />
		</Tag >
	);
};

const DrawerHeader = styled('div')(({ theme }) => ({
	...theme.mixins.toolbar,
}));

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => {
	return ({
		width: DRAWER_WIDTH,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...navOpenedMixin(theme),
			'& .MuiDrawer-paper': navOpenedMixin(theme),
		}),
		...(!open && {
			...navClosedMixin(theme),
			'& .MuiDrawer-paper': navClosedMixin(theme),
		}),
	})
});
