import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {Alert, Autocomplete, Box, Grid, TextField} from '@mui/material';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {ISnackbarSlice, openSnackbar} from '../../redux/features/snackbar';
import {useQueryClient} from 'react-query';
import {provinces, setErrorSnackbar} from "../../helper/utils/helper";
import {updateSchoolIntitutionalProfile} from "../../requests/school.request";
import {useAppSelector} from "../../redux/store";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setPatchSchool} from "../../redux/features/auth";
import {LoadingButton} from "@mui/lab";
import useDialog from "../../hooks/useDialog";
import AddConfirmationDialog from "./AddConfirmation.dialog";
import moment from "moment-timezone";

const validationSchema = yup.object({
    street: yup.string().required("Street is required"),
    email: yup.string().nullable().email().required("Email is required"),
    institutionalFormOfOwnership: yup.string().required("Institutional Form Of Ownership is required"),
    institutionalTypeForOT: yup.string().nullable(),
    municipalityCity: yup.string().required("Municipality City is required"),
    fax: yup.string().nullable(),
    heiTypeId: yup.number().nullable(),
    zipCode: yup.string().required("ZipCode is required"),
    institutionalHeadTelephone: yup.string().required("Institutional Head Telephone is required"),
    yearEstablished: yup.string().required("Year Established is required"),
    secRegistration: yup.string().required("Sec Registration is required"),
    yearConvertedtoCollegeStatus: yup.string().nullable(),
    yearConvertedtoUniversityStatus: yup.string().nullable(),
    nameofInstitutionalHead: yup.string().nullable(),
    titleofHeadofInstitution: yup.string().nullable(),
    longitude: yup.string().required("Longitude is required"),
    latitude: yup.string().required("latitude is required"),
    highestEducationalAttainmentoftheHead: yup.string().nullable(),
    provinceId: yup.string().nullable(),
    provinceName: yup.string().nullable().notRequired(),
    institutionalCode: yup.string().required("Institutional Code is required")
})
const hietypes = [
    { id: 1, name: 'PN' },
    { id: 2, name: 'PS' },
    { id: 3, name: 'SUC' },
    { id: 4, name: 'LUC' },
];

const initialValues = {
    institutionalCode: "",
    street: "",
    institutionalFormOfOwnership: "",
    institutionalTypeId: "",
    municipalityCity: "",
    fax: "",
    institutionalTypeForOT: "",
    zipCode: "",
    institutionalHeadTelephone: "",
    yearEstablished: "",
    secRegistration: "",
    yearConvertedtoCollegeStatus: "",
    yearConvertedtoUniversityStatus: "",
    nameofInstitutionalHead: "",
    titleofHeadofInstitution: "",
    longitude: "",
    latitude: "", approvedBy: "", approvedDate: "",
    email: "",
    heiTypeId: 0,
    schoolName: "",
    highestEducationalAttainmentoftheHead: "",
    provinceId: "",
    provinceName: ""
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface IProps {
    open: boolean
    currentSchool?: any,
    handleClose: () => void
}

const UpdateSchool = React.memo((props: IProps) => {
    const { open, handleClose , currentSchool} = props
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const {schoolId, regionId} = useAddEntities();
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])
    const type = useAppSelector((store) => store?.auth?.user?.type?.name);
    const roles = useAppSelector((store) => store?.auth?.user?.roles);
    const _school = useAppSelector((store) => store?.auth?.user?.school);
    const [loading, setLoading] = useState(false)
    const {
        handleOpen: handleOpenEditInstitutionalProfile,
        handleClose: handleCloseEditInstitutionalProfile,
        open: openInstitutionalProfile,
    } = useDialog();
    var school = currentSchool ?? _school
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, actions) => {
            try {
                setLoading(true)
                const res = await updateSchoolIntitutionalProfile(values, schoolId)
                if(res.message == "Request successful! Your changes have been saved.."){
                    setSnackbarStore({
                        message: "Successfully updated institutional profile",
                        severity: "success",
                        open: true,
                    })
                    handleCloseEditInstitutionalProfile()
                }
                else if(!res.succeeded){
                    setSnackbarStore({
                        message: res.message,
                        severity: "error",
                        open: true,
                    })
                    handleCloseEditInstitutionalProfile()
                }else{
                    setSnackbarStore({
                        message: "Successfully updated institutional profile",
                        severity: "success",
                        open: true,
                    })
                    handleCloseEditInstitutionalProfile()
                }


                if (!(type?.toLowerCase() == "ched" || type?.toLowerCase() == "national" || roles?.toLowerCase() == "superadmin")) {
                    dispatch(setPatchSchool(values));
                }
                queryClient.setQueriesData('schoolId', (oldData: any) => {
                    return {
                        data: {
                            ...oldData.data,
                            ...res?.data,
                            province: {
                                ...oldData.province,
                                name: res?.data?.provinceName,
                                id: res?.data?.provinceId
                            },
                            institutionalCode: res?.data?.institutionalCode
                        }
                    }
                })

                setLoading(false)
                handleClose()
            } catch (error: any) {
                handleCloseEditInstitutionalProfile()
                setLoading(false)
                setErrorSnackbar(error, setSnackbarStore)
            }
        }
    })
    const handleSubmit = React.useCallback(() => {
        formik.handleSubmit()
    }, [formik])
    function resetForm() {
        if (school?.provinceId && school?.province?.name) {
            const _provinces = provinces?.findIndex((c: any) => c.name == school?.province?.name)
            if (_provinces > -1) {
                setSelectedProvinceId({
                    id: school?.provinceId,
                    name: school?.province?.name
                });
            }
        }



        formik.setFieldValue("provinceId", school?.provinceId ?? "");
        formik.setFieldValue("institutionalTypeForOT", school?.institutionalTypeForOT ?? "");
        formik.setFieldValue("institutionalCode", school?.institutionalCode ?? "");
        formik.setFieldValue("street", school?.street ?? "");
        formik.setFieldValue("institutionalFormOfOwnership", school?.institutionalFormOfOwnership ?? "");
        formik.setFieldValue("institutionalTypeId", school?.institutionalTypeId ?? school?.heiTypeId ?? "");
        formik.setFieldValue("municipalityCity", school?.municipalityCity ?? "");
        formik.setFieldValue("fax", school?.fax ?? "");
        formik.setFieldValue("zipCode", school?.zipCode ?? "");
        formik.setFieldValue("institutionalHeadTelephone", school?.institutionalHeadTelephone ?? "");
        formik.setFieldValue("yearEstablished", school?.yearEstablished ?? "");
        formik.setFieldValue("secRegistration", school?.secRegistration ?? "");
        formik.setFieldValue("yearConvertedtoCollegeStatus", school?.yearConvertedtoCollegeStatus ?? "");
        formik.setFieldValue("yearConvertedtoUniversityStatus", school?.yearConvertedtoUniversityStatus ?? "");
        formik.setFieldValue("nameofInstitutionalHead", school?.nameofInstitutionalHead ?? "");
        formik.setFieldValue("titleofHeadofInstitution", school?.titleofHeadofInstitution ?? "");
        formik.setFieldValue("longitude", school?.longitude ?? "");
        formik.setFieldValue("latitude", school?.latitude ?? "");
        formik.setFieldValue("email", school?.email ?? "");
        formik.setFieldValue("approvedBy", school?.approvedBy ?? "");
        formik.setFieldValue("approvedDate", school?.approvedDate ?? "");
        formik.setFieldValue("highestEducationalAttainmentoftheHead", school?.highestEducationalAttainmentoftheHead ?? "");
    }


    useEffect(() => {
        resetForm()
    }, [currentSchool])
    const [selectedProvinceId, setSelectedProvinceId] = React.useState<any>({
        id: 0,
    });

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Edit Institutional Profile</DialogTitle>
                <DialogContent>
                    <Alert severity='info'>
                        Please fill in the details of the school you want to add
                        <br/>
                        Note: Fields with <strong>*</strong> are required
                    </Alert>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 3,
                        }}
                    >


                        <Grid container gap={2}>
                            <Grid item flex={1}>

                                <TextField
                                    label="Institutional Form of Ownership"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='institutionalFormOfOwnership'
                                    name='institutionalFormOfOwnership'
                                    value={formik.values?.institutionalFormOfOwnership}
                                    onChange={formik.handleChange}
                                    error={formik.touched?.institutionalFormOfOwnership && Boolean(formik.errors?.institutionalFormOfOwnership)}
                                    helperText={formik.touched?.institutionalFormOfOwnership && formik.errors?.institutionalFormOfOwnership}
                                />
                            </Grid>


                            <Grid item flex={1}>
                                <TextField
                                    label="Institutional Code"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='institutionalCode'
                                    name='institutionalCode'
                                    value={formik.values?.institutionalCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched?.institutionalCode && Boolean(formik.errors?.institutionalCode)}
                                    helperText={formik.touched?.institutionalCode && formik.errors?.institutionalCode}
                                />


                            </Grid>
                        </Grid>


                        <Grid container gap={2}>
                            <Grid item flex={1}>

                                <TextField
                                    label="Street"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='street'
                                    name='street'
                                    value={formik.values.street}
                                    onChange={formik.handleChange}
                                    error={formik.touched.street && Boolean(formik.errors.street)}
                                    helperText={formik.touched.street && formik.errors.street}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='email'
                                    name='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />

                            </Grid>
                        </Grid>
                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="Fax"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    //required
                                    id='fax'
                                    name='fax'
                                    value={formik.values.fax}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fax && Boolean(formik.errors.fax)}
                                    helperText={formik.touched.fax && formik.errors.fax}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Municipality City"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='municipalityCity'
                                    name='municipalityCity'
                                    value={formik.values.municipalityCity}
                                    onChange={formik.handleChange}
                                    error={formik.touched.municipalityCity && Boolean(formik.errors.municipalityCity)}
                                    helperText={formik.touched.municipalityCity && formik.errors.municipalityCity}
                                />

                            </Grid>
                        </Grid>
                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="Institutional Head Telephone"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='institutionalHeadTelephone'
                                    name='institutionalHeadTelephone'
                                    value={formik.values.institutionalHeadTelephone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.institutionalHeadTelephone && Boolean(formik.errors.institutionalHeadTelephone)}
                                    helperText={formik.touched.institutionalHeadTelephone && formik.errors.institutionalHeadTelephone}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="ZipCode"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='zipCode'
                                    name='zipCode'
                                    value={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                                />

                            </Grid>
                        </Grid>

                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="Year Converted to College Status"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    //required
                                    id='yearConvertedtoCollegeStatus'
                                    name='yearConvertedtoCollegeStatus'
                                    value={formik.values.yearConvertedtoCollegeStatus}
                                    onChange={formik.handleChange}
                                    error={formik.touched.yearConvertedtoCollegeStatus && Boolean(formik.errors.yearConvertedtoCollegeStatus)}
                                    helperText={formik.touched.yearConvertedtoCollegeStatus && formik.errors.yearConvertedtoCollegeStatus}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Year Established"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='yearEstablished'
                                    name='yearEstablished'
                                    value={formik.values.yearEstablished}
                                    onChange={formik.handleChange}
                                    error={formik.touched.yearEstablished && Boolean(formik.errors.yearEstablished)}
                                    helperText={formik.touched.yearEstablished && formik.errors.yearEstablished}
                                />

                            </Grid>

                        </Grid>
                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="Name of Institutional Head"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='nameofInstitutionalHead'
                                    name='nameofInstitutionalHead'
                                    value={formik.values.nameofInstitutionalHead}
                                    onChange={formik.handleChange}
                                    error={formik.touched.nameofInstitutionalHead && Boolean(formik.errors.nameofInstitutionalHead)}
                                    helperText={formik.touched.nameofInstitutionalHead && formik.errors.nameofInstitutionalHead}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Year Converted to University Status"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    // required
                                    id='yearConvertedtoUniversityStatus'
                                    name='yearConvertedtoUniversityStatus'
                                    value={formik.values.yearConvertedtoUniversityStatus}
                                    onChange={formik.handleChange}
                                    error={formik.touched.yearConvertedtoUniversityStatus && Boolean(formik.errors.yearConvertedtoUniversityStatus)}
                                    helperText={formik.touched.yearConvertedtoUniversityStatus && formik.errors.yearConvertedtoUniversityStatus}
                                />

                            </Grid>

                        </Grid>
                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="Highest Educational Attainment of the Head"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='highestEducationalAttainmentoftheHead'
                                    name='highestEducationalAttainmentoftheHead'
                                    value={formik.values.highestEducationalAttainmentoftheHead}
                                    onChange={formik.handleChange}
                                    error={formik.touched.highestEducationalAttainmentoftheHead && Boolean(formik.errors.highestEducationalAttainmentoftheHead)}
                                    helperText={formik.touched.highestEducationalAttainmentoftheHead && formik.errors.highestEducationalAttainmentoftheHead}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Title of Head of Institution"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    //required
                                    id='titleofHeadofInstitution'
                                    name='titleofHeadofInstitution'
                                    value={formik.values.titleofHeadofInstitution}
                                    onChange={formik.handleChange}
                                    error={formik.touched.titleofHeadofInstitution && Boolean(formik.errors.titleofHeadofInstitution)}
                                    helperText={formik.touched.titleofHeadofInstitution && formik.errors.titleofHeadofInstitution}
                                />

                            </Grid>
                        </Grid>
                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="X-Coordinate"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='longitude'
                                    name='longitude'
                                    value={formik.values.longitude}
                                    onChange={formik.handleChange}
                                    error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                                    helperText={formik.touched.longitude && formik.errors.longitude}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Latest SEC Registration/Enabling Law or Charter"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='secRegistration'
                                    name='secRegistration'
                                    value={formik.values.secRegistration}
                                    onChange={formik.handleChange}
                                    error={formik.touched.secRegistration && Boolean(formik.errors.secRegistration)}
                                    helperText={formik.touched.secRegistration && formik.errors.secRegistration}
                                />

                            </Grid>

                        </Grid>
                        <Grid container gap={2}>

                            <Grid item flex={1}>
                                <TextField
                                    label="Approved Date"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    type={"date"}
                                    required
                                    disabled={true}
                                    id='approvedDate'
                                    name='approvedDate'
                                    value={moment.utc(formik.values.approvedDate).tz('Asia/Manila').format("YYYY-MM-DD")}
                                    onChange={formik.handleChange}
                                    error={formik.touched.approvedDate && Boolean(formik.errors.approvedDate)}
                                    helperText={formik.touched.approvedDate && formik.errors.approvedDate}
                                />
                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    label="Y-Coordinate"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='latitude'
                                    name='latitude'
                                    value={formik.values.latitude}
                                    onChange={formik.handleChange}
                                    error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                                    helperText={formik.touched.latitude && formik.errors.latitude}
                                />

                            </Grid>

                        </Grid>
                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <div style={{paddingTop: 6}}>
                                    <form onSubmit={(event) => event.preventDefault()}>
                                        <>
                                            <Autocomplete
                                                id={"province-autocomplete"}
                                                size="small"
                                                defaultValue={selectedProvinceId}
                                                value={selectedProvinceId}
                                                onChange={(event: any, newValue: any) => {
                                                    formik.setFieldValue("provinceId", newValue?.id)
                                                    formik.setFieldValue("provinceName", newValue?.name)
                                                    if (newValue) {
                                                        setSelectedProvinceId(newValue);
                                                    }

                                                }}
                                                getOptionLabel={(option) => {
                                                    return option?.name ?? ""
                                                }
                                                }
                                                renderOption={(props, option) => (
                                                    <li {...props} id={"setting-province" + option.id} key={option.id}>
                                                        <div
                                                            style={{flex: 1, display: "flex", flexDirection: "column"}}>
                                                            {option?.name ?? ""}</div>
                                                    </li>
                                                )}
                                                options={[...(provinces || [])] || []}


                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select Province"/>
                                                )}
                                            />
                                        </>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item flex={1}>
                                <TextField
                                    disabled
                                    label="Approved By"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='approvedBy'
                                    name='approvedBy'
                                    value={formik.values.approvedBy}
                                    onChange={formik.handleChange}
                                    error={formik.touched.approvedBy && Boolean(formik.errors.approvedBy)}
                                    helperText={formik.touched.approvedBy && formik.errors.approvedBy}
                                />

                            </Grid>
                        </Grid>

                        <Grid container gap={2}>
                            <Grid item flex={1}>
                                <TextField
                                    label="Institutional Type (For OT)"
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                    fullWidth
                                    required
                                    id='institutionalTypeForOT'
                                    name='institutionalTypeForOT'
                                    value={formik.values?.institutionalTypeForOT}
                                    onChange={formik.handleChange}
                                    error={formik.touched?.institutionalTypeForOT && Boolean(formik.errors?.institutionalTypeForOT)}
                                    helperText={formik.touched?.institutionalTypeForOT && formik.errors?.institutionalTypeForOT}
                                />

                            </Grid>
                            <Grid item flex={1}>
                                <Autocomplete
                                    options={hietypes}

                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue("institutionalTypeId", newValue ? newValue.id : "");
                                    }}
                                    value={
                                        hietypes.find((type) => type.id === (formik.values.institutionalTypeId || formik.values.heiTypeId)) || null
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="institutionalTypeId"
                                            variant="outlined"
                                            margin="dense"
                                            size="small"
                                            fullWidth
                                            required
                                            error={formik.touched.institutionalTypeId && Boolean(formik.errors.institutionalTypeId)}
                                            helperText={formik.touched.institutionalTypeId && formik.errors.institutionalTypeId}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button id={"cancelschoolInfoDetail"} onClick={handleClose}>Cancel</Button>
                    <LoadingButton disabled={!(formik.isValid && formik.dirty)} loading={loading}
                                   id={"updateschoolInfoDetail"} variant='contained'
                                   onClick={handleOpenEditInstitutionalProfile}>Update</LoadingButton>
                </DialogActions>
            </Dialog>
            <AddConfirmationDialog
                isLoading={loading}
                title={"Please be advised that the National Office will be informed of these changes."}
                open={openInstitutionalProfile}
                handleClose={handleCloseEditInstitutionalProfile}
                onSave={handleSubmit}></AddConfirmationDialog>
        </>

    )
})

export default UpdateSchool