import {Box, Chip, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {AddRounded} from "@mui/icons-material";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions, MUIDataTableToolbar, TableToolbar} from "mui-datatables";
import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useDebounce} from "../../hooks/useDebounce";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {useSchoolQuery} from "../../hooks/useSchoolQuery";
import useDialog from "../../hooks/useDialog";
import {updatesSo} from "../../redux/features/specialOrder";
import {usePager} from "../../hooks/usePager";
import AddConfigurationRequirementDialog from "../dialogs/AddConfigurationRequirement.dialog";
import {useRequirementQuery} from "../../hooks/useRequirementQuery";
import EditConfigurationRequirementDialog from "../dialogs/EditConfigurationRequirement.dialog";
import {setEditRequirementValue} from "../../redux/features/requirements";
import {setDeleteDialog} from "../../redux/features/app";
import {deleteSchoolProgramSoRequirements} from "../../requests/requirements.request";
import {useQueryClient} from "react-query";
import {CheckCircle, Edit, Trash, XCircle} from "lucide-react";

interface IReportTableProps {
    title: string;
}

export const RequirementsTable: FC<IReportTableProps> = (prop) => {
    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    const dispatch = useDispatch();
    const configurationProgram = useAppSelector((store) => store?.program?.configurationProgram);
    const [selectedSchool, setSelectedSchool] = React.useState<any>({name: "All", id: undefined});
    const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const user = useAppSelector((store) => store?.auth?.user)

    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: undefined,
        name: "All"
    });
    const type = useSelector((store: RootState) => store.auth?.user?.type)


    const roles = useAppSelector((store) => store?.auth?.user?.roles) as
        | "User"
        | "Admin"
        | "SuperAdmin";

    const rowSelectionChange = (newTableState: any) => {
        var _updates: any[] = []
        newTableState?.selectedRows?.data?.forEach((c: { index: number, dataIndex: number }) => {
            var _data = newTableState?.data?.[c.dataIndex]?.data

            _updates.push({
                studentId: _data?.[9],
                specialOrderId: _data?.[3],
                schoolId: _data?.[2],
                programId: _data?.[1]
            })
            return c
        })
        dispatch(updatesSo(_updates))
    }
    const queryClient = useQueryClient();
    const [name, setName] = React.useState<any>("");
    const debouncedName = useDebounce(name, 500);
    const {data: dataSchools} = useSchoolQuery({
        pageSize: "1000",
        search: '',
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
    });
    const {changePage, changeRowsPerPage} = usePager(setPage, setPageSize);
    const {
        data: requirements,
        isLoading
    } = useRequirementQuery(configurationProgram?.id, configurationProgram?.schoolId, configurationProgram?.studentId, debouncedSearchKey);
    const totalCount = useMemo(() => {
        return requirements?.totalCount
    }, [requirements?.totalCount])
    const _pageSize = useMemo(() => {
        return requirements?.pageSize
    }, [requirements?.pageSize])

    const handleDelete = (id: string | number, name: string | undefined) => {
        dispatch(
            setDeleteDialog({
                open: true,
                data: {
                    id,
                    title: `Delete Requirement`,
                    content: "requirementQuery",
                    body: `Are you sure you want to delete this requirement ${name}?`,
                    onDelete: async () => {
                        try {
                            var res = await deleteSchoolProgramSoRequirements(id)

                            return res
                        } catch (error) {
                            throw error
                        }
                    },
                },
            })
        );
    };

    const options: MUIDataTableOptions = {
        filter: false,
        textLabels: {
            body: {
                noMatch: isLoading ? "Please wait while we load your content." : "Sorry, no matching records can be found."
            }
        }, serverSide: true,
        search: true,
        elevation: 0, page: Math.min(typeof totalCount === "number" ? totalCount : 10, page),
        count: totalCount,
        selectableRows: 'none',
        resizableColumns: false,
        rowsPerPage: pageSize,
        onTableChange: (action, newTableState) => {
            switch (action) {
                case "search":
                    setSearchKey(newTableState.searchText);
                    break;
                case "rowSelectionChange":
                    rowSelectionChange(newTableState);
                    break;
                case "changeRowsPerPage":
                    changeRowsPerPage(newTableState.rowsPerPage);
                    break;

                case "changePage":
                    changePage(newTableState.page, (newTableState.data?.length ?? 100));
                    break;

            }
        },
    };
    const schoolAdminColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        }, {
            name: "name",
            label: "Name"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: false,
            },
        }, {
            name: "isRequired",
            label: "Required"?.toUpperCase(),
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (data: any) => {

                    return (
                        <div style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>


                            <Chip avatar={data ? <CheckCircle color={"#307d33"}/> : <XCircle color={"#d2302f"}/>}
                                  variant={"outlined"} color={data ? "success" : "error"}
                                  label={data ? "Required" : "Not Required"}/>
                        </div>

                    );
                },
            },
        },
        {
            name: "id",
            label: "Action",
            options: {
                filter: true,
                sort: true,

                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const name =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "name")
                            ];
                    const isRequired =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "isRequired")
                            ];
                    return (
                        <Stack direction="row" spacing={1}>
                            <Tooltip title="Delete" arrow>
                                <IconButton
                                    color="error"
                                    size="small"
                                    value={value}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(value, name);
                                    }
                                    }
                                >
                                    <Trash/>
                                </IconButton>
                            </Tooltip>
                            <div style={{flex: 1, display: "flex",}}>
                                <div style={{paddingRight: 6}}>
                                    <Tooltip title="Update" arrow>
                                        <IconButton color={"primary"} id={"view" + tableMeta?.rowIndex} onClick={() => {
                                            dispatch(setEditRequirementValue({
                                                name: name,
                                                isRequired: isRequired,
                                                id: value
                                            }))
                                            handleOpenEditRequirement()
                                        }}>
                                            <Edit/>
                                        </IconButton>
                                    </Tooltip>

                                </div>


                            </div>
                        </Stack>


                    );
                },
            },
        }
    ];
    useEffect(() => {
        if (dataSchools?.data && user?.school?.id) {
            setSelectedSchool(user?.school?.id ? dataSchools?.data?.find(c => c.id == user?.school?.id) : {
                name: "All",
                id: undefined
            });
        } else if (!selectedSchool) {
            setSelectedSchool({name: "All", id: undefined})
        }
    }, [dataSchools]);
    const {
        handleOpen: handleOpenAddRequirement,
        handleClose: handleCloseAddRequirement,
        open: openAddRequirement,
    } = useDialog();
    const {
        handleOpen: handleOpenEditRequirement,
        handleClose: handleCloseEditRequirement,
        open: openEditRequirement,
    } = useDialog();

    function _setEditRequirementValue() {
        dispatch(setEditRequirementValue({
            name: "",
            isRequired: false,
            id: 0
        }))
    }

    return <>
        <Helmet>
            <title>Requirements</title>
        </Helmet>

        <div>
            <MUIDataTable
                options={options}
                components={{

                    TableToolbar: useCallback((props: MUIDataTableToolbar) => {
                        return (
                            <>
                                <TableToolbar {...props} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        mx: 3,
                                        my: 1,
                                    }}
                                >
                                    <Stack direction="row" gap={1}>

                                    </Stack>

                                    {type?.id == 2 && ["Admin"].indexOf(roles) > -1 ?
                                        <Stack spacing={1} direction="row">
                                            <Button
                                                onClick={handleOpenAddRequirement}
                                                startIcon={<AddRounded/>}
                                                variant="contained"
                                            >
                                                Add Requirements
                                            </Button>
                                        </Stack> : null}
                                </Box>
                            </>
                        );
                    }, []),
                }}
                title={
                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div style={{display: "flex", flex: 1}}>
                            <Typography variant={"h6"}>{prop?.title}</Typography>
                        </div>

                    </div>

                }

                data={requirements?.data as any}
                columns={schoolAdminColumns}
            />
        </div>
        <AddConfigurationRequirementDialog
            open={openAddRequirement}
            handleClose={handleCloseAddRequirement}
            onSave={(payload: any) => {

            }
            }
        ></AddConfigurationRequirementDialog>


        <EditConfigurationRequirementDialog
            open={openEditRequirement}
            handleClose={() => {
                _setEditRequirementValue();
                handleCloseEditRequirement()
            }}
            onSave={(payload: any) => {
                _setEditRequirementValue();
            }
            }
        ></EditConfigurationRequirementDialog>

    </>
}


