import {Box, Chip, Stack, Typography} from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const CHIP_MAX_WIDTH = 100;
const CHIP_ICON_WIDTH = 30;
const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        padding: theme.spacing(0.5)
    },
    chip: {
        maxWidth: CHIP_MAX_WIDTH
    }
}));

export function InstitutionalProfile(props: { school: any }) {


    const classes = useStyles();
    const EllipsisText = (props: any) => {
        const {children} = props;

        return (
            <div
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH
                }}
            >
                {children}
            </div>
        );
    };

    return <Stack direction="row" gap={10}>
        <Stack gap={1}>
            <Box>
                <Typography variant="subtitle1">
                    School ID/Code
                </Typography>
                <Chip
                    variant="filled"
                    label={props?.school?.code ?? props?.school?.institutionalCode ?? "-"}
                ></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">School Name</Typography>
                <Chip
                    variant="filled"
                    label={props?.school?.name ?? "-"}
                ></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    School Address
                </Typography>
                <Chip
                    variant="filled"
                    className={classes.chip}
                    label={
                        <EllipsisText>{(props?.school?.address ?? "") + " " + (props?.school?.street ?? "-") + ", " + (props?.school?.municipalityCity ?? "-")}</EllipsisText>}
                ></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    School Email
                </Typography>
                <Chip variant="filled" label={props.school?.email ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    School Fax
                </Typography>
                <Chip variant="filled" label={props?.school?.fax ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    School Zip
                </Typography>
                <Chip variant="filled" label={props?.school?.zipCode ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Approved Date
                </Typography>
                <Chip variant="filled"
                      label={moment.utc(props?.school?.approvedDate).tz('Asia/Manila').format('MMMM D, YYYY, h:mm a') ?? "-"}></Chip>
            </Box> <Box>
            <Typography variant="subtitle1">
                Approved By
            </Typography>
            <Chip variant="filled" label={props?.school?.approvedBy ?? "-"}></Chip>
        </Box>
        </Stack>
        <Stack gap={1}>


            <Box>
                <Typography variant="subtitle1">
                    Institutional Head Telephone
                </Typography>
                <Chip variant="filled" label={props?.school?.institutionalHeadTelephone ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">Institutional Form Of Ownership</Typography>
                <Chip
                    variant="filled"
                    label={props?.school?.institutionalFormOfOwnership ?? "-"}
                ></Chip>
            </Box>

            <Box>
                <Typography variant="subtitle1">
                    Name of InstitutionalHead
                </Typography>
                <Chip variant="filled" label={props?.school?.nameofInstitutionalHead ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Title of head of institution
                </Typography>
                <Chip variant="filled" label={props?.school?.titleofHeadofInstitution ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Highest Educational Attainment of the Head
                </Typography>
                <Chip variant="filled"
                      label={props?.school?.highestEducationalAttainmentoftheHead ?? "-"}></Chip>
            </Box>
        </Stack>


        <Stack gap={1}>
            <Box>
                <Typography variant="subtitle1">
                    Year Establish
                </Typography>
                <Chip variant="filled" label={props?.school?.yearEstablished ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Sec Registration
                </Typography>
                <Chip variant="filled" label={props?.school?.secRegistration ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Year Converted To College Status
                </Typography>
                <Chip variant="filled" label={props?.school?.yearConvertedtoCollegeStatus ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Year Converted To University Status
                </Typography>
                <Chip variant="filled" label={props?.school?.yearConvertedtoUniversityStatus ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Longitude
                </Typography>
                <Chip variant="filled" label={props?.school?.longitude ?? "-"}></Chip>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    Latitude
                </Typography>
                <Chip variant="filled" label={props?.school?.latitude ?? "-"}></Chip>
            </Box>
            {
                props?.school?.province?.name ?
                    <Box>
                        <Typography variant="subtitle1">
                            Province
                        </Typography>
                        <Chip variant="filled" label={props?.school?.province?.name ?? "-"}></Chip>
                    </Box>
                    : <></>
            }

            {
                props?.school?.institutionalTypeForOT ?
                    <Box>
                        <Typography variant="subtitle1">
                            Institutional Type (For OT)
                        </Typography>
                        <Chip variant="filled" label={props?.school?.institutionalTypeForOT ?? "-"}></Chip>
                    </Box>
                    : <></>
            }
        </Stack>
    </Stack>;
}
