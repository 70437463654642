import * as Yup from "yup";

export interface IRegionSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name: string;
  code: string;
  value?: string | null;
}

export const regionSchema: Yup.SchemaOf<IRegionSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  name: Yup.string()
    .required("Name is required")
    .min(1, "Name must be at least 1 character"),
  code: Yup.string()
    .required("Code is required")
    .min(1, "Code must be at least 1 character"),
  value: Yup.string().nullable(),
});

export type RegionType = Yup.InferType<typeof regionSchema>;
