import {AxiosRequestConfig} from "axios";
import {useQuery} from "react-query";
import {getWorkspaces} from "../../requests/workspace.request";

export const useWorkspaceQuery = (params: AxiosRequestConfig["params"]) => {
  return useQuery(["workspaces", params?.search, params?.schoolId, params?.curriculumId, params?.pageNumber, params?.page, params?.pageSize], () => getWorkspaces(params), {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};
