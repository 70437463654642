import * as Yup from "yup";

export interface ILevelSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name: string;
}

export const levelSchema: Yup.SchemaOf<ILevelSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  createdBy: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  name: Yup.string().required("Name is required"),
});

export type LevelType = Yup.InferType<typeof levelSchema>;
