import {loginResponseSchema, registerSchema} from "../schemas/auth.schema";
import api from "./config";

interface LoginData {
  username: string;
  password: string;
}

export const login = async (data: LoginData) => {

    const res = await api.post("/login", data);



    var localStorageRefreshToken: any = localStorage.getItem('session')
    const refreshToken = JSON.parse(localStorageRefreshToken);
    if (!refreshToken?.accessToken) {
      localStorage.removeItem("session");
      localStorage.removeItem("user");
    }
    localStorage.setItem("session", JSON.stringify(res?.data));
    localStorage.setItem("user", JSON.stringify(res?.data.user));
    return await loginResponseSchema.validate(res?.data, {
        strict: true,
        abortEarly: false,
        stripUnknown: true,
    });

};
export const forgotpassword = async (data: {
    email: string
}) => {
    const res = await api.post("/forgot-password", data);
    return res;
};
export const resetpassword = async (data: {
    password: string
}, token: string | null, userId: number | string | null) => {
    const res = await api.post(`/confirm-reset-password`, {...data, token, userId});
    return res;
};


type RegisterData = {
    schoolId: string;
    schoolName: string;
    schoolAddress: string;
    userName: string;

    password: string;
    confirmPassword: string;
    email: string;
};

type RegisterResponseType = {
  message: string;
};

export const register = async (data: RegisterData) => {

  const res = await api.post("/register", data);
  return await registerSchema.validate(res?.data, {
    strict: true,
    abortEarly: false,
    stripUnknown: true,
  });
};

type ChangePasswordData = {
  email: string;
  oldPassword: string;
  newPassword: string;
};

export const changePassword = async (data: ChangePasswordData) => {
  const res = await api.post("/update-password", data);

  return res?.data;
};
