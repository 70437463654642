//create Snackbar Context

import {Alert, Slide, SlideProps, Snackbar as MuiSnackbar} from "@mui/material";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeSnackbar} from "../redux/features/snackbar";
import {RootState} from "../redux/store";

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up"/>;
}

const Snackbar = ({children}: any) => {
    const snackbarStore = useSelector((store: RootState) => store.snackbar);
    const dispatch = useDispatch();

    const handlecCloseSnackbar = useCallback(() => {
        dispatch(closeSnackbar());
    }, []);

    return (<>
            <MuiSnackbar
                anchorOrigin={{vertical: 'top', horizontal:  snackbarStore.position ? snackbarStore.position : 'center'}}
                open={snackbarStore.open}
                TransitionComponent={SlideTransition}
                //autoHideDuration={10000}
                onClose={handlecCloseSnackbar}
            >
                <Alert onClose={handlecCloseSnackbar} severity={snackbarStore.severity}>
                    {snackbarStore.message}
                </Alert>
            </MuiSnackbar>
            {children}
        </ >)
}

export default Snackbar;
