// MessageToolbar.tsx

import React from "react";
import { Button, Tooltip } from "@mui/material";
import { MessageSquareDashedIcon, Pencil, Smile, Trash } from "lucide-react";
import {EmojiPopover} from "./emoji-popover";

interface ToolbarProps {
    isAuthor: boolean;
    isPending: boolean;
    handleEdit: () => void;
    handleThread: () => void;
    handleDelete: () => void;
    handleReaction: (value: string) => void;
    hideThreadButton?: boolean;
}

export const MessageToolbar = ({
                                   isAuthor,
                                   isPending,
                                   handleEdit,
                                   handleThread,
                                   handleDelete,
                                   handleReaction,
                                   hideThreadButton,
                               }: ToolbarProps) => {
    return (
        <div className="absolute top-1/2 right-5 -translate-y-1/2 group-hover:opacity-100 opacity-0 transition-opacity">
            <div className="flex items-center space-x-2 bg-white border rounded-md shadow-md p-1">
                <EmojiPopover  onEmojiSelect={handleReaction}>
                    <Button variant="text" size="small" disabled={isPending}>
                        <Smile className="w-4 h-4" />
                    </Button>
                </EmojiPopover>
                {!hideThreadButton && (
                    <Tooltip title="Reply in thread">
                        <Button variant="text" size="small" onClick={handleThread} disabled={isPending}>
                            <MessageSquareDashedIcon className="w-4 h-4" />
                        </Button>
                    </Tooltip>
                )}
                {isAuthor && (
                    <Tooltip title="Edit message">
                        <Button variant="text" size="small" onClick={handleEdit} disabled={isPending}>
                            <Pencil className="w-4 h-4" />
                        </Button>
                    </Tooltip>
                )}
                {isAuthor && (
                    <Tooltip title="Delete message">
                        <Button variant="text" size="small" onClick={handleDelete} disabled={isPending}>
                            <Trash className="w-4 h-4" />
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
