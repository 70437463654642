import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import {Alert, Box, OutlinedInput, Select, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import {patchCourse} from "../../requests/course.request";
import {SearchSchoolResponseType} from "../../types";
import SearchCurriculumTextField from "../SearchTextField/SearchCurriculumTextField";
import {setCurriculum, setCurriculumBreadcrumb, setCurriculumId, setCurriculumTemp,} from "../../redux/features/school";
import {RootState, useAppSelector} from "../../redux/store";
import {setErrorSnackbar} from "../../helper/utils/helper";
import FormControl from "@mui/material/FormControl";
import {usePrerequisiteQuery} from "../../hooks/usePrerequisiteQuery";
import {usePrerequisiteByCourseIdQuery} from "../../hooks/usePrerequisiteByCourseIdQuery";
import {CheckRounded} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {Transition} from "../../wrapper/Transition";
import {useAddEntities} from "../../hooks/useAddEntities";
import {useCourseByIdQuery} from "../../hooks/useCourseByIdQuery";
import {setEditCourse} from "../../redux/features/course";

const validationSchema = yup.object({
  code: yup.string().required("Code is required"),
  name: yup.string().required("Name is required"),
  description: yup.string().nullable(),
  lecture: yup.number().required("Lecture is required"),
  laboratory: yup.number().required("Laboratory is required"),
  curriculumId: yup.number().nullable(),
  prerequisite: yup.string().nullable(),
  prerequisiteCourseCodes: yup.array().nullable(),
  programId: yup.number().nullable(),
  programName: yup.string().nullable(),
  levelId: yup.number().nullable(),
  levelName: yup.string().nullable(),
  disciplineId: yup.number().nullable(),
  disciplineName: yup.string().nullable(),
  majorId: yup.number().nullable(),
  majorName: yup.string().nullable(),
  yearLevel: yup.number().required("Year Level id is required").nullable(),
  courseSemester: yup.number().required("Course Semester id is required").nullable(),
});

const initialValues = {
  schoolId: "",
  curriculumName: "",
  yearLevel: 0,
  courseSemester: 0,
  code: "",
  name: "",
  description: "",
  curriculumId: "",
  oldCurriculumId: 0,
  lecture: "",
  laboratory: "",
  prerequisite: "",
  prerequisiteCourseCodes: [] as string[],
  programId: 0,
  programName: "",
  levelId: 0,
  levelName: "",
  disciplineId: 0,
  disciplineName: "",
  majorId: 0,
  majorName: "",
};


interface IProps {
  open: boolean;
  handleClose: () => void;
  setting?: boolean;
}

const EditCourse = React.memo((props: IProps) => {
  const {open, handleClose, setting} = props;
  const dispatch = useDispatch();
  const [school, setSchool] = useState<any>()
  const queryClient = useQueryClient();
  const {schoolId, regionId} = useAddEntities();
  const editCoursesId = useAppSelector((store) => store?.course?.editCoursesId);
  const editCurriculumId = useAppSelector((store) => store?.course?.editCurriculumId);
  const editCourseSchoolId = useAppSelector((store) => store?.course?.editCourseSchoolId);
  const editCourseSchoolName = useAppSelector((store) => store?.course?.editCourseSchoolName);

  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);
  const [loading, setLoading] = useState(false)
  const curriculumCount = useAppSelector(
      (store) => store?.curriculum?.curriculumCount
  );
  const tempCurriculum = useAppSelector(
      (store) => store?.school?.curriculumTemp
  );
  const curriculumName = useAppSelector(
      (store) => store?.school?.curriculumName
  );


  const programId = useAppSelector(
      (store) => store?.program?.programId
  );
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {

      try {
        values.schoolId = editCourseSchoolId ? editCourseSchoolId : (schoolId ? schoolId : school?.id)
        if( values.programId == 0){
          values.programId = programId
        }
        values.laboratory = values.laboratory?.toString();
        values.lecture = values.lecture?.toString();
        if (values.prerequisite) {
          values.prerequisiteCourseCodes = values.prerequisite.split(/\s*(?:,|$)\s*/)
        }

        setLoading(true)

        var res = await patchCourse(editCoursesId, values);
        dispatch(setEditCourse({editCoursesId: 0, editCurriculumId: 0}))
        queryClient.setQueriesData(['courses'], (oldData: any) => {

          return {
            ...oldData,
            data: oldData?.data?.map((t: any) => {
              var _course = {...t}
              if (editCoursesId == t?.id) {
                _course.courseCode = values?.code
                _course.courseDescription = values?.description
                _course.courseName = values?.name
                _course.courseSemester = values?.courseSemester
                _course.curriculumName = values?.curriculumName
                _course.laboratory = values?.laboratory
                _course.lecture = values?.lecture
                _course.courseYearLevel = values?.yearLevel
              }
              return _course
            })
          }
        })

        setLoading(false)
        if (res?.message) {
          var __message = values?.name + " already exists in our system. Please try a different one."

          setSnackbarStore({
            message: res?.message,
            severity: __message == res?.message ? "error" : (res.succeeded ? "success" : "error"),
            open: true,
          });

        } else {
          setSnackbarStore({
            message: "Successfully added course",
            severity: (res.succeeded ? "success" : "error"),
            open: true,
          })
        }

        setLoading(false)
        queryClient.invalidateQueries("courses");

        if (curriculumName != null) {
          dispatch(setCurriculumId(values?.curriculumId));
          dispatch(setCurriculumBreadcrumb(values?.curriculumName));
          dispatch(setCurriculum(tempCurriculum));
        }

        handleClose();
      } catch (error: any) {
        setLoading(false)
        setErrorSnackbar(error, setSnackbarStore);
      }
    },
  });
  const {data: prerequisiteData, refetch} = usePrerequisiteQuery({
    ...formik.values.curriculumName && {curriculumName: formik.values.curriculumName},
    ...curriculumCount && {
      curriculumCount: curriculumCount
    }
  });


  const {data: prerequisiteByCourseIdData} = usePrerequisiteByCourseIdQuery({
    ...formik.values.curriculumId && {courseId: formik.values.curriculumId},
    ...curriculumCount && {
      curriculumCount: curriculumCount
    }
  });


  const {data: courseByCourseIdData} = useCourseByIdQuery(editCoursesId, {
    ...formik.values.curriculumId && {courseId: formik.values.curriculumId},
    ...curriculumCount && {
      curriculumCount: curriculumCount
    }
  });


  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const handleOnSelected = useCallback(
      (value: any) => {
        dispatch(setCurriculumTemp(value));
        formik.setFieldValue("programId", programId ?? value?.programId ?? "");
        formik.setFieldValue("programName", value?.programName ?? "");
        formik.setFieldValue("levelId", value?.levelId ?? 0);
        formik.setFieldValue("levelName", value?.levelName ?? "");
        formik.setFieldValue("disciplineId", value?.disciplineId ?? 0);
        formik.setFieldValue("disciplineName", value?.disciplineName ?? "");
        formik.setFieldValue("majorId", value?.majorId ?? 0);
        formik.setFieldValue("majorName", value?.majorName ?? "");
        formik.setFieldValue("levelId", value?.levelId ?? 0);
        formik.setFieldValue("levelName", value?.levelName ?? "");
        formik.setFieldValue("curriculumId", value?.curriculumId ?? "");
        formik.setFieldValue("curriculumName", value?.curriculumName ?? "");
        if (value?.curriculumId == null) {
          formik.setFieldValue('prerequisiteCourseCodes', [])
        }

      },
      [formik]
  );
  const schoolSelectorById = useAppSelector(
      (store) => store?.school.schoolSelectorById
  );


  useEffect(() => {
    var courseByCourseIdDataIndex = courseByCourseIdData?.data?.curriculumSubjects?.findIndex((c: any) => c.subjectId == editCoursesId && editCurriculumId == c.curriculumId)
    var subjectByCourseIdDataIndex = courseByCourseIdData?.data?.subjectUnits?.findIndex((c: any) => c.subjectId == editCoursesId)


    if (courseByCourseIdDataIndex > -1) {
      var courseByCourseIdDataCurriculumSubjects = courseByCourseIdData?.data?.curriculumSubjects?.[courseByCourseIdDataIndex]
      var subjectByCourseIdDataCurriculumSubjects = courseByCourseIdData?.data?.subjectUnits?.[subjectByCourseIdDataIndex]

      handleOnSelected({
        curriculumId: courseByCourseIdDataCurriculumSubjects?.curriculum?.id,
        curriculumName: courseByCourseIdDataCurriculumSubjects?.curriculum?.name,
      })
      setLoading(true)
      formik.setFieldValue("yearLevel", courseByCourseIdData?.data?.yearLevel ?? "", true);
      formik.setFieldValue("courseSemester", courseByCourseIdData?.data?.semester ?? "", true);
      formik.setFieldValue("code", courseByCourseIdData?.data?.courseCode ?? "", true);
      formik.setFieldValue("description", courseByCourseIdData?.data?.description ?? "", true);
      formik.setFieldValue("name", courseByCourseIdData?.data?.name ?? "", true);
      formik.setFieldValue("programId", courseByCourseIdData?.data?.programId ?? 0 , true);
      formik.setFieldValue("programName", courseByCourseIdData?.data?.program?.name ?? "", true);
      formik.setFieldValue("levelId", courseByCourseIdData?.data?.levelId ?? 0 , true);
      formik.setFieldValue("levelName", courseByCourseIdData?.data?.level?.name ?? "", true);
      formik.setFieldValue("majorId", courseByCourseIdData?.data?.majorId ?? 0 , true);
      formik.setFieldValue("majorName", courseByCourseIdData?.data?.major?.name ?? "", true);
      formik.setFieldValue("disciplineId", courseByCourseIdData?.data?.disciplineId ?? 0 , true);
      formik.setFieldValue("disciplineName", courseByCourseIdData?.data?.discipline?.name ?? "", true);
      formik.setFieldValue("oldCurriculumId", editCurriculumId ?? "", true);
      formik.setFieldValue("oldLecture", subjectByCourseIdDataCurriculumSubjects?.unit?.lecture ?? "" ?? "", true);
      formik.setFieldValue("oldLaboratory", subjectByCourseIdDataCurriculumSubjects?.unit?.laboratory ?? "", true);

      formik.setFieldValue("lecture", subjectByCourseIdDataCurriculumSubjects?.unit?.lecture ?? "", true);
      formik.setFieldValue("laboratory", subjectByCourseIdDataCurriculumSubjects?.unit?.laboratory ?? "", true);

      var prerequisitesCourseCodes = courseByCourseIdData?.data?.prerequisite?.prerequisitesCourseCodes;

      if (prerequisitesCourseCodes) {
        formik.setFieldValue("prerequisiteCourseCodes", typeof prerequisitesCourseCodes === 'string' ? prerequisitesCourseCodes?.split(',') : prerequisitesCourseCodes ?? [], true);
      } else if (courseByCourseIdDataCurriculumSubjects?.curriculum?.id == null) {
        formik.setFieldValue('prerequisiteCourseCodes', [], true)
      }

      setLoading(false)
    }
  }, [courseByCourseIdData])


  const handleChange = (event: any) => {
    const {
      target: {value},
    } = event;
    formik.setFieldValue('prerequisiteCourseCodes', typeof value === 'string' ? value?.split(',') : value)

  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [names, setName] = useState<{ id: number, courseCode: string }[]>([])
  useEffect(() => {

    if (prerequisiteData?.data) {
      setName(prerequisiteData?.data)
    }

  }, [prerequisiteData, prerequisiteByCourseIdData, formik?.values?.curriculumName,])

  useEffect(() => {
    if (prerequisiteByCourseIdData?.prerequisitesCourseCodes) {
      formik.setFieldValue('prerequisiteCourseCodes', prerequisiteByCourseIdData?.prerequisitesCourseCodes?.split(","))
    }

  }, [prerequisiteByCourseIdData])

  const prerequisites = useMemo(() => names?.map((c: any) => {
    return {
      program: c.program,
      curriculumName: c?.curriculumName,
      courseName: c?.courseName,
      courseDescription: c?.courseDescription,
      courseCode: c?.courseCode,
      value: c.courseCode,
      id: c.id
    }
  }), [names, prerequisiteData?.data, prerequisiteByCourseIdData]);
  const type = useAppSelector((store: RootState) => store.auth?.user?.type)
  const roles = useAppSelector((store) => store?.auth?.user?.roles) as
      | "User"
      | "Admin"
      | "SuperAdmin";


  const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
  const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
    setSchool(value)
    formik.setFieldValue('schoolId', value?.id ?? "")
  }, [formik])
  const authStore = useSelector((store: RootState) => store.auth);
  return (
      <Dialog
          open={open}
          TransitionComponent={Transition}
          // keepMounted
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Update Course <p
            style={{
              margin: 0,
              fontSize: 12,
              fontStyle: "italic"
            }}>({authStore?.user?.school?.name ?? editCourseSchoolName ?? ""})</p>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Please fill in the details of the course you want to add
            <br/>
            Note: Fields with <strong>*</strong> are required
          </Alert>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 3,
              }}
          >
            <Stack direction="row" gap={1}>

              <Box
                  sx={{
                    width: "100%",
                  }}
              >

                {
                  tempCurriculum ? <SearchCurriculumTextField
                      required={true}
                      defaultValue={tempCurriculum}
                      label="Curriculum"
                      onSelected={handleOnSelected}
                      schoolId={schoolId}
                  /> : null
                }

              </Box>

              <TextField
                  select
                  label="Year Level"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  required={true}
                  id="yearLevel"
                  name="yearLevel"
                  value={formik.values.yearLevel}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.yearLevel && Boolean(formik.errors.yearLevel)
                  }
                  helperText={formik.touched.yearLevel && formik.errors.yearLevel}
              >
                <MenuItem value={1}>1st Year</MenuItem>
                <MenuItem value={2}>2nd Year</MenuItem>
                <MenuItem value={3}>3rd Year</MenuItem>
                <MenuItem value={4}>4th Year</MenuItem>
                <MenuItem value={5}>5th Year</MenuItem>
              </TextField>
              <TextField

                  select
                  required={true}
                  label="Semester"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  id="courseSemester"
                  name="courseSemester"
                  value={formik.values.courseSemester}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.courseSemester &&
                      Boolean(formik.errors.courseSemester)
                  }
                  helperText={
                      formik.touched.courseSemester && formik.errors.courseSemester
                  }
              >
                <MenuItem value={1}>1st Semester</MenuItem>
                <MenuItem value={2}>2nd Semester</MenuItem>
                <MenuItem value={3}>Mid Year / Summer</MenuItem>
              </TextField>
            </Stack>
            <TextField
                label="Code"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
            />
            <TextField
                label="Name"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="coursename"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                label="Description"
                variant="outlined"
                margin="dense"
                size="small"
                fullWidth
                required
                id="coursedescription"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                    formik.touched.description && Boolean(formik.errors.description)
                }
                helperText={formik.touched.description && formik.errors.description}
            />


            <FormControl sx={{width: "100%",}}>
              <Select aria-label={"course-select"}
                      inputProps={{
                        'id': `course-select-role`,
                        'aria-label': 'Without label'
                      }}
                      SelectDisplayProps={{
                        // @ts-ignore
                        "id": `course-select-role`
                      }}

                      id="course-select-role"
                  //disabled={!( formik?.values?.curriculumId) }
                      size={"small"}
                      multiple
                      displayEmpty
                      value={formik.values.prerequisiteCourseCodes}
                      onChange={handleChange}
                      input={<OutlinedInput/>}

                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <em>Select Prerequisite</em>;
                        }
                        return selected?.join(', ');
                      }}
                      MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  <em>Select Prerequisite</em>;
                </MenuItem>
                <MenuItem  onClick={() => formik.setFieldValue("prerequisiteCourseCodes", [])}>
                  <Button  sx={{ display: 'block',}}
                           disabled={formik.values.prerequisiteCourseCodes.length === 0}>
                    <p  style={{color: "blue"}}>
                      Clear Prerequisites
                    </p>
                  </Button>
                </MenuItem>
                {
                  (formik.values.curriculumName && prerequisites?.length > 0) ? prerequisites?.sort((a: any, b: any) => {

                    let fa = a?.value?.toLowerCase?.() || "",
                        fb = b?.value?.toLowerCase?.() || "";

                    if (fa < fb) {
                      return -1;
                    }
                    if (fa > fb) {
                      return 1;
                    }
                    return 0;
                  })?.map((name: any) => {

                        return <MenuItem
                            key={name?.id}
                            value={name?.value}
                            aria-label={name?.value}
                        >
                          <div style={{justifyContent: "space-between", flex: 1, display: "flex", flexDirection: "row"}}>
                            <div>
                              {name?.value}
                              <p style={{
                                fontStyle: "italic",
                                margin: 0,
                                fontSize: 12
                              }}>{name?.program ? name.program + " - " : ""} {name?.curriculumName ? name.curriculumName : "-"} {name?.courseCode ? name.courseCode + " - " : ""} {name?.courseName ? name?.courseName + "" : ""}</p>

                            </div>

                            {formik?.values?.prerequisiteCourseCodes?.length > 0 ? formik?.values?.prerequisiteCourseCodes?.findIndex((c: any) => c == name?.value) > -1 ?
                                <div>
                                  <CheckRounded color={"success"}/>
                                </div> : null : null}
                          </div>
                        </MenuItem>
                      }
                  ) : null}

              </Select>
            </FormControl>


            <Stack direction="row" gap={1}>
              <TextField
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  inputMode={"numeric"}
                  label="Number (units) of Lecture"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  required
                  id="lecture"
                  name="lecture"
                  value={formik.values.lecture}
                  onChange={formik.handleChange}
                  error={formik.touched.lecture && Boolean(formik.errors.lecture)}
                  helperText="Please enter number or units of lecture "
              />
              {formik.touched.lecture && formik.errors.lecture ?
                  <Typography variant="caption" color="error">
                    {formik.errors.lecture}
                  </Typography> : null
              }
              <TextField
                  type="number"
                  InputProps={{
                    inputProps: {min: 0},
                  }}
                  inputMode={"numeric"}
                  label="Number (units) of Laboratory "
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  required
                  id="laboratory"
                  name="laboratory"
                  value={formik.values.laboratory}
                  onChange={formik.handleChange}
                  error={
                      formik.touched.laboratory && Boolean(formik.errors.laboratory)
                  }
                  helperText="Please enter number or units of laboratory "
              />
              {formik.touched.laboratory && formik.errors.laboratory ?
                  <Typography variant="caption" color="error">
                    {formik.errors.laboratory}
                  </Typography> : null
              }

            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id={"cancelCourse"} onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={loading} id={"addCourse"}
                         disabled={!(formik.isValid && formik.dirty)}
                         variant="contained"
                         onClick={handleSubmit}
          >
            {loading ? "Updating" : "Update"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  );
});

export default EditCourse;
