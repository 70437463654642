export function usePager(setPage: (value: (((prevState: number) => number) | number)) => void, setPageSize: (value: (((prevState: number) => number) | number)) => void) {
    const changePage = (_page: number, _pageSize: number) => {

        setPage(_page)
        if (_pageSize) {
            setPageSize(_pageSize)
        }

    }
    const changeRowsPerPage = (_page: number) => {
        if (_page) {
            setPageSize(_page)
        }
    }
    return {changePage, changeRowsPerPage};
}
