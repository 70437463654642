import {AxiosRequestConfig} from 'axios';
import {useQuery} from "react-query";
import {getSpecialOrderGroupByStudents} from "../requests/special-order.request";

export const useSpecialOrderReceiptGroupByStudentQuery = (params: AxiosRequestConfig['params'], specialOrderMajorId: string | number | undefined, specialOrderIdValue: string | number | undefined, specialOrderProgramIdValue: string | number | undefined) => {
   return useQuery(["specialOrderReceiptGroupByStudent" + specialOrderMajorId + specialOrderIdValue + specialOrderProgramIdValue, params, params.status, params.regionId, params.schoolId, params.majorId, params.programId], async () => {
       var oldData = await getSpecialOrderGroupByStudents(params)
       if (oldData?.data) {

           for await (const student of oldData?.data) {

               for await (const s of student?.students) {
                   s.isPaid = true
                   s.attachment = s.receiptFile

               }
           }

        }
        return {
            ...oldData, data: oldData?.data
        }
    }, {
        refetchOnWindowFocus: false,
    });
}
