import Quill from "quill";
import { useRef, useState } from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Editor from "../../editor";
import {useMutation} from "react-query";
import {MessageInputType} from "../../../../schemas/major.schema";
import {createMessage} from "../../../../requests/message.request";



interface ChatInputProps {
  placeholder: string;
  conversationId:any;
};

type CreateMesageValues = {
  conversationId: any;
  workspaceId: any;
  body: string;
  image: any | undefined;
};

export const ChatInput = ({ placeholder, conversationId }: ChatInputProps) => {
  const [editorKey, setEditorKey] = useState(0);
  const [isPending, setIsPending] = useState(false);

  const editorRef = useRef<Quill | null>(null);
  const {workspaceId} = useParams() as {
    workspaceId: string
  };

  const {mutate: message, isLoading} = useMutation({
    mutationKey: "createMessage", mutationFn: async (value: MessageInputType) => {
      return await createMessage(value)
    }
  });
  const handleSubmit = async ({
    body,
    image
  }: {
    body: string;
    image: File | null;
  }) => {
    try {
      setIsPending(true);
      editorRef?.current?.enable(false);

      const values: CreateMesageValues = {
        conversationId,
        workspaceId,
        body,
        image: undefined,
      };
      await message(values as MessageInputType)
      setEditorKey((prevKey) => prevKey + 1);
    } catch (error) {
      toast.error("Failed to send message");
    } finally {
      setIsPending(false);
      editorRef?.current?.enable(true);
    }
  };

  return (
    <div className="px-5 w-full">
      <Editor
        key={editorKey}
        placeholder={placeholder}
        onSubmit={handleSubmit}
        disabled={isPending}
        innerRef={editorRef}
      />
    </div>
  );
};
