import React, { useCallback, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions
} from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import ChatIcon from "@mui/icons-material/Chat";
import GroupIcon from "@mui/icons-material/Group";
import { WorkspaceContentProps } from "./WorkspaceContent";
import { useAppSelector } from "../../redux/store";
import { Logout, PersonAdd } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import { useWorkspaceQuery } from "../../hooks/workspaces/use-get-workspaces";
import { useHistory } from "react-router-dom";
import { useCreateWorkspaceModal } from "../../store/use-create-workspace-modal";
import PreferenceModal from "./create-workspace-preference-modal";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { fetchChannelsByWorkspaceId, createChannel } from "../../requests/channel";
import {fetchMembersByWorkspaceId} from "../../requests/member.request";
import InviteMemberModal from "./create-workspace-join-code-modal";

const drawerWidth = 240;

const Sidebar: React.FC<WorkspaceContentProps> = ({ workspaceId }) => {
    const user = useAppSelector((store) => store?.auth?.user)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [_open, setOpen] = useCreateWorkspaceModal();
    const [preferenceOpen, setPreferenceOpen] = useState(false);
    const [channelDialogOpen, setChannelDialogOpen] = useState(false);
    const [newChannelName, setNewChannelName] = useState("");
    const [nameError, setNameError] = useState(false);
    const { data: channels, isLoading } = useQuery<any[], Error>(
        ["channels", workspaceId],
        () => fetchChannelsByWorkspaceId(workspaceId),
        { enabled: !!workspaceId }
    );

    const queryClient = useQueryClient();
    const createChannelMutation = useMutation(createChannel, {
        onSuccess: () => {
            queryClient.invalidateQueries(["channels", workspaceId]);
            setChannelDialogOpen(false);
            setNameError(false);
            setNewChannelName("");
        }
    });

    const handleCreateChannel = () => {
        if (!newChannelName.trim()) {
            setNameError(true);
        } else {
            createChannelMutation.mutate({ workspaceId, channelName: newChannelName });
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const workspaces = useWorkspaceQuery({ pageSize: 999 });
    const history = useHistory();
    const pushTo = useCallback(
        (path: string) => {
            history.push(path);
        },
        [history]
    );
    const { data: members, isLoading: isMembersLoading } = useQuery(
        ["members", workspaceId],
        () => fetchMembersByWorkspaceId(workspaceId),
        { enabled: !!workspaceId }
    );

    const [isInviteOpen, setIsInviteOpen] = useState(false);

    const handleOpenInvite = () => setIsInviteOpen(true);
    const handleCloseInvite = () => setIsInviteOpen(false);
    return (
        <Box
            sx={{
                height: '100%',
                width: drawerWidth,
                backgroundColor: "white",
                overflowY: "auto",
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Box sx={{ padding: 2 }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar variant="rounded" sx={{ width: 32, height: 32 }}>
                            {user?.userName?.charAt(0).toUpperCase() ?? "A"}
                        </Avatar>
                    </IconButton>
                </Tooltip>
                {user?.userName ?
                    <Button onClick={() => setPreferenceOpen(true)}>{user?.userName}</Button> : null
                }
                <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', marginTop: 2 }}>


                    <Button onClick={() => setPreferenceOpen(true)}>Preference</Button>
                    <Tooltip title="Invite New Member">
                    <Button onClick={handleOpenInvite}>Invite People to {workspaces?.data?.data.find((c: any) => c.id == workspaceId)?.name}</Button>
                    </Tooltip>
                </Box>
                <InviteMemberModal
                    workspaceId={workspaces?.data?.data.find((c: any) => c.id == workspaceId)?.id}
                    open={isInviteOpen}
                    setOpen={setIsInviteOpen}
                    name={workspaces?.data?.data.find((c: any) => c.id == workspaceId)?.name}
                    joinCode={workspaces?.data?.data.find((c: any) => c.id == workspaceId)?.joinCode}
                />
                <PreferenceModal initialValue={workspaces?.data?.data.find((c: any) => c.id == workspaceId)} open={preferenceOpen} setOpen={setPreferenceOpen} />
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{
                        paper: {
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                maxHeight: "70%",
                                overflowY: 'auto',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {
                        workspaces?.data?.data?.map((e: any) => (
                            <MenuItem key={e.id} onClick={() => pushTo(`/workspace/${e.id}`)} style={{ minWidth: "100px" }}>
                                <Avatar variant="rounded" sx={{ width: 32, height: 32, ml: 2 }}>{e?.name?.charAt(0).toUpperCase() ?? "A"}</Avatar>
                                {e?.name}
                            </MenuItem>
                        ))
                    }
                    <MenuItem onClick={() => setOpen(true)}>
                        <ListItemIcon>
                            <PersonAdd fontSize="small" />
                        </ListItemIcon>
                        Add a New Workspace
                    </MenuItem>
                </Menu>
            </Box>
            <Divider />
            <List>
                <ListItem button>
                    <ListItemIcon><InboxIcon /></ListItemIcon>
                    <ListItemText primary="Threads" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon><ChatIcon /></ListItemIcon>
                    <ListItemText primary="All DMs" />
                </ListItem>
                <ListItem button>
                    <ListItemIcon><GroupIcon /></ListItemIcon>
                    <ListItemText primary="Mentions & Reactions" />
                </ListItem>
            </List>
            <Divider />

            <List>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', marginTop: 2 }}>
                    <Typography sx={{ fontSize: 12, color: '#6c6c6c' }}>Channels</Typography>
                    <Button onClick={() => setChannelDialogOpen(true)} size="small">+ Add Channel</Button>
                </Box>
                {
                    !isLoading ? channels?.map(c => {
                        const displayName = c.name.startsWith("#") ? c.name : `# ${c.name}`;
                        return  <ListItem onClick={() => history.push(`/workspace/${workspaceId}/channel/${c.id}`)} button>
                            <ListItemText primary={`${displayName}`}/>
                        </ListItem>
                    }) : null
                }
            </List>
            <Divider />
            <List>
                <Typography sx={{ padding: 2, fontSize: 12, color: '#6c6c6c' }}>Direct Messages</Typography>
                {!isMembersLoading ? (
                    members?.map((member:any) => (
                        <ListItem onClick={() => pushTo(`/workspace/${workspaceId}/member/${member.id}`)} button key={member.userId}>
                            <ListItemIcon>
                                <Avatar variant={"rounded"} sx={{ width: 24, height: 24 }}>{(member?.fullName ?? "A")?.toUpperCase()?.charAt(0)}</Avatar>
                            </ListItemIcon>

                            <ListItemText primary={`${member.fullName}`} secondary={member.role} />
                        </ListItem>
                    ))
                ) : (
                    <Typography sx={{ padding: 2 }}>Loading members...</Typography>
                )}
            </List>

            <Dialog open={channelDialogOpen} onClose={() => setChannelDialogOpen(false)}>
                <DialogTitle>Create a New Channel</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Channel Name"
                        fullWidth
                        value={newChannelName}
                        onChange={(e) => {
                            setNewChannelName(e.target.value);
                            setNameError(false);  // Clear error when typing
                        }}
                        error={nameError}
                        helperText={nameError ? "Channel name is required." : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setChannelDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateChannel} disabled={createChannelMutation.isLoading}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Sidebar;
