import { useTheme } from '@mui/material/styles';
import { LightModeOutlined, ModeNightOutlined } from '@mui/icons-material';

import { ActionItem } from '../../Actions/ActionItem';
import { LIGHT_MODE_THEME } from '../../../helper/utils/constants';
import { useThemeModeContext } from '../../../contexts/ThemeModeContext';

export const ThemeSwitcher = ({ disableTooltip = false }: { disableTooltip?: boolean }) => {
	const theme = useTheme();
	const { toggleThemeMode } = useThemeModeContext()

	return (
		<ActionItem
			title='Toggle Theme'
			icon={theme.palette.mode === LIGHT_MODE_THEME ? LightModeOutlined : ModeNightOutlined}
			onClick={toggleThemeMode}
			disableTooltip={disableTooltip}
		/>
	);
};
