import * as yup from "yup";
import { IProgramSchema, programSchema } from "./program.schema";
import {
  IPromotionalReportSchema,
  promotionalReportSchema,
} from "./promotionalReport.schema";
import { IRecordSchema, recordSchema } from "./record.schema";
import { IStudentSchema, studentSchema } from "./student.schema";

export interface IEnrollmentSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  promotionalReportUuid?: string | null;
  promotionalReportId?: number;
  promotionalReport: IPromotionalReportSchema;
  programId: number;
  studentId: number;
  studentUuid?: string | null;
  yearLevel?: string | null;
  student: IStudentSchema;
  programUuid?: string | null;
  program: IProgramSchema;
  records: IRecordSchema[];
}

export const enrollmentSchema: yup.SchemaOf<IEnrollmentSchema> = yup
  .object()
  .shape({
    id: yup.number().required("Id is required"),
    uuid: yup.string().nullable(),
    dateCreated: yup.string().nullable(),
    createdBy: yup.string().nullable(),
    lastModifiedDate: yup.string().nullable(),
    lastModifiedBy: yup.string().nullable(),
    promotionalReportUuid: yup.string().nullable(),
    promotionalReportId: yup
      .number()
      .required("Promotional Report Id is required"),
    promotionalReport: yup.lazy(() => promotionalReportSchema),
    programId: yup.number().required("Program Id is required"),
    studentId: yup.number().required("Student Id is required"),
    studentUuid: yup.string().nullable(),
    yearLevel: yup.string().nullable(),
    student: yup.lazy(() => studentSchema),
    programUuid: yup.string().nullable(),
    program: yup.lazy(() => programSchema),
    records: yup.array().of(recordSchema),
  });

export type EnrollmentType = yup.InferType<typeof enrollmentSchema>;
