import { useMutation } from 'react-query';
import api from "../requests/config";

interface DownloadSOReportParams {
    selectedSchool: { id: number | undefined; name: string };
    dateFrom: Date | null;
    dateTo: Date | null;
}

const downloadSOReport = async ({ selectedSchool, dateFrom, dateTo }: DownloadSOReportParams) => {
    const response = await api.post(
        '/download/so-report',
        {
            schoolId: selectedSchool.id,
            dateFrom,
            dateTo,
        },
        {
            responseType: 'blob',  }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'SOReport.xlsx'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

};

export const useDownloadSOReportMutation = () => {
    return useMutation(downloadSOReport);
};
