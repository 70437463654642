import Axios from "axios";
import axios, {AxiosRequestConfig} from "axios";
import {
    ExcelCourseType,
    GetCourseSelectResponseType,
    GetCoursesResponseType,
    GetStudentResponseType,
    UploadCourseType,
} from "../types";
import api from "./config";

let cancelToken = Axios.CancelToken.source()
export const getCourses = async (params: AxiosRequestConfig["params"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get<GetCoursesResponseType>("/courses", {
        ...{cancelToken: cancelToken?.token},
        params
    });
    return res?.data;
};
export const getTranscriptOfRecords = async (params: AxiosRequestConfig["params"]) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get<GetStudentResponseType>(`/students/${params.studentId}/transcript-of-records`, {
        ...{cancelToken: cancelToken?.token},
        params
    });
    return res?.data;
};
export const getCoursesSelect = async () => {
  const res = await api.get<GetCourseSelectResponseType>("/courses/select");
  return res?.data;
};
export const postPrerequisiteByCourse = async (data: {courseId: number, prerequisitesCourseCodes: string[]}) => {
  const res = await api.post(`/courses/${data.courseId}/prerequisite`,  {}, {
  params:{
    ...data.courseId && {courseId: data.courseId},
    ...data.prerequisitesCourseCodes && {prerequisitesCourseCodes: data.prerequisitesCourseCodes.toString()},
  }
  });
  return res?.data;
};
export const getPrerequisiteByCourse = async (data: {courseId: number, prerequisitesCourseCodes: string[]}) => {
  if(!data.courseId) return
  const res = await api.get<{prerequisitesCourseCodes: string}>(`/courses/${data.courseId}/prerequisite`,   {
    params:{
      ...data.courseId && {courseId: data.courseId}
    }
  });
  return res?.data;
};

export const deleteCourse = async (id: number) => {
  const res = await api.post(`/courses/${id}`);
  return res?.data;
};

export const getCourse = async (id: number | string | undefined, params: any) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    if (!id) return
    const res = await api.get(`/courses/${id}`, {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data;
};

type CourseData = {
  schoolId: string;
  code: string;
  name: string;
  description: string;
  lecture: string;
  laboratory: string;
};

export const postCourse = async (data: CourseData) => {
  const res = await api.post("/courses", data, {
    params: {
      ...(data.schoolId != null && {schoolId: data.schoolId}),
    },
  });
  return res?.data;
};

export const patchCourse = async (id: number | undefined, data: any) => {
  if (!id) return
  const res = await api.patch("/courses/" + id, data, {
    params: {
      ...(data.schoolId != null && {schoolId: data.schoolId}),
    },
  });
  return res?.data;
};
export const postPrequisite = async (data: CourseData) => {
  const res = await api.post("/courses", data, {
    params: {
      ...(data.schoolId != null && {schoolId: data.schoolId}),
    },
  });
  return res?.data;
};

export const postCheckCourse = async (data: { curriculumName: string | number; prerequisiteCourseCodes: string[] }) => {
  const res = await api.post("/courses/check-courses", data);
  return res?.data;
};

export const uploadCourses = async (data: ExcelCourseType[]) => {
  const res = await api.post<UploadCourseType>("/courses/load-courses", data);
  return res?.data;
};

export const uploadCoursesCurriculum = async (data: ExcelCourseType[]) => {
    const res = await api.post<UploadCourseType>("/courses/load-courses-curriculum", data);
    return res?.data;
};

