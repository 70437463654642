/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { Box } from '@mui/material';

import { Navigation } from '../Navigation';
import { Header } from '../Header';

import { COLOR_SECONDARY } from '../../helper/utils/constants';

interface ILayout {
	children: React.ReactNode;
}

export const Layout: FC<ILayout> = ({ children }) => {
	const [open, setOpen] = useState(false);
	const toggleNavigation = () => setOpen((status) => !status);

	return (
		<div
			css={css`
				min-height: 100vh;
			`}
		>
			<div
				css={css`
					display: flex;
					min-height: calc(100vh - ${/*FOOTER_HEIGHT*/ 0}px);
				`}
			>
				<Box component='header'>
					<Header toggleNavigation={toggleNavigation} />
				</Box>
				<Navigation open={open} handleClose={toggleNavigation} />
				<Box
					component='main'
					sx={{ flexGrow: 1 }}
					css={css`
						background-color: ${COLOR_SECONDARY};
						
					`}
				>
					{children}
				</Box>
			</div>
			{/* <Box component='footer'>
				<Footer />
			</Box> */}
		</div>
	);
};
