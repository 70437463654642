import {Box, Chip, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions} from "mui-datatables";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDebounce} from "../hooks/useDebounce";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../redux/store";
import {useSchoolQuery} from "../hooks/useSchoolQuery";
import {useAddEntities} from "../hooks/useAddEntities";
import AddConfirmationDialog from "../components/dialogs/AddConfirmation.dialog";
import useDialog from "../hooks/useDialog";
import {
    addConfirmationDialogTitle,
    isOnApplyAll,
    setRowsSelected,
    setUploadRequirement,
    updatesSo,
    viewStudentIdTranscriptOfRecord
} from "../redux/features/specialOrder";
import {
    approveStudentsGroupByProgram,
    getAllStudentInfo,
    patchStudentsGroupByProgram,
    postStudentsGroupByProgram
} from "../requests/student.request";
import {useQueryClient} from "react-query";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import TranscriptOfRecordsDialog from "../components/dialogs/TranscriptOfRecords.dialog";
import {setTabValue} from "../redux/features/student";
import {setLoading} from "../redux/features/app";
import {useSpecialOrderGroupByStudentsQuery} from "../hooks/useSpecialOrderGroupByStudents";
import FileManagerDialog from "../components/dialogs/FileManager.dialog";
import {UploadIcon} from "lucide-react";
import WordIcon from "../svg/wordicon";
import PdfIcon from "../svg/pdf";
import {getElement} from "../helper/utils/formatting";
import {Attachment, CheckRounded} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import FilesPreviewDialog from "../components/dialogs/FilesPreview.dialog";
import soarLoading from "../assets/soar-loading.json";
import Lottie from "lottie-react";

export function SpecialOrderSchoolsPage(props: any) {
    const dispatch = useDispatch();
    const { schoolId, regionId, userInstitutionalName } = useAddEntities();
    const paymentGateway = useSelector((store: RootState) => store.specialOrder?.paymentGateway);
    const [selectedYearLevel, setSelectedYearLevel] = React.useState<any>({ name: "All", id: undefined });
    const user = useAppSelector((store) => store?.auth?.user);
    const [selectedRegionId, setSelectedRegionId] = React.useState<any>({
        id: undefined,
        name: "All"
    });
    const queryClient = useQueryClient();
    const specialOrderSchoolId = useSelector((store: RootState) => store?.specialOrder.specialOrderSchoolId)
    const specialOrderIdValue = useSelector((store: RootState) => store?.specialOrder.specialOrderIdValue)
    const specialOrderProgramId = useSelector((store: RootState) => store?.specialOrder.specialOrderProgramId)
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const type = useSelector((store: RootState) => store.auth?.user?.type)
    const sos = useSelector((store: RootState) => store.specialOrder?.sos)
    const rowsSelected = useSelector((store: RootState) => store.specialOrder?.rowsSelected)
    const paramsStatus = useSelector((store: RootState) => store.specialOrder?.paramsStatus)
    const specialOrderSchoolName = useSelector((store: RootState) => store.specialOrder?.specialOrderSchoolName)
    const [selectAllButton, setSelectAllButton] = React.useState<any>();
    const confirmationDialogTitle = useSelector((store: RootState) => store.specialOrder?.confirmationDialogTitle)
    const viewStateStudentIdTranscriptOfRecord = useSelector((store: RootState) => store.specialOrder?.viewStateStudentIdTranscriptOfRecord)
    const [searchProgram, setSearchProgram] = React.useState("");
    const [degree, setDegree] = useState<any>(0)
    const debouncedSearchProgram = useDebounce(searchProgram, 500);
    const roles = useAppSelector((store) => store?.auth?.user?.roles) as
        | "User"
        | "Admin"
        | "SuperAdmin";
    const [searchKey, setSearchKey] = React.useState<any>("");
    const debouncedSearchKey = useDebounce(searchKey, 500);
    const applySOOrApprove = (type?.id == 1 && roles.toLowerCase() == "admin" && (paramsStatus == null)) ? "Apply" : (type?.id == 2 && roles.toLowerCase() == "admin" && (paramsStatus == "pending")) ? "Approve" : ""
    const [tabIndex, setTabIndex] = React.useState(0);

    const typeMemo = useMemo(() => {

        return type?.id != 3 &&
            (
                (type?.id == 1 && roles.toLowerCase() == "admin" && (paramsStatus == null)) ||
                (type?.id == 2 && roles.toLowerCase() == "admin" && (paramsStatus == "pending"))
            )
    }, [type?.id, roles.toLowerCase(), tabIndex, paramsStatus])
    const chiefTypeMemo = useMemo(() => {

        return type?.id != 3 &&
            (
                (type?.id == 7 && roles.toLowerCase() == "admin" && (paramsStatus == 'approved'))
            )
    }, [type?.id, roles.toLowerCase(), tabIndex, paramsStatus])


    const {data: dataSchools} = useSchoolQuery({
        pageSize: "1000",
        search: '',
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
    });
    const [selectedSchool, setSelectedSchool] = React.useState<any>({name: "All", id: undefined});
    const [selectedProgram, setSelectedProgram] = React.useState<any>({name: "All", id: undefined});
    const [page, setPage] = React.useState(0);
    const {data: students, refetch, isLoading, isFetching} = useSpecialOrderGroupByStudentsQuery({
        pageSize: "100",
        search: debouncedSearchKey,
        ...specialOrderMajorIdValue && {majorId: specialOrderMajorIdValue},
        ...selectedRegionId?.id > 0 && {regionId: selectedRegionId?.id},
        ...((paramsStatus) && {status: paramsStatus}),
        ...((degree > 0 && degree != 2) && {levelId: degree}),
        ...(selectedYearLevel?.id > 0 && {yearLevel: selectedYearLevel?.id}),
        ...((specialOrderSchoolId > 0 || specialOrderSchoolId) && {schoolId: specialOrderSchoolId || schoolId}),
        ...((specialOrderProgramId > 0 || specialOrderProgramId) && {programId: specialOrderProgramId}),
        ...(selectedProgram?.name ? selectedProgram?.name != "All" && selectedProgram && {programName: selectedProgram?.name} : null),
        ...(page && {page: page + 1}),
    }, specialOrderMajorIdValue, specialOrderSchoolId, specialOrderProgramId, type?.id, roles.toLowerCase());
    const studentData = useMemo(() => {
        return students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0]
    }, [students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0]])

    /*useEffect(() => {
         if(studentData?.students?.length > 0 && (type?.id == 2 && roles.toLowerCase() == "admin" && (paramsStatus == "pending")) ){
             dispatch(setRowsSelected(studentData?.students?.map((c: any) => c.id) ))
         }
     }, [studentData?.students])
 */
    const options: MUIDataTableOptions = {
        filter: false,
        search: true,

        elevation: 0,
        textLabels: {
            body: {
                noMatch: isLoading || isFetching ? <Box display="flex" justifyContent="center" flexDirection={"column"}
                                                        alignItems="center" p={3}>
                    <Lottie style={{height: 80}} animationData={soarLoading}/>
                    <Typography variant="body1">
                        {isLoading || isFetching ? "Please wait while we load your content." : "Sorry, no matching records can be found."}
                    </Typography>
                </Box> : "Sorry, no matching records can be found."
            }
        },
        rowsSelected: rowsSelected ?? [],
        selectableRows: typeMemo ? "multiple" : "none",
        resizableColumns: false,
        rowsPerPage: 100,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

            return (
                <div style={{paddingRight: 6}}>
                    <Tooltip title={applySOOrApprove}>
                        <Button onClick={() => {

                            //handleSelectedOpenSpecialOrder()
                            dispatch(isOnApplyAll(true))
                            setSelectAllButton(() => () => setSelectedRows([]))
                            props?.handleNext()
                        }
                        } color={"success"} variant={"contained"}>{applySOOrApprove} All</Button>
                    </Tooltip>
                </div>

            )
        },


        onTableChange: (action, newTableState) => {
            switch (action) {

                case "search":
                    setSearchKey(newTableState.searchText);
                    break;
                case "rowSelectionChange":
                    rowSelectionChange(newTableState);
                    break;
                case "rowDelete":
                    handleSelectedOpenSpecialOrder()
                    break;

            }
        },
    };

    const rowSelectionChange = (newTableState: any) => {
        var _updates: any[] = []
        var _rowSelected: any[] = [];


        newTableState?.selectedRows?.data?.forEach((c: { index: number, dataIndex: number }) => {
            var _data = newTableState?.data?.[c.dataIndex]?.data
            if (_data?.[10]) {
                _rowSelected.push(c.dataIndex)
                _updates.push({
                    majorId: studentData?.majorId,
                    studentId: _data?.[1],
                    studentNumber: _data?.[5],
                    specialOrderId: _data?.[4],
                    schoolId: schoolId ?? _data?.[3],
                    completed: false,
                    programId: studentData?.programId,
                    name: `${_data?.[6] ?? ""} ${_data?.[7] ?? ""} ${_data?.[8] ?? ""} `
                })
            }
            return c
        })
        dispatch(setRowsSelected(_rowSelected))
        dispatch(updatesSo(_updates))
    }


    const {
        handleOpen: handleOpenSpecialOrder,
        handleClose: handleCloseSpecialOrder,
        open: openSpecialOrder,
    } = useDialog();





    const {
        handleOpen: handleSelectedOpenSpecialOrder,
        handleClose: handleSelectCloseSpecialOrder,
        open: openSelectedSpecialOrder,
    } = useDialog();


    const {
        handleOpen: handleOpenTranscriptOfRecord,
        handleClose: handleCloseTranscriptOfRecord,
        open: openTranscriptOfRecord,
    } = useDialog();


    const {
        handleOpen: handleSelectedOpenRequirementSpecialOrder,
        handleClose: handleSelectCloseRequirementSpecialOrder,
        open: openSelectedRequirementSpecialOrder,
    } = useDialog();


    function downloadURI(uri: string, name: string) {
        var link: any = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const schoolAdminColumns: MUIDataTableColumn[] = [
        {
            name: "soNumber",
            label: "SO Number",
            options: {
                display: paramsStatus == "approved",
                filter: false,
                sort: false,
            },
        },
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        }, {
            name: "programId",
            label: "ProgramId",
            options: {

                display: false,
                filter: false,
                sort: false,
            },
        }, {
            name: "schoolId",
            label: "SchoolId",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        }, {
            name: "specialOrderId",
            label: "SpecialOrderId",
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            name: "studentNumber",
            label: "Student Number",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        }, {
            name: "firstName",
            label: "First Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        },
        {
            name: "middleName",
            label: "Middle Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        },
        {
            name: "lastName",
            label: "Last Name",
            options: {
                display: true,
                filter: false,
                sort: true,
            },
        }, {
            name: "status",
            label: "Requirement Status",
            options: {
                display: paramsStatus != "approved" && !(paramsStatus == "pending" && type?.id == 1 && roles?.toLowerCase() == "admin"),
                filter: false,
                sort: true,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    return (
                        <div style={{flex: 1, display: "flex", flexDirection: "column",}}>
                            <div>
                                {value?.map((v: any) => {
                                    return <><Tooltip title={v?.name} arrow>
                                        <IconButton name={v?.name}>
                                            {getElement(v)}
                                        </IconButton>
                                    </Tooltip>
                                    </>
                                })
                                }
                            </div>
                        </div>
                    );
                },
            },
        },
        {
            name: "requirementCompleted",
            label: "Requirement Complete",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        },
        {
            name: "loading",
            label: "loading",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        },
        {
            name: "id",
            label: "Action",
            options: {

                filter: true,
                sort: true,
                customBodyRender: (value: any, tableMeta, updateValue: any) => {
                    const _programId =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "programId")
                            ];
                    const _studentNumber =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "studentNumber")
                            ];
                    const _requirementCompleted =
                        tableMeta.rowData[
                            schoolAdminColumns.findIndex((x) => x.name === "requirementCompleted")
                            ];
                    const _schoolId = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "schoolId")
                        ];
                    const specialOrderId = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "specialOrderId")
                        ];
                    const firstName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "firstName")
                        ];
                    const middleName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "middleName")
                        ];

                    const lastName = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "lastName")
                        ];
                    const _majorId = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "majorId")
                        ];

                    const _loading = tableMeta.rowData[
                        schoolAdminColumns.findIndex((x) => x.name === "loading")
                        ];
                    var studentDataIndex = students?.data?.[0]?.students.findIndex((c: any) => c.id == value)
                    var __studentData = students?.data?.[0]?.students[studentDataIndex]
                    var __status =   students?.data?.[0]?.status
                    return (
                        <div style={{flex: 1, display: "flex",}}>
                            <div style={{paddingRight: 6}}>
                                <LoadingButton loading={_loading} id={"view" + tableMeta?.rowIndex}
                                               onClick={async () => {
                                                   queryClient.setQueriesData('specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId, (oldData: any) => {
                                                       return {
                                                           ...oldData, data: oldData?.data?.map((sogbs: any) => {
                                                               sogbs.students = sogbs?.students?.map((sogbsStudents: any) => {
                                                                   if (sogbsStudents.id == value) {
                                                                       sogbsStudents.loading = true
                                                                   }

                                                                   return sogbsStudents
                                                               })
                                                               return sogbs
                                                           })
                                                       }
                                                   })

                                                   try {
                                                       await getAllStudentInfo(value, specialOrderSchoolId)
                                                       queryClient.setQueriesData('specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId, (oldData: any) => {
                                                           return {
                                                               ...oldData, data: oldData?.data?.map((sogbs: any) => {
                                                                   sogbs.students = sogbs?.students?.map((sogbsStudents: any) => {
                                                                       if (sogbsStudents.id == value) {
                                                                           sogbsStudents.loading = false
                                                                       }

                                                                       return sogbsStudents
                                                                   })
                                                                   return sogbs
                                                               })
                                                           }
                                                       })
                                                   } catch (e) {
                                                       queryClient.setQueriesData('specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId, (oldData: any) => {
                                                           return {
                                                               ...oldData, data: oldData?.data?.map((sogbs: any) => {
                                                                   sogbs.students = sogbs?.students?.map((sogbsStudents: any) => {
                                                                       if (sogbsStudents.id == value) {
                                                                           sogbsStudents.loading = false
                                                                       }

                                                                       return sogbsStudents
                                                                   })
                                                                   return sogbs
                                                               })
                                                           }
                                                       })
                                                   }

                                                   dispatch(setTabValue(1))
                                                   dispatch(viewStudentIdTranscriptOfRecord(value))
                                                   handleOpenTranscriptOfRecord()
                                               }} variant={"outlined"}>View</LoadingButton>
                            </div>

                            {__status == "approved" && paramsStatus == "approved"  && (students?.data?.[0]?.students?.[studentDataIndex]?.attachment != null && students?.data?.[0]?.students?.[studentDataIndex]?.attachment != "")   ? <>
                                {students?.data?.[0]?.students?.[studentDataIndex]?.soNumberWordFile ?
                                    <div style={{paddingRight: 6}}>
                                        <Button
                                            id={"download" + tableMeta?.rowIndex} onClick={() => {
                                            downloadURI(students?.data?.[0]?.students?.[studentDataIndex]?.soNumberWordFile, __studentData.firstName + __studentData.middleName + __studentData.lastName + __studentData.id + ".docx")
                                        }} variant={"outlined"}><WordIcon/></Button>
                                    </div> : null
                                }

                                {students?.data?.[0]?.students?.[studentDataIndex]?.soNumberFile ?
                                    <div style={{paddingRight: 6}}>
                                        <Button id={"download" + tableMeta?.rowIndex} onClick={() => {
                                            downloadURI(students?.data?.[0]?.students[studentDataIndex].soNumberFile, __studentData.firstName + __studentData.middleName + __studentData.lastName + __studentData.id + "pdf")
                                        }} variant={"outlined"}><PdfIcon/></Button>
                                    </div> : null
                                }

                                {students?.data?.[0]?.students?.[studentDataIndex]?.attachment ?
                                    <div style={{paddingRight: 6}}>
                                        <Button id={"download" + tableMeta?.rowIndex} onClick={() => {
                                            handlePreview(`Receipt No: ${firstName} ${middleName} ${lastName}`, students?.data?.[0]?.students?.[studentDataIndex]?.attachment)
                                            //downloadURI(students?.data?.[0]?.students[studentDataIndex].attachment, __studentData.firstName + __studentData.middleName + __studentData.lastName + __studentData.id + "pdf")
                                        }} variant={"outlined"}><Attachment/></Button>
                                    </div> : null
                                }

                            </> : paramsStatus == "approved" && (students?.data?.[0]?.students?.[studentDataIndex]?.attachment == null || students?.data?.[0]?.students?.[studentDataIndex]?.attachment == "") ? <Chip label="For Approval" color="warning" /> : null}


                            {typeMemo && !(type?.id == 2 && roles.toLowerCase() == "admin") ?
                                <div style={{paddingRight: 6}}>
                                    <Tooltip title={"Upload Requirements"}>
                                        <Button id={"view" + tableMeta?.rowIndex} onClick={() => {
                                            dispatch(setUploadRequirement({
                                                studentId: value,
                                                studentNumber: _studentNumber,
                                                specialOrderId: specialOrderId,
                                                schoolId: parseInt(schoolId?.toString()) ?? parseInt(_schoolId?.toString() ?? 0),
                                                programId: studentData?.programId,
                                                majorId: studentData?.majorId,
                                            }))
                                            handleSelectedOpenRequirementSpecialOrder()
                                        }} variant={"outlined"}><UploadIcon/></Button>
                                    </Tooltip>
                                </div> : null
                            }
                            {typeMemo && !(sos?.length > 0) ?
                                <div>
                                    <Tooltip title={applySOOrApprove}>
                                        <Button disabled={!_requirementCompleted} id={"so" + tableMeta?.rowIndex}
                                                onClick={async () => {
                                                    dispatch(addConfirmationDialogTitle((type?.id == 1 && roles.toLowerCase() == "admin" ? "You are applying a Special Order for " : "You are approving a Special Order for ") + firstName + " " + (middleName ?? "") + " " + lastName + " . Do you want to proceed?"))
                                                    let _updates: any[] = []
                                                    _updates.push({
                                                        majorId: studentData?.majorId,
                                                        studentId: value,
                                                        studentNumber: _studentNumber,
                                                        specialOrderId: specialOrderId,
                                                        schoolId: schoolId ?? _schoolId,
                                                        completed: false,
                                                        programId: studentData?.programId,
                                                        name: `${firstName ?? ""} ${middleName ?? ""} ${lastName ?? ""} `
                                                    })
                                                    dispatch(updatesSo(_updates))
                                                    handleOpenSpecialOrder()
                                                }
                                                } color={"success"} variant={"contained"}><CheckRounded/></Button>
                                    </Tooltip>
                                </div> : null}



                            {  chiefTypeMemo ?
                                <div>
                                    <Tooltip title={applySOOrApprove}>
                                        <Button disabled={__status == "approved"} id={"so" + tableMeta?.rowIndex}
                                                onClick={async () => {
                                                    dispatch(addConfirmationDialogTitle((type?.id == 1 && roles.toLowerCase() == "admin" ? "You are applying a Special Order for " : "You are approving a Special Order for ") + firstName + " " + (middleName ?? "") + " " + lastName + " . Do you want to proceed?"))
                                                    let _updates: any[] = []
                                                    _updates.push({
                                                        majorId: studentData?.majorId,
                                                        studentId: value,
                                                        studentNumber: _studentNumber,
                                                        specialOrderId: specialOrderId,
                                                        completed: false,
                                                        programId: studentData?.programId,
                                                        name: `${firstName ?? ""} ${middleName ?? ""} ${lastName ?? ""} `
                                                    })

                                                    dispatch(updatesSo(_updates))
                                                    handleOpenSpecialOrder()
                                                }
                                                } color={"success"} variant={"contained"}><CheckRounded/></Button>
                                    </Tooltip>
                                </div> : null}

                        </div>

                    );
                },
            },
        },
    ];


    useEffect(() => {
        if (dataSchools?.data && user?.school?.id) {
            setSelectedSchool(user?.school?.id ? dataSchools?.data?.find(c => c.id == user?.school?.id) : {
                name: "All",
                id: 0
            });
        } else if (!selectedSchool) {
            setSelectedSchool({name: "All", id: undefined})
        }
    }, [dataSchools]);

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])


    async function handleSelectSubmit(_sos: any[]) {
        dispatch(setLoading(true))
        for (const __sos of _sos) {
            if (type?.id == 1 && roles.toLowerCase() == "admin") {
                var _patchStudentsGroupByProgram = await postStudentsGroupByProgram(__sos, paymentGateway)
                queryClient.setQueriesData('pending-count', (oldData: any) => {
                    return _patchStudentsGroupByProgram.soApprovedCount
                })
                setSnackbarStore({
                    message: 'Application successfully applied!', severity: 'success', open: true
                })
            } else if ((type?.id == 2 || type?.id == 4) && roles.toLowerCase() == "admin") {
                var _patchStudentsGroupByProgram = await patchStudentsGroupByProgram(__sos)

                queryClient.setQueriesData('pending-count', (oldData: any) => {
                    return _patchStudentsGroupByProgram.soPendingCount
                })
                queryClient.setQueriesData('soapprovedCount', (oldData: any) => {
                    return _patchStudentsGroupByProgram.soApprovedCount
                })
                queryClient.setQueriesData('studentsGroupByProgram', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((student: any) => {
                            if (student.id === _patchStudentsGroupByProgram.data.id) {
                                student.status = _patchStudentsGroupByProgram.data.status
                                student.isActive = _patchStudentsGroupByProgram.data.isActive
                                return student
                            }
                            return student
                        })
                    }
                })
                setSnackbarStore({
                    message: 'Application successfully  for approved!', severity: 'success', open: true
                })
            } else if (type?.id == 7 && roles.toLowerCase() == "admin") {
                var _approvedStudentsGroupByProgram = await approveStudentsGroupByProgram(__sos)

                queryClient.setQueriesData('pending-count', (oldData: any) => {
                    return _approvedStudentsGroupByProgram.soPendingCount
                })
                queryClient.setQueriesData('soapprovedCount', (oldData: any) => {
                    return _approvedStudentsGroupByProgram.soApprovedCount
                })
                queryClient.setQueriesData('studentsGroupByProgram', (oldData: any) => {
                    return {
                        ...oldData, data: oldData?.data?.map((student: any) => {
                            if (student.id === _approvedStudentsGroupByProgram.data.id) {
                                student.status = _approvedStudentsGroupByProgram.data.status
                                student.isActive = _approvedStudentsGroupByProgram.data.isActive
                                return student
                            }
                            return student
                        })
                    }
                })
                setSnackbarStore({
                    message: 'Application successfully approved!', severity: 'success', open: true
                })
            }
            queryClient.setQueriesData(
                `studentsGroupByProgram`,
                (oldData: any) => {
                    return {
                        ...oldData,
                        data: oldData?.data?.filter(
                            (t: any) => !(t.id == __sos.studentId &&
                                t.schoolId == __sos.schoolId &&
                                t.programId == __sos.programId)
                        ),
                    };
                }
            );
            dispatch(setLoading(false))
        }
    }

    const handleClose = () => {
        dispatch(updatesSo([]))
        dispatch(addConfirmationDialogTitle(""))
        handleCloseSpecialOrder()
    }

    const [title, setTitle] = useState("")
    const [preview, setPreview] = useState("")
    const titleMemo = useMemo(() => {
        return <div style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div style={{display: "flex", flex: 1}}>
                <Typography
                    variant={"h6"}>{(selectedSchool?.name != "All" ? selectedSchool?.name : specialOrderSchoolName) || ""}</Typography>
            </div>
        </div>
    }, [])
    const {
        handleOpen: handleOpenPreview,
        handleClose: handleClosePreview,
        open: openPreview,
    } = useDialog();
    const filesPreviewHandleClose = () => {
        setTitle("")
        setPreview("")
        handleClosePreview()
    }

    function handlePreview(_name: string, _preview: string) {
        setTitle(_name)
        setPreview(_preview)
        handleOpenPreview()
    }

    return <>
        <Helmet>
            <title>Special Orders</title>
        </Helmet>
        <Box
            component="main"
            sx={{pt: 2}}

        >
            <Paper>
                <MUIDataTable
                    options={options}
                    title={titleMemo}
                    data={(students?.data?.find((c: any) => c.id == specialOrderIdValue) ?? students?.data?.[0])?.students as any}
                    columns={schoolAdminColumns}
                />
            </Paper>
            <TranscriptOfRecordsDialog
                open={openTranscriptOfRecord}
                handleClose={handleCloseTranscriptOfRecord}
                onSave={async () => {

                    /* var _programId = specialOrderProgramId;
                     var _____files = [...files]
                     var retry = 0
                     let body: IPostFileUploadsSpecialOrderRequirementFiles = {
                         formFiles: [],
                         specialOrderRequirementId: 0,
                         specialOrderRequirementsName: 0,
                         studentNumber: "",
                         schoolId: 0,
                         programId: 0,
                         fileUploadType: 1,
                         majorId: 0,
                         uploadedBy: user.id,
                         studentId: 0
                     };
                     for (const c of _____files) {
                         body = {
                             formFiles: c.files,
                             specialOrderRequirementId: c.id,
                             specialOrderRequirementsName: c.name,
                             studentNumber: 0 ,
                             schoolId: +schoolId,
                             programId: +_programId,
                             fileUploadType: 1,
                             majorId: specialOrderMajorIdValue,
                             uploadedBy: user.id,
                             studentId: viewStateStudentIdTranscriptOfRecord
                         };
                         try {
                             body.programId = _programId

                             if (c.files?.filter((c: any) => !c?.id && !c.schoolId && !c.programId)?.length > 0) {

                                 var _PostFileUploadsSpecialOrderRequirementFiles = await PostFileUploadsSpecialOrderRequirementFiles(body)

                                 _____files = _____files.map((file: any) => {

                                     if (c?.id == file?.id) {
                                         var _______file = JSON.parse(JSON.stringify(file))
                                         _______file.files = _______file?.files?.filter((c: any) => c.id)
                                         _PostFileUploadsSpecialOrderRequirementFiles.data?.forEach((____file: any) => {
                                             _______file?.files.push({
                                                 schoolId: schoolId,
                                                 programId: specialOrderProgramId,
                                                 studentNumber: 0,
                                                 preview: ____file
                                             })
                                         })
                                     }

                                     return _______file
                                 })
                                 setTimeout(() => {
                                 }, 10000)


                             }

                         } catch (e) {

                             setErrorSnackbar(e, setSnackbarStore);
                         }


                     }
                     var __queryKey =  'specialOrderGroupByStudents' + specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId
                     queryClient.setQueriesData(__queryKey, (oldData: any) => {
                         return {
                             ...oldData, data: oldData?.data?.map((sogbs: any) => {
                                 sogbs.students = sogbs?.students?.map((sogbsStudents: any) => {

                                     sogbsStudents.status = sogbsStudents.status.map((sogbsStudentsStatus: any) => {
                                         var filesIndex = files.findIndex((file: any) => file?.id == (sogbsStudentsStatus?.id))
                                         if (filesIndex > -1 && !sogbsStudentsStatus?.isDeleted && sogbsStudents.id == viewStateStudentIdTranscriptOfRecord && files?.[filesIndex]?.files?.length > 0 && sogbsStudents.id == viewStateStudentIdTranscriptOfRecord) {
                                             sogbsStudentsStatus.status = true
                                         }
                                         return sogbsStudentsStatus
                                     })

                                     sogbsStudents.requirementCompleted = sogbsStudents.status.length == sogbsStudents.status.filter((c: any) => c.status == true)?.length
                                     return sogbsStudents
                                 })
                                 return sogbs
                             })
                         }
                     })*/
                }}
                title={"Transcript Of Records"}
                studentId={viewStateStudentIdTranscriptOfRecord}/>
            <AddConfirmationDialog isLoading={isLoading} title={confirmationDialogTitle}
                                   open={openSpecialOrder}
                                   handleClose={handleClose}
                                   onSave={() => {
                                       if (type?.id == 2) {
                                           handleCloseSpecialOrder()
                                       }
                                       props?.handleNext()
                                   }}></AddConfirmationDialog>


            <AddConfirmationDialog isLoading={isLoading} title={"Are you sure you want to submit the selected item ?"}
                                   open={openSelectedSpecialOrder}
                                   handleClose={() => {
                                       selectAllButton([])

                                       handleSelectCloseSpecialOrder()
                                   }
                                   }
                                   onSave={() => {
                                       selectAllButton([])
                                       handleSelectSubmit(sos)
                                   }}></AddConfirmationDialog>


            <FilesPreviewDialog open={openPreview}
                                handleClose={filesPreviewHandleClose}
                                preview={preview}
                                title={title}/>

            <FileManagerDialog title={"Requirements"}
                               open={openSelectedRequirementSpecialOrder}
                               handleClose={() => {

                                   handleSelectCloseRequirementSpecialOrder()
                               }
                               }
                               onSave={() => {

                               }}></FileManagerDialog>

        </Box>

    </>
}
