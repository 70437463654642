import {AxiosRequestConfig} from 'axios';
import {useQuery} from "react-query";
import {getSpecialOrderGroupByStudents} from "../requests/special-order.request";
import {getRequirements, getSoRequirements, getStudentSoRequirements} from "../requests/requirements.request";

export const useSpecialOrderGroupByStudentsQuery = (params: AxiosRequestConfig['params'], specialOrderMajorId: string | number | undefined, specialOrderIdValue: string | number | undefined, specialOrderProgramIdValue: string | number | undefined, type?: string | number | undefined, role?: any) => {
    return useQuery(["specialOrderGroupByStudents" + specialOrderMajorId + specialOrderIdValue + specialOrderProgramIdValue, params, params.status, params.regionId, params.schoolId, params.majorId, params.programId], async () => {
        var oldData = await getSpecialOrderGroupByStudents(params)
        if (oldData?.data) {
            if (params.status != "approved" && !((params.status == "pending") && type == 1 && role?.toLowerCase() == "admin")) {
                for await (const student of oldData?.data) {
                    for await (const s of student?.students) {
                        var gsr = await getSoRequirements(student.programId, student.schoolId),
                            gr = await getRequirements(),
                            soRequirements = [],
                            mergeArray: any[] = []
                        gr.data = gr?.data?.filter((c: any) => {
                            return c.isRequired
                        })
                        if (student.programId && student.schoolId) {
                            soRequirements = gsr?.data.map((gsrData: any) => {
                                gsrData.id = gsrData.specialOrderRequirementId ?? gsrData.id
                                return gsrData
                            })
                            mergeArray = [...gr?.data, ...gsr?.data]
                            var gssr = await getStudentSoRequirements(
                                student.programId,
                                student.schoolId,
                                s.studentNumber,
                                (student?.majorId ?? specialOrderMajorId),
                                s.id
                            )
                            mergeArray = mergeArray.map((c: any) => {
                                const findIndex = gssr?.data?.findIndex((gssrItem: any) => gssrItem.specialOrderRequirementId == c.id)
                                if (findIndex > -1) {
                                    c.status = true
                                } else {
                                    c.status = false
                                }
                                return c
                            })
                        }
                        s.requirementCompleted = mergeArray.length == mergeArray?.filter(c => c.status == true)?.length
                        s.status = mergeArray
                        s.loading = false
                        s.isPaid = true
                    }
                }
            }
        }
        return {
            ...oldData, data: oldData?.data
        }
    }, {
        refetchOnWindowFocus: false,
    });
}
