import {ClassNameMap} from '@material-ui/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import {
    GmailTabItemClassKey,
    GmailTabItemStyleProps,
    GmailTabsClassKey,
    GmailTabsStyleProps,
    tabItemStyles,
    tabsStyles,
} from './gmailTabs.styles';

export {tabsStyles as gmailTabsStyles, tabItemStyles as gmailTabItemStyles};

// @ts-ignore
export const useGmailTabsStyles: (
    props?: GmailTabsStyleProps
) => ClassNameMap<GmailTabsClassKey> = makeStyles(tabsStyles);

// @ts-ignore
export const useGmailTabItemStyles: (
    props?: GmailTabItemStyleProps
) => ClassNameMap<GmailTabItemClassKey> = makeStyles(tabItemStyles);