import * as Yup from "yup";
import {
  curriculumSchoolSchema,
  ICurriculumSchoolSchema,
} from "./curriculumSchool.schema";
import {
  curriculumSubjectSchema,
  ICurriculumSubjectSchema,
} from "./curriculumSubjectSchema.schema";
import { IProgramSchema, programSchema } from "./program.schema";
export interface ICurriculumSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name?: string | null;
  programId?: number | null;
  program: IProgramSchema;
  isDeleted: boolean;
  curriculumSubjects: ICurriculumSubjectSchema[];
  curriculumSchools: ICurriculumSchoolSchema[];
}

export const curriculumSchema: Yup.SchemaOf<ICurriculumSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    name: Yup.string().nullable(),
    programId: Yup.number().nullable(),
    program: Yup.lazy(() => programSchema),
    isDeleted: Yup.boolean().required(),
    curriculumSubjects: Yup.array(Yup.lazy(() => curriculumSubjectSchema)),
    curriculumSchools: Yup.array(Yup.lazy(() => curriculumSchoolSchema)),
  });

export type CurriculumType = Yup.InferType<typeof curriculumSchema>;

export const curriculumInputSchema = Yup.object().shape({
  schoolId: Yup.string().required("School Id is required"),
  programId: Yup.string().required("Program Id is required"),
  name: Yup.string().required("Name is required"),
});

export type CurriculumInputType = Yup.InferType<typeof curriculumInputSchema>;
