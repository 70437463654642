import { IRegionSchema, regionSchema } from "./region.schema";
import * as Yup from "yup";
import { IProvinceSchema, provinceSchema } from "./province.schema";
import {
  IProgramSchoolSchema,
  programSchoolSchema,
} from "./programSchool.schema";
import { academicYearSchema, IAcademicYearSchema } from "./academicYear.schema";
import { IUserSchema, userSchema } from "./user.schema.type";
import {
  ISchoolStudentSchema,
  schoolStudentSchema,
} from "./schoolStudent.schema";

export interface ISchoolSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  name: string;
  address?: string | null;
  provinceId?: number | null;
  provinceUuid?: string | null;
  province: IProvinceSchema;
  region: IRegionSchema;
  users: IUserSchema[];
  regionId?: number | null;
  institutionalCode?: string | null;
  institutionalFormOfOwnership?: string | null;
  institutionalType?: string | null;
  institutionalTypeId?: number | null;
  street?: string | null;
  municipality?: string | null;
  zipcode?: string | null;
  fax?: string | null;
  institutionalHeadTelephone?: string | null;
  yearEstablished?: string | null;
  secRegistration?: string | null;
  approvedDate?: string | null;
  yearConvertedToCollegeStatus?: string | null;
  yearConvertedToUniversityStatus?: string | null;
  nameofInstitutionalHead?: string | null;
  titleofHeadofInstitution?: string | null;
  highestEducationalAttainmentoftheHead?: string | null;
  approvedBy?: string | null;
  description?: string | null;
  image?: string | null;
  website?: string | null;
  phone?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  status?: string | null;
  program_Schools: IProgramSchoolSchema[];
  schoolStudents: ISchoolStudentSchema[];
  academicYears: IAcademicYearSchema[];
}

export const schoolSchema: Yup.SchemaOf<ISchoolSchema> = Yup.object().shape({
  id: Yup.number().required("Id is required"),
  uuid: Yup.string().nullable(),
  dateCreated: Yup.string().nullable(),
  lastModifiedDate: Yup.string().nullable(),
  lastModifiedBy: Yup.string().nullable(),
  name: Yup.string().required("Name is required"),
  address: Yup.string().nullable(),
  provinceId: Yup.number().nullable(),
  provinceUuid: Yup.string().nullable(),
  province: provinceSchema,
  region: regionSchema,
  users: Yup.array(Yup.lazy(() => userSchema)),
  regionId: Yup.number().nullable(),
  institutionalCode: Yup.string().nullable(),
  institutionalFormOfOwnership: Yup.string().nullable(),
  institutionalType: Yup.string().nullable(),
  institutionalTypeId: Yup.number().nullable(),
  street: Yup.string().nullable(),
  municipality: Yup.string().nullable(),
  zipcode: Yup.string().nullable(),
  fax: Yup.string().nullable(),
  institutionalHeadTelephone: Yup.string().nullable(),
  yearEstablished: Yup.string().nullable(),
  secRegistration: Yup.string().nullable(),
  approvedDate: Yup.string().nullable(),
  yearConvertedToCollegeStatus: Yup.string().nullable(),
  yearConvertedToUniversityStatus: Yup.string().nullable(),
  nameofInstitutionalHead: Yup.string().nullable(),
  titleofHeadofInstitution: Yup.string().nullable(),
  highestEducationalAttainmentoftheHead: Yup.string().nullable(),
  approvedBy: Yup.string().nullable(),
  description: Yup.string().nullable(),
  image: Yup.string().nullable(),
  website: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  latitude: Yup.string().nullable(),
  longitude: Yup.string().nullable(),
  status: Yup.string().nullable(),
  program_Schools: Yup.array().of(programSchoolSchema),
  schoolStudents: Yup.array().of(schoolStudentSchema),
  academicYears: Yup.array().of(academicYearSchema),
});

export type SchoolType = Yup.InferType<typeof schoolSchema>;
