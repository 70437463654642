// create slice for app
import { ReactNode } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPromotionalReportSlice {
  loading: boolean;
  isLockedDialog: Partial<IsLockedDialog>;
}

type IsLockedDialog = {
  open: boolean;
  data: Partial<{
    isLocked: any;
    id: any;
    title: string;
    body: string;
    content: ReactNode;
    handleIsLocked: any;
  }>;
};
const initialState: IPromotionalReportSlice = {
  loading: false,
  isLockedDialog: {
    open: false,
    data: {
      isLocked: false,
      id: null,
      title: "IsLocked item",
      content: null,
      handleIsLocked: null,
    },
  },
};

const appSlice = createSlice({
  name: "promotionalReport",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      return state;
    },
    setIsLockedDialog: (
      state,
      action: PayloadAction<IPromotionalReportSlice["isLockedDialog"]>
    ) => {
      state.isLockedDialog = action.payload;

      return state;
    },
  },
});

export const { setLoading, setIsLockedDialog } = appSlice.actions;
export default appSlice.reducer;
