import axios, {AxiosRequestConfig} from "axios";
import {StudentEvaluationType} from "../schemas/student.schema";
import {GetStudentsGroupByNameResponseType, GetStudentsResponseType} from "../types";
import api from "./config";

export const getStudents = async (params: AxiosRequestConfig["params"]) => {
  const res = await api.get<GetStudentsResponseType>("/students", {params});
  return res?.data;
};

export const getStudentsGroupByProgram = async (params: AxiosRequestConfig["params"]) => {
  const res = await api.get<GetStudentsGroupByNameResponseType>("/group-by-program", {params});
  return res?.data;
};


export const postStudentsGroupByProgram = async (data: {
    studentId: number,
    schoolId: number,
    programId: number
}, paymentGateway: string) => {
    const res = await api.post("/special-orders", {...data, paymentGateway});
    return res?.data;
};

export const patchStudentsGroupByProgram = async (data: {
    specialOrderId?: any;
    studentId: any;
    schoolId: any;
    programId: any
}) => {
    if (data.specialOrderId == null) return
    const res = await api.patch("/special-orders/" + data.specialOrderId, data);
    return res?.data;
};

export const approveStudentsGroupByProgram = async (data: {
    specialOrderId?: any;
    studentId: any;
    schoolId: any;
    programId: any
}) => {
    if (data.specialOrderId == null) return
    const res = await api.patch("/special-orders/approved/" + data.specialOrderId, data);
    return res?.data;
};
let cancelToken = axios.CancelToken.source();
export const getStudent = async (id: number, schoolId?: any) => {

    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const res = await api.get<any>(`/students/${id}`, {
        ...{cancelToken: cancelToken?.token},
        params: {
            ...schoolId && {schoolId: schoolId}
        }
    });
    return res?.data;
};

export const getAllStudentInfo = async (id: number, schoolId?: any) => {
    let endpoints = [
        `/students/${id}?schoolId=${schoolId}`,
        `/students/${id}/evaluation?schoolId=${schoolId}`,
        `/students/${id}/transcript-of-records?schoolId=${schoolId}`
    ];
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    var requests: any = {students: [], evaluations: [], tor: []}

    const res = await axios.all(endpoints.map((endpoint) => api.get(endpoint)),).then(
        axios.spread((students, evaluations, tor) => {
            requests.students = students
            requests.evaluations = evaluations
            requests.tor = tor
        })
    );
    return requests;
};

export const postStudent = async (data: any) => {
    const res = await api.post<any>(`/students`, data);
    return res?.data;
};

export const deleteStudent = async (id: any) => {
    const res = await api.post<any>(`/students/${id}`);
    return res?.data;
};

export const getGraduatesCount = async (startDate?: any, endDate?: any, regionId?: any, selectedSemester?: any, selectedSchoolYear?: any, rest?: any) => {

    const res = await api({
        url: `/dashboard/graduates-count`,
        params: {
            ...rest && {...rest},
            ...regionId > 0 && {regionId: regionId},
            ...selectedSemester && {semester: selectedSemester},
            ...selectedSchoolYear && {schoolYear: selectedSchoolYear},
            ...startDate && {dateStart: startDate},
            ...endDate && {dateEnd: endDate},
        },
        method: "GET",
    });

    return res?.data?.data;
};

export const getV2GraduatesCount = async (startDate?: any, endDate?: any, regionId?: any, selectedSemester?: any, selectedSchoolYear?: any, rest?: any) => {

    const res = await api({
        url: `/dashboard/v2/graduates-count`,
        params: {
            ...rest && {...rest},
            ...regionId > 0 && {regionId: regionId},
            ...selectedSemester && {semester: selectedSemester},
            ...selectedSchoolYear && {schoolYear: selectedSchoolYear},
            ...startDate && {dateStart: startDate},
            ...endDate && {dateEnd: endDate},
        },
        method: "GET",
    });

    return res?.data?.data;
};

export const getSchoolStudents = async (
    params: AxiosRequestConfig["params"]
) => {
    const res = await api.get<GetStudentsResponseType>(
        `schools/${params.schoolId}/students`,
        {params}
    );
    return res?.data;
};
export const getEnrolleesCount = async (startDate: any, endDate: any, regionId: any, selectedSemester: any, selectedSchoolYear: any ) => {
  if (!(startDate && endDate)) {
    return;
  }
  const res = await api({
    url: `/dashboard/enrollees-count`,
    params: {
      ...regionId > 0 && {regionId: regionId},
      ...selectedSemester > 1 && { semester: selectedSemester },
      ...selectedSchoolYear  > 0 && { schoolYear: selectedSchoolYear },
      ...{ dateStart: startDate },
      ...{ dateEnd: endDate },
    },
    method: "GET",
  });
  return res?.data;
};

export const getStudentEvaluation = async (id: number | string, schoolId?: number | string, regionId?: number | string) => {
    const res = await api.get(
        `/students/${id}/evaluation`, {
            params: {
                ...schoolId && {schoolId: schoolId},
                ...regionId && {regionId: regionId}
            }
        }
    );
    return res?.data;
};


export const patchStudent = async (id: number, data: any) => {
  const res = await api.patch<StudentEvaluationType>(
      `/students/${id}`, data
  );
  return res?.data;
};

