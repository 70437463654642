import {useQuery} from 'react-query';
import {getLevel} from "../requests/level.request";


export const useLevelQuery = () => {
    return useQuery(['level'], ({signal}) => getLevel(signal), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}
