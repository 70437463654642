import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import {useTheme} from "@mui/material/styles";
import {Box, IconButton} from "@mui/material";
import {FirstPageRounded, KeyboardArrowLeft, KeyboardArrowRight, LastPageRounded} from "@mui/icons-material";
import {useAppSelector} from "../../redux/store";

export function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 1);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(1, Math.ceil(count / rowsPerPage) - 1));
    };
    const roles = useAppSelector((store) => store?.auth?.user?.roles);

    return (
       true   ? <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                id={"handleFirstPageButtonClick"}
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageRounded /> : <FirstPageRounded />}
            </IconButton>
            <IconButton
                id={"handleBackButtonClick"}
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                id={"handleNextButtonClick"}
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                id={"handleLastPageButtonClick"}
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageRounded /> : <LastPageRounded />}
            </IconButton>
        </Box> : null
    );
}
