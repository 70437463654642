import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation } from 'react-query';
import { getStudentSoRequirements } from '../../requests/requirements.request';
import { ImageList, ImageListItem, DialogContent as MuiDialogContent } from '@mui/material';

interface IProps {
    open: boolean;
    handleClose: () => void;
    onSave: () => void;
    programId: any;
    schoolId: any;
    studentNumber: any;
    majorId: any;
    studentId: any;
}

interface IError {
    message: string;
}

export const ReviewDialog = React.memo((props: IProps) => {
    const { open, onSave, handleClose, programId, schoolId, studentId, studentNumber, majorId } = props;

    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const { mutate, data, isLoading, error } = useMutation(
        async () => {
            return await getStudentSoRequirements(programId, schoolId, studentNumber, majorId, studentId);
        }
    );

    useEffect(() => {
        if (open) {
            mutate();
        }
    }, [open, mutate]);

    const handleImageClick = (url: string) => {
        setSelectedImage(url);
    };

    const handlePreviewClose = () => {
        setSelectedImage(null);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Student Requirements"}
                </DialogTitle>
                <DialogContent>
                    {isLoading ? (
                        <DialogContentText id="alert-dialog-description">
                            Loading...
                        </DialogContentText>
                    ) : error ? (
                        <DialogContentText id="alert-dialog-description">
                            Error: {(error as IError)?.message}
                        </DialogContentText>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                                {data?.data?.map((item: any) => (
                                    <ImageListItem key={item.url} onClick={() => handleImageClick(item.url)}>
                                        <img
                                            srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                                            loading="lazy"
                                            alt=""
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={onSave} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={selectedImage !== null}
                onClose={handlePreviewClose}
                aria-labelledby="image-preview-dialog-title"
                aria-describedby="image-preview-dialog-description"
            >
                <DialogTitle id="image-preview-dialog-title">
                    {"Image Preview"}
                </DialogTitle>
                <MuiDialogContent>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Preview"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </MuiDialogContent>
                <DialogActions>
                    <Button onClick={handlePreviewClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
});