import * as React from "react";
const CourseSvg = (props: any) => (
    <svg
        fill="#ffff"
        width="31px"
        height="31px"
        viewBox="0 0 74.4 74.4"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0 74.396h56.88V17.515H0v56.88ZM52.505 21.891v48.129H4.375V21.891zM74.4 0v56.885h-13.126v-4.38h8.751V4.375H21.895V13.126H17.515V0zM13.126 39.406h30.628V35.031H13.126zm0 17.479h30.628v-4.38H13.126zm0 -8.742h21.877v-4.375H13.126z"
            fillRule="evenodd"
        />
    </svg>
);
export default CourseSvg;
