import {useQuery} from 'react-query';
import {useCallback} from "react";
import {ProgramResponseType} from "../types";
import flat from "flat";
import {getMajorByNameList} from "../requests/major.request";

export const useSearchMajorQuery = (str: string, schoolId: any, programId?: any) => {
    return useQuery(['majors', str, schoolId?.regionId, schoolId?.programId, schoolId?.schoolId, schoolId?.pageSize, schoolId?.page, programId], ({signal}) => getMajorByNameList(str, signal, schoolId), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: useCallback((data: ProgramResponseType) => {
            return (
                {
                    ...data,
                    data: data?.data.map(el => ({...el, ...flat(el, {safe: true}) as object})) // flatten the object // not type-safe
                }
            )
        }, []),
    });
}
