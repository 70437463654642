import axios, {AxiosRequestConfig} from "axios"
import {GetSchoolResponseType, SchoolType} from "../types"
import api from "./config"

export const getSchools = async (queryParams: AxiosRequestConfig) => {
    const res = await api.get<GetSchoolResponseType>("/schools", {params: queryParams})
    return res?.data
}


type SchoolData = {
    schoolId: string,
    schoolName: string,
    schoolAddress: string,
}

export const postSchools = async (data: SchoolData) => {
    const res = await api.post("/schools", data, {
        params:{
            postCount: Math.random()
        }
    })
    return res?.data
}

export const deleteSchool = async (id: number) => {
    if(id == null){
        return
    }
    const res = await api.post(`/schools/${id}`)
    return res?.data
}
let cancelToken = axios.CancelToken.source();
export const getSchoolsByInstituteCode = async (instituteCode: string, signal: AxiosRequestConfig["signal"], regionId?: number | string | undefined) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/schools/${instituteCode}`, {
        signal,
        params: {
            ...{cancelToken: cancelToken?.token},
            ...regionId && {regionId: regionId}
        }
    });
    return res?.data
}
export const getSchoolsById = async (instituteCode: string | null | undefined) => {
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    if(!instituteCode) return
    const res = await api.get(`/schools/${instituteCode}`, {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data
}


export const getSchoolById = async (id: string | null | undefined) => {
   if (!id) return
    if (typeof cancelToken != typeof undefined) {
        cancelToken?.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios.CancelToken.source()
    const res = await api.get(`/schools/id/${id}`, {
        ...{cancelToken: cancelToken?.token},
    });
    return res?.data
}
export const getSchoolsExportEFormA = async (schoolId?: any) => {
    const res = await api({
        url: `/schools/export/e-form-a`,
        method: 'GET',
        responseType: 'blob',
        params: {
            ...(schoolId && {schoolId: schoolId})
        }
    });
    return res?.data
}
export const getSchoolsExportEFormBC = async (level?: string, schoolId?: any, regionId?: any) => {
    const res = await api({
        url: `/schools/export/e-form-b-c`,
        method: 'GET',
        responseType: 'blob',
        params: {
            ...(schoolId && {schoolId: schoolId}),
            ...(level && {level: level}),
            ...(regionId && {regionId: regionId})
        }
    });
    return res?.data
}

export const getSchoolsExportPrcListOfGraduates = async (schoolId?: any) => {
    const res = await api({
        url: `/schools/export/prc-list-of-graduates`,
        method: 'GET',
        responseType: 'blob',
        params:{
            ...(schoolId && {schoolId: schoolId}),
        }});
    return res?.data
}
export const updateSchoolStatus = async (id: number | string, status: string) => {
    const res = await api.patch<SchoolType>(`/schools/update-school-status/${id}`, { status });
    return res?.data;
}


export const getSchoolCount = async (startDate: any , endDate: any, regionId: any) => {

    if(!(startDate && endDate)){
        return
    }
    const res = await api({
        url: `/dashboard/active-schools-count`,
        params: {
            ...regionId > 0 && {regionId: regionId},
            ...(
                {dateStart: startDate}),
            ...(
                {dateEnd: endDate})
        },
        method: 'GET'});
    return res?.data
}
type UpdateSchoolIntitutionalProfile =  {
    institutionalCode: any,
    street: any,
    provinceId: any,
    institutionalFormOfOwnership: any,
    institutionalTypeId: any,
    municipalityCity: any,
    fax: any,
    zipCode: any,
    email: any,
    institutionalHeadTelephone: any,
    yearEstablished: any,
    secRegistration: any,
    yearConvertedtoCollegeStatus: any,
    yearConvertedtoUniversityStatus: any,
    nameofInstitutionalHead: any,
    titleofHeadofInstitution: any,
    longitude: any,
    latitude: any,
    schoolName: any,
    approvedBy: any, approvedDate: any,
    highestEducationalAttainmentoftheHead: any,
    institutionalTypeForOT: any,
    provinceName: string
}
export const updateSchoolIntitutionalProfile = async (data: UpdateSchoolIntitutionalProfile, schoolId?: number | string) => {
    var school = {
        institutionalCode: data?.institutionalCode,
        institutionalTypeForOT: data?.institutionalTypeForOT,
        street: data?.street,
        institutionalFormOfOwnership: data?.institutionalFormOfOwnership,
        ...data?.institutionalTypeId && {institutionalTypeId: data?.institutionalTypeId},
        municipalityCity: data?.municipalityCity,
        fax: data?.fax,
        provinceId: data?.provinceId == "" ? null : data?.provinceId,
        provinceName: data?.provinceName == "" ? null : data?.provinceName,
        zipCode: data?.zipCode,
        email: data?.email,
        institutionalHeadTelephone: data?.institutionalHeadTelephone,
        yearEstablished: data?.yearEstablished,
        secRegistration: data?.secRegistration,
        yearConvertedtoCollegeStatus: data?.yearConvertedtoCollegeStatus,
        yearConvertedtoUniversityStatus: data?.yearConvertedtoUniversityStatus,
        nameofInstitutionalHead: data?.nameofInstitutionalHead,
        titleofHeadofInstitution: data?.titleofHeadofInstitution,
        longitude: data?.longitude,
        latitude: data?.latitude,
        schoolName: data?.schoolName,
        highestEducationalAttainmentoftheHead: data?.highestEducationalAttainmentoftheHead,
        approvedBy: data?.approvedBy,
        approvedDate: data?.approvedDate
    }
    const res = await api.patch(`/schools/institutional-profile`, school, {
        params:{
            ...schoolId && {schoolId: schoolId}
        }
    });

    return res?.data;
}
