import PdfIcon from "../svg/pdf";
import React from "react";

export function useFileExtension(filePath: any) {
    var extension = filePath?.split('.')?.pop()

    var result = null;
    if (extension == "pdf") {
        result = <PdfIcon/>
    }

    return result
}
