import * as Yup from "yup";

export interface IUserProfileSchema {
  id?: number | null;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  userId?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  designation?: string | null;
}

export const userProfileSchema: Yup.SchemaOf<IUserProfileSchema> =
  Yup.object().shape({
    id: Yup.number().nullable(),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    userId: Yup.string().nullable(),
    firstName: Yup.string().nullable(),
    middleName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    designation: Yup.string().nullable(),
  });

export type UserProfileType = Yup.InferType<typeof userProfileSchema>;
