import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import { X as XIcon } from 'lucide-react';

interface ThumbnailProps {
    url: string | null | undefined;
}

export const Thumbnail: React.FC<ThumbnailProps> = ({ url }) => {
    const [open, setOpen] = useState(false);

    if (!url) return null;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box
                onClick={handleOpen}
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    maxWidth: 360,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'grey.300',
                    my: 2,
                    cursor: 'zoom-in',
                }}
            >
                <img
                    src={url}
                    alt="Message image"
                    style={{ borderRadius: '8px', objectFit: 'cover', width: '100%', height: '100%' }}
                />
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent
                    sx={{
                        position: 'relative',
                        maxWidth: 800,
                        p: 0,
                        bgcolor: 'transparent',
                        boxShadow: 'none',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 8, right: 8, color: 'grey.500' }}
                    >
                        <XIcon />
                    </IconButton>
                    <img
                        src={url}
                        alt="Message image"
                        style={{ borderRadius: '8px', objectFit: 'cover', width: '100%' }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};
