import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, useAppSelector} from '../../redux/store';
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useQueryClient} from "react-query";
import {setLogoutDialog} from "../../redux/features/app";
import {onLogout} from "../../redux/features/auth";
import {getParamsStatus} from "../../redux/features/specialOrder";
import useLogSignalr from "../../hooks/useLogSignalr";
const LogoutDialog = () => {
    const logoutDialogStateOpen = useSelector((state: RootState) => state.app?.logoutDialog?.open);
    const dispatch = useDispatch();
    const queryClient = useQueryClient()

    const handleClose = useCallback(() => {

        dispatch(setLogoutDialog({
            open: false,
        }))
    }, [])


    const {
        destroyAppSignalR,
        connectionStatus
    } = useLogSignalr();

    const authStore = useAppSelector((store) => store.auth);

    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))

    }, [])
    const handleLogout = async () => {

        queryClient.setQueriesData('requirementSoQuery', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('specialOrderGroupBySchool', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('specialOrderGroupBySchool' + "approved", (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('specialOrderGroupBySchool' + "pending", (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        queryClient.setQueriesData('schools', (oldData: any) => {
            return {
                ...oldData,
                data: []
            }
        })
        const localStorageRefreshToken = localStorage.getItem('session');

        if (localStorageRefreshToken) {
            var refreshToken = JSON.parse(localStorageRefreshToken);
            refreshToken.refreshToken = ""
            refreshToken.isAuth = false
            refreshToken.token = ""
            localStorage.setItem("session", JSON.stringify(refreshToken));

        }
        const cacheName = 'v18'; // Replace with your actual cache name
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
            try {
                await navigator.serviceWorker.controller.postMessage({action: 'clearCache', cacheName});
                console.log('Service worker cache cleared successfully.');
            } catch (error) {
                console.error('Error clearing service worker cache:', error);
            }
        }
        dispatch(getParamsStatus(null))
        dispatch(onLogout());
        dispatch(setLogoutDialog({
            open: false,
        }))
        

        if (connectionStatus === "connected" && authStore.isAuth) {
            await destroyAppSignalR();
        }

    }





    return (
        <Dialog open={logoutDialogStateOpen ?? false} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to logout?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button id={"cancelLogout"} color='inherit' onClick={handleClose}>Cancel</Button>
                <Button id={"addlogout"} variant='outlined' color='error'  onClick={handleLogout}>Logout</Button>
            </DialogActions>
        </Dialog>

    )
}
export default LogoutDialog;
