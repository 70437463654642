    import React from "react";
    import { Avatar, Tooltip, IconButton } from "@mui/material";
    import { format, isToday, isYesterday } from "date-fns";
    import Editor from "./editor";
    import Renderer from "./renderer";
    import { toast } from "react-toastify";
    import { MoreVert } from "@mui/icons-material";
    import {MessageToolbar} from "./MessageToolbar";
    import {useMutation} from "react-query";
    import {
        MessageInputType,
        RemoveMessageInputType,
        ToggleReactionMessageInputType,
        UpdateMessageInputType
    } from "../../schemas/major.schema";
    import {removeMessage, toggleReaction, updateMessage} from "../../requests/message.request";
    import {Thumbnail} from "./thumbnail";
    import {Reactions} from "./reactions";
    import {usePanel} from "./use-panel";
    import {ThreadBar} from "./thread-bar";

    interface MessageProps {
        id: string;
        memberId: string;
        authorImage?: string;
        authorName?: string;
        isAuthor: boolean;
        reactions: any;
        body: any;
        image: string | null | undefined;
        createdAt: any;
        updatedAt: any;
        isEditing: boolean;
        isCompact?: boolean;
        setEditingId: any;
        hideThreadButton?: boolean;
        threadCount?: number;
        threadImage?: string;
        threadName?: string;
        threadTimestamp?: number;
    }

    const formatFullTime = (date: Date | null | undefined): string => {
        if (!date || isNaN(date.getTime())) {
            return "Unknown date";
        }

        return `${isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : format(date, "MMM d, yyyy")} at ${format(date, "h:mm:ss a")}`;
    };
    const formatTime = (date: Date | null | undefined): string => {
        if (!date || isNaN(date.getTime())) {
            return "Unknown date";
        }

        return format( date, "h:mm a")
    };
    const Message = ({
                         id,
                         isAuthor,
                         memberId,
                         authorImage,
                         authorName = "Member",
                         reactions,
                         body,
                         image,
                         createdAt,
                         updatedAt,
                         isEditing,
                         isCompact,
                         setEditingId,
                         hideThreadButton,
                         threadCount,
                         threadImage,
                         threadName,
                         threadTimestamp,
                     }: MessageProps) => {

        const { parentMessageId, onOpenMessage, onOpenProfile, onClose } = usePanel();

        const {mutate: onUpdateMesage, isLoading:isUpdatingMessage} = useMutation({
            mutationKey: "onUpdateMessage", mutationFn: async (value: UpdateMessageInputType) => {
                return await updateMessage(value)
            }
        });

        const {mutate: onRemoveMesage, isLoading: isRemovingMessage} = useMutation({
            mutationKey: "onRemoveMessage", mutationFn: async (value: RemoveMessageInputType) => {
                return await removeMessage(value)
            }
        });

        const {mutate: onToggleReaction, isLoading: isTogglingReaction} = useMutation({
            mutationKey: "onToggleReaction", mutationFn: async (value: ToggleReactionMessageInputType) => {
                return await toggleReaction(value)
            }
        });

        const handleReaction = (value: string) => {
            onToggleReaction({messageId: id, value} as ToggleReactionMessageInputType , {
                onError: () => {
                    toast.error("Failed to toggle reaction");
                },
            });
        };
        const isPending = isUpdatingMessage || isTogglingReaction
        const handleRemove = async () => {
            const ok = window.confirm("Are you sure you want to delete this message?");
            if (ok) {
                onRemoveMesage({id} as RemoveMessageInputType, {
                    onSuccess: () => {
                        toast.success("Message deleted");
                        if (parentMessageId === id) {
                            onClose();
                        }
                    },
                    onError: () => {
                        toast.error("Failed to delete message");
                    },
                });
            }
        };

        const handleUpdate = ({body}: { body: string }) => {
            onUpdateMesage({id, body} as UpdateMessageInputType, {
                onSuccess: () => {
                    toast.success("Message updated");
                    setEditingId(null);
                },
                onError: () => {
                    toast.error("Failed to update message");
                }
            });
        };

        const avatarFallback = authorName.charAt(0).toUpperCase();
        const parsedBody = (() => {
            try {
                return JSON.parse(body);
            } catch {
                return body; // Fall back if not JSON
            }
        })();

        if (isCompact) {
            return (
                <div  className={
                    `flex gap-4 p-4 rounded-lg hover:bg-gray-100 transition-all group relative ${isEditing ? "bg-[#f2c74433] hover:bg-[#f2c74433]" : ""} ${isRemovingMessage ? "bg-rose-500/50 transform transition-all scale-y-0 origin-bottom duration-200" : ""}`
                }>
                    <Tooltip title={formatFullTime(new Date(createdAt))}>
                        <div className="flex-1 min-w-0  ">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <Tooltip title={formatFullTime(new Date(createdAt))}>
                                        <span className="text-xs text-gray-400">{format(new Date(createdAt), "h:mm a")}</span>
                                    </Tooltip>
                                </div>
                                <IconButton size="small" className="opacity-0 group-hover:opacity-100 transition-opacity">
                                    <MoreVert fontSize="small"/>
                                </IconButton>


                            </div>

                            {isEditing ? (
                                <Editor
                                    onSubmit={handleUpdate}
                                    disabled={false}
                                    defaultValue={parsedBody}
                                    onCancel={() => setEditingId(null)}
                                    variant="update"
                                />
                            ) : (
                                <div className="flex flex-col w-full">
                                    <Renderer value={body}/>
                                    <Thumbnail url={image}/>
                                    {updatedAt ? (
                                        <span className="text-xs text-muted-foreground">
                    (edited)
                  </span>
                                    ) : null}
                                    <Reactions currentMemberId={memberId} data={reactions} onChange={handleReaction}/>
                                    <ThreadBar
                                        count={threadCount}
                                        image={threadImage}
                                        name={threadName}
                                        timestamp={threadTimestamp}
                                        onClick={() => onOpenMessage(id)}
                                    />
                                </div>
                            )}


                        </div>

                    </Tooltip>
                    {!isEditing && (
                        <MessageToolbar
                            isAuthor={isAuthor}
                            isPending={isPending}
                            handleEdit={() => setEditingId(id)}
                            handleThread={() => onOpenMessage(id)}
                            handleDelete={handleRemove}
                            handleReaction={handleReaction}
                            hideThreadButton={hideThreadButton}
                        />
                    )}

                </div>
            );
        }

        return (
            <div
                className={
                    `flex gap-4 p-4 rounded-lg hover:bg-gray-100 transition-all group relative ${isEditing ? "bg-[#f2c74433] hover:bg-[#f2c74433]" : ""} ${isRemovingMessage ? "bg-rose-500/50 transform transition-all scale-y-0 origin-bottom duration-200" : ""}`

                }>

                <Tooltip title={authorName}>
                    <Avatar onClick={() => onOpenProfile(memberId)} src={authorImage} alt={authorName}>{avatarFallback}</Avatar>
                </Tooltip>
                <div className="flex-1 min-w-0  ">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <span onClick={() => onOpenProfile(memberId)} className="text-sm font-semibold text-gray-900">{authorName}</span>
                            <Tooltip title={formatFullTime(new Date(createdAt))}>
                                <span className="text-xs text-gray-400">{formatTime(new Date(createdAt))}</span>
                            </Tooltip>
                        </div>
                        <IconButton size="small" className="opacity-0 group-hover:opacity-100 transition-opacity">
                            <MoreVert fontSize="small"/>
                        </IconButton>


                    </div>

                    {isEditing ? (
                        <Editor
                            onSubmit={handleUpdate}
                            disabled={false}
                            defaultValue={parsedBody}
                            onCancel={() => setEditingId(null)}
                            variant="update"
                        />
                    ) : (
                        <div className="flex flex-col w-full">
                            <Renderer value={body}/>
                            <Thumbnail url={image}/>
                            {updatedAt ? (
                                <span className="text-xs text-muted-foreground">
                    (edited)
                  </span>
                            ) : null}
                            <Reactions currentMemberId={memberId} data={reactions} onChange={handleReaction}/>
                            <ThreadBar
                                count={threadCount}
                                image={threadImage}
                                name={threadName}
                                timestamp={threadTimestamp}
                                onClick={() => onOpenMessage(id)}
                            />
                        </div>
                    )}


                </div>
                {!isEditing && (
                    <MessageToolbar
                        isAuthor={isAuthor}
                        isPending={isPending}
                        handleEdit={() => setEditingId(id)}
                        handleThread={() => onOpenMessage(id)}
                        handleDelete={handleRemove}
                        handleReaction={handleReaction}
                        hideThreadButton={hideThreadButton}
                    />
                )}
            </div>
        )

    };

    export default Message;
