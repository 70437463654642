import { IUserProfileSchema, userProfileSchema } from "./user-profile.schema";
import * as Yup from "yup";
import { ITypeSchema, typeSchema } from "./type.schema";
import { ISchoolSchema, schoolSchema } from "./school.schema";
import { IRegionSchema, regionSchema } from "./region.schema";
import {
  IUserApplicationSchema,
  userApplicationSchema,
} from "./userApplication.schema";
import { is } from "immutable";

export interface IUserSchema {
  id?: string | null;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  lockoutEnd?: string | null;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  schoolId?: number | null;
  school: ISchoolSchema;
  status: boolean;
  approvedBy?: string | null;
  region: IRegionSchema;
  regionId?: number | null;
  userProfile: IUserProfileSchema;
  typeId?: number;
  type: ITypeSchema;
  isDeleted?: boolean;
  lastUpdateDate?: string | null;
  userApplications: IUserApplicationSchema[];

  roles: string;
}

export const userSchema: Yup.SchemaOf<IUserSchema> = Yup.object().shape({
  id: Yup.string().nullable(),
  userName: Yup.string().nullable(),
  normalizedUserName: Yup.string().nullable(),
  email: Yup.string().nullable(),
  normalizedEmail: Yup.string().nullable(),
  emailConfirmed: Yup.boolean().optional(),
  passwordHash: Yup.string().nullable(),
  securityStamp: Yup.string().nullable(),
  concurrencyStamp: Yup.string().nullable(),
  phoneNumber: Yup.string().nullable(),
  phoneNumberConfirmed: Yup.boolean().optional(),
  twoFactorEnabled: Yup.boolean().optional(),
  lockoutEnd: Yup.string().nullable(),
  lockoutEnabled: Yup.boolean().optional(),
  accessFailedCount: Yup.number().optional(),
  schoolId: Yup.number().nullable(),
  school: Yup.lazy(() => schoolSchema),
  status: Yup.boolean().required("Status is required"),
  approvedBy: Yup.string().nullable(),
  region: Yup.lazy(() => regionSchema),
  // regionId: Yup.number().nullable(),
  regionId: Yup.number().when("typeId", {
    is: 3,
    then: Yup.number().required("Region Id is required"),
    otherwise: Yup.number().optional(),
  }),
  userProfile: Yup.lazy(() => userProfileSchema),
  typeId: Yup.number().optional(),
  type: Yup.lazy(() => typeSchema),
  isDeleted: Yup.boolean().optional(),
  lastUpdateDate: Yup.string().nullable(),
  userApplications: Yup.array().of(userApplicationSchema),

  roles: Yup.string().required("Roles is required"),
});

export type UserType = Yup.InferType<typeof userSchema>;
