import {useQuery} from 'react-query';
import {getCurriculumByName} from "../requests/curriculum.request";
import {useCallback} from "react";
import {ProgramResponseType} from "../types";
import flat from "flat";


export const useSearchCurriculumQuery = (str: string, schoolId: any, programId?:any ) => {
    return useQuery(['curriculums', str, schoolId?.regionId, schoolId?.programId, schoolId?.schoolId, schoolId?.pageSize, schoolId?.page, programId], ({signal}) => getCurriculumByName(str, signal, schoolId), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: useCallback((data: ProgramResponseType) => {
            return (
                {
                    ...data,
                    data: data?.data.map(el => ({...el, ...flat(el, {safe: true}) as object})) // flatten the object // not type-safe
                }
            )
        }, []),
    });
}
