import { useDispatch } from "react-redux";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { BASE_URL, setHeaderToken } from "../requests/config";
import { useAppSelector } from "../redux/store";
import {SnackbarKey, useSnackbar } from "notistack";
import { onLogin } from "../redux/features/auth";
import {ISnackbarSlice, openSnackbar} from "../redux/features/snackbar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function useLogSignalr() {
    const dispatch = useDispatch();
    const user = useAppSelector((store) => store?.auth);
    const signalr = useRef<HubConnection | null>(null);
    const [connectionStatus, setConnectionStatus] = useState<"reconnecting" |"disconnected" | "connecting" | "connected" | "disconnecting">("disconnected");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const toastId = React.useRef<any>(null);
    const [userToken, setUserToken] = useState("");
    const [progressArray, setProgressArray] = useState<any[]>([]);
    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === "session") {
                const updatedSession = JSON.parse(event.newValue || "null");
                setUserToken(updatedSession?.token);
            }
        };

        const localStorageRefreshToken = localStorage.getItem("session");
        const sessionToken = JSON.parse(localStorageRefreshToken || "null");
        if (sessionToken?.token) {
            setHeaderToken(sessionToken?.token);
            setUserToken(sessionToken?.token);
            dispatch(
                onLogin({
                    ...user,
                    token: sessionToken?.token,
                    isAuth: true,
                })
            );
        }

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);


    const initAppSignalR = async (token?:string) => {
        const localStorageRefreshToken: any = localStorage.getItem("session");
        const sessionToken = JSON.parse(localStorageRefreshToken);
        const accessToken = sessionToken?.token;
        setUserToken(accessToken);

        if (connectionStatus === "connecting" || connectionStatus === "connected"   ) {
            return;
        }

        if (signalr.current) {
            await destroyAppSignalR();
        }

        setConnectionStatus("connecting");

        signalr.current = new HubConnectionBuilder()
            .withUrl(`${BASE_URL}/loghub`, {
                transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
                accessTokenFactory: () => token ?? accessToken ?? userToken ?? user?.token,
            })
            .withAutomaticReconnect([0, 2000, 10000, 30000])
            .build();

        signalr.current.onclose(() => {

            setConnectionStatus("disconnected");
        })
        signalr.current.onreconnected(() => {
            setConnectionStatus("connected");
        });
        signalr.current.onreconnecting(() => {
            const localStorageRefreshToken: any = localStorage.getItem("session");
            const sessionToken = JSON.parse(localStorageRefreshToken);
            const accessToken = sessionToken?.token;
            setUserToken(accessToken);
            setConnectionStatus("reconnecting");
        });

        try {
            await signalr.current.start();
            setConnectionStatus("connected");
        } catch (error) {
            console.error("SignalR Connection Error: ", error);
            setConnectionStatus("disconnected");
        }
        return () => {
            if (signalr.current) {
                signalr.current.stop();
            }
        };
    };
    const destroyAppSignalR =async () => {
        if ( signalr?.current) {
            setConnectionStatus("disconnecting");
            try {
                console.log("disconnected")
                await signalr.current.stop();
                signalr.current = null;

                setConnectionStatus("disconnected");
            } catch (error) {
                console.error("Error stopping SignalR connection: ", error);
                setConnectionStatus("disconnected");
            }
        }
    };
    const onAppConnection = (connection: any, callback = () => {}) => signalr.current?.on(connection, callback);

    async function FileGenerationComplete(fileUrl?: any, fileName?: any) {
        console.log("our download is starting")
        if (Notification.permission === "granted") {
            new Notification("New Notification", {
                body: fileUrl,
            });
        }
        enqueueSnackbar('Your download is ready', {
            variant: 'info',
            action: (
                <button onClick={() => {
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }}>
                    Download
                </button>
            )
        });
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    async function onAddLog(id?: any, data?: any) {
        if (Notification.permission === "granted") {
            new Notification("New Notification", {
                body: data,
            });
        }
        enqueueSnackbar(data);
    }
    async function OnInit(id?: any, data?: any) {
        console.log("id?: any, data?: any", id, data)

    }
    const action = (snackbarId:  SnackbarKey) => (
        <>
            <button onClick={() => { closeSnackbar(snackbarId) }}>
                Dismiss
            </button>
        </>
    );


    async function onProgress(id?: any, data?: any) {
        try {
            const res = JSON.parse(data);
            const progressPercentage = res?.percentage || 0;
            const persist = res?.persist ?? true;

            if (toastId.current === null) {
                toastId.current = toast('Uploading ' + progressPercentage + '%', {
                    type: "success",
                    progress: progressPercentage / 100
                });
            } else {
                toast.update(toastId.current, {
                    type: "success",
                    progress: progressPercentage / 100
                });
            }

            if (!persist && toastId.current) {
                toast.dismiss(toastId.current);
                toastId.current = null;
            }

        } catch (e) {
            console.error("Error parsing data: ", e);
        }
    }
    return {
        initAppSignalR,
        onProgress,
        onAddLog,
        OnInit,
        FileGenerationComplete,
        onAppConnection,
        destroyAppSignalR,
        connectionStatus
    };
}

export default useLogSignalr;
