import * as Yup from "yup";
import { curriculumSchema, ICurriculumSchema } from "./curriculum.schema";
import { ISubjectSchema, subjectSchema } from "./subject.schema";

export interface ICurriculumSubjectSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  curriculumId: number;
  curriculum: ICurriculumSchema;
  subjectId: number;
  subject: ISubjectSchema;
}

export const curriculumSubjectSchema: Yup.SchemaOf<ICurriculumSubjectSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    curriculumId: Yup.number().required("CurriculumId is required"),
    curriculum: Yup.lazy(() => curriculumSchema),
    subjectId: Yup.number().required("SubjectId is required"),
    subject: subjectSchema,
  });

export type CurriculumSubjectType = Yup.InferType<
  typeof curriculumSubjectSchema
>;
