import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "../../redux/store";
import {useFormik} from "formik";
import {setErrorSnackbar} from "../../helper/utils/helper";
import * as yup from "yup";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useRequirementSoQuery} from "../../hooks/useRequirementSoQuery";
import {useRequirementQuery} from "../../hooks/useRequirementQuery";
import {useQueryClient} from "react-query";
import {useStudentRequirementSoQuery} from "../../hooks/useStudentRequirementSoQuery";
import {setLoading} from "../../redux/features/app";
import FilesDialog from "../dialogs/Files.dialog";
import {CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
import {studentFileManagerSave} from "./SaveFileManager";


interface IProps {
    studentId?: string | number;
    schoolId?: string | number;
    programId?: string | number;
    majorId?: string | number;
    handleClose: () => void;
    onSave: () => void;
    title: string
}


const initialValues = {
    formType: "dob"
}
const validationSchema = yup.object({
    formType: yup.string(), // userName: yup.string().required('UserName is required'),

})


interface IStudentRequirementSO {
    specialOrderRequirementId: number | string | any
    studentId: number | string | any
    url: number | string | any
    schoolId: number | string | any
    programId: number | string | any
    majorId: number | string | any
    id: number | string | any
    fileExtension: number | string | any
    dateCreated: string | string | any
    isDeleted: string | string | any
    lastModifiedDate: string | string | any
    fileName: string | string | any
    fileSize: string | string | any
}


const StudentFileManager = React.memo((props: IProps) => {
    const {handleClose, onSave, title, majorId = 0, studentId = 0, schoolId = 0, programId = 0} = props;
    const specialOrderSchoolId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderSchoolId
    );
    const specialOrderMajorIdValue = useSelector((store: RootState) => store.specialOrder?.specialOrderMajorIdValue)
    const specialOrderProgramId = useAppSelector(
        (store) => store?.specialOrder?.specialOrderProgramId
    );
    const queryClient = useQueryClient();
    const queryKey = useAppSelector(
        (store) => store?.specialOrder?.queryKey
    );
    const user = useSelector((store: RootState) => store.auth.user);
    const specialOrderIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderIdValue
    );
    const specialOrderStudentNumberValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentNumberValue
    );
    const specialOrderStudentIdValue = useAppSelector(
        (store) => store?.specialOrder?.specialOrderStudentIdValue
    );


    const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload))
    }, [])

    const files = useSelector((store: RootState) => store.specialOrder?.requirements?.files)

    const formik = useFormik({
        initialValues, validationSchema,
        onSubmit: async (values, actions) => {
            dispatch(setLoading(true))

            try {
                let _queryKey = `specialOrderGroupByStudents${specialOrderMajorIdValue + specialOrderSchoolId + specialOrderProgramId}`

                await studentFileManagerSave(files, user, specialOrderStudentNumberValue, schoolId, programId, majorId, studentId, setSnackbarStore, queryClient, specialOrderStudentIdValue, queryKey ?? _queryKey);
                dispatch(setLoading(false))

            } catch (error: any) {
                dispatch(setLoading(false))
                setErrorSnackbar(error, setSnackbarStore);

            }


        }
    })

    const dispatch = useDispatch();

    const {
        data: requirements,
        isLoading,
        refetch: refetch
    } = useRequirementQuery(programId, schoolId, +studentId);
    const {
        data: requirementSO,
        isLoading: isLoadingSo,
        refetch: refetchSo
    } = useRequirementSoQuery(programId, schoolId, +studentId);


    const {
        data: studentrequirementSO,
        isLoading: isLoadingStudentRequirementSO,
        refetch: refetchStudentRequirementSO
    } = useStudentRequirementSoQuery(programId, schoolId, +studentId, specialOrderStudentNumberValue, majorId);





    const _handleSave = () => {
        formik.handleSubmit();
    }

    return (
        <>
            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                {requirements?.data ?
                    requirements?.data.filter((c: any, requirementsData: number) => {
                        return c.isRequired && !c.isDeleted
                    })?.map((c: any, filesIndex: number) => {
                        return <FilesDialog
                            studentId={studentId ?? specialOrderStudentIdValue}
                            filesIndex={filesIndex}
                            fileDialogKey={`${(+majorId ?? 0) + (+schoolId ?? 0) + (+programId ?? 0)}`}
                            requirement={c}/>
                    }) : null
                }

            </div>


            {requirements?.data?.length == 0 || requirements == null ?
                <div className={" flex justify-center align-middle"}>
                    <div>
                        <CircularProgress/>
                    </div>
                </div> : null}
            <div className={"flex justify-center align-middle"}>
                <Button variant={"outlined"} onClick={_handleSave}>Save</Button>
            </div>

        </>

    );
});

export default StudentFileManager
