import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Notifications} from "../Actions";
import {Divider, Typography} from "@mui/material";
import {useNotificationQuery} from "../../hooks/useNotificationQuery";
import {useMutation, useQueryClient} from "react-query";
import moment from "moment-timezone";
import {useHistory} from "react-router-dom";
import Button from "@mui/material/Button";
import {markAsSeenRequest} from "../../requests/markAsSeenRequest";

export default function Notification() {
    const history = useHistory<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const queryClient = useQueryClient()
    const open = Boolean(anchorEl);
    const [seeMore, setSeeMore] = React.useState(5)
    const onSeeMore =() => {
        setSeeMore(seeMore + 5)
    }
    const {data, isLoading} = useNotificationQuery(seeMore);
    const {mutate: markAsRead, isLoading: markAsReadLoading} = useMutation({
        mutationKey: "markAsRead",
        mutationFn: (data: number) => markAsSeenRequest(data),
        onSuccess(data: any) {
            queryClient.setQueriesData('notification-unseen', (oldData: any) => {
                const _oldData = JSON.parse(JSON.stringify(oldData))
                if (_oldData?.data?.count) {
                    _oldData.data.count = (_oldData.data.count - 1) >= 0 ? _oldData.data.count - 1 : 0
                }
                if (_oldData?.data?.data) {
                    _oldData.data.data = _oldData?.data?.data?.map((c: any) => {
                        if (c.id === data.data) {
                            c.isSeen = true
                            return c
                        }
                        return c
                    })
                }
                return _oldData
            })
            queryClient.invalidateQueries(['notification-unseen'])
        }
    });
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

        queryClient.setQueriesData('notification-unseen', (oldData: any) => {
            const _oldData = JSON.parse(JSON.stringify(oldData))
            if (_oldData?.data?.count) {
                _oldData.data.count = 0
            }
            if (_oldData?.data?.data) {
                _oldData.data.data = _oldData?.data?.data?.map((c: any) => {
                    c.isSeen = true
                    markAsRead(c.promotionalReportId)
                    return c
                })
            }
            return _oldData
        })
    };
    const handleClose = () => {
        setAnchorEl(null);

    };

    function handleOpen(id: number, headerName: string) {
        markAsRead(id)
        if (id) {
            history.push(`/enrollments/${id}?tab=${headerName == "PROMOTIONAL REPORT" ? 1 : 2}`)
        }

        handleClose()
    }

    return (
        <div>
            <Notifications onClick={handleClick as any} total={data?.data?.count ?? 0}/>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {data?.data?.data?.length == 0 ? <MenuItem disabled={true} sx={{fontSize: '0.75rem'}}>
                    <div style={{
                        flex: 1,
                        maxWidth: "300px",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal"
                    }}>
                        <Typography variant="body2">No notification yet</Typography>
                    </div>

                </MenuItem> : null}
                {
                    data?.data?.data?.map((c: any) => {
                        return <><MenuItem sx={{fontSize: '0.75rem'}} onClick={() => handleOpen(c.promotionalReportId, c.headerName)}>
                            <div style={{
                                flex: 1,
                                maxWidth: "300px",
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "normal"
                            }}>
                                <Typography sx={{fontWeight: c.isSeen ? "" : 'bold'}}
                                            variant="body2">{c.message}</Typography>
                                {c.fileUrl ? <a href={c.fileUrl} target="_blank" rel="noopener noreferrer"
                                                style={{color: 'blue', textDecoration: 'none'}}>
                                    <Typography variant="caption" display="block">Excel Link</Typography>

                                </a> : null}
                                <Typography variant="caption"
                                            display="block">{moment.utc(c.dateCreated).tz('Asia/Manila').format('MMMM D, YYYY, h:mm a')}</Typography>
                            </div>


                        </MenuItem>
                            <Divider/>
                        </>
                    })
                }
                <Divider/>
                <MenuItem onClick={onSeeMore}>
                    See More
                </MenuItem>
            </Menu>
        </div>
    );
}
