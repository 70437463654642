import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Alert, Box, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {patchCurriculum} from "../../requests/curriculum.request";
import SearchTextField from "../SearchTextField/SearchProgramTextField";
import {SearchProgramResponseType, SearchSchoolResponseType} from "../../types";
import {useAddEntities} from "../../hooks/useAddEntities";
import {setProgram, setProgramBreadcrumb, setProgramId, setProgramTemp,} from "../../redux/features/school";
import {RootState, useAppSelector} from "../../redux/store";
import {useMutation, useQueryClient} from "react-query";
import {CurriculumInputType} from "../../schemas/curriculum.schema";
import {SaveRounded} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {Transition} from "../../wrapper/Transition";
import {useGetCurriculum} from "../../hooks/useGetCurriculumQuery";

const validationSchema = yup.object({
  programId: yup
      .string()
      .required("Program ID is required")
      .min(1, "Program ID must be at least 1 characters"),
  name: yup.string().required("Curriculum Name is required"),
});

const initialValues = {
  schoolId: "",
  id: "",
  programId: "",
  programName: "",
  name: "",
};

interface IProps {
  open: boolean;
  handleClose: () => void;
  setting?: boolean;
}

const EditCurriculum = (props: IProps) => {
  const {open, handleClose, setting} = props;
  const dispatch = useDispatch();
  const [school, setSchool] = useState<any>()

  const tempProgram = useAppSelector((store) => store?.school?.tempProgram);
  const programName = useAppSelector((store) => store?.auth?.user);
  const typeId = useAppSelector((store) => store?.auth?.user?.type?.id);
  const editCurriculumsId = useAppSelector((store) => store?.curriculum?.editCurriculumsId);
  const editCurriculimSchoolId = useAppSelector((store) => store?.curriculum?.editCurriculimSchoolId);
  const editCurriculimSchoolName = useAppSelector((store) => store?.curriculum?.editCurriculimSchoolName);

  const {data: curriculum} = useGetCurriculum(editCurriculumsId);


  const queryClient = useQueryClient();

  const {mutate: updateCurriculum, isLoading} = useMutation({
    mutationKey: "updateCurriculum",
    mutationFn: async (value: CurriculumInputType) => {
      return await patchCurriculum(value)
    }
  });

  const {schoolId, regionId} = useAddEntities();
  const setSnackbarStore = React.useCallback((payload: ISnackbarSlice) => {
    dispatch(openSnackbar(payload));
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: async (values, actions) => {

      values.schoolId = editCurriculimSchoolId ? editCurriculimSchoolId : (schoolId ? schoolId : school?.id)
      void updateCurriculum(values as unknown as CurriculumInputType, {
        onSuccess: (res) => {
          if (programName != null) {
            dispatch(setProgramId(values?.programId));
            dispatch(setProgramBreadcrumb(values?.programName));
            dispatch(setProgram(tempProgram));
          }

          if (res.message) {
            var __message = res?.data?.name + " already exists in our system. Please try a different one."
            setSnackbarStore({
              message: res.message,
              severity: __message == res.message ? "error" : "success",
              open: true,
            })
          } else {
            setSnackbarStore({
              message: "Successfully update curriculum",
              severity: "success",
              open: true,
            });
          }
          //  dispatch(setCurriculumTableTabOpenCount())
          /*   dispatch(setTabValue(2))
                    dispatch(setCourseTabState(true))*/
          queryClient.setQueriesData(['curriculums'], (oldData: any) => {
            return {
              ...oldData,
              data: oldData.data.map((t: any) => {
                var _curriculum = {...t}
                if (values?.id == t?.curriculumId) {
                  _curriculum.curriculumName = values.name
                  _curriculum.programName = values.programName
                  _curriculum.programId = values.programId
                }
                return _curriculum
              })
            }
          })
          handleClose();
        },
        onError: (res:any) => {
          setSnackbarStore({
            message: res?.response?.data?.message ?? "Failed to edit curriculum",
            severity: "error",
            open: true,
          });
        },
      });
    },
  });

  const handleOnSelected = (value: SearchProgramResponseType, shouldValidate?: boolean) => {
    formik.setFieldValue("programId", value?.id ?? "", shouldValidate != null ? shouldValidate : true);
    formik.setFieldValue("programName", value?.name ?? "", shouldValidate != null ? shouldValidate : true);
    dispatch(setProgramTemp(value));
  };
  const schoolSelectorById = useAppSelector(
      (store) => store?.school.schoolSelectorById
  );


  useEffect(() => {
    if (curriculum?.curriculumName) {
      formik.setFieldValue("name", curriculum.curriculumName ?? "", true);
    }
    if (curriculum?.program) {
      handleOnSelected(curriculum?.program, false)
    }

    if (curriculum?.curriculumId) {
      formik.setFieldValue("id", curriculum.curriculumId ?? "", true);
    }

  }, [curriculum])

  const [onErrorSelectedSchool, setOnErrorSelectedSchool] = useState<any>(false)
  const handleOnSelectedSchool = useCallback((value: SearchSchoolResponseType) => {
    setSchool(value)
    formik.setFieldValue('schoolId', value?.id ?? "")
  }, [formik])
  const handleSubmit = React.useCallback(() => {
    formik.handleSubmit();
  }, [formik]);
  const authStore = useSelector((store: RootState) => store.auth);

  return (
      <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Edit Curriculum <p
            style={{
              margin: 0,
              fontSize: 12,
              fontStyle: "italic"
            }}>({authStore?.user?.school?.name ?? editCurriculimSchoolName ?? ""})</p>
        </DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Please fill in the details of the curriculum you want to add
            <br/>
            Note: Fields with <strong>*</strong> are required
          </Alert>
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 3,
              }}
          >

            {tempProgram ?
                <SearchTextField
                    defaultValue={tempProgram}
                    schoolId={schoolId}
                    label="Program"
                    disabled={true}
                    onSelected={handleOnSelected}
                /> : null
            }


            {
              tempProgram ? <>
                <Typography variant="caption" color="error">
                  {formik?.touched?.name && formik.errors.programId}
                </Typography>


                <TextField
                    label="Curriculum Name"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                    id="curriculumname"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik?.touched?.name && Boolean(formik?.errors?.name)}
                    helperText="Please enter curriculum name with the correct format (e.g. CEA-BSIT-2023-2024-1)"
                />
              </> : null
            }

          </Box>

        </DialogContent>

        <DialogActions>
          <Button id={"cancelCurriculum"} onClick={handleClose}>Cancel</Button>
          <LoadingButton
              loading={isLoading}
              disabled={
                  formik.values.name.length < 1 || formik.values.programId.length < 1
              }
              id={"loadingAdding"}
              loadingPosition="start"
              variant="contained"
              startIcon={<SaveRounded/>}
              onClick={handleSubmit}
          >
            {isLoading ? "Updating" : "Update"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  );
};

export default EditCurriculum;
