import React, {useCallback, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ArrowDropDown } from '@mui/icons-material';
import { useSchoolQuery } from '../../hooks/useSchoolQuery';
import { useDownloadSOReportMutation } from '../../hooks/useDownloadSOReportMutation';
import {ISnackbarSlice, openSnackbar} from "../../redux/features/snackbar";
import {useAppDispatch} from "../../redux/store";
import {AxiosError} from "axios";
import {LoadingButton} from "@mui/lab";

interface CustomPrintDialogProps {
    open: boolean;
    handleClose: () => void;
    handlePrint: (selectedSchool: { id: number | undefined; name: string }, dateFrom: Date | null, dateTo: Date | null) => void;
}

interface SchoolType {
    id: number | undefined;
    name: string;
}

export const PrintDialog: React.FC<CustomPrintDialogProps> = ({ open, handleClose, handlePrint }) => {
    const dispatch = useAppDispatch();
    const [selectedSchool, setSelectedSchool] = useState<SchoolType>({ id: 0, name: 'All' });
    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);
    const [selectedRegionId, setSelectedRegionId] = useState<SchoolType>({ id: 0, name: 'All' });
    const setSnackbarStore = useCallback((payload: ISnackbarSlice) => {
        dispatch(openSnackbar(payload));
    }, []);
    const { data: dataSchools, isLoading } = useSchoolQuery({
        pageSize: '1000',
        search: '',
        ...(selectedRegionId.id && { regionId: selectedRegionId.id }),
    });

    const { mutate: downloadSOReport, isLoading: isDownloading } = useDownloadSOReportMutation();

    const handlePrintClick = async () => {
        try {
        await downloadSOReport({ selectedSchool, dateFrom, dateTo }, {
            onSuccess: (res) => {
                setSnackbarStore({ message: 'Report downloaded successfully!', severity: 'success', open: true });
                handleClose();
            },
            onError: (error) => {
                setSnackbarStore({ message: 'Failed to download report.', severity: 'error', open: true });
            },
        });
        } catch (error) {

        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>SO Report</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box display="flex" justifyContent="space-between">
                        <DatePicker
                            label="Date From"
                            value={dateFrom}
                            onChange={(newValue) => setDateFrom(newValue)}
                            renderInput={(params) => <TextField {...params} margin="normal" style={{ marginRight: '8px' }} />}
                        />
                        <DatePicker
                            label="Date To"
                            value={dateTo}
                            onChange={(newValue) => setDateTo(newValue)}
                            renderInput={(params) => <TextField {...params} margin="normal" style={{ marginLeft: '8px' }} />}
                        />
                    </Box>
                </LocalizationProvider>
                <Box style={{ marginTop: '15px' }}>
                    <form onSubmit={(event) => event.preventDefault()}>
                        <>
                            <Autocomplete
                                disabled={isLoading}
                                disableClearable
                                id="heiacademiccalendarselectAutocomplete"
                                value={selectedSchool}
                                onChange={(event: any, newValue: SchoolType | null) => {
                                    if (newValue?.id && newValue?.name) {
                                        setSelectedRegionId({ id: newValue.id, name: newValue.name });
                                    } else {
                                        setSelectedRegionId({ id: 0, name: 'All' });
                                    }
                                    setSelectedSchool(newValue || { id: 0, name: 'All' });
                                }}
                                getOptionLabel={(option) => option.name}
                                popupIcon={<ArrowDropDown id={'ArrowDropDown-' + 'heiacademiccalendarselectAutocomplete'} />}
                                renderOption={(props, option: SchoolType) => (
                                    <li {...props} id={'heiacademiccalendarselectAutocomplete-' + option.id} key={option.id}>
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                            {option.name}
                                            <p style={{ fontStyle: 'italic', margin: 0, fontSize: 12 }}>
                                                {option.name}
                                            </p>
                                        </div>
                                    </li>
                                )}
                                options={[{ name: 'All', id: undefined }, ...(dataSchools?.data || [])] || []}
                                renderInput={(params) => <TextField {...params} label="Select HEI Profile" />}
                            />
                        </>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={isDownloading} onClick={handlePrintClick} color="primary" disabled={isDownloading}>Print</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
