import * as Yup from "yup";
import { IProgramSchema, programSchema } from "./program.schema";
import { ISchoolSchema, schoolSchema } from "./school.schema";

export interface IProgramSchoolSchema {
  id: number;
  uuid?: string | null;
  dateCreated?: string | null;
  createdBy?: string | null;
  lastModifiedDate?: string | null;
  lastModifiedBy?: string | null;
  programId?: number | null;
  program: IProgramSchema;
  programUuid?: string | null;
  schoolId?: number | null;
  school: ISchoolSchema;
  schoolUuid?: string | null;
}

export const programSchoolSchema: Yup.SchemaOf<IProgramSchoolSchema> =
  Yup.object().shape({
    id: Yup.number().required("Id is required"),
    uuid: Yup.string().nullable(),
    dateCreated: Yup.string().nullable(),
    createdBy: Yup.string().nullable(),
    lastModifiedDate: Yup.string().nullable(),
    lastModifiedBy: Yup.string().nullable(),
    programId: Yup.number().nullable(),
    program: Yup.lazy(() => programSchema),
    programUuid: Yup.string().nullable(),
    schoolId: Yup.number().nullable(),
    school: Yup.lazy(() => schoolSchema),
    schoolUuid: Yup.string().nullable(),
  });
export type ProgramSchoolType = Yup.InferType<typeof programSchoolSchema>;
