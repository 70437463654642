// create slice for app
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReactNode} from "react";

export interface IProgramSlice {
    programs: any;
    programId: number;
    majorId: number;
    majorName: string;
    name: string;
    disciplineId: number;
    disciplineName: string;
    levelId: number;
    levelName: string;
    configurationProgram: any;
    isApproveDialog: Partial<IsApprovedDialog>;
}

type IsApprovedDialog = {
    open: boolean;
    data: Partial<{
        isApprove: any;
        id: any;
        title: string;
        body: string;
        content: ReactNode;
        handleIsApprove: any;
    }>;
};

type ApprovedDialog = {
    open: boolean;
    data: Partial<{
        isApprove: any;
        id: any;
        title: string;
        body: string;
        content: ReactNode;
        handleIsApprove: any;
    }>;
};
const initialState: IProgramSlice = {
    programs: null,
    programId: 0,
    levelId: 0,
    name: "",
    levelName: "",
    majorId: 0,
    majorName: "",
    disciplineId: 0,
    disciplineName: "",
    configurationProgram: {},
    isApproveDialog: {
        open: false,
        data: {
            isApprove: false,
            id: null,
            title: "Is Approve item",
            content: null,
            handleIsApprove: null,
        },
    },

};

const appSlice = createSlice({
    name: "programs",
    initialState,
    reducers: {
        setIsApproveDialog: (
            state,
            action: PayloadAction<IProgramSlice["isApproveDialog"]>
        ) => {
            state.isApproveDialog = action.payload;

            return state;
        },
        setPrograms: (state, action: PayloadAction<any>) => {
            state.programs = action.payload;
            return state;
        },

        setEditProgramId: (state, action: PayloadAction<IProgramSlice["programId"]>) => {
            state.programId = action.payload;
            return state;
        },
        setEditMajorId: (state, action: PayloadAction<IProgramSlice["majorId"]>) => {
            state.majorId = action.payload;
            return state;
        },
        setEditMajorName: (state, action: PayloadAction<IProgramSlice["majorName"]>) => {
            state.majorName = action.payload;
            return state;
        },
        setEditLevelId: (state, action: PayloadAction<IProgramSlice["levelId"]>) => {
            state.levelId = action.payload;
            return state;
        },
        setEditLevelName: (state, action: PayloadAction<IProgramSlice["levelName"]>) => {
            state.levelName = action.payload;
            return state;
        },
        setEditDisciplineId: (state, action: PayloadAction<IProgramSlice["disciplineId"]>) => {
            state.disciplineId = action.payload;
            return state;
        },
        setEditDisciplineName: (state, action: PayloadAction<IProgramSlice["disciplineName"]>) => {
            state.disciplineName = action.payload;
            return state;
        },
        setEditName: (state, action: PayloadAction<IProgramSlice["name"]>) => {
            state.name = action.payload;
            return state;
        },
        setConfigurationProgram: (state, action: PayloadAction<any>) => {
            state.configurationProgram = action.payload;
            return state;
        },
        setUpdateProgramsCurriculumByIndex: (state, action: PayloadAction<any>) => {
            const _programs = {...state.programs};
            if (_programs.data && action?.payload?.index > -1) {
                _programs.data[action.payload.index].subject_Programs = action.payload
                    ?.curriculum
                    ? action.payload?.curriculum
                    : [];
            }
            state.programs = _programs;

            return state;
        },
    },
});

export const {
    setEditMajorId,
    setEditMajorName,
    setEditLevelId,
    setEditLevelName,
    setEditDisciplineId,
    setEditDisciplineName,
    setEditName,
    setEditProgramId,
    setIsApproveDialog,
    setConfigurationProgram,
} =
    appSlice.actions;
export default appSlice.reducer;
