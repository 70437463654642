import React, { useEffect, useState } from "react";
import { AlertTriangle, Loader } from "lucide-react";

import { Conversation } from "./conversation";

import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {createOrGet} from "../../../../requests/conversation.request";
import {toast} from "react-toastify";
import {useScreenDimension} from "../../../../hooks/useScreenDimension";
import {usePanel} from "../../use-panel";
import {Helmet} from "react-helmet-async";
import {APP_TITLE} from "../../../../helper/utils/constants";
import {Box, Paper} from "@mui/material";
import Toolbar from "../../Toolbar";
import Sidebar from "../../sidebar";
import ChannelContent from "../../ChannelContent";
import RightSidebar from "../../rigthSideBar";

const MemberIdPage = () => {
  const { memberId, workspaceId } = useParams() as { memberId: string, workspaceId:string };

    const { height } = useScreenDimension()

  const [conversationId, setConversationId] = useState<any>(null);
    const { mutate, data, isLoading, isError, error } = useMutation<any, Error, { memberId: string, workspaceId: string }>(
        async ({ memberId, workspaceId }) => await createOrGet(memberId, workspaceId),
        {
            onSuccess: (data) => {
                console.log(data)
                setConversationId(data);
            },
            onError: (error) => {
                toast.error( error.message || "An error occurred");
            },
        }
    );

    useEffect(() => {
        if (memberId && workspaceId ) {
            mutate({ memberId, workspaceId });
        }
    }, [memberId, workspaceId]);
    const { parentMessageId, profileMemberId, onClose } = usePanel();

    const showPanel = !!parentMessageId || !!profileMemberId;

  if (!conversationId) {
    return (
      <div className="h-full flex flex-col gap-y-2 items-center justify-center">
        <AlertTriangle className="size-6 text-muted-foreground" />
        <span className="text-sm text-muted-foreground">
          Conversation not found
        </span>
      </div>
    );
  }

  return <div>
      <Helmet>
          <title>Workspace | {APP_TITLE}</title>
      </Helmet>
      <main
      >
          <Box sx={{
              flex: 1,
              display: "flex",
              paddingTop: 8.5,
          }}>

          </Box>

          <Box
              sx={{

              }}
          >

              <Paper
                  sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      height: height -110,
                      backgroundColor: "#E9E9E9",
                  }}
              >
                  <Sidebar workspaceId={workspaceId ?? "Unknown"}/>
                  <Box className="flex-1 w-full">
                    <Conversation id={conversationId}/>
                  </Box>
                  {showPanel ? <RightSidebar workspaceId={workspaceId ?? "Unknown"}/> : null}
              </Paper>
          </Box>
      </main>
  </div>

};

export default MemberIdPage;
